export interface IHistory<T> {
  past: T[]
  future: T[]
}

export function make<T>(): IHistory<T> {
  return {
    past: [],
    future: []
  }
}

export function push<T>(
  history: IHistory<T>,
  current: T,
  maxSize: number = 10
): IHistory<T> {
  const past = [...history.past, current]

  // Clean up
  if (past.length > maxSize) {
    past.splice(0, past.length - maxSize)
  }

  return {
    past,
    future: []
  }
}

export function hasPrevious<T>(history: IHistory<T>): boolean {
  return history.past.length > 0
}

export function hasNext<T>(history: IHistory<T>): boolean {
  return history.future.length > 0
}

export function goBack<T>(
  history: IHistory<T>,
  current: T
): [T | undefined, IHistory<T>] {
  const next = history.past[history.past.length - 1]

  if (!next) {
    return [next, history]
  }

  const nextHistory = {
    past: history.past.slice(0, history.past.length - 1),
    future: [current, ...history.future]
  }

  return [next, nextHistory]
}

export function goForward<T>(
  history: IHistory<T>,
  current: T
): [T | undefined, IHistory<T>] {
  const [next, ...nextFuture] = history.future

  if (!next) {
    return [next, history]
  }

  const nextHistory = {
    past: [...history.past, current],
    future: nextFuture
  }

  return [next, nextHistory]
}
