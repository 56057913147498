import { React, M, withTargetValue, IVariant } from '../common'

interface IProps {
  variant: IVariant
  onChange: (variant: IVariant) => void
}

export default class TitleField extends React.Component<IProps> {
  handleChange = (title: string) => {
    const { variant, onChange } = this.props

    onChange({
      ...variant,
      title
    })
  }

  render() {
    return (
      <M.TextField
        label="Override title"
        // NOTE: We use an uncontrolled input because the round-trip
        // to firebase seems to cause the cursor to jump to the end when editing.
        // Possible React issue. This fix means that the field won't update live.
        defaultValue={this.props.variant.title}
        onChange={withTargetValue(this.handleChange)}
        margin="normal"
        fullWidth
        helperText="Leave blank to use the recipe title"
        style={{ marginBottom: 16 }}
      />
    )
  }
}
