import { React, M, withTargetValue, IVariant } from '../common'

interface IProps {
  variant: IVariant
  onChange: (variant: IVariant) => void
}

export default class NotesField extends React.PureComponent<IProps> {
  handleChange = (notes: string) => {
    const { variant, onChange } = this.props

    onChange({
      ...variant,
      notes
    })
  }

  render() {
    return (
      <M.TextField
        label="Notes"
        // NOTE: We use an uncontrolled input because the round-trip
        // to firebase seems to cause the cursor to jump to the end when editing.
        // Possible React issue. This fix means that the field won't update live.
        defaultValue={this.props.variant.notes || ''}
        multiline
        onChange={withTargetValue(this.handleChange)}
        margin="normal"
        fullWidth
      />
    )
  }
}
