import DraggableList from 'react-draggable-list'
import { React, M, styled, ILineItem } from '../common'
import LineItem from './line-item'
import NewLineItem from './new-line-item'
import { IRecipeRuleset } from '../../../types'

interface IProps {
  instructionId: string
  lineItems: ILineItem[]
  ruleset: IRecipeRuleset
  onSort: (items: ILineItem[]) => void
  onPushItem: (ingredientId: number) => void
  onSetItem: (item: ILineItem) => void
  onDeleteItem: (id: string) => void
  onDeleteInstruction: () => void
}

interface IState {
  hasNewItem: boolean
}

export default class LineItemList extends React.Component<IProps, IState> {
  state: IState = {
    hasNewItem: false
  }

  handleShowNew = () => {
    this.setState({ hasNewItem: true })
  }

  handleHideNew = () => {
    this.setState({ hasNewItem: false })
  }

  handleSaveNew = (ingredientId: number) => {
    const { onPushItem } = this.props

    onPushItem(ingredientId)
    this.setState({ hasNewItem: false })
  }

  render() {
    const { lineItems, onSort, ruleset } = this.props
    const { hasNewItem } = this.state

    return (
      <Container>
        <DraggableList
          itemKey="id"
          template={LineItem}
          list={lineItems}
          onMoveEnd={onSort}
          padding={0}
          springConfig={{
            stiffness: 500,
            damping: 50
          }}
          constrainDrag
          commonProps={{
            onDelete: this.props.onDeleteItem,
            onChange: this.props.onSetItem
          }}
        />

        {hasNewItem && (
          <NewLineItem
            ruleset={ruleset}
            onSave={this.handleSaveNew}
            onCancel={this.handleHideNew}
          />
        )}

        {!hasNewItem && (
          <Actions>
            <AddButton onClick={this.handleShowNew}>Add ingredient</AddButton>
            <DeleteButton onClick={this.props.onDeleteInstruction}>
              Delete instruction
            </DeleteButton>
          </Actions>
        )}
      </Container>
    )
  }
}

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`

const DeleteButton = styled(M.Button).attrs({
  size: 'small',
  variant: 'flat'
})`
  color: #f44336 !important;
  &:hover {
    background-color: #ffe4e2 !important;
  }
`

const AddButton = styled(M.Button).attrs({
  size: 'small',
  variant: 'flat'
})`
  background-color: #e0e0e0 !important;
`

const Container = styled.div`
  flex: 1;
  margin-right: -16px;
`
