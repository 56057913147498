import { React, M, styled, withTargetValue, IVariant } from '../common'

interface IProps {
  variant: IVariant
  onChange: (variant: IVariant) => void
}

export default class CookingMinutesField extends React.PureComponent<IProps> {
  handleChange = (value: string) => {
    const { variant, onChange } = this.props

    onChange({
      ...variant,
      cookingMinutes: parseInt(value)
    })
  }

  render() {
    return (
      <Container>
        <Input
          // NOTE: We use an uncontrolled input because the round-trip
          // to firebase seems to cause the cursor to jump to the end when editing.
          // Possible React issue. This fix means that the field won't update live.
          defaultValue={this.props.variant.cookingMinutes}
          onChange={withTargetValue(this.handleChange)}
        />
        <Label>minutes to cook</Label>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  align-items: baseline;
  margin-left: 16px;
  min-width: 160px;
`

const Input = styled(M.TextField).attrs({
  type: 'number',
  margin: 'normal',
  inputProps: {
    style: {
      textAlign: 'right',
      width: 50
    }
  }
})``

const Label = styled(M.Typography).attrs({
  variant: 'body1'
})``
