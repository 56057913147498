import * as KitchenSupport from '../../kitchen-support'
import { ILineItem, IUnitFamily } from '../../types'
import * as Util from './util'

export interface IReport {
  wasteCount: number
  warnings: string[]
}

export function getReport(
  lineItems: ILineItem[],
  unitFamily: IUnitFamily
): IReport {
  const report: IReport = {
    wasteCount: 0,
    warnings: []
  }

  for (const lineItem of lineItems) {
    const ingredient = KitchenSupport.findIngredient(lineItem.ingredientId)

    if (!ingredient.isPerishable) {
      continue
    }

    const unitAssignment = ingredient.unitAssignments[unitFamily]

    const unit = KitchenSupport.findUnit(unitAssignment.unitId)

    const { packageAmount } = unitAssignment

    if (
      hasRemainder(lineItem.amount, packageAmount) &&
      mustUseWholeIngredient(ingredient.id)
    ) {
      // Warn if the ingredient must only use whole amounts
      const unitName = unit.name == '-' ? ingredient.name : unit.name
      report.warnings.push(
        `${ingredient.name}: use a full ${unitName} to prevent food waste.`
      )
      report.wasteCount += 1
    } else if (!isMultipleOf(lineItem.amount, packageAmount)) {
      report.wasteCount += 1

      // Show warning messages for any lineItems which don't use up at least
      // 1/2 of the package amount for the ingredient, if perishable.
      if (!isMultipleOf(2 * lineItem.amount, packageAmount)) {
        if (isInherentlyWastefulIngredient(ingredient.id)) {
          // Warn about using inherently wasteful ingredients, but don't suggest
          // an amount to use because it is misleading.
          report.warnings.push(
            `${ingredient.name} is a high waste ingredient. Avoid using it whenever possible.`
          )
        } else {
          const unitName = unit.name == '-' ? 'ct' : unit.name
          report.warnings.push(
            `${
              ingredient.name
            } has a package size of ${Util.numberToFractionString(
              packageAmount
            )} ${unitName}. Use multiples of ${Util.numberToPrettyFractionString(
              0.5 * packageAmount
            )} ${unitName} to prevent food waste.`
          )
        }
      }
    }
  }

  return report
}

function isMultipleOf(x: number, y: number): boolean {
  return isSmall(x % y)
}

function isSmall(x: number): boolean {
  return Math.abs(x) < 0.001
}

function hasRemainder(x: number, y: number): boolean {
  return x % y !== 0
}

/**
 * Inherently wasteful ingredients are those which it is not reasonable
 * to use in non-wasteful amounts.
 * NOTE: We are still experimenting with the right solution for these
 * ingredients. To stay flexible, we've avoided solving this the *right*
 * way, by adding a field to the ingredients table. Having that table as
 * the single source of truth for ingredients is cleaner, but this simple
 * hack is more flexible in the short term.
 */
function isInherentlyWastefulIngredient(id: number): boolean {
  const tomatoPasteId = 323
  return id == tomatoPasteId
}

/**
 * For some ingredients, it's reasonable to assume that
 * a fractional remainder will likely be wasted.
 * In the case where were can't change the ingredient's
 * package amount, but want Chefs to use the entire
 * portion, add the ingredient here.
 */
function mustUseWholeIngredient(id: number): boolean {
  const eggplantId = 18
  const tofuId = 56
  return id == eggplantId || id == tofuId
}
