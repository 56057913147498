import { React, styled, M, Variant, IVariant } from '../common'
import { round } from './util'

interface IProps {
  variant: IVariant
}

export default class Nutrition extends React.Component<IProps> {
  render() {
    const { variant } = this.props

    const n = Variant.compileNutrition(variant)

    return (
      <Container>
        <Item>
          <Value>{round(n.energy || 0)}</Value>
          <Label>calories</Label>
        </Item>
        <Item>
          <Value>{round(n.fat || 0, 1)}g</Value>
          <Label>fat</Label>
        </Item>
        <Item>
          <Value>{round(n.protein || 0, 1)}g</Value>
          <Label>protein</Label>
        </Item>
        <Item>
          <Value>{round(n.carbs || 0, 1)}g</Value>
          <Label>carbs</Label>
        </Item>
        <Item>
          <Value>{round(n.fiber || 0, 1)}g</Value>
          <Label>fiber</Label>
        </Item>
        <Item>
          <Value>{round(n.sugars || 0, 1)}g</Value>
          <Label>sugar</Label>
        </Item>
        <Item>
          <Value>{round(n.cholesterol || 0, 1)}mg</Value>
          <Label>cholesterol</Label>
        </Item>
        <Item>
          <Value>{round(n.sodium || 0, 1)}mg</Value>
          <Label>sodium</Label>
        </Item>
        <Item>
          <Value>{round(n.saturated || 0, 1)}mg</Value>
          <Label>saturated</Label>
        </Item>
      </Container>
    )
  }
}

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #e3e3e3 !important; */
  width: 70px;
  height: 60px;
  /* border-radius: 40px !important; */
  justify-content: center;
`

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  /* background-color: #303aa5 !important; */
  /* margin-bottom: 12px; */
  /* margin-top: 12px; */
  position: fixed;
  top: 4px;
  right: 80px;
  z-index: 1100;
  /* width: 408px; */
  /* height: 80px; */
  /* padding: 16px; */
  /* padding-bottom: 0; */
`

const Value = styled(M.Typography).attrs({
  variant: 'title'
})`
  color: white !important;
  font-size: 14px !important;
`

const Label = styled(M.Typography).attrs({
  variant: 'caption'
})`
  color: white !important;
`
