import * as React from 'react'
import { IUser, IStore, IAuth, IUserMap } from './types'
import App from './app'
import SignIn from './sign-in'
import Loading from './loading'
import Subscribe from './app/views/subscribe'
import VersionChecker from './app/views/version-checker'

interface IProps {
  store: IStore
}

export default class Root extends React.Component<IProps> {
  renderContent = (userId: string) => (isLoaded: boolean, users?: IUser[]) => {
    if (isLoaded) {
      const userMap: IUserMap = {}
      for (const user of users || []) {
        userMap[user.id] = user
      }

      const currentUser = userMap[userId]

      if (!currentUser) {
        throw new Error('Current user does not exist')
      }

      return (
        <App
          currentUser={currentUser}
          users={userMap}
          store={this.props.store}
        />
      )
    } else {
      return <Loading />
    }
  }

  renderAuth = (isLoaded: boolean, auth?: IAuth) => {
    if (isLoaded) {
      if (auth) {
        return (
          <Subscribe
            subscription={this.props.store.subscribeToUsers}
            param
            render={this.renderContent(auth.userId)}
          />
        )
      } else {
        return <SignIn onSignIn={this.props.store.signIn} />
      }
    } else {
      return <Loading />
    }
  }

  render() {
    return (
      <>
        <Subscribe
          subscription={this.props.store.subscribeToAuthState}
          param
          render={this.renderAuth}
        />
        <VersionChecker store={this.props.store} />
      </>
    )
  }
}
