import { React, M, styled, withTargetValue } from '../common'
import { INewRecipeParams } from './types'
import { IRecipeRuleset } from '../../../types'

interface IProps {
  onClose: () => void
  onSubmit: (params: INewRecipeParams) => void
}

type IState = INewRecipeParams

export default class NewRecipe extends React.Component<IProps, IState> {
  state: IState = {
    title: '',
    ruleset: 'dinner'
  }

  handleCancel = () => {
    this.props.onClose()
  }

  handleChangeLabel = (title: string) => {
    this.setState({ title })
  }

  handleChangeruleset = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ ruleset: event.target.value as IRecipeRuleset })
  }

  handleSubmit = async () => {
    this.props.onSubmit(this.state)
  }

  render() {
    const { title, ruleset } = this.state

    return (
      <Container>
        <Title>New Recipe</Title>

        <Input
          value={title}
          onChange={withTargetValue(this.handleChangeLabel)}
        />
        <M.Select
          value={ruleset}
          onChange={this.handleChangeruleset}
          displayEmpty
          style={{ marginBottom: 16 }}
          fullWidth
        >
          <M.MenuItem value={'dinner'}>Dinner</M.MenuItem>
          <M.MenuItem value={'breakfast'}>Breakfast</M.MenuItem>
          <M.MenuItem value={'dessert'}>Dessert</M.MenuItem>
          <M.MenuItem value={'simple'}>Super Simple</M.MenuItem>
          <M.MenuItem value={'snack'}>Snack</M.MenuItem>
          <M.MenuItem value={'cpg'}>CPG</M.MenuItem>
        </M.Select>

        <Actions>
          <CancelButton onClick={this.handleCancel}>Cancel</CancelButton>

          <SubmitButton disabled={!title} onClick={this.handleSubmit}>
            OK
          </SubmitButton>
        </Actions>
      </Container>
    )
  }
}

const Title = styled(M.Typography).attrs({
  variant: 'title'
})``

const Actions = styled.div`
  display: flex;
`

const CancelButton = styled(M.Button).attrs({
  color: 'secondary',
  variant: 'contained'
})`
  margin: 0 8px !important;
`

const SubmitButton = styled(M.Button).attrs({
  color: 'primary',
  variant: 'contained'
})`
  margin: 0 8px !important;
`

const Container = styled(M.Paper).attrs({
  elevation: 4
})`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
`

const Input = styled(M.TextField).attrs({
  autoFocus: true,
  placeholder: 'Title...'
})`
  width: 300px;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
`
