import * as React from 'react'
import * as M from '@material-ui/core'
import styled from 'styled-components'
import Icon from './icon'

export default class FabPlus extends React.Component<{
  onClick: () => void
}> {
  render() {
    return (
      <Fab>
        <Button onClick={this.props.onClick}>
          <Icon.Add />
        </Button>
      </Fab>
    )
  }
}

const Fab = styled.div`
  position: fixed;
  bottom: 12px;
  right: 12px;
`

const Button = styled(M.Button).attrs({
  variant: 'fab',
  color: 'primary'
})``
