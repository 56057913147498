import { IVariant, IInstruction, ILineItem } from '../../types'
import * as KitchenSupport from '../../kitchen-support'
import * as Variant from './variant'

/**
 * Returns true if the variant has an ingredients that
 * have gluten.
 */
export function variantHasGluten(variant: IVariant): boolean {
  for (const ingredientId of getAllIngredientIds(variant)) {
    if (ingredientHasGluten(ingredientId)) {
      return true
    }
  }

  return false
}

/**
 * Returns true if the variant can be made gluten free by substituting
 * ingredients.
 */
export function canMakeVariantGlutenFree(variant: IVariant): boolean {
  // If any ingredient has gluten and there is no substitution, then
  // we *cannot* make this variant gluten-free.
  for (const ingredientId of getAllIngredientIds(variant)) {
    if (ingredientHasGluten(ingredientId) && !SUBSTITUTIONS[ingredientId]) {
      return false
    }
  }

  return true
}

/**
 * Make a gluten-free copy of the variant by substituting ingredients.
 * NOTE: We only apply substitutions blindly here, without verifying that
 * the new variant *is* fully gluten-free.
 */
export function convertVariantToGlutenFree(variant: IVariant): IVariant {
  return {
    ...variant,
    // Append a tag to the label so that we can uniquely identify
    // auto-gluten-free variants in admin.
    label: variant.label + ' auto-gluten-free',
    // Ensure that auto-gluten-free variants are ranked last by
    // adding a large value.
    // HACK: This is a bit fragile. Ideally we would instead find the max
    // rank in the recipe, but that would be pretty messy.
    rank: variant.rank + 100,
    // Convert instructions to gluten free by replacing known convertable
    // ingredients.
    instructions: variant.instructions.map(convertInstructionToGlutenFree)
  }
}

function convertInstructionToGlutenFree(
  instruction: IInstruction
): IInstruction {
  return {
    ...instruction,
    lineItems: instruction.lineItems.map(convertLineItemToGlutenFree)
  }
}

function convertLineItemToGlutenFree(lineItem: ILineItem): ILineItem {
  // Substituted ingredients all have the same amount/units, so we only
  // need to swap the ingredien id.
  const substituteIngredientId = SUBSTITUTIONS[lineItem.ingredientId]

  return {
    ...lineItem,
    ingredientId: substituteIngredientId || lineItem.ingredientId
  }
}

function getAllIngredientIds(variant: IVariant): number[] {
  return Variant.getAllLineItems(variant).map(li => li.ingredientId)
}

function ingredientHasGluten(ingredientId: number): boolean {
  const ingredient = KitchenSupport.findIngredient(ingredientId)
  return ingredient.restrictionViolations.indexOf(GLUTEN_RESTRICTION_ID) >= 0
}

const GLUTEN_RESTRICTION_ID = 1

/**
 * Mapping from glutenous ingredient id to gluten-free substitution.
 * See https://docs.google.com/spreadsheets/d/1hSg07P_TjonIuJzrgJ_uAS0twdhYyjnn2l7CfO5zaqs/edit#gid=0
 * NOTE: This should probably be moved to the database to maintain a single source
 * of truth for ingredients, but while we are testing this out, it is more flexible here.
 */
const SUBSTITUTIONS: { [id: number]: number | undefined } = {
  116: 271,
  299: 300,
  58: 301,
  61: 62,
  119: 211,
  63: 64,
  66: 67,
  213: 321,
  53: 54,
  59: 60,
  191: 266,
  128: 127
}
