import { React, styled, M, Util, Recipe, IRecipe } from '../common'

interface IProps {
  recipe: IRecipe
}

export default class StateLine extends React.PureComponent<IProps> {
  render() {
    const { recipe } = this.props

    if (recipe.isDeleted) {
      return (
        <Content>
          <M.Chip label="Archived" />
        </Content>
      )
    }

    switch (Recipe.state(recipe)) {
      case 'draft':
        return (
          <Content>
            <M.Chip label="Draft" />
          </Content>
        )
      case 'published':
        return (
          <Content>
            <M.Chip label="Published" />
            <Details>
              Published on {Util.formatDate(new Date(recipe.publishedAt || 0))}.
            </Details>
          </Content>
        )
      case 'unpublished-changes':
        return (
          <Content>
            <M.Chip label="Unpublished Changes" />
            <Details>
              Last published on{' '}
              {Util.formatDate(new Date(recipe.publishedAt || 0))}
              <br />
              Updated on {Util.formatDate(new Date(recipe.updatedAt))}
            </Details>
          </Content>
        )
    }
  }
}

const Content = styled.div``

const Details = styled(M.Typography).attrs({ variant: 'caption' })`
  margin-top: 8px !important;
`
