import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import AlgoliaSearch = require('algoliasearch')
import * as Config from './config'

import {
  IUser,
  IRecipe,
  IVariant,
  ICallable,
  IStore,
  ISignInError,
  IInstructionSearchResult,
  IAuth,
  IUserData,
  IRemoteConfig
} from './types'

export default class Store implements IStore {
  private app: firebase.app.App
  private secondaryApp: firebase.app.App
  private store: firebase.firestore.Firestore
  private instructionIndex: AlgoliaSearch.Index

  constructor() {
    this.app = findOrCreateApp('[DEFAULT]')
    // Secondary app used to allow us to create a user without logging in as them
    this.secondaryApp = findOrCreateApp('secondary')
    this.store = firebase.firestore()

    this.instructionIndex = AlgoliaSearch(
      Config.ALGOLIA_APP_ID,
      Config.ALGOLIA_KEY
    ).initIndex('instruction_snippets')
  }

  signIn = async (email: string, password: string): Promise<ISignInError> => {
    try {
      const auth = await this.app
        .auth()
        .signInWithEmailAndPassword(email, password)
      if (auth.user) {
        return
      } else {
        console.log('No user in auth')
        return 'unexpected-error'
      }
    } catch (e) {
      if (typeof e != 'object') {
        console.log('Invalid error object returned', e)
        return 'unexpected-error'
      }

      switch (e.code) {
        case 'auth/invalid-email':
          return 'invalid-email'
        case 'auth/wrong-password':
          return 'wrong-password'
      }

      console.log('No code in error', e.code)
      return 'unexpected-error'
    }
  }

  signOut = (): Promise<void> => {
    return this.app.auth().signOut()
  }

  uploadFile = async (path: string, file: File): Promise<string> => {
    const ref = this.app
      .storage()
      .ref()
      .child(path)
    const task = ref.put(file)
    const snapshot = await task

    const url = await snapshot.ref.getDownloadURL()
    return url
  }

  subscribeToAuthState = () => (fn: (auth?: IAuth) => void): ICallable => {
    return this.app.auth().onAuthStateChanged(user => {
      if (user) {
        fn({ userId: user.uid })
      } else {
        fn()
      }
    })
  }

  subscribeToUsers = () => (fn: (users?: IUser[]) => void): ICallable => {
    return this.store.collection('users').onSnapshot(coll => {
      const users = coll.docs.map(d => d.data() as IUser)
      fn(users)
    })
  }

  subscribeToRecipes = () => (fn: (recipes?: IRecipe[]) => void): ICallable => {
    return this.store.collection('recipes').onSnapshot(coll => {
      const recipes = coll.docs.map(d => d.data() as IRecipe)
      fn(recipes)
    })
  }

  subscribeToRecipe = (id: string) => (
    fn: (recipe?: IRecipe) => void
  ): ICallable => {
    return this.store
      .collection('recipes')
      .doc(id)
      .onSnapshot(doc => {
        if (doc.exists) {
          fn(doc.data() as IRecipe)
        } else {
          fn()
        }
      })
  }

  subscribeToRecipeVariants = (id: string) => (
    fn: (variants?: IVariant[]) => void
  ): ICallable => {
    return this.store
      .collection('variants')
      .where('recipeId', '==', id)
      .onSnapshot(ss => {
        const variants = ss.docs.map(doc => doc.data() as IVariant)
        fn(variants)
      })
  }

  subscribeToVariant = (id: string) => (
    fn: (variant?: IVariant) => void
  ): ICallable => {
    return this.store
      .collection('variants')
      .doc(id)
      .onSnapshot(doc => {
        if (doc.exists) {
          fn(doc.data() as IVariant)
        } else {
          fn()
        }
      })
  }

  subscribeToRemoteConfig = () => (
    fn: (config?: IRemoteConfig) => void
  ): ICallable => {
    return this.store
      .collection('config')
      .doc('config')
      .onSnapshot(doc => {
        if (doc.exists) {
          fn(doc.data() as IRemoteConfig)
        } else {
          fn()
        }
      })
  }

  setRecipe(recipe: IRecipe): Promise<void> {
    return this.store
      .collection('recipes')
      .doc(recipe.id)
      .set(recipe)
  }

  touchRecipe(id: string): Promise<void> {
    return this.store
      .collection('recipes')
      .doc(id)
      .update({ updatedAt: Date.now() })
  }

  setVariant(variant: IVariant): Promise<void> {
    return this.store
      .collection('variants')
      .doc(variant.id)
      .set(variant)
  }

  setVariants(variants: IVariant[]): Promise<void> {
    const batch = this.store.batch()
    for (const variant of variants) {
      const ref = this.store.collection('variants').doc(variant.id)
      batch.set(ref, variant)
    }
    return batch.commit()
  }

  async searchInstructions(query: string): Promise<IInstructionSearchResult[]> {
    const response = await this.instructionIndex.search(query)

    return response.hits.map(hit => ({
      id: hit.objectID,
      message: hit.message,
      highlighted: hit._highlightResult.message.value,
      count: hit.count
    }))
  }

  async createUser(userData: IUserData): Promise<boolean> {
    try {
      const userCred = await this.secondaryApp
        .auth()
        .createUserWithEmailAndPassword(userData.email, userData.password)

      if (!userCred.user) {
        return false
      }

      const id = userCred.user.uid
      await this.store
        .collection('users')
        .doc(id)
        .set({
          id,
          displayName: userData.displayName,
          email: userData.email
        })
      return true
    } catch (e) {
      console.log('Error creating user', userData, e)
      return false
    }
  }
}

function findOrCreateApp(appName: string): firebase.app.App {
  const config = getFirebaseConfig()

  if (!firebase.apps.length) {
    return firebase.initializeApp(config, appName)
  }

  const app = firebase.apps.find(app => !!app && app.name == appName)

  if (app) {
    return app
  } else {
    return firebase.initializeApp(config, appName)
  }
}

function getFirebaseConfig(): IFirebaseConfig {
  return {
    apiKey: Config.FIREBASE_API_KEY,
    authDomain: Config.FIREBASE_AUTH_DOMAIN,
    databaseURL: Config.FIREBASE_DATABASE_URL,
    projectId: Config.FIREBASE_PROJECT_ID,
    storageBucket: Config.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: Config.FIREBASE_MESSAGING_SENDER_ID
  }
}

interface IFirebaseConfig {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
}
