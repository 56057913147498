import { INutrition, ILineItem, IUnitFamily } from './types'
import * as Unit from './unit'
import * as Ingredient from './ingredient'

export function compile(
  lineItems: ILineItem[],
  unitFamily: IUnitFamily,
  servings: number
): INutrition {
  const nutrition: INutrition = {}

  for (const li of lineItems) {
    const ing = Ingredient.find(li.ingredientId)

    const unitAssignment = ing.unitAssignments[unitFamily]
    const unit = Unit.find(unitAssignment.unitId)
    const amountInBase = unitAssignment.amountInBase || unit.amountInBase || 0
    const densityCorrection = unit.isVolumeBased ? ing.density || 0 : 1
    const scale = (amountInBase * densityCorrection) / (100 * servings)

    for (const f in ing.nutrition) {
      const amountInG = li.amount * (ing.nutrition[f] || 0) * scale

      nutrition[f] = (nutrition[f] || 0) + amountInG
    }
  }

  return nutrition
}
