import * as React from 'react'
import Router from '../typerouter'
import Recipe from './scenes/recipe'
import RecipeList from './scenes/recipe-list'
import Variant from './scenes/variant'
import Team from './scenes/team'
import Profile from './scenes/profile'
import Preview from './scenes/preview'
import RecipeStats from './scenes/recipe-stats'
import RecipeChecker from './scenes/recipe-checker'
import Drawer from './views/drawer'
import ErrorPage from './views/error-page'
import NotFoundPage from './views/not-found-page'
import { IUser, IStore, IUserMap } from '../types'

interface IProps {
  currentUser: IUser
  users: IUserMap
  store: IStore
}

interface IState {
  drawer: boolean
}

export default class App extends React.Component<IProps, IState> {
  state: IState = {
    drawer: false
  }

  toggleDrawer = () => {
    this.setState({ drawer: !this.state.drawer })
  }

  renderDrawer = (router: Router) => {
    return (
      <Drawer
        isOpen={this.state.drawer}
        onClose={this.toggleDrawer}
        nav={router}
        currentUser={this.props.currentUser}
        store={this.props.store}
      />
    )
  }

  render() {
    const { currentUser, store, users } = this.props

    return (
      <Router
        initialRoute={{ path: 'RecipeList', params: {} }}
        routeMap={{
          RecipeList,
          Recipe,
          Variant,
          Team,
          Profile,
          Preview,
          RecipeStats,
          RecipeChecker
        }}
        getSceneProps={nav => {
          return {
            currentUser,
            users,
            nav,
            store,
            drawer: this.renderDrawer(nav),
            onToggleDrawer: this.toggleDrawer
          }
        }}
        renderNotFound={nav => <NotFoundPage nav={nav} />}
        renderError={nav => <ErrorPage nav={nav} />}
      />
    )
  }
}
