import { React, styled, M, withTargetValue, IRecipe } from '../common'
import VarietyTagsList from './variety-tags-list'
import { IRecipeRuleset } from '../../../types'

interface IProps {
  recipe: IRecipe
  onChange: (recipe: IRecipe) => void
  onClose: () => void
}

interface IState {
  recipe: IRecipe
}

export default class EditTitle extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      recipe: {
        ...props.recipe
      }
    }
  }

  submit = () => {
    this.props.onChange(this.state.recipe)
    this.props.onClose()
  }

  cancel = () => {
    this.props.onClose()
  }

  setRecipe = (recipe: IRecipe) => {
    this.setState({ recipe })
  }

  changeTitle = (title: string) => {
    this.setRecipe({
      ...this.state.recipe,
      title
    })
  }

  handleChangeruleset = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { recipe } = this.state
    this.setRecipe({ ...recipe, ruleset: event.target.value as IRecipeRuleset })
  }

  render() {
    const { recipe } = this.state

    const titleLength = recipe.title.length

    return (
      <Container>
        <Title>Edit Recipe</Title>

        <Input
          value={recipe.title}
          onChange={withTargetValue(this.changeTitle)}
          label="Title"
          helperText={`${titleLength} characters`}
          error={titleLength < 25 || titleLength > 75}
          autoFocus
        />

        <VarietyTagsList recipe={recipe} onChange={this.setRecipe} />

        <M.Select
          value={recipe.ruleset}
          onChange={this.handleChangeruleset}
          displayEmpty
          style={{ marginBottom: 16 }}
          fullWidth
        >
          <M.MenuItem value={'dinner'}>Dinner</M.MenuItem>
          <M.MenuItem value={'breakfast'}>Breakfast</M.MenuItem>
          <M.MenuItem value={'dessert'}>Dessert</M.MenuItem>
          <M.MenuItem value={'simple'}>Simple</M.MenuItem>
          <M.MenuItem value={'snack'}>Snack</M.MenuItem>
          <M.MenuItem value={'cpg'}>CPG</M.MenuItem>
        </M.Select>

        <Actions>
          <CancelButton onClick={this.props.onClose}>Cancel</CancelButton>

          <SubmitButton disabled={!recipe.title} onClick={this.submit}>
            OK
          </SubmitButton>
        </Actions>
      </Container>
    )
  }
}

const Title = styled(M.Typography).attrs({
  variant: 'title'
})``

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`

const CancelButton = styled(M.Button).attrs({
  color: 'secondary',
  variant: 'contained'
})`
  margin: 0 8px !important;
`

const SubmitButton = styled(M.Button).attrs({
  color: 'primary',
  variant: 'contained'
})`
  margin: 0 8px !important;
`

const Container = styled(M.Paper).attrs({
  elevation: 4
})`
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  padding: 32px;
  width: 500px;
`

const Input = styled(M.TextField).attrs({
  fullWidth: true
})`
  margin-top: 16px !important;
  margin-bottom: 16px !important;
`
