import { React, M, IVariant, styled } from '../common'

interface IProps {
  variant: IVariant
  onUpload: () => void
  onRemove: () => void
}

export default class TitleField extends React.Component<IProps> {
  render() {
    const { variant, onUpload, onRemove } = this.props

    const { imageUrl } = variant

    return (
      <Container>
        <Title>Override image</Title>

        {imageUrl && <Image src={imageUrl} />}

        {!imageUrl && (
          <div>
            <Body>No override image. Using the recipe's image.</Body>
          </div>
        )}

        <Actions>
          <UploadButton onClick={onUpload}>Upload an Image</UploadButton>

          {imageUrl && (
            <RemoveButton onClick={onRemove}>Remove Image</RemoveButton>
          )}
        </Actions>
      </Container>
    )
  }
}

const Container = styled.div`
  margin-top: 8px;
`

const Image = styled.img`
  width: 300px;
  height: 300px;
`

const Body = styled(M.Typography).attrs({
  variant: 'body1'
})``

const Title = styled(M.Typography).attrs({
  variant: 'subheading'
})``

const RemoveButton = styled(M.Button).attrs({
  variant: 'contained',
  color: 'secondary'
})`
  margin-left: 8px !important;
`

const UploadButton = styled(M.Button).attrs({
  variant: 'contained'
})``

const Actions = styled.div`
  margin-top: 8px;
`
