import { IVariant, IRecipeRuleset } from '../../../types'
import * as InstructionChecks from './instruction-checks'
import { Variant } from '../common'

export interface IVariantReport {
  errors: string[]
  instructions: { [id: string]: string[] }
}

export function check(
  variant: IVariant,
  ruleset: IRecipeRuleset
): IVariantReport {
  const isBreakfast = ruleset == 'breakfast'
  const isDessert = ruleset == 'dessert'
  const isSimple = ruleset == 'simple'
  const isSnack = ruleset == 'snack'
  const errors: string[] = []

  // Title length
  if (
    variant.title.length > 0 &&
    (variant.title.length < 25 || variant.title.length > 70)
  ) {
    errors.push(
      `Title is ${variant.title.length} characters long. It should be between 25-70 characters`
    )
  }

  // Cooking tip length
  if (variant.cookingTip && variant.cookingTip?.length > 200) {
    errors.push(
      `Cooking tip ${variant.cookingTip?.length} characters long. It should be no more than 175-200 characters`
    )
  }

  // Category presence
  if (!variant.category) {
    errors.push(`Category is missing`)
  }

  // Allowed menu count
  if (variant.allowedMenuIds.length == 0) {
    errors.push(`No allowed menus chosen`)
  }

  // Cookware count
  const numCookwares = variant.cookwareIds.length
  if (numCookwares == 0) {
    errors.push('No cookwares selected')
  }

  if (numCookwares == 1) {
    errors.push(`Only 1 cookware selected`)
  }

  // Cooking time
  if (isBreakfast) {
    if (variant.cookingMinutes < 5 || variant.cookingMinutes > 30) {
      errors.push(
        `Cooking time is ${variant.cookingMinutes} minutes. It should be between 5-30 minutes`
      )
    }
  } else if (isDessert) {
    if (variant.cookingMinutes < 10 || variant.cookingMinutes > 45) {
      errors.push(
        `Cooking time is ${variant.cookingMinutes} minutes. It should be between 10-45 minutes`
      )
    }
  } else if (isSimple) {
    if (variant.cookingMinutes < 15 || variant.cookingMinutes > 45) {
      errors.push(
        `Cooking time is ${variant.cookingMinutes} minutes. It should be between 15-45 minutes`
      )
    }
  } else if (isSnack) {
    if (variant.cookingMinutes < 5 || variant.cookingMinutes > 45) {
      errors.push(
        `Cooking time is ${variant.cookingMinutes} minutes. It should be between 5-45 minutes`
      )
    }
  } else {
    if (variant.cookingMinutes < 20 || variant.cookingMinutes > 45) {
      errors.push(
        `Cooking time is ${variant.cookingMinutes} minutes. It should be between 20-45 minutes`
      )
    }
  }

  // Instruction count
  {
    const instructionCount = variant.instructions.length
    if (isBreakfast) {
      if (instructionCount < 4 || instructionCount > 12) {
        errors.push(
          `There are ${instructionCount} instructions. Should be between 4-12.`
        )
      }
    } else if (isDessert || isSnack) {
      if (instructionCount < 3 || instructionCount > 14) {
        errors.push(
          `There are ${instructionCount} instructions. Should be between 3-14.`
        )
      }
    } else if (isSimple) {
      if (instructionCount < 5 || instructionCount > 14) {
        errors.push(
          `There are ${instructionCount} instructions. Should be between 5-14.`
        )
      }
    } else {
      if (instructionCount < 10 || instructionCount > 16) {
        errors.push(
          `There are ${instructionCount} instructions. Should be between 10-16.`
        )
      }
    }
  }

  // Calories
  {
    const calories = Variant.compileNutrition(variant).energy || 0
    if (isBreakfast) {
      if (calories < 200 || calories > 500) {
        errors.push(
          `Calories should be between 200-500 per serving, but it is ${Math.round(
            calories
          )}.`
        )
      }
    } else if (isDessert) {
      if (calories < 200 || calories > 750) {
        errors.push(
          `Calories should be between 200-750 per serving, but it is ${Math.round(
            calories
          )}.`
        )
      }
    } else if (isSnack) {
      if (calories < 150 || calories > 360) {
        errors.push(
          `Calories should be between 150-360 per serving, but it is ${Math.round(
            calories
          )}.`
        )
      }
    } else {
      if (calories < 500 || calories > 700) {
        errors.push(
          `Calories should be between 500-700 per serving, but it is ${Math.round(
            calories
          )}.`
        )
      }
    }
  }

  const instructions: { [id: string]: string[] } = {}

  for (const instruction of variant.instructions) {
    instructions[instruction.id] = InstructionChecks.check(instruction)
  }

  return {
    errors,
    instructions
  }
}

export function isEmptyReport(report: IVariantReport): boolean {
  for (const id in report.instructions) {
    if (report.instructions[id].length > 0) {
      return false
    }
  }

  return report.errors.length == 0
}
