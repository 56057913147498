import { React, styled, M, IUser, Avatar, Util } from '../common'

interface IProps {
  user: IUser
  createdAt: number
}

export default class AuthorLine extends React.PureComponent<IProps> {
  render() {
    const { user, createdAt } = this.props

    return (
      <Container>
        <Avatar user={user} size={40} />
        <Content>
          <M.Typography variant="body2">
            Created by <strong>{user.displayName}</strong>
          </M.Typography>
          <M.Typography variant="caption">
            {Util.formatDate(new Date(createdAt))}
          </M.Typography>
        </Content>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  min-width: 260px;
  margin-top: 24px;
  margin-bottom: 16px;
`

const Content = styled.div`
  margin-left: 8px;
`
