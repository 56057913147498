import * as Publish from '../../services/publish'
import * as ProRecipeAssignment from '../../services/pro-recipe-assignment'
import {
  React,
  styled,
  M,
  withTargetValue,
  Recipe,
  Admin,
  IRecipe,
  IVariant,
  IStore,
  IUser
} from '../common'

interface IProps {
  currentUser: IUser
  store: IStore
  recipe: IRecipe
  variants: IVariant[]
  onClose: () => void
}

interface IState {
  note: string
  isPublishing: boolean
}

export default class PublishModal extends React.Component<IProps, IState> {
  state: IState = {
    note: '',
    isPublishing: false
  }

  errors = Publish.validate(this.props.recipe, this.props.variants)

  handleCancel = () => {
    this.props.onClose()
  }

  handleChangeNote = (note: string) => {
    this.setState({ note })
  }

  handleSubmit = async () => {
    const { store, recipe, variants, currentUser, onClose } = this.props
    const { note } = this.state

    this.setState({ isPublishing: true })

    try {
      // Assign pro status to unpublished recipes.
      if (!recipe.publishedAt) {
        recipe.isPro = ProRecipeAssignment.shouldMakeRecipePro(recipe, variants)
      }

      const payload = Publish.makePayload({
        recipe,
        variants,
        userId: currentUser.id,
        note: `${currentUser.displayName}: ${note || 'Initial upload'}`
      })

      await Admin.publishRecipe(payload)

      store.setRecipe(Recipe.markPublished(recipe))
    } catch (e) {
      console.error('Error publishing recipe', e)
      alert('Error publishing recipe')
    }

    onClose()

    this.setState({ isPublishing: false })
  }

  renderErrors() {
    return (
      <div>
        <ErrorTitle>Please fix the following errors:</ErrorTitle>
        <ul>
          {this.errors.map(error => (
            <li>
              <Body>{error}</Body>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  renderNoteInput() {
    return (
      <Input
        value={this.state.note}
        onChange={withTargetValue(this.handleChangeNote)}
        label="What's changed?"
        helperText="Briefly describe the changes you've made to this recipe."
        autoFocus
        multiline
      />
    )
  }

  renderDraft() {
    return <Body>Are you sure you want to publish this recipe?</Body>
  }

  render() {
    const { recipe } = this.props
    const { note, isPublishing } = this.state

    const isDraft = !recipe.publishedAt
    const hasErrors = this.errors.length > 0

    // Can't publish if there are errors.
    // Require a note unless this is the first publish.
    const isDisabled = hasErrors || (!note && !isDraft)

    return (
      <Container>
        <Title>{isDraft ? 'Publish' : 'Publish changes'}</Title>

        {hasErrors
          ? this.renderErrors()
          : isDraft
          ? this.renderDraft()
          : this.renderNoteInput()}

        <Actions>
          <CancelButton onClick={this.props.onClose}>Cancel</CancelButton>

          <SubmitButton disabled={isDisabled} onClick={this.handleSubmit}>
            {isPublishing ? 'Publishing...' : 'Publish'}
          </SubmitButton>
        </Actions>
      </Container>
    )
  }
}

const Title = styled(M.Typography).attrs({
  variant: 'title'
})``

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`

const CancelButton = styled(M.Button).attrs({
  color: 'secondary',
  variant: 'contained'
})`
  margin: 0 8px !important;
`

const SubmitButton = styled(M.Button).attrs({
  color: 'primary',
  variant: 'contained'
})`
  margin: 0 8px !important;
`

const Container = styled(M.Paper).attrs({
  elevation: 4
})`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
  width: 500px;
`

const Input = styled(M.TextField).attrs({
  fullWidth: true
})`
  margin-top: 16px !important;
  margin-bottom: 16px !important;
`

const ErrorTitle = styled(M.Typography).attrs({
  variant: 'body2'
})``

const Body = styled(M.Typography).attrs({
  variant: 'body1'
})``
