import { IVariant, IRecipe } from '../../types'

export function shouldMakeRecipePro(recipe: IRecipe, variants: IVariant[]) {
  // Dessert recipes are always pro.
  if (recipe.ruleset == 'dessert') {
    return true
  }

  const hasVeganVariant = !!variants.find(isVeganVariant)

  // Assign pro to 40% of recipes, but 0% of recipes with vegan
  // variants (we don't have enough free vegan recipes).
  return hasVeganVariant ? false : Math.random() < 0.4
}

function isVeganVariant(variant: IVariant): boolean {
  return variant.allowedMenuIds.indexOf(VEGAN_MENU_ID) >= 0
}

const VEGAN_MENU_ID = 6
