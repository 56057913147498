import { DraggableProvided } from 'react-beautiful-dnd'
import {
  React,
  styled,
  M,
  withTargetValue,
  Icon,
  IInstruction,
  ILineItem,
  IInstructionSearchResult
} from '../common'
import * as Instructions from '../../services/instruction'
import { debounce } from './util'
import RootRef from '@material-ui/core/RootRef'
import LineItemList from './line-item-list'
import SuggestedMessage from './suggested-message'
import { IRecipeRuleset } from '../../../types'

interface IProps {
  instruction: IInstruction
  ruleset: IRecipeRuleset
  number: number
  provided: DraggableProvided
  isExpanded: boolean
  onDelete: (id: string) => void
  onToggle: (id: string, isExpanded: boolean) => void
  onChange: (instruction: IInstruction) => void
  getSuggestedInstructions: (msg: string) => Promise<IInstructionSearchResult[]>
}

interface IState {
  newIngredient: string
  message: string
  suggestedMessages: IInstructionSearchResult[]
  suggestedLineItemIds: string[]
}

export default class Instruction extends React.PureComponent<IProps, IState> {
  inputRef?: HTMLTextAreaElement

  state: IState = {
    newIngredient: '',
    suggestedLineItemIds: [],
    suggestedMessages: [],
    message: this.props.instruction.message
  }

  // Make sure undo/redo changes the message.
  UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.instruction.message != this.state.message) {
      this.setState({ message: nextProps.instruction.message })
    }
  }

  handleDelete = () => {
    const { instruction, onDelete } = this.props
    onDelete(instruction.id)
  }

  clearSuggestedMessages = () => {
    this.setState({ suggestedMessages: [] })
  }

  setMessage = (message: string) => {
    this.setState({ message }, this.saveMessage)
  }

  // HACK: There is a subtle bug that causes the cursor to jump based on
  // timing. Debouncing at 1s (or more) makes this very unlikely to happen,
  // but is definitely a hack. The timing issue happens because saves to
  // firebase are delayed, and we don't have a good way to differentiate between
  // local external changes (e.g. history), vs. remote changes.
  saveMessage = debounce(() => {
    const { instruction, onChange } = this.props
    onChange(Instructions.setMessage(instruction, this.state.message))
  }, 1000)

  updateSuggestedMessages = debounce(async () => {
    const results = await this.props.getSuggestedInstructions(
      this.state.message
    )
    this.setState({ suggestedMessages: results.slice(0, 30) })
  }, 200)

  handleChangeMessage = (message: string) => {
    this.setMessage(message)

    if (message.length < 3 || message.length > 40) {
      this.clearSuggestedMessages()
    } else {
      this.updateSuggestedMessages()
    }
  }

  handlePushLineItem = (ingredientId: number) => {
    const { instruction, onChange } = this.props
    onChange(Instructions.pushLineItem(instruction, ingredientId))
  }

  handleSetLineItem = (item: ILineItem) => {
    const { instruction, onChange } = this.props
    onChange(Instructions.setLineItem(instruction, item))
  }

  handleDeleteLineItem = (id: string) => {
    const { instruction, onChange } = this.props
    onChange(Instructions.deleteLineItem(instruction, id))
  }

  handleSortLineItems = (items: ILineItem[]) => {
    const { instruction, onChange } = this.props
    onChange(Instructions.setLineItems(instruction, items))
  }

  handleToggle = () => {
    const { instruction, isExpanded } = this.props
    if (!isExpanded) {
      this.props.onToggle(instruction.id, true)
    }
  }

  handleClickExpand = () => {
    const { instruction, isExpanded } = this.props
    this.props.onToggle(instruction.id, !isExpanded)
  }

  handleBlurInput = () => {
    // Clear suggested messages
    this.clearSuggestedMessages()

    // Always save the value that is in the field when blurred,
    // so that changes from things like grammar extensions get
    // saved (eventually).
    // NOTE: For some reason the "debounce" breaks saving this,
    // so doing it manually...
    const { instruction, onChange } = this.props

    const ref = this.inputRef
    if (ref) {
      const message = ref.value

      // NOTE: Blur fires at unexpected times and we don't
      // want to update the variant without needing to
      // (waste of bandwidth, and also marks the recipe as having
      // changes).
      if (ref.value != message) {
        this.setState({ message })
        onChange(Instructions.setMessage(instruction, message))
      }
    }
  }

  handleSelectSuggestedMessage = (message: string) => {
    this.clearSuggestedMessages()
    this.setMessage(message)
  }

  renderSuggestedMessages() {
    const { suggestedMessages } = this.state

    if (suggestedMessages.length == 0) {
      return null
    }

    return (
      <SuggestedMessageList>
        {this.state.suggestedMessages.map((suggestion, i) => (
          <SuggestedMessage
            key={i}
            suggestion={suggestion}
            onSelect={this.handleSelectSuggestedMessage}
          />
        ))}
      </SuggestedMessageList>
    )
  }

  handleInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key == 'Escape') {
      e.preventDefault()
      const t = e.target as HTMLInputElement
      t.blur()
    }
  }

  render() {
    const { provided, instruction, ruleset } = this.props

    return (
      <RootRef rootRef={provided.innerRef}>
        <M.ExpansionPanel
          {...provided.draggableProps}
          expanded={this.props.isExpanded}
          onChange={this.handleToggle}
          CollapseProps={{
            style: {
              overflow: this.props.isExpanded ? 'visible' : 'hidden'
            }
          }}
        >
          <M.ExpansionPanelSummary
            expandIcon={<Icon.ExpandMore />}
            IconButtonProps={{
              onClick: this.handleClickExpand
            }}
            {...provided.dragHandleProps}
          >
            <InstructionNumber value={this.props.number} />
            <Input
              inputRef={(ref: any) => (this.inputRef = ref)}
              value={this.state.message}
              onChange={withTargetValue(this.handleChangeMessage)}
              onBlur={this.handleBlurInput}
              onKeyDown={this.handleInputKeyDown}
            />
            {this.renderSuggestedMessages()}
          </M.ExpansionPanelSummary>
          <M.ExpansionPanelDetails>
            <LineItemList
              instructionId={instruction.id}
              lineItems={Instructions.getLineItems(instruction)}
              ruleset={ruleset}
              onSort={this.handleSortLineItems}
              onPushItem={this.handlePushLineItem}
              onSetItem={this.handleSetLineItem}
              onDeleteItem={this.handleDeleteLineItem}
              onDeleteInstruction={this.handleDelete}
            />
          </M.ExpansionPanelDetails>
        </M.ExpansionPanel>
      </RootRef>
    )
  }
}

function InstructionNumber(props: { value: number }) {
  return (
    <Chip>
      <ChipLabel>{props.value}</ChipLabel>
    </Chip>
  )
}

const Input = styled(M.TextField).attrs({
  fullWidth: true,
  multiline: true,
  autoFocus: true,
  spellCheck: true,
  InputProps: {
    disableUnderline: true
  }
})``

const SuggestedMessageList = styled(M.Paper)`
  position: absolute;
  top: 60px;
  left: 0;
  background-color: white;
  z-index: 100;
  padding: 16px;
  max-height: 400px;
  overflow: scroll;
`

const Chip = styled.div`
  position: absolute;
  left: 6px;
  top: 3px;
`

const ChipLabel = styled(M.Typography).attrs({
  variant: 'body2'
})`
  color: #999 !important;
  font-size: 11px !important;
  font-weight: 300 !important;
`
