import * as Colors from '@material-ui/core/colors'
import { React, Variant, IVariant, M, styled } from '../common'

interface IProps {
  variant: IVariant
}

export default class WasteReport extends React.Component<IProps> {
  render() {
    const { variant } = this.props
    const report = Variant.getWasteReport(variant)

    if (report.warnings.length == 0) {
      return null
    }

    return (
      <Container>
        <M.Typography variant="body2" gutterBottom>
          Waste warning:
        </M.Typography>
        {report.warnings.map((warning, i) => (
          <M.Typography key={i} component="p">
            {i + 1}. {warning}
          </M.Typography>
        ))}
      </Container>
    )
  }
}

const Container = styled('div')`
  padding: 16px;
  background-color: ${Colors.yellow['100']} !important;
  margin-bottom: 16px;
  border-radius: 3px;
`
