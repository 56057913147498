import { React, styled, M, withTargetValue } from '../common'

interface IProps {
  onSubmit: (label: string) => void
  onClose: () => void
}

interface IState {
  label: string
}

export default class NewVariant extends React.Component<IProps, IState> {
  state: IState = {
    label: ''
  }

  cancel = () => {
    this.props.onClose()
  }

  changeLabel = (label: string) => {
    this.setState({ label })
  }

  submit = async () => {
    this.props.onSubmit(this.state.label)
  }

  render() {
    const { label } = this.state

    return (
      <Container>
        <Title>New Variant</Title>

        <Input value={label} onChange={withTargetValue(this.changeLabel)} />

        <Actions>
          <CancelButton onClick={this.props.onClose}>Cancel</CancelButton>

          <SubmitButton disabled={!label} onClick={this.submit}>
            OK
          </SubmitButton>
        </Actions>
      </Container>
    )
  }
}

const Title = styled(M.Typography).attrs({
  variant: 'title'
})``

const Actions = styled.div`
  display: flex;
`

const CancelButton = styled(M.Button).attrs({
  color: 'secondary',
  variant: 'contained'
})`
  margin: 0 8px !important;
`

const SubmitButton = styled(M.Button).attrs({
  color: 'primary',
  variant: 'contained'
})`
  margin: 0 8px !important;
`

const Container = styled(M.Paper).attrs({
  elevation: 4
})`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
`

const Input = styled(M.TextField).attrs({
  autoFocus: true,
  placeholder: 'Label...'
})`
  width: 300px;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
`
