import { IFilters } from './types'

export default class FilterStore {
  _filter: IFilters = {}

  set(filter: IFilters) {
    this._filter = filter
  }

  get(): IFilters {
    return this._filter
  }
}
