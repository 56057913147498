import * as React from 'react'
import * as M from '@material-ui/core'
import { IStore } from '../../types'
import * as Config from '../../config'
import * as AppVersion from '../services/app-version'
import Subscribe from './subscribe'

interface IProps {
  store: IStore
}

export default function VersionChecker(props: IProps) {
  if (!Config.REQUIRE_LATEST_VERSION) {
    return null
  }

  return (
    <Subscribe
      subscription={props.store.subscribeToRemoteConfig}
      param=""
      render={(isLoaded, config) => {
        if (isLoaded && config && config.latestVersion) {
          return <Content latestVersion={config.latestVersion} />
        } else {
          return null
        }
      }}
    />
  )
}

function Content(props: { latestVersion: string }) {
  if (AppVersion.VERSION == props.latestVersion) {
    return null
  }
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'white',
        zIndex: 99999999999,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
      }}
    >
      <M.Typography variant="title">An update is available</M.Typography>
      <br />
      <M.Typography variant="body1">
        You are using Chef v{AppVersion.VERSION}. The latest version is v
        {props.latestVersion}.
      </M.Typography>

      <br />

      <M.Button variant="contained" onClick={handleUpdate}>
        Update Now
      </M.Button>
    </div>
  )
}

function handleUpdate() {
  document.location.reload(true)
}
