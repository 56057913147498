import { React, M, Icon, styled } from '../common'
import * as Admin from '../../services/admin'

interface IProps {
  recipeId: string
}

interface IState {
  result?: Admin.IFeedbackResult
  isLoading: boolean
  filter: string
}

export default class FeedbackReport extends React.Component<IProps, IState> {
  state: IState = {
    result: undefined,
    isLoading: false,
    filter: ''
  }

  componentDidMount() {
    this.loadFeedbacks()
  }

  loadFeedbacks = async () => {
    const { recipeId } = this.props

    this.setState({ isLoading: true })

    const result = await Admin.getFeedbacks({
      recipeId,
      filter: this.getFilter()
    })

    this.setState({ result, isLoading: false })
  }

  getFilter = (): string | undefined => {
    const { filter } = this.state
    return filter && filter.length > 0 ? filter : undefined
  }

  handleLoadMore = async () => {
    const { recipeId } = this.props
    const { result } = this.state

    this.setState({ isLoading: true })

    if (!result) {
      return
    }

    const maxId = result.feedbacks[result.feedbacks.length - 1].id

    const nextResult = await Admin.getFeedbacks({
      recipeId,
      filter: this.getFilter(),
      maxId
    })

    for (const feedback of nextResult.feedbacks) {
      result.feedbacks.push(feedback)
    }

    this.setState({ result, isLoading: false })
  }

  handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ filter: event.target.value })
  }

  handleSearch = () => {
    this.setState({ result: undefined }, this.loadFeedbacks)
  }

  renderResult = () => {
    const { result, isLoading } = this.state

    if (result) {
      return (
        <div>
          <Padding>
            <M.Typography variant="subheading">
              {result.total} results
            </M.Typography>
          </Padding>

          <M.Table>
            <M.TableHead>
              <Tr>
                <Td />
                <Td>Date</Td>
                <Td>Message</Td>
              </Tr>
            </M.TableHead>
            <M.TableBody>
              {result.feedbacks.map(feedback => (
                <Tr key={feedback.id}>
                  <Td>
                    {feedback.is_positive ? (
                      <Icon.ThumbUp />
                    ) : feedback.star_rating ? (
                      <M.Typography variant="body2" style={{ minWidth: 40 }}>
                        {feedback.star_rating} ⭐
                      </M.Typography>
                    ) : (
                      <Icon.ThumbDown />
                    )}
                  </Td>
                  <Td style={{ minWidth: 200 }}>
                    {formatTime(feedback.created_at)}
                  </Td>
                  <Td>{feedback.body}</Td>
                </Tr>
              ))}
            </M.TableBody>
          </M.Table>

          {result.total > result.feedbacks.length && (
            <Footer>
              <M.Button onClick={this.handleLoadMore} variant="contained">
                Load More
              </M.Button>
              {isLoading && <ButtonProgress />}
            </Footer>
          )}
        </div>
      )
    } else {
      return <M.CircularProgress />
    }
  }

  render() {
    const { filter } = this.state

    return (
      <Container>
        <Padding>
          <Filters>
            <M.TextField
              placeholder="Filter reviews"
              onChange={this.handleChangeFilter}
              value={filter}
              style={{ marginRight: 8 }}
            />

            <M.Button variant="contained" onClick={this.handleSearch}>
              Filter
            </M.Button>
          </Filters>

          <M.Typography variant="title">Reviews</M.Typography>
        </Padding>

        {this.renderResult()}
      </Container>
    )
  }
}

const Filters = styled.div`
  float: right;
`

const ButtonProgress = styled(M.CircularProgress)`
  position: relative;
  top: -38px;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`

const Container = styled(M.Paper)`
  margin: 16px 0;
`

const Padding = styled.div`
  padding: 16px;
`

const Tr = M.TableRow
const Td = M.TableCell

function formatPercent(a: number, b: number): string {
  if (b == 0) {
    return '-'
  } else {
    return Math.round((100 * a) / b) + '%'
  }
}

function formatTime(time: number): string {
  return new Date(time).toDateString()
}
