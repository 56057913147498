import * as React from 'react'
import { ISceneProps, Navbar, Page } from '../common'

interface IProps extends ISceneProps {}

export default class Profile extends React.Component<IProps> {
  render() {
    return (
      <>
        {this.props.drawer}

        <Navbar title="My Profile" onToggleDrawer={this.props.onToggleDrawer} />

        <Page />
      </>
    )
  }
}
