import { readEnv } from './util'

export const FIREBASE_API_KEY = readEnv(
  'FIREBASE_API_KEY',
  'Firebase API key',
  process.env['FIREBASE_API_KEY']
)

export const FIREBASE_AUTH_DOMAIN = readEnv(
  'FIREBASE_AUTH_DOMAIN',
  'Firebase auth domain',
  process.env['FIREBASE_AUTH_DOMAIN']
)

export const FIREBASE_DATABASE_URL = readEnv(
  'FIREBASE_DATABASE_URL',
  'Firebase database URL',
  process.env['FIREBASE_DATABASE_URL']
)

export const FIREBASE_PROJECT_ID = readEnv(
  'FIREBASE_PROJECT_ID',
  'Firebase project id',
  process.env['FIREBASE_PROJECT_ID']
)

export const FIREBASE_STORAGE_BUCKET = readEnv(
  'FIREBASE_STORAGE_BUCKET',
  'Firebase storage bucket',
  process.env['FIREBASE_STORAGE_BUCKET']
)

export const FIREBASE_MESSAGING_SENDER_ID = readEnv(
  'FIREBASE_MESSAGING_SENDER_ID',
  'Firebase storage bucket',
  process.env['FIREBASE_MESSAGING_SENDER_ID']
)

export const ALGOLIA_APP_ID = readEnv(
  'ALGOLIA_APP_ID',
  'Algolia app id',
  process.env['ALGOLIA_APP_ID']
)

export const ALGOLIA_KEY = readEnv(
  'ALGOLIA_KEY',
  'Algolia key',
  process.env['ALGOLIA_KEY']
)

export const ADMIN_SERVICE_ENDPOINT = readEnv(
  'ADMIN_SERVICE_ENDPOINT',
  'Base URL for admin service',
  process.env['ADMIN_SERVICE_ENDPOINT']
)

export const ADMIN_SERVICE_PASSWORD = readEnv(
  'ADMIN_SERVICE_PASSWORD',
  'HTTP Basic authentication password for admin service',
  process.env['ADMIN_SERVICE_PASSWORD']
)

export const REQUIRE_LATEST_VERSION =
  readEnv(
    'REQUIRE_LATEST_VERSION',
    'Should the app check the latest version and force an update?',
    process.env['REQUIRE_LATEST_VERSION']
  ) == 'yes'
