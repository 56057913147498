import Subscribe from '../../views/subscribe'
import Footer from '../../views/footer'
import {
  React,
  M,
  ISceneProps,
  Recipe,
  Variant,
  FabPlus,
  Page,
  Navbar,
  Modal,
  IRecipe,
  Util,
  styled
} from '../common'
import RecipeThumb from './recipe-thumb'
import NewRecipe from './new-recipe'
import FilterStore from './filter-store'
import { INewRecipeParams, IFilters } from './types'

const filterStore = new FilterStore()

interface IProps extends ISceneProps {}

interface IState {
  filter: IFilters
  newRecipeModal: boolean
  showArchived: boolean
}

export default class RecipeList extends React.Component<IProps, IState> {
  state: IState = {
    newRecipeModal: false,
    // Restore filters if they were previously set in this session.
    filter: filterStore.get(),
    showArchived: false
  }

  submitNewRecipe = async (params: INewRecipeParams) => {
    const { store, nav, currentUser } = this.props

    const recipe = Recipe.make({
      ...params,
      authorId: currentUser.id
    })

    await store.setRecipe(recipe)
    await store.setVariant(Variant.makeDefault(recipe.id))

    nav.go('Recipe', { id: recipe.id })
  }

  showRecipe = (id: string) => {
    const { nav } = this.props
    nav.go('Recipe', { id })
  }

  toggleNewRecipe = () => {
    this.setState({ newRecipeModal: !this.state.newRecipeModal })
  }

  setFilter = (key: keyof IFilters, value?: string) => {
    const { filter } = this.state
    filter[key] = value
    this.setState({ filter })

    // We store the latest filters so that they can be restored
    // when navigating back to the recipe list.
    filterStore.set(filter)
  }

  filterRecipes = (recipes: IRecipe[]): IRecipe[] => {
    const { currentUser } = this.props
    const { filter, showArchived } = this.state

    return recipes
      .sort((a, b) => {
        switch (filter.order) {
          case 'title':
            return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
          case 'publish_date':
            return (b.publishedAt || 0) - (a.publishedAt || 0)
          default:
            // Sort archived recipes last
            if (a.isDeleted && !b.isDeleted) {
              return 1
            }
            if (b.isDeleted && !a.isDeleted) {
              return -1
            }
            return b.createdAt - a.createdAt
        }
      })
      .filter(r => {
        if (r.isDeleted && !showArchived) {
          return false
        }

        if (
          filter.title &&
          !r.title.toLowerCase().includes(filter.title.toLowerCase())
        ) {
          return false
        }

        const filterAuthorId = filter.authorId || currentUser.id
        if (filterAuthorId != 'anyone' && filterAuthorId != r.authorId) {
          return false
        }

        const filterState = filter.recipeState || 'any'
        if (filterState != 'any' && filterState != Recipe.state(r)) {
          return false
        }

        if (filter.meal && filter.meal != 'any' && filter.meal != r.ruleset) {
          return false
        }

        return true
      })
  }

  handleToggleArchived = () => {
    this.setState({ showArchived: !this.state.showArchived })
  }

  renderContent = (_isLoaded: boolean, maybeRecipes?: IRecipe[]) => {
    const recipes = this.filterRecipes(maybeRecipes || [])
    const { filter, showArchived } = this.state

    return (
      <>
        {this.props.drawer}

        <Navbar title="Recipes" onToggleDrawer={this.props.onToggleDrawer} />

        <Page>
          <SearchControls>
            <M.TextField
              label="Filter by title"
              value={filter.title || ''}
              onChange={event => this.setFilter('title', event.target.value)}
              style={{ marginRight: 16, minWidth: 260 }}
            />

            <M.FormControl style={{ marginRight: 16 }}>
              <M.InputLabel>Created by</M.InputLabel>
              <M.Select
                value={filter.authorId || this.props.currentUser.id}
                onChange={event =>
                  this.setFilter('authorId', event.target.value)
                }
                style={{ minWidth: 120 }}
              >
                <M.MenuItem value="anyone">Anyone</M.MenuItem>
                {Util.objectValues(this.props.users).map(u => (
                  <M.MenuItem value={u.id}>
                    {u.id == this.props.currentUser.id ? 'Me' : u.displayName}
                  </M.MenuItem>
                ))}
              </M.Select>
            </M.FormControl>

            <M.FormControl style={{ marginRight: 16 }}>
              <M.InputLabel>State</M.InputLabel>
              <M.Select
                value={filter.recipeState || 'any'}
                onChange={event =>
                  this.setFilter('recipeState', event.target.value)
                }
                style={{ minWidth: 120 }}
              >
                <M.MenuItem value="any">Any</M.MenuItem>
                <M.MenuItem value="draft">Draft</M.MenuItem>
                <M.MenuItem value="published">Published</M.MenuItem>
                <M.MenuItem value="unpublished-changes">
                  Unpublished Changes
                </M.MenuItem>
              </M.Select>
            </M.FormControl>

            <M.FormControl style={{ marginRight: 16 }}>
              <M.InputLabel>Meal</M.InputLabel>
              <M.Select
                value={filter.meal || 'any'}
                onChange={event => this.setFilter('meal', event.target.value)}
                style={{ minWidth: 120 }}
              >
                <M.MenuItem value="any">Any</M.MenuItem>
                <M.MenuItem value="dinner">Dinner</M.MenuItem>
                <M.MenuItem value="breakfast">Breakfast</M.MenuItem>
                <M.MenuItem value="dessert">Dessert</M.MenuItem>
                <M.MenuItem value="simple">Simple</M.MenuItem>
                <M.MenuItem value="snack">Snack</M.MenuItem>
                <M.MenuItem value="cpg">CPG</M.MenuItem>
              </M.Select>
            </M.FormControl>

            <M.FormControl>
              <M.InputLabel>Order by</M.InputLabel>
              <M.Select
                value={filter.order || 'date'}
                onChange={event => this.setFilter('order', event.target.value)}
                style={{ minWidth: 120 }}
              >
                <M.MenuItem value="date">Recently Created</M.MenuItem>
                <M.MenuItem value="title">Title</M.MenuItem>
                <M.MenuItem value="publish_date">Recently Published</M.MenuItem>
              </M.Select>
            </M.FormControl>
          </SearchControls>

          <RecipesList>
            <M.Grid spacing={16} container>
              {recipes.map(r => (
                <M.Grid item key={r.id}>
                  <RecipeThumb
                    key={r.id}
                    recipe={r}
                    onClick={() => this.showRecipe(r.id)}
                  />
                </M.Grid>
              ))}
            </M.Grid>
          </RecipesList>

          <M.Button
            variant="flat"
            onClick={this.handleToggleArchived}
            style={{ marginTop: 16, marginLeft: 16 }}
          >{`${showArchived ? 'Hide' : 'Show'} archived recipes`}</M.Button>

          <Footer />

          <FabPlus onClick={this.toggleNewRecipe} />

          <Modal
            open={this.state.newRecipeModal}
            onClose={this.toggleNewRecipe}
          >
            <NewRecipe
              onSubmit={this.submitNewRecipe}
              onClose={this.toggleNewRecipe}
            />
          </Modal>
        </Page>
      </>
    )
  }

  render() {
    const { store } = this.props

    return (
      <Subscribe
        subscription={store.subscribeToRecipes}
        param=""
        render={this.renderContent}
      />
    )
  }
}

const RecipesList = styled.div`
  margin: 24px 24px 64px !important;
`

const SearchControls = styled.div`
  margin: 24px;
`
