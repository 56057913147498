import * as Kitchen from '../../../kitchen-support'
import { React, IRecipe, Util } from '../common'
import Multiselect from '../../views/multiselect'

const TAGS = Util.objectValues(Kitchen.VARIETY_TAGS)

interface IProps {
  recipe: IRecipe
  onChange: (recipe: IRecipe) => void
}

export default class VarietyTagsList extends React.PureComponent<IProps> {
  handleChange = (selectedNames: string[]) => {
    const { recipe, onChange } = this.props

    const varietyTagIds = TAGS.filter(
      t => selectedNames.indexOf(t.name) >= 0
    ).map(t => t.id)

    onChange({
      ...recipe,
      varietyTagIds
    })
  }

  render() {
    const ids = this.props.recipe.varietyTagIds || []

    return (
      <Multiselect
        placeholder="Variety tags..."
        popperProps={{
          disablePortal: true,
          placement: 'top'
        }}
        items={TAGS.map(t => t.name)}
        selected={ids.map(id => Kitchen.findVarietyTag(id).name)}
        onChange={this.handleChange}
      />
    )
  }
}
