import { React, M, IVariant } from '../common'

interface IProps {
  variant: IVariant
  onChange: (variant: IVariant) => void
}

export default class CategoryField extends React.Component<IProps> {
  handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { variant, onChange } = this.props

    onChange({
      ...variant,
      category: event.target.value
    })
  }

  render() {
    return (
      <M.FormControl>
        <M.InputLabel>Category</M.InputLabel>
        <M.Select
          value={this.props.variant.category || ''}
          onChange={this.handleChange}
          displayEmpty
          style={{ marginBottom: 16, minWidth: 120 }}
        >
          <M.MenuItem value={'meat'}>Meat</M.MenuItem>
          <M.MenuItem value={'fish'}>Fish</M.MenuItem>
          <M.MenuItem value={'vegetarian'}>Vegetarian</M.MenuItem>
        </M.Select>
      </M.FormControl>
    )
  }
}
