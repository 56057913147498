import * as React from 'react'
import * as M from '@material-ui/core'
import styled from 'styled-components'
import Router from '../../typerouter'

interface IProps {
  nav: Router
}

export default class ErrorPage extends React.Component<IProps> {
  handleBack = () => {
    this.props.nav.go('RecipeList')
  }

  render() {
    return (
      <Container>
        <Title>Woopsie! Something went wrong.</Title>
        <M.Button onClick={this.handleBack} variant="contained">
          Go back home
        </M.Button>
      </Container>
    )
  }
}

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
`

const Title = styled(M.Typography).attrs({ variant: 'title' })`
  padding-bottom: 8px;
`
