import { IInstruction } from '../../../types'
import DICTIONARY from './dictionary'

export function check(instruction: IInstruction): string[] {
  const errors: string[] = []

  const msg = instruction.message

  if (msg.length < 5) {
    errors.push(
      `Has ${msg.length} characters. Should have at least 5 characters.`
    )
  }

  if (msg.length > 300) {
    errors.push(
      `Has ${msg.length} characters. Should have less than 300 characters.`
    )
  }

  if (msg.includes('  ')) {
    errors.push(`Contains a double space`)
  }

  // TODO: Temporarily disabled because a bug is causing *most* instructions
  // to have a trailing space...
  // if (msg.endsWith(' ')) {
  //   errors.push('Has a trailing space')
  // }

  if (msg.startsWith(' ')) {
    errors.push('Has a leading space')
  }

  {
    const m = msg.trim()
    if (!m.endsWith('.') && !m.endsWith('.)') && !m.endsWith('!')) {
      errors.push('Is missing a period at the end')
    }
  }

  if (/\d+\.\d+/.test(msg)) {
    errors.push('Has a decimal-formatted number')
  }

  const timeFormat = /([0-9]{1,2}) to ([0-9]{1,2}) minutes/.exec(msg)
  if (timeFormat) {
    errors.push(
      `Has incorrectly formatted time range. Time ranges should be written as: ${timeFormat[1]}-${timeFormat[2]} minutes`
    )
  }

  if (/°/.test(msg) && !/\d+°(F|C)/.test(msg)) {
    errors.push('Has an misformatted temperature')
  }

  if (msg.includes('"')) {
    errors.push('Uses inch shorthand')
  }

  {
    const first = msg.trim()[0] || ''
    if (first == first.toLowerCase()) {
      errors.push(`Isn't capitalized`)
    }
  }

  if (msg.includes(' - ')) {
    errors.push(`Has a dash with spaces around it`)
  }

  if (msg.includes('(') && !msg.includes(')')) {
    errors.push('Has unclosed parentheses')
  }

  {
    const words = getUnknownWords(msg)
    if (words.length > 0) {
      errors.push(`Contains unknown words: ${words.join(', ')}`)
    }
  }

  return errors
}

function getUnknownWords(msg: string): string[] {
  const unknownWords: string[] = []
  for (const word of tokenizeWords(msg)) {
    if (!DICTIONARY.has(word)) {
      unknownWords.push(word)
    }
  }
  return unknownWords
}

function tokenizeWords(msg: string): string[] {
  return msg
    .trim()
    .split(/[\s\-\/]/)
    .map(cleanWord)
}

function cleanWord(word: string): string {
  word = word.toLowerCase()

  // Trim punctuation from end of word
  // (repeat to account for multiple marks in arbitrary
  // order).
  const stopChars = [')', '.', ',', ';', '!']
  for (let i = 0; i < 3; i++) {
    for (const char of stopChars) {
      if (word.endsWith(char)) {
        word = word.slice(0, word.length - 1)
      }
    }
  }

  if (word.startsWith('(')) {
    word = word.slice(1)
  }

  return word
}
