export function round(x: number, digits: number = 0): number {
  const s = Math.pow(10, digits)
  return Math.round(s * x) / s
}

export function debounce(func: () => void, wait = 50) {
  let h: number
  return () => {
    clearTimeout(h)
    h = window.setTimeout(func, wait)
  }
}

/**
 * Call the given function as long as it has not been called in the
 * past "delay" milliseconds.
 */
export function debounceLeading<F extends (...params: any[]) => void>(
  fn: F,
  delay: number
): F {
  let waitUntil = 0

  return function(this: any, ...args: any[]) {
    const now = Date.now()

    if (waitUntil < now) {
      fn.apply(this, args)
    }

    waitUntil = now + delay
  } as F
}
