import Pica from 'pica'

function readFileAsync(file: File): Promise<any> {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

function loadImageAsync(src: string): Promise<any> {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img')
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = src
  })
}

export async function resizeImage(file: File) {
  const pica = Pica()

  const resizedCanvas = document.createElement('canvas')
  resizedCanvas.height = 400
  resizedCanvas.width = 400
  const image = await readFileAsync(file)
  const img = await loadImageAsync(
    'data:image/jpeg;base64,' + Buffer.from(image, 'utf8').toString('base64')
  )

  const result = await pica.resize(img, resizedCanvas)

  return pica.toBlob(result, 'image/jpeg', 0.9)
}
