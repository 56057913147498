import { React, M, styled, withTargetValue, IUserData } from '../common'

interface IProps {
  onClose: () => void
  onSubmit: (userData: IUserData) => void
}

interface IState {
  user: IUserData
}

export default class NewUser extends React.Component<IProps, IState> {
  state: IState = {
    user: {
      displayName: '',
      email: '',
      password: ''
    }
  }

  handleCancel = () => {
    this.props.onClose()
  }

  handleSubmit = async () => {
    this.props.onSubmit(this.state.user)
  }

  isValid = (): boolean => {
    const { user } = this.state
    return (
      user.displayName.length > 0 &&
      user.email.length > 5 &&
      user.password.length > 7
    )
  }

  setUser = (attribute: string) => (value: string) => {
    this.setState({
      user: {
        ...this.state.user,
        [attribute]: value
      }
    })
  }

  render() {
    const { user } = this.state

    return (
      <Container>
        <Title>Add a team member</Title>

        <Input
          label="Name"
          autoFocus
          value={user.displayName}
          onChange={withTargetValue(this.setUser('displayName'))}
        />

        <Input
          label="Email"
          value={user.email}
          onChange={withTargetValue(this.setUser('email'))}
        />

        <Input
          label="Password"
          value={user.password}
          onChange={withTargetValue(this.setUser('password'))}
        />

        <Actions>
          <CancelButton onClick={this.handleCancel}>Cancel</CancelButton>

          <SubmitButton disabled={!this.isValid()} onClick={this.handleSubmit}>
            OK
          </SubmitButton>
        </Actions>
      </Container>
    )
  }
}

const Title = styled(M.Typography).attrs({
  variant: 'title'
})``

const Actions = styled.div`
  display: flex;
`

const CancelButton = styled(M.Button).attrs({
  color: 'secondary',
  variant: 'contained'
})`
  margin: 0 8px !important;
`

const SubmitButton = styled(M.Button).attrs({
  color: 'primary',
  variant: 'contained'
})`
  margin: 0 8px !important;
`

const Container = styled(M.Paper).attrs({
  elevation: 4
})`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
`

const Input = styled(M.TextField)`
  width: 300px;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
`
