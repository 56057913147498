export function readEnv(
  code: string,
  description: string,
  value: string | undefined
): string {
  if (!value) {
    throw new Error(
      `Missing required environment variable ${code}: '${description}'`
    )
  }

  return value
}
