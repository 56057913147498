import { React, M, styled, IVariant, Variant, Icon } from '../common'

interface IProps {
  variant: IVariant
  onEdit: (id: string) => void
  onCopy: (id: string) => void
}

export default class VariantThumb extends React.Component<IProps> {
  menuAnchor: HTMLElement | null = null

  handleClick = () => {
    const { variant, onEdit } = this.props
    onEdit(variant.id)
  }

  handleCopy = () => {
    const { variant, onCopy } = this.props
    onCopy(variant.id)
  }

  render() {
    const { variant } = this.props

    return (
      <div style={{ paddingBottom: 8 }}>
        <Container>
          <Label onClick={this.handleClick}>
            <Chip style={{ backgroundColor: Variant.getColor(variant) }}>
              {variant.servingCount}
            </Chip>
            {variant.label}
          </Label>
          <div>
            <M.Tooltip title="Make a copy">
              <M.IconButton onClick={this.handleCopy}>
                <Icon.FileCopy />
              </M.IconButton>
            </M.Tooltip>
          </div>
        </Container>
      </div>
    )
  }
}

const Container = styled(M.Paper)`
  padding: 0 16px;
  cursor: move;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled(M.Typography).attrs({
  variant: 'body2'
})`
  cursor: pointer;
  flex: 1;
  display: flex !important;
  align-items: center;
  height: 50px;
  justify-content: flex-start;
`

const Chip = styled.div`
  background-color: #ddd;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  color: white;
`
