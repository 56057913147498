import { React, Navbar, ISceneProps, styled, Util, Page, M } from '../common'
import FeedbackReport from './feedback-report'
import StatsReport from './stats-report'

interface IProps extends ISceneProps {
  params: { recipeId: string; recipeTitle: string }
}

export default class RecipeStats extends React.Component<IProps> {
  handleBack = () => {
    const { nav, params } = this.props
    nav.go('Recipe', { id: params.recipeId })
  }

  render() {
    const { params, drawer, onToggleDrawer } = this.props
    return (
      <Container>
        {drawer}
        <Navbar
          onBack={this.handleBack}
          title={Util.truncate(params.recipeTitle, 30) + ' / ' + 'Stats'}
          onToggleDrawer={onToggleDrawer}
        />

        <Page>
          <StatsReport recipeId={params.recipeId} />
          <FeedbackReport recipeId={params.recipeId} />
        </Page>
      </Container>
    )
  }
}

const Container = styled.div`
  background-color: #f3f3f3;
`
