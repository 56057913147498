import Add from '@material-ui/icons/Add'
import Archive from '@material-ui/icons/Archive'
import ArrowBack from '@material-ui/icons/ArrowBack'
import AccountCircle from '@material-ui/icons/AccountCircle'
import More from '@material-ui/icons/MoreVert'
import Restaurant from '@material-ui/icons/Restaurant'
import Looks4 from '@material-ui/icons/Looks4'
import Looks6 from '@material-ui/icons/Looks6'
import FileCopy from '@material-ui/icons/FileCopy'
import Delete from '@material-ui/icons/Delete'
import CloudUpload from '@material-ui/icons/CloudUpload'
import Edit from '@material-ui/icons/Edit'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Cancel from '@material-ui/icons/Cancel'
import ExpandMore from '@material-ui/icons/ExpandMore'
import AddAPhoto from '@material-ui/icons/AddAPhoto'
import Menu from '@material-ui/icons/Menu'
import Star from '@material-ui/icons/Star'
import ExitToApp from '@material-ui/icons/ExitToApp'
import Group from '@material-ui/icons/Group'
import Visibility from '@material-ui/icons/Visibility'
import ThumbUp from '@material-ui/icons/ThumbUp'
import ThumbDown from '@material-ui/icons/ThumbDown'
import Unarchive from '@material-ui/icons/Unarchive'
import ShowChart from '@material-ui/icons/ShowChart'
import Spellcheck from '@material-ui/icons/Spellcheck'

import { SvgIconProps } from '@material-ui/core/SvgIcon'

const ICONS: { [k: string]: React.ComponentType<SvgIconProps> } = {
  Add,
  Archive,
  ArrowBack,
  AccountCircle,
  More,
  Restaurant,
  Looks4,
  Looks6,
  FileCopy,
  Delete,
  CloudUpload,
  Edit,
  CheckCircle,
  Cancel,
  ExpandMore,
  AddAPhoto,
  Menu,
  Star,
  ExitToApp,
  Group,
  Visibility,
  ThumbUp,
  ThumbDown,
  Unarchive,
  ShowChart,
  Spellcheck
}

export default ICONS
