import * as React from 'react'
import { IUser } from '../../types'
import * as User from '../services/user'
import { Typography } from '@material-ui/core'

interface IProps {
  user: IUser
  size: number
}

export default class Avatar extends React.Component<IProps> {
  render() {
    const { user, size } = this.props

    return (
      <div
        style={{
          backgroundColor: User.getColor(user),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: size,
          minWidth: size,
          height: size,
          borderRadius: size / 2
        }}
      >
        <Typography
          variant="body2"
          style={{ color: 'white', fontSize: size / 2, marginBottom: 2 }}
        >
          {User.getInitial(user).toUpperCase()}
        </Typography>
      </div>
    )
  }
}
