import {
  React,
  styled,
  M,
  withTargetValue,
  IComment,
  IUserMap,
  IVariant,
  Util
} from '../common'
import Comment from './comment'

interface IProps {
  userId: string
  users: IUserMap
  variant: IVariant
  onChange: (variant: IVariant) => void
}

interface IState {
  newBody: string
}

export default class CommentList extends React.PureComponent<IProps, IState> {
  state: IState = {
    newBody: ''
  }

  setComments = (comments: IComment[]) => {
    const { variant, onChange } = this.props

    onChange({
      ...variant,
      comments
    })
  }

  handleChangeNewBody = (newBody: string) => {
    this.setState({ newBody })
  }

  handleSubmit = () => {
    const comment: IComment = {
      id: Util.makeId(),
      authorId: this.props.userId,
      body: this.state.newBody,
      ...Util.makeTimestamps()
    }

    this.setState({ newBody: '' })

    this.setComments(this.props.variant.comments.concat(comment))
  }

  handleChange = (comment: IComment) => {
    this.setComments(
      this.props.variant.comments.map(c => (c.id == comment.id ? comment : c))
    )
  }

  handleDelete = (id: string) => {
    this.setComments(this.props.variant.comments.filter(c => c.id != id))
  }

  render() {
    return (
      <Container>
        {this.props.variant.comments.map(comment => (
          <Comment
            key={comment.id}
            comment={comment}
            author={this.props.users[comment.authorId]}
            currentUserId={this.props.userId}
            onChange={this.handleChange}
            onDelete={this.handleDelete}
          />
        ))}

        <NewComment>
          <M.TextField
            value={this.state.newBody}
            onChange={withTargetValue(this.handleChangeNewBody)}
            label="Comment..."
            fullWidth
            multiline
            margin="normal"
          />
          <M.Button
            color="primary"
            size="small"
            variant="contained"
            disabled={!this.state.newBody}
            onClick={this.handleSubmit}
          >
            Submit
          </M.Button>
        </NewComment>
      </Container>
    )
  }
}

const Container = styled.div``
const NewComment = styled.div``
