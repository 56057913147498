import * as History from '../../services/history'
import ImageUploadModal from '../../views/image-upload-modal'
import {
  React,
  styled,
  Variant,
  Navbar,
  IAction,
  ISceneProps,
  IVariant,
  IRecipe,
  M,
  Util
} from '../common'
import { debounceLeading } from './util'
import ServingCountField from './serving-count-field'
import LabelField from './label-field'
import TitleField from './title-field'
import ImageField from './image-field'
import NotesField from './notes-field'
import CategoryField from './category-field'
import CookingMinutesField from './cooking-minutes-field'
import MakeGlutenFreeField from './make-gluten-free-field'
import InstructionList from './instruction-list'
import CommentList from './comment-list'
import CookwaresList from './cookwares-list'
import AllowedMenusList from './allowed-menus-list'
import Nutrition from './nutrition'
import WasteReport from './waste-report'
import CookingTipField from './cooking-tip-field'

const MAX_HISTORY = 100
const HISTORY_DELAY = 300

interface IProps extends ISceneProps {
  variant: IVariant
  recipe: IRecipe
  onChange: (variant: IVariant) => void
}

interface IState {
  advanced: boolean
  imageModal: boolean
}

export default class Content extends React.Component<IProps, IState> {
  history = History.make<IVariant>()

  state: IState = {
    advanced: false,
    imageModal: false
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  toggleImageModal = () => {
    this.setState({ imageModal: !this.state.imageModal })
  }

  handleSubmitImageUrl = (imageUrl: string, thumbnailUrl: string) => {
    const updatedVariant = Variant.setImage(
      this.props.variant,
      imageUrl,
      thumbnailUrl
    )
    this.setVariant(updatedVariant)
    this.toggleImageModal()
  }

  handleRemoveImage = () => {
    this.setVariant(Variant.clearImage(this.props.variant))
  }

  setVariant = (variant: IVariant) => {
    this.updateHistory(this.props.variant)
    this.props.onChange(variant)
  }

  handleKeyDown = (event: KeyboardEvent) => {
    // Execute undo/redo on cmd+z / cmd+shift+z
    const z = event.keyCode == 90
    const cmd = event.ctrlKey || event.metaKey
    const shift = event.shiftKey

    if (z && cmd) {
      event.preventDefault()

      if (shift) {
        this.handleRedo()
      } else {
        this.handleUndo()
      }
    }
  }

  handleBack = () => {
    const { nav, variant } = this.props
    nav.go('Recipe', { id: variant.recipeId })
  }

  handleTwoToFour = async () => {
    this.setVariant(Variant.twoToFour(this.props.variant))
  }

  handleFourToSix = async () => {
    this.setVariant(Variant.fourToSix(this.props.variant))
  }

  handleConfirmDelete = () => {
    this.handleBack()
    this.setVariant(Variant.softDelete(this.props.variant))
  }

  handleDelete = () => {
    const isPublished = !!this.props.recipe.publishedAt

    if (
      (!isPublished || confirm(PUBLISHED_DELETE_WARNING)) &&
      confirm(DELETE_WARNING)
    ) {
      this.handleConfirmDelete()
    }
  }

  updateHistory = debounceLeading((variant: IVariant) => {
    this.history = History.push(this.history, variant, MAX_HISTORY)
  }, HISTORY_DELAY)

  handleUndo = () => {
    const [nextVariant, history] = History.goBack(
      this.history,
      this.props.variant
    )

    if (nextVariant) {
      this.props.onChange(nextVariant)
      this.history = history
    }
  }

  handleRedo = () => {
    const [nextVariant, history] = History.goForward(
      this.history,
      this.props.variant
    )

    if (nextVariant) {
      this.props.onChange(nextVariant)
      this.history = history
    }
  }

  handlePreview = () => {
    this.props.nav.go('Preview', { id: this.props.variant.id })
  }

  toggleAdvanced = () => {
    this.setState({ advanced: !this.state.advanced })
  }

  getSuggestedInstructions = (msg: string) => {
    return this.props.store.searchInstructions(msg)
  }

  getActions = (): IAction[] => {
    const { variant } = this.props
    const actions: IAction[] = []

    actions.push({
      label: 'Preview',
      icon: 'Visibility',
      onClick: this.handlePreview
    })

    if (variant.servingCount == 2) {
      actions.push({
        label: 'Convert to 4-serving',
        icon: 'Looks4',
        onClick: this.handleTwoToFour
      })
    }

    if (variant.servingCount == 4) {
      actions.push({
        label: 'Convert to 6-serving',
        icon: 'Looks6',
        onClick: this.handleFourToSix
      })
    }

    actions.push({
      label: 'Delete',
      icon: 'Delete',
      onClick: this.handleDelete
    })

    return actions
  }

  render() {
    const { drawer, recipe, store, variant } = this.props

    return (
      <>
        {drawer}

        <Navbar
          title={
            Util.truncate(recipe.title, 30) +
            ' / ' +
            variant.label +
            ' × ' +
            variant.servingCount
          }
          actions={this.getActions()}
          onBack={this.handleBack}
          onToggleDrawer={this.props.onToggleDrawer}
        />

        <Container>
          <Left>
            <LabelField variant={variant} onChange={this.setVariant} />

            <NotesField variant={variant} onChange={this.setVariant} />

            <ServingRow>
              <ServingCountField variant={variant} onChange={this.setVariant} />

              <CookingMinutesField
                variant={variant}
                onChange={this.setVariant}
              />
            </ServingRow>

            <CookwaresList variant={variant} onChange={this.setVariant} />
            <AllowedMenusList variant={variant} onChange={this.setVariant} />

            <MakeGlutenFreeField variant={variant} onChange={this.setVariant} />

            <M.Button
              variant="flat"
              style={{ marginTop: 16 }}
              size="small"
              onClick={this.toggleAdvanced}
            >
              {this.state.advanced
                ? 'Hide Advanced Options'
                : 'Show Advanced Options'}
            </M.Button>

            {this.state.advanced && (
              <>
                <TitleField variant={variant} onChange={this.setVariant} />
                <CategoryField variant={variant} onChange={this.setVariant} />

                <ImageField
                  variant={variant}
                  onUpload={this.toggleImageModal}
                  onRemove={this.handleRemoveImage}
                />

                <CookingTipField variant={variant} onChange={this.setVariant} />
              </>
            )}
          </Left>

          <Right>
            <WasteReport variant={variant} />

            <InstructionList
              variant={variant}
              ruleset={recipe.ruleset}
              onChange={this.setVariant}
              getSuggestedInstructions={this.getSuggestedInstructions}
            />

            <CommentList
              variant={variant}
              userId={this.props.currentUser.id}
              users={this.props.users}
              onChange={this.setVariant}
            />
          </Right>

          <Nutrition variant={variant} />

          <ImageUploadModal
            path="variant_images"
            isVisible={this.state.imageModal}
            onClose={this.toggleImageModal}
            uploadFile={store.uploadFile}
            onSubmit={this.handleSubmitImageUrl}
          />
        </Container>
      </>
    )
  }
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
`

const Left = styled.div`
  width: 440px;
  max-height: 100vh;
  padding: 16px;
  padding-top: 80px;
  padding-bottom: 180px;
  overflow-y: scroll;
`

const Right = styled.div`
  width: 100%;
  max-height: 100vh;
  padding-right: 16px;
  padding-top: 94px;
  padding-left: 32px;
  overflow-y: scroll;
  /* Work-around for Safari/Firefox because of elements being over-constrained */
  /* Learn more https://stackoverflow.com/a/38997047/3735046 */
  display: flex;
  flex-direction: column;
  &::after {
    content: '';
    flex: 0 0 180px;
  }
`

const ServingRow = styled.div`
  display: flex;
  align-items: baseline;
`

const PUBLISHED_DELETE_WARNING = `WARNING! This recipe has already been published. Deleting this variant *could* break things! If you are sure that this variant has *never been published* to Admin (Catherine can check), then it is safe to delete. If not, or you are unsure, then it is best to check with Mitch. Are you sure?`
const DELETE_WARNING = 'Are you sure you want to delete this recipe variant?'
