import { ITimestamps } from '../../types'

export function values<T>(object: { [k: string]: T }): T[] {
  const vs: T[] = []

  for (const k in object) {
    vs.push(object[k])
  }

  return vs
}

export function withTargetValue(handler: (value: string) => void) {
  return function(event: { currentTarget: { value: string } }) {
    handler(event.currentTarget.value)
  }
}

// See: https://gist.github.com/gordonbrander/2230317
export function makeId() {
  return Math.random()
    .toString(36)
    .substr(2, 11)
}

export function makeTimestamps(): ITimestamps {
  const now = Date.now()

  return {
    createdAt: now,
    updatedAt: now
  }
}

export function formatFractions(str: string) {
  if (!str.includes('/')) {
    return str
  }

  const fractions: { [k: string]: string } = {
    '1/2': '½',
    '1/4': '¼',
    '1/8': '⅛',
    '3/4': '¾',
    '1/3': '⅓',
    '2/3': '⅔'
  }

  for (const raw in fractions) {
    const pretty = fractions[raw]
    str = str.replace(raw, pretty)
  }

  return str
}

export function numberToPrettyFractionString(x: number): string | undefined {
  const whole = Math.floor(x)
  const decimal = x - whole

  if (closeTo(decimal, 0)) {
    return whole.toString()
  } else {
    const fractionString = decimalToFractionString(decimal)

    if (fractionString) {
      if (whole > 0) {
        return `${whole} ${fractionString}`
      } else {
        return fractionString
      }
    }
  }
}

export function numberToFractionString(x: number): string {
  return numberToPrettyFractionString(x) || x.toString()
}

function decimalToFractionString(x: number): string | undefined {
  const conversions: [number, string][] = [
    [0.125, '⅛'],
    [0.25, '¼'],
    [1 / 3, '⅓'],
    [3 / 8, '⅜'],
    [0.5, '½'],
    [2 / 3, '⅔'],
    [0.75, '¾'],
    // If the decimal is close to 1 but not quite...
    [1, '1']
  ]

  for (const c of conversions) {
    if (closeTo(x, c[0])) {
      return c[1]
    }
  }
}

export function reorder<T>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function formatDate(date: Date) {
  var monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const day = date.getDate()

  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  return `${monthNames[monthIndex]} ${day}${getOrdinal(day)}, ${year}`
}

function getOrdinal(day: number): string {
  switch (day) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export function objectValues<T>(object: { [k: string]: T }): T[] {
  const vs: T[] = []
  for (let k in object) {
    vs.push(object[k])
  }
  return vs
}

export function closeTo(
  a: number,
  b: number,
  tolerance: number = 0.01
): boolean {
  return Math.abs(a - b) < tolerance
}

export function truncate(str: string, n: number): string {
  if (str.length > n) {
    return str.slice(0, n - 2) + '…'
  } else {
    return str
  }
}
