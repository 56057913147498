import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  HookProvided
} from 'react-beautiful-dnd'
import { React, IVariant, styled } from '../common'
import VariantThumb from './variant-thumb'

interface IProps {
  variants: IVariant[]
  onSort: (source: number, destination: number) => void
  onEdit: (id: string) => void
  onCopy: (id: string) => void
}

export default class VariantList extends React.Component<IProps> {
  handleDragEnd = (result: DropResult, _provided: HookProvided) => {
    const { onSort } = this.props

    // dropped outside the list
    if (!result.destination) {
      return
    }

    onSort(result.source.index, result.destination.index)
  }

  render() {
    const { variants } = this.props

    return (
      <Container>
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="variants">
            {provided => (
              <div ref={provided.innerRef}>
                {variants.map((v, i) => {
                  return (
                    <Draggable key={v.id} draggableId={v.id} index={i}>
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <VariantThumb
                            variant={v}
                            onEdit={this.props.onEdit}
                            onCopy={this.props.onCopy}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    )
  }
}

const Container = styled.div`
  width: 100%;
`
