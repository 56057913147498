export default new Set([
  "aren't",
  "day's",
  "doesn't",
  "don't",
  "hasn't",
  "isn't",
  "it's",
  "won't",
  "you'll",
  '1',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '160°f',
  '165°f',
  '17',
  '175°c',
  '177°c',
  '18',
  '190°c',
  '1½',
  '2',
  '20',
  '200°c',
  '204°c',
  '205°c',
  '218°',
  '218°c',
  '22',
  '220°c',
  '225°c',
  '230°c',
  '232°c',
  '24',
  '240°c',
  '25',
  '250°c',
  '260°c',
  '3',
  '30',
  '35',
  '350°f',
  '36',
  '375°f',
  '4',
  '400°f',
  '425°f',
  '450',
  '450°f',
  '45°',
  '475°f',
  '5',
  '500°f',
  '6',
  '60',
  '7',
  '70°c',
  '75°c',
  '8',
  '9',
  'a',
  'about',
  'absorbed',
  'according',
  'accumulated',
  'achieve',
  'add',
  'adding',
  'additional',
  'adhere',
  'adheres',
  'after',
  'afterwards',
  'again',
  'against',
  'agave',
  'aioli',
  'alfredo',
  'all',
  'allow',
  'allowing',
  'almond',
  'almonds',
  'almost',
  'along',
  'alongside',
  'already',
  'also',
  'alternatively',
  'aluminum',
  'aminos',
  'among',
  'amount',
  'amounts',
  'an',
  'anchovy',
  'and',
  'angle',
  'another',
  'any',
  'apart',
  'appear',
  'apple',
  'apples',
  'approx',
  'approximately',
  'apricots',
  'are',
  'area',
  'around',
  'arrange',
  'arranging',
  'arrowroot',
  'artichoke',
  'artichokes',
  'arugula',
  'as',
  'aside',
  'asparagus',
  'assemble',
  'assembled',
  'at',
  'available',
  'avocado',
  'avocadoes',
  'avocados',
  'avoid',
  'away',
  'baby',
  'back',
  'bacon',
  'bake',
  'baked',
  'baking',
  'ball',
  'balls',
  'balsamic',
  'banana',
  'bananas',
  'base',
  'basil',
  'batches',
  'batter',
  'be',
  'bean',
  'beans',
  'beat',
  'beaten',
  'because',
  'become',
  'becomes',
  'bed',
  'beef',
  'been',
  'beet',
  'beets',
  'before',
  'begin',
  'begins',
  'begun',
  'bell',
  'below',
  'berries',
  'beside',
  'best',
  'between',
  'bias',
  'big',
  'bit',
  'bite',
  'bits',
  'black',
  'blackberries',
  'blackberry',
  'blemished',
  'blend',
  'blender',
  'blot',
  'blue',
  'blueberries',
  'blueberry',
  'board',
  'boats',
  'body',
  'boil',
  'boiled',
  'boiling',
  'boils',
  'bok',
  'bolognese',
  'both',
  'bottom',
  'bottoms',
  'bowl',
  'bowls',
  'box',
  'bread',
  'breadcrumb',
  'breadcrumbs',
  'breaded',
  'breading',
  'break',
  'breakfast',
  'breaking',
  'breast',
  'breasts',
  'bright',
  'bring',
  'broccoli',
  'broil',
  'broiler',
  'broken',
  'broth',
  'brown',
  'browned',
  'browning',
  'browns',
  'brush',
  'brussels',
  'bubble',
  'bubbly',
  'bun',
  'buns',
  'burger',
  'burgers',
  'burn',
  'burner',
  'burning',
  'burst',
  'but',
  'butter',
  'buttered',
  'butternut',
  'by',
  'béchamel',
  'cabbage',
  'cabbages',
  'cacciatore',
  'cajun',
  'cake',
  'cakes',
  'came',
  'can',
  'canned',
  'cannellini',
  'cans',
  'cap',
  'capers',
  'caps',
  'caramelized',
  'cardamom',
  'careful',
  'carefully',
  'carrot',
  'carrots',
  'cashews',
  'casing',
  'casings',
  'cast',
  'catch',
  'cauliflower',
  'cayenne',
  'celery',
  'center',
  'change',
  'char',
  'charred',
  'check',
  'checking',
  'cheddar',
  'cheese',
  'cherries',
  'cherry',
  'chia',
  'chicken',
  'chickpea',
  'chickpeas',
  'chiffonade',
  'chili',
  'chilies',
  'chill',
  'chilled',
  'chimichurri',
  'chips',
  'chives',
  'chocolate',
  'chop',
  'chopped',
  'chops',
  'chowder',
  'choy',
  'chunk',
  'chunkier',
  'chunks',
  'cider',
  'cigar',
  'cilantro',
  'cinnamon',
  'clean',
  'cleanup',
  'clear',
  'close',
  'closed',
  'cloth',
  'clove',
  'cloves',
  'clumped',
  'cm',
  'coarsely',
  'coat',
  'coated',
  'coats',
  'cob',
  'cobs',
  'cocoa',
  'coconut',
  'cod',
  'colander',
  'colcannon',
  'cold',
  'collapse',
  'color',
  'combination',
  'combine',
  'combined',
  'combining',
  'comes',
  'complete',
  'completely',
  'compote',
  'condiments',
  'consistency',
  'consistently',
  'constantly',
  'container',
  'containers',
  'containing',
  'contents',
  'continue',
  'continues',
  'continuing',
  'continuously',
  'cook',
  'cooked',
  'cooking',
  'cooks',
  'cookware',
  'cool',
  'cooled',
  'core',
  'cores',
  'coriander',
  'corn',
  'corners',
  'cornstarch',
  'counter',
  'couple',
  'couscous',
  'cover',
  'covered',
  'covering',
  'covers',
  'crack',
  'cranberries',
  'cream',
  'creamy',
  'create',
  'creating',
  'crema',
  'crisp',
  'crisps',
  'crispy',
  'crosswise',
  'crouton',
  'croutons',
  'crumb',
  'crumble',
  'crumbled',
  'crumbly',
  'crumbs',
  'crush',
  'crushed',
  'crust',
  'crusted',
  'crusty',
  'crystals',
  'cube',
  'cubes',
  'cucumber',
  'cucumbers',
  'cumin',
  'cup',
  'cups',
  'curls',
  'curry',
  'custard',
  'cut',
  'cutlets',
  'cuts',
  'cutting',
  'damage',
  'damp',
  'dark',
  'darken',
  'de',
  'defrost',
  'deglaze',
  'degree',
  'depending',
  'desired',
  'develop',
  'diagonal',
  'diagonally',
  'diameter',
  'dice',
  'diced',
  'different',
  'dijon',
  'dill',
  'dinner',
  'dip',
  'dipping',
  'directions',
  'directly',
  'dirt',
  'discard',
  'dish',
  'dishes',
  'dispersing',
  'dissolved',
  'distribute',
  'disturb',
  'disturbing',
  'divide',
  'do',
  'does',
  'doing',
  'dollop',
  'dollops',
  'done',
  'doneness',
  'don’t',
  'double',
  'doubled',
  'down',
  'downward',
  'drain',
  'drained',
  'dredge',
  'dredging',
  'dressed',
  'dressing',
  'dried',
  'drip',
  'drippings',
  'drizzle',
  'drizzling',
  'drop',
  'drumstick',
  'drumsticks',
  'dry',
  'dumplings',
  'during',
  'dust',
  'each',
  'ear',
  'easier',
  'easily',
  'easy',
  'edamame',
  'edge',
  'edges',
  'egg',
  'eggplant',
  'eggplants',
  'eggs',
  'eight',
  'either',
  'else',
  'empty',
  'enchilada',
  'enchiladas',
  'end',
  'ends',
  'enjoy',
  'enough',
  'ensure',
  'entire',
  'equal',
  'evaporate',
  'evaporated',
  'evaporates',
  'even',
  'evenly',
  'every',
  'everything',
  'excess',
  'exposing',
  'extra',
  'eyes',
  'face',
  'facing',
  'fajita',
  'falling',
  'falls',
  'fat',
  'feta',
  'fettuccine',
  'few',
  'filet',
  'filets',
  'fill',
  'fillet',
  'fillets',
  'filling',
  'final',
  'finally',
  'finely',
  'fingers',
  'finish',
  'finished',
  'firm',
  'firmly',
  'firmness',
  'first',
  'fish',
  'fit',
  'fitting',
  'flake',
  'flaked',
  'flakes',
  'flaky',
  'flat',
  'flatbread',
  'flatbreads',
  'flatten',
  'flatter',
  'flavors',
  'flesh',
  'flip',
  'flipping',
  'float',
  'florets',
  'flour',
  'flow',
  'fluff',
  'foams',
  'foamy',
  'foil',
  'fold',
  'followed',
  'following',
  'food',
  'for',
  'fork',
  'forks',
  'form',
  'forming',
  'four',
  'fragrant',
  'freezer',
  'frequently',
  'fresh',
  'freshly',
  'fridge',
  'fried',
  'fries',
  'frittata',
  'fritters',
  'from',
  'frothy',
  'frozen',
  'fruit',
  'fry',
  'full',
  'fully',
  'further',
  'gallo',
  'garlic',
  'garlicky',
  'garnish',
  'garnishing',
  'gather',
  'gazpacho',
  'generously',
  'gentle',
  'gently',
  'get',
  'gets',
  'gills',
  'ginger',
  'give',
  'glass',
  'glasses',
  'glaze',
  'gloves',
  'gnocchi',
  'go',
  'goat',
  'golden',
  'golf',
  'grab',
  'gradually',
  'grain',
  'granita',
  'granola',
  'grape',
  'grapefruit',
  'grapefruits',
  'grapes',
  'grate',
  'grated',
  'grater',
  'grates',
  'gravy',
  'grease',
  'greased',
  'greek',
  'green',
  'greens',
  'grill',
  'grilled',
  'grit',
  'grits',
  'ground',
  'guacamole',
  'half',
  'halfway',
  'halibut',
  'halve',
  'halves',
  'hand',
  'handful',
  'handfuls',
  'handle',
  'handling',
  'hands',
  'hard',
  'harissa',
  'has',
  'hash',
  'have',
  'head',
  'heads',
  'hearts',
  'heat',
  'heated',
  'heats',
  'heavy',
  'held',
  'help',
  'herb',
  'herbs',
  'high',
  'hold',
  'holding',
  'honey',
  'hop',
  'horizontally',
  'hot',
  'hour',
  'hours',
  'husks',
  'ice',
  'if',
  'immediately',
  'immersion',
  'in',
  'inch',
  'inches',
  'including',
  'incorporate',
  'incorporated',
  'increase',
  'individual',
  'ingredient',
  'ingredients',
  'inside',
  'instructions',
  'intact',
  'internal',
  'into',
  'invert',
  'iron',
  'is',
  'it',
  'italian',
  'its',
  'jalapeno',
  'jalapenos',
  'jalapeño',
  'jalapeños',
  'jars',
  'joe',
  'juice',
  'juices',
  'juicy',
  'julienne',
  'just',
  'kale',
  'kebabs',
  'keep',
  'keeping',
  'kernels',
  'ketchup',
  'kheema',
  'kitchen',
  'kiwi',
  'kiwifruit',
  'kiwifruits',
  'kiwis',
  'knife',
  'korma',
  'ladle',
  'lamb',
  'lamp',
  'large',
  'larger',
  'lasagna',
  'last',
  'later',
  'lay',
  'layer',
  'layers',
  'lazy',
  'leaf',
  'least',
  'leave',
  'leaves',
  'leaving',
  'leek',
  'leeks',
  'left',
  'leftover',
  'lemon',
  'lemons',
  'length',
  'lengthwise',
  'lentil',
  'lentils',
  'let',
  'letting',
  'lettuce',
  'level',
  'lid',
  'lids',
  'lie',
  'lift',
  'light',
  'lightly',
  'like',
  'lime',
  'limes',
  'limited',
  'line',
  'lined',
  'liquid',
  'little',
  'lo',
  'long',
  'longer',
  'loose',
  'loosely',
  'loosen',
  'low',
  'lower',
  'lowering',
  'lumps',
  'lunch',
  'mac',
  'macaroni',
  'maintain',
  'make',
  'making',
  'mandoline',
  'mange',
  'mango',
  'mangoes',
  'mangos',
  'many',
  'maple',
  'marinade',
  'marinara',
  'marinate',
  'marinates',
  'mark',
  'marks',
  'mash',
  'mashed',
  'masher',
  'mashing',
  'massage',
  'may',
  'mayo',
  'mayonaise',
  'mayonnaise',
  'meal',
  'meanwhile',
  'measure',
  'measuring',
  'meat',
  'meatball',
  'meatballs',
  'medallions',
  'medium',
  'medium-high heat',
  'mein',
  'melt',
  'melted',
  'melts',
  'membrane',
  'membranes',
  'metal',
  'microwave',
  'middle',
  'milk',
  'min',
  'mince',
  'minced',
  'minimum',
  'mint',
  'minted',
  'minty',
  'minute',
  'minutes',
  'mitt',
  'mitts',
  'mix',
  'mixed',
  'mixing',
  'mixture',
  'mm',
  'moisten',
  'moistened',
  'moisture',
  'moons',
  'more',
  'most',
  'mostly',
  'mound',
  'moussaka',
  'mousse',
  'move',
  'moved',
  'moving',
  'mozzarella',
  'much',
  'muffin',
  'mug',
  'multiple',
  'mushroom',
  'mushrooms',
  'mustard',
  'naan',
  'near',
  'nearby',
  'nearly',
  'necessary',
  'need',
  'needed',
  'needs',
  'new',
  'next',
  'nice',
  'nicely',
  'no',
  'non',
  'nonstick',
  'noodle',
  'noodles',
  'not',
  'nutmeg',
  'nutritional',
  'nuts',
  'oatmeal',
  'oats',
  'occasionally',
  'of',
  'off',
  'often',
  'oil',
  'olive',
  'olives',
  'omelet'
  'on',
  'once',
  'one',
  'ones',
  'onion',
  'onions',
  'only',
  'onto',
  'opaque',
  'open',
  'opening',
  'opposite',
  'optional',
  'or',
  'orange',
  'oranges',
  'oregano',
  'original',
  'other',
  'otherwise',
  'out',
  'outdoor',
  'outer',
  'outside',
  'oval',
  'oven',
  'ovenproof',
  'over',
  'overcook',
  'overnight',
  'overtop',
  'package',
  'pad',
  'pair',
  'pairs',
  'pale',
  'pan',
  'pancake',
  'pancakes',
  'panini',
  'panko',
  'pans',
  'paper',
  'paprika',
  'parallel',
  'parchment',
  'parfait',
  'parmesan',
  'parmesean',
  'parsley',
  'parsnip',
  'parsnips',
  'part',
  'parts',
  'pasta',
  'pastachios',
  'paste',
  'pat',
  'patties',
  'patting',
  'patty',
  'pea',
  'peach',
  'peaches',
  'peak',
  'peaks',
  'peanut',
  'peanuts',
  'pear',
  'pears',
  'peas',
  'pecans',
  'peel',
  'peeled',
  'peeler',
  'peeling',
  'peels',
  'pepper',
  'peppers',
  'per',
  'periodically',
  'person',
  'pick',
  'pico',
  'piece',
  'pieces',
  'pierce',
  'pierced',
  'piercing',
  'pilaf',
  'pile',
  'piles',
  'pinch',
  'pine',
  'pineapple',
  'pineapples',
  'pink',
  'pistachios',
  'pit',
  'pita',
  'pitas',
  'pith',
  'pits',
  'pizza',
  'pizzas',
  'pkg',
  'place',
  'places',
  'placing',
  'plain',
  'plank',
  'planks',
  'plastic',
  'plate',
  'plates',
  'plump',
  'poach',
  'poached',
  'pocket',
  'polenta',
  'poppy',
  'poppyseed',
  'pork',
  'porridge',
  'portion',
  'portions',
  'portobello',
  'position',
  'positions',
  'possible',
  'pot',
  'potato',
  'potatoes',
  'pour',
  'powder',
  'powered',
  'pre',
  'preferably',
  'prefered',
  'preheat',
  'preheated',
  'prep',
  'prepare',
  'prepared',
  'preparing',
  'press',
  'pressing',
  'prevent',
  'prick',
  'process',
  'processor',
  'produce',
  'proof',
  'prosciutto',
  'pudding',
  'puddings',
  'puffs',
  'pull',
  'pulse',
  'pumpkin',
  'pureé',
  'purée',
  'puréed',
  'push',
  'put',
  'quarter',
  'quarters',
  'quesadilla',
  'quesadillas',
  'quick',
  'quickly',
  'quinoa',
  'rack',
  'racks',
  'radish',
  'radishes',
  'ragu',
  'raisins',
  'ramekins',
  'ranch',
  'rapid',
  'rare',
  'raspberries',
  'raspberry',
  'raw',
  'reach',
  'reached',
  'reaches',
  'read',
  'ready',
  'recipe',
  'rectangles',
  'red',
  'reduce',
  'reduced',
  'reduces',
  'reduction',
  'refrigerate',
  'refrigerator',
  'regular',
  'regularly',
  'release',
  'remain',
  'remainder',
  'remaining',
  'remove',
  'removed',
  'removing',
  'repeat',
  'reposition',
  'required',
  'resembles',
  'reserve',
  'reserved',
  'reserving',
  'rest',
  'rested',
  'return',
  'returning',
  'reveal',
  'ribbons',
  'ribs',
  'rice',
  'rich',
  'ricotta',
  'right',
  'rim',
  'rings',
  'rinse',
  'rinsed',
  'rip',
  'rise',
  'risotto',
  'roast',
  'roasted',
  'roasting',
  'roll',
  'rolling',
  'rolls',
  'romaine',
  'room',
  'root',
  'roots',
  'rosemary',
  'rotating',
  'rough',
  'roughly',
  'rounded',
  'rounds',
  'rows',
  'rub',
  'run',
  'running',
  'runny',
  'runs',
  'safe',
  'sage',
  'salad',
  'salmon',
  'salsa',
  'salt',
  'same',
  'sandwich',
  'sandwiches',
  'sauce',
  'saucepan',
  'sausage',
  'sausages',
  'sauté',
  'sautéed',
  'save',
  'saved',
  'saving',
  'scallops',
  'scampi',
  'scape',
  'scatter',
  'schnitzel',
  'scoop',
  'scooped',
  'scoops',
  'scramble',
  'scrambled',
  'scrape',
  'scraping',
  'sealed',
  'seam',
  'sear',
  'seared',
  'season',
  'seasoned',
  'seasoning',
  'second',
  'seconds',
  'section',
  'sections',
  'see',
  'seed',
  'seeds',
  'seems',
  'segments',
  'separate',
  'separating',
  'seperate',
  'serve',
  'service',
  'serving',
  'sesame',
  'set',
  'sets',
  'setting',
  'several',
  'shake',
  'shaking',
  'shakshuka',
  'shallot',
  'shallots',
  'shallow',
  'shape',
  'shards',
  'sharp',
  'shave',
  'sheet',
  'sheets',
  'shell',
  'shells',
  'short',
  'should',
  'shred',
  'shredded',
  'shreds',
  'shrimp',
  'shuck',
  'side',
  'sides',
  'sift ',
  'silicone',
  'silk',
  'silks',
  'simmer',
  'simmering',
  'simply',
  'single',
  'sink',
  'sit',
  'six',
  'size',
  'sized',
  'skewer',
  'skewers',
  'skillet',
  'skin',
  'skin-side down',
  'skins',
  'skip',
  'slaw',
  'slice',
  'sliced',
  'slicer',
  'slices',
  'slicing',
  'slide',
  'sliding',
  'slightly',
  'slits',
  'slivered',
  'sloppy',
  'slotted',
  'slowly',
  'slurry',
  'small',
  'smaller',
  'smash',
  'smoked',
  'smooth',
  'smoother',
  'smoothie',
  'snack',
  'snap',
  'so',
  'soak',
  'soaked',
  'soba',
  'soft',
  'soften',
  'softened',
  'softens',
  'sole',
  'solid',
  'some',
  'something',
  'soup',
  'source',
  'soy',
  'soya',
  'spaghetti',
  'spatula',
  'spears',
  'speed',
  'spice',
  'spiced',
  'spices',
  'spicy',
  'spin',
  'spinach',
  'spinner',
  'spiralizer',
  'split',
  'spoon',
  'spoonful',
  'spoonfuls',
  'spooning',
  'spoons',
  'spread',
  'spreading',
  'sprig',
  'sprigs',
  'spring',
  'sprinkle',
  'sprinkling',
  'sprouts',
  'squares',
  'squash',
  'squashes',
  'squeeze',
  'squeezing',
  'stack',
  'stacking',
  'stacks',
  'stainer',
  'staining',
  'stainless',
  'stalk',
  'stalks',
  'stand',
  'starch',
  'start',
  'started',
  'starting',
  'starts',
  'steady',
  'steak',
  'steaks',
  'steam',
  'steel',
  'stem',
  'stems',
  'step',
  'stew',
  'stick',
  'sticking',
  'sticks',
  'sticky',
  'still',
  'stir',
  'stir-fry',
  'stirring',
  'stop',
  'stopping',
  'stove',
  'straight',
  'strain',
  'strained',
  'strainer',
  'strands',
  'strawberries',
  'strawberry',
  'stream',
  'strings',
  'strips',
  'stroganoff',
  'stuff',
  'stuffed',
  'submerged',
  'sugar',
  'sun',
  'sun-dried tomatoes',
  'sure',
  'surface',
  'sweet',
  'swell',
  'swirl',
  'swirling',
  'switching',
  'swollen',
  'syrup',
  'tablespoon',
  'tablespoons',
  'taco',
  'tahini',
  'tails',
  'take',
  'tamari',
  'tap',
  'tartar',
  'taste',
  'tbsp',
  'tea',
  'tear',
  'teaspoon',
  'temperature',
  'temporarily',
  'tender',
  'tenders',
  'tent',
  'teriyaki',
  'test',
  'testdasdfd',
  'tested',
  'texture',
  'textured',
  'thai',
  'that',
  'thaw',
  'the',
  'their',
  'them',
  'then',
  'there',
  'thermometer',
  'they',
  'thick',
  'thicken',
  'thickened',
  'thickens',
  'thicker',
  'thickness',
  'thigh',
  'thighs',
  'thin',
  'thinly',
  'third',
  'thirds',
  'this',
  'thoroughly',
  'though',
  'thread',
  'threads',
  'three',
  'through',
  'throughout',
  'thyme',
  'tight',
  'tightly',
  'tilapia',
  'tilt',
  'tilting',
  'time',
  'times',
  'tin',
  'tins',
  'tip',
  'tips',
  'to',
  'toast',
  'toasted',
  'toaster',
  'toasting',
  'toasts',
  'tofu',
  'together',
  'tomato',
  'tomatoes',
  'tongs',
  'too',
  'tools',
  'top',
  'topped',
  'topping',
  'toppings',
  'tops',
  'tortilla',
  'tortillas',
  'toss',
  'tossing',
  'total',
  'touch',
  'tough',
  'towards',
  'towel',
  'towels',
  'transfer',
  'transferred',
  'translucent',
  'travel',
  'tray',
  'triangles',
  'trim',
  'trimmed',
  'trimmings',
  'tsp',
  'tuna',
  'turkey',
  'turmeric',
  'turn',
  'turned',
  'turning',
  'turnip',
  'turnips',
  'turns',
  'twist',
  'two',
  'tzatziki',
  'uncooked',
  'uncover',
  'uncovered',
  'under',
  'underneath',
  'uniform',
  'unless',
  'unroll',
  'until',
  'untouched',
  'up',
  'upper',
  'upright',
  'use',
  'used',
  'using',
  'vanilla',
  'vegetable',
  'vegetables',
  'veggie',
  'veggies',
  'vertically',
  'very',
  'vigorously',
  'vinaigrette',
  'vinegar',
  'virgin',
  'volume',
  'wait',
  'waiting',
  'walnut',
  'walnuts',
  'warm',
  'warmed',
  'warms',
  'wash',
  'washed',
  'washing',
  'watch',
  'water',
  'way',
  'wear',
  'wedge',
  'wedges',
  'well',
  'when',
  'where',
  'while',
  'whipped',
  'whisk',
  'whisking',
  'white',
  'whites',
  'whole',
  'wide',
  'will',
  'wilt',
  'wilted',
  'wilting',
  'wilts',
  'wine',
  'wings',
  'wipe',
  'wiping',
  'wire',
  'with',
  'within',
  'without',
  'woody',
  'worcestershire',
  'work',
  'working',
  'wrap',
  'wraps',
  'wring',
  'xylitol',
  'yeast',
  'yellow',
  'yet',
  'yogurt',
  'yolk',
  'yolks',
  'you',
  'your',
  'zest',
  'zested',
  'zesting',
  'zucchini',
  'zucchinis',
  '¼',
  '½',
  '¾',
  '​chicken',
  '⅓',
  '⅔',
  '⅛'
])
