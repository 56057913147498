import { closeTo } from './util'

export const BASE_COMMON_UNIT = 'ml'

export type ICommonUnit = 'ml' | 'pinch' | 'tsp' | 'tbsp' | 'cup' | 'fl oz'

interface ILineItem {
  unit: ICommonUnit
  amount: number
}

export const COMMON_UNITS: ICommonUnit[] = ['ml', 'pinch', 'tsp', 'tbsp', 'cup']

const CUP = 240
const TBSP = CUP / 16
const TSP = TBSP / 3
const PINCH = TSP / 16

export function convertCommonToMl(unit: ICommonUnit, amount: number): number {
  switch (unit) {
    case 'pinch':
      return amount * PINCH
    case 'tsp':
      return amount * TSP
    case 'tbsp':
      return amount * TBSP
    case 'cup':
      return amount * CUP
    case 'ml':
      return amount
  }
  return 0
}

export function convertMlToClosestCommon(ml: number): ILineItem {
  // Special (inexact) simplified conversions
  if (closeTo(TBSP * 4.5, ml)) {
    return { unit: 'cup', amount: 1 / 4 }
  }
  if (closeTo(TBSP * 6, ml)) {
    return { unit: 'cup', amount: 1 / 3 }
  }
  if (closeTo(TBSP * 9, ml)) {
    return { unit: 'cup', amount: 1 / 2 }
  }

  // 1+ whole cups
  const wholeCups = Math.round(ml / CUP)
  if (wholeCups > 0 && closeTo(ml / CUP, wholeCups)) {
    return { unit: 'cup', amount: wholeCups }
  }

  // Fractional cups
  const fractionalPart = (ml / CUP) % 1
  const integerPart = ml / CUP - fractionalPart
  for (const i of [1 / 4, 1 / 3, 1 / 2, 2 / 3, 3 / 4, 4 / 3, 3 / 2, 8 / 3]) {
    if (closeTo(CUP * fractionalPart, CUP * i)) {
      return { unit: 'cup', amount: i + integerPart }
    }
  }

  // Tablespoons
  for (const i of [1, 3 / 2, 2, 3, 4, 5, 6]) {
    if (closeTo(ml, TBSP * i)) {
      return { unit: 'tbsp', amount: i }
    }
  }

  // Teaspoons
  for (const i of [
    1 / 8,
    1 / 4,
    3 / 8,
    1 / 2,
    3 / 4,
    1,
    3 / 2,
    2,
    5 / 2,
    4,
    5 / 4,
    2.5,
    15 / 4
  ]) {
    if (closeTo(ml, TSP * i)) {
      return { unit: 'tsp', amount: i }
    }
  }

  // Pinches
  for (const i of [1, 3]) {
    if (closeTo(ml, PINCH * i)) {
      return { unit: 'pinch', amount: i }
    }
  }

  // Fallback on ML
  return { unit: 'ml', amount: ml }
}
