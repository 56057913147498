import * as Kitchen from '../../../kitchen-support'
import Multiselect from '../../views/multiselect'
import { React, Util, IVariant } from '../common'

interface IProps {
  variant: IVariant
  onChange: (variant: IVariant) => void
}

const COOKWARES = Util.objectValues(Kitchen.COOKWARES)

export default class CookwaresList extends React.PureComponent<IProps> {
  handleChange = (selectedNames: string[]) => {
    const { variant, onChange } = this.props

    const cookwareIds = COOKWARES.filter(
      c => selectedNames.indexOf(c.name) >= 0
    ).map(c => c.id)

    onChange({
      ...variant,
      cookwareIds
    })
  }

  render() {
    return (
      <Multiselect
        placeholder="Add cookware..."
        items={COOKWARES.map(c => c.name)}
        selected={this.props.variant.cookwareIds.map(
          id => Kitchen.findCookware(id).name
        )}
        onChange={this.handleChange}
      />
    )
  }
}
