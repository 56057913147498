import { IRecipe, IVariant, ILineItem } from '../../../types'
import { Variant } from '../common'

export function check(recipe: IRecipe, variants: IVariant[]): string[] {
  const errors: string[] = []

  // Title length
  if (recipe.title.length < 25 || recipe.title.length > 70) {
    errors.push(
      `Title is ${recipe.title.length} characters long. It should be between 25-70 characters`
    )
  }

  // Image presence
  if (!recipe.imageUrl) {
    errors.push('Image is missing')
  }

  // Variety tags
  if (
    recipe.ruleset == 'dinner' &&
    (!recipe.varietyTagIds || recipe.varietyTagIds.length == 0)
  ) {
    errors.push(`No variety tags were chosen.`)
  }

  // Scalings
  {
    const labels = unique(variants.map(v => v.label))

    for (const label of labels) {
      const groupList = variants.filter(v => v.label == label)

      const two = groupList.find(v => v.servingCount == 2)
      const four = groupList.find(v => v.servingCount == 4)
      const six = groupList.find(v => v.servingCount == 6)

      if (!two) {
        errors.push(`Missing 2-serving variant for ${label}`)
        continue
      }

      if (four) {
        // Cooking time for 4 > 2
        if (four.cookingMinutes <= two.cookingMinutes) {
          errors.push(
            `Cooking time is the same for ${label} x 4 and ${label} x 2`
          )
        }

        // Line items are scaled
        const scaled = Variant.twoToFour(two)
        const lineItemDiffs = getLineItemDiffs(four, scaled)
        if (lineItemDiffs.length > 0) {
          errors.push(
            `<strong>${label} x 4</strong> isn't an exact 2x scaling of <strong>${label} x 2</strong>:<br/>
             <small>${lineItemDiffs.join('<br />')}</small>
          `
          )
        }
      } else {
        // 4 should exist
        errors.push(`Missing 4-serving variant for ${label}`)
      }

      if (six) {
        if (four) {
          // Cooking time for 6 > 4
          if (six.cookingMinutes < four.cookingMinutes) {
            errors.push(
              `Cooking time is the same for ${label}x6 and ${label}x4`
            )
          }

          // Line items are scaled
          const scaled = Variant.fourToSix(four)
          const lineItemDiffs = getLineItemDiffs(scaled, six)
          if (lineItemDiffs.length > 0) {
            errors.push(
              `<strong>${label} x 6</strong> isn't an exact 1.5x scaling of <strong>${label} x 4</strong>:<br/>
               <small>${lineItemDiffs.join('<br />')}</small>
              `
            )
          }
        } else {
          // Cooking time for 6 > 2 (if no 4 present)
          if (six.cookingMinutes <= two.cookingMinutes) {
            errors.push(
              `Cooking time is the same for ${label}x6 and ${label}x2`
            )
          }
        }
      } else {
        // 6 should exist
        errors.push(`Missing 6-serving variant for ${label}`)
      }
    }
  }

  return errors
}

function unique<T>(xs: T[]): T[] {
  return Array.from(new Set(xs))
}

function getLineItemDiffs(va: IVariant, vb: IVariant): string[] {
  const lineItemsA = Variant.compileLineItems(va)
  const lineItemsB = Variant.compileLineItems(vb)

  const diffs: string[] = []

  for (const itemA of lineItemsA) {
    const itemB = lineItemsB.find(
      item => item.ingredientId == itemA.ingredientId
    )
    if (itemB) {
      if (itemB.amount != itemA.amount) {
        diffs.push(
          `Expected "${getLineItemMessage(
            itemA
          )}" but found "${getLineItemMessage(itemB)}"`
        )
      }
    } else {
      diffs.push(`Expected "${getLineItemMessage(itemA)}" but found nothing`)
    }
  }

  for (const itemB of lineItemsB) {
    const itemA = lineItemsA.find(
      item => item.ingredientId == itemB.ingredientId
    )
    if (!itemA) {
      diffs.push(`Unexpected "${getLineItemMessage(itemB)}"`)
    }
  }

  return diffs
}

function getLineItemMessage(item: ILineItem): string {
  return Variant.compileLineItemMessage(item, 'us', true)
}
