import { React, M, IVariant } from '../common'

interface IProps {
  variant: IVariant
  onChange: (variant: IVariant) => void
}

export default class ServingCountField extends React.PureComponent<IProps> {
  handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { variant, onChange } = this.props

    const servingCount = parseInt(event.target.value)

    onChange({
      ...variant,
      servingCount
    })
  }

  render() {
    return (
      <M.Select
        value={this.props.variant.servingCount}
        onChange={this.handleChange}
        displayEmpty
        style={{ marginBottom: 16 }}
        fullWidth
      >
        <M.MenuItem value={2}>2 servings</M.MenuItem>
        <M.MenuItem value={4}>4 servings</M.MenuItem>
        <M.MenuItem value={6}>6 servings</M.MenuItem>
      </M.Select>
    )
  }
}
