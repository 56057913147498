import { IUser } from '../../types'
import * as Palette from './palette'

export function getColor(user: IUser): string {
  return Palette.pick(user.id)
}

export function getInitial(user: IUser): string {
  return user.displayName[0]
}
