import * as Kitchen from '../../kitchen-support'
import { IVariant, ILineItem, IInstruction, IUnitFamily } from '../../types'
import * as Util from './util'
import * as Palette from './palette'
import * as Instruction from './instruction'
import * as Waste from './waste'
import { convertMlToClosestCommon } from './common-unit'

const COUNT_UNIT = '-'
const DEFAULT_UNIT_FAMILY: IUnitFamily = 'us'

export function sortByRank(variants: IVariant[]): IVariant[] {
  return variants.sort((a, b) => a.rank - b.rank)
}

export function getAllLineItems(variant: IVariant): ILineItem[] {
  const lineItems: ILineItem[] = []

  for (const instruction of variant.instructions) {
    for (const lineItem of Instruction.getLineItems(instruction)) {
      lineItems.push(lineItem)
    }
  }

  return lineItems
}

export function compileNutrition(variant: IVariant) {
  return Kitchen.compileNutrition(
    getAllLineItems(variant),
    DEFAULT_UNIT_FAMILY,
    variant.servingCount
  )
}

export function getColor(variant: IVariant): string {
  return Palette.pick(variant.label)
}

export function duplicate(variant: IVariant, rank: number): IVariant {
  return {
    ...variant,
    id: Util.makeId(),
    label: 'Copy of ' + variant.label,
    rank,
    comments: []
  }
}

export function makeDefault(recipeId: string): IVariant {
  return make(recipeId, 'Default', 0)
}

export function make(recipeId: string, label: string, rank: number): IVariant {
  return {
    id: Util.makeId(),
    rank,
    label,
    title: '',
    recipeId: recipeId,
    servingCount: 2,
    cookingMinutes: 30,
    lineItems: [],
    instructions: [],
    cookwareIds: [],
    comments: [],
    allowedMenuIds: [],
    isDeleted: false,
    ...Util.makeTimestamps()
  }
}

export function twoToFour(variant: IVariant): IVariant {
  const instructions = variant.instructions.map(ins => ({
    ...ins,
    lineItems: (ins.lineItems || []).map(li => ({
      ...li,
      amount: li.amount * 2
    }))
  }))

  return {
    ...variant,
    servingCount: 4,
    instructions
  }
}

export function fourToSix(variant: IVariant): IVariant {
  const instructions = variant.instructions.map(ins => ({
    ...ins,
    lineItems: (ins.lineItems || []).map(li => ({
      ...li,
      amount: li.amount * 1.5
    }))
  }))

  return {
    ...variant,
    servingCount: 6,
    instructions
  }
}

export function softDelete(variant: IVariant): IVariant {
  return {
    ...variant,
    isDeleted: true
  }
}

export function compileLineItems(variant: IVariant): ILineItem[] {
  const amounts: { [k: string]: number } = {}

  for (const instruction of variant.instructions) {
    for (const lineItem of instruction.lineItems) {
      if (lineItem.ingredientId == Kitchen.WATER.id) {
        continue
      }
      const { ingredientId, amount } = lineItem
      if (!amounts[ingredientId]) {
        amounts[ingredientId] = amount
      } else {
        amounts[ingredientId] += amount
      }
    }
  }

  const lineItems: ILineItem[] = []

  for (const ingredientId in amounts) {
    lineItems.push({
      id: (lineItems.length + 1).toString(),
      ingredientId: parseInt(ingredientId),
      amount: amounts[ingredientId]
    })
  }

  return lineItems
}

export function compileSecondaryMessage(
  lineItems: ILineItem[],
  unitFamily: IUnitFamily
): string | undefined {
  if (lineItems.length > 0) {
    return lineItems
      .map(item => compileLineItemMessage(item, unitFamily, true))
      .join('\n')
  }
}

export function compileLineItemMessage(
  item: ILineItem,
  unitFamily: IUnitFamily,
  showEssentialAmount: boolean = false
): string {
  const ingredient = Kitchen.findIngredient(item.ingredientId)
  const unit = Kitchen.findUnit(ingredient.unitAssignments[unitFamily].unitId)

  // Only show the ingredient name for essential ingredients (unless overridden)
  if (!showEssentialAmount && ingredient.isEssential) {
    return ingredient.name
  }

  // NOTE: Fractions should be singular (e.g. 1/2 lemon),
  // and there is no 0 amount, so this works for pluralization.
  const isPlural = item.amount > 1

  const ingredientName = isPlural ? ingredient.pluralName : ingredient.name
  let unitName = isPlural ? unit.pluralName : unit.name
  let amount = item.amount

  // Convert items measured in "ml" to the closest nice "common" unit.
  if (unitName == 'ml') {
    const commonUnit = convertMlToClosestCommon(item.amount)
    unitName = commonUnit.unit
    amount = commonUnit.amount
  }

  const amountStr = Util.numberToFractionString(amount)

  if (unitName == COUNT_UNIT) {
    // Show no unit name for count units
    return `${amountStr} ${ingredientName}`
  } else {
    return `${amountStr} ${unitName}${getAmountDescription(
      unitName,
      amount
    )} ${ingredientName}`
  }
}

export function convertToMetric(variant: IVariant): IVariant {
  return {
    ...variant,
    unitFamily: 'metric',
    instructions: variant.instructions.map(convertInstructionToMetric)
  }
}

function convertInstructionToMetric(instruction: IInstruction): IInstruction {
  return {
    ...instruction,
    message: convertInstructionMessageToMetric(instruction.message),
    lineItems: instruction.lineItems.map(convertLineItemToMetric)
  }
}

function convertInstructionMessageToMetric(msg: string): string {
  // Replace imperial cooking temperatures with metric+imperial
  // e.g. 450°F => 230°C (450°F)

  const re = /(\d+)°F/g

  return msg.replace(re, x => {
    const t = x.split('°')[0]

    const f = parseInt(t)

    if (Number.isNaN(f)) {
      throw new Error('Failed to parse temperature')
    }

    // Celsius rounded to nearest 5
    const c = 5 * Math.round(((f - 32) * 5) / 9 / 5)

    return `${c}°C (${f}°F)`
  })
}

function convertLineItemToMetric(lineItem: ILineItem): ILineItem {
  // The only amounts that actually change are for "lb", "oz", and "fl oz"
  const { unitId } = Kitchen.findIngredient(
    lineItem.ingredientId
  ).unitAssignments.us

  return {
    ...lineItem,
    amount: convertAmountToMetric(unitId, lineItem.amount)
  }
}

export function convertAmountToMetric(unitId: number, amount: number): number {
  // The only amounts that actually change are for "lb", "oz", and "fl oz"
  switch (unitId) {
    case 10: // lb -> kg
      return Math.round(0.453592 * amount * 100) / 100
    case 11: // oz -> g
      return Math.round(28.3495 * amount)
    case 60: // fl oz -> ml
      return Math.round(29.5735 * amount)
    default:
      return amount
  }
}

export function getAmountDescription(unitName: string, amount: number): string {
  if (unitName == 'fl oz') {
    // Add an extra description to items measured in "fl oz",
    // showing equivalent cups as a helpful hint
    const cups = amount / CUP_IN_FL_OZ
    const prettyAmount = Util.numberToPrettyFractionString(cups)

    // Only show the extra amount if it is a pretty fraction that
    // isn't zero.
    if (prettyAmount && prettyAmount != '0') {
      const isPlural = cups > 1
      return ` (${prettyAmount} cup${isPlural ? 's' : ''})`
    } else {
      return ''
    }
  } else if (unitName == 'ml') {
    // Add an extra description to items measured in "ml",
    // showing equivalent cups as a helpful hint
    // NOTE: Most "ml" items are converted to another common unit.
    // These are just the ingredients which are fixed to "ml" for
    // metric.
    const cups = amount / CUP_IN_ML
    const prettyAmount = Util.numberToPrettyFractionString(cups)

    // Only show the extra amount if it is a pretty fraction that
    // isn't zero.
    if (prettyAmount && prettyAmount != '0') {
      const isPlural = cups > 1
      return ` (${prettyAmount} cup${isPlural ? 's' : ''})`
    } else {
      return ''
    }
  } else {
    return ''
  }
}

export function getWasteReport(v: IVariant) {
  const lineItems = compileLineItems(v)
  return Waste.getReport(lineItems, v.unitFamily || DEFAULT_UNIT_FAMILY)
}

export function setImage(
  variant: IVariant,
  imageUrl: string,
  thumbnailUrl: string
): IVariant {
  return {
    ...variant,
    imageUrl: imageUrl,
    thumbnailUrl: thumbnailUrl,
    hasNewImage: true
  }
}

export function clearImage(variant: IVariant): IVariant {
  const updated = { ...variant, hasNewImage: true }
  // NOTE: We delete the properties entirely instead of setting
  // to undefined, because firestore doesn't support undefined.
  delete updated.imageUrl
  delete updated.thumbnailUrl
  return updated
}

const CUP_IN_ML = 240
const CUP_IN_FL_OZ = 8
