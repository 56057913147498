import DB from './database'
import * as Unit from './unit'
import * as Ingredient from './ingredient'
import * as Nutrition from './nutrition'
import * as Cookware from './cookware'
import { IMenu, IVarietyTag } from './types'

export const MENUS = DB.menus

export function findMenu(id: number): IMenu {
  const menu = MENUS[id]
  if (!menu) {
    throw new Error('Menu not found: ' + id)
  }

  return menu
}

export const VARIETY_TAGS = DB.varietyTags

export function findVarietyTag(id: number): IVarietyTag {
  const tag = VARIETY_TAGS[id]
  if (!tag) {
    throw new Error('Variety tag not found: ' + id)
  }

  return tag
}

export const INGREDIENTS = Ingredient.ALL

export const WATER = Ingredient.WATER

export const UNITS = Unit.ALL

export const COOKWARES = Cookware.ALL

export const findUnit = Unit.find

export const findIngredient = Ingredient.find

export const findCookware = Cookware.find

export const compileNutrition = Nutrition.compile
