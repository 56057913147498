import {
  React,
  ISceneProps,
  Subscribe,
  IVariant,
  IRecipe,
  styled,
  M,
  Variant,
  Navbar
} from '../common'
import VariantReport from './variant-report'
import * as Checker from './checker'
import RecipeErrorList from './recipe-error-list'

interface IProps extends ISceneProps {
  params: { recipeId: string }
}

export default class RecipeChecker extends React.Component<IProps> {
  handleClose = () => {
    const { nav, params } = this.props
    nav.go('Recipe', { id: params.recipeId })
  }

  renderContent = (recipe: IRecipe, variants: IVariant[]) => {
    variants = Variant.sortByRank(variants.filter(v => !v.isDeleted))

    const report = Checker.makeReport(recipe, variants)

    const hasRecipeErrors = report.recipeErrors.length > 0

    return (
      <Container>
        <Navbar onBack={this.handleClose} title={`Checker | ${recipe.title}`} />
        <Content>
          {hasRecipeErrors && (
            <>
              <SectionTitle>
                I found some possible issues with this recipe:
              </SectionTitle>
              <RecipeErrorList errors={report.recipeErrors} />
            </>
          )}

          <SectionTitle>
            Here's what I found checking each variant:
          </SectionTitle>
          {variants.map(variant => (
            <VariantReport
              key={variant.id}
              variant={variant}
              report={report.variantReports[variant.id]}
            />
          ))}
        </Content>
      </Container>
    )
  }

  render() {
    const { store, params } = this.props
    return (
      <Subscribe
        subscription={store.subscribeToRecipe}
        param={params.recipeId}
        render={(isRecipeLoaded, recipe) => (
          <Subscribe
            subscription={store.subscribeToRecipeVariants}
            param={params.recipeId}
            render={(isVariantsLoaded, variants) => {
              if (isRecipeLoaded && isVariantsLoaded && recipe && variants) {
                return this.renderContent(recipe, variants)
              } else {
                return null
              }
            }}
          />
        )}
      />
    )
  }
}

const Container = styled.div.attrs({})`
  display: flex;
  flex-direction: column;
  padding: 96px 32px 64px;
`

const Content = styled('div')`
  margin: 0 auto;
  flex: 1;
  width: 800px;
`
const SectionTitle = styled(M.Typography).attrs({
  variant: 'subheading',
  color: 'textSecondary'
})`
  margin-bottom: 8px !important;
`
