import { ICookware } from './types'
import DB from './database'

export const ALL = DB.cookwares

export function find(id: number): ICookware {
  const cookware = ALL[id]

  if (!cookware) {
    throw new Error('Cookware not found: ' + id)
  }

  return cookware
}
