import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  HookProvided
} from 'react-beautiful-dnd'
import {
  React,
  styled,
  M,
  Instruction,
  IInstruction,
  IInstructionSearchResult,
  IVariant,
  Util
} from '../common'
import InstructionView from './instruction'
import { IRecipeRuleset } from '../../../types'

interface IProps {
  variant: IVariant
  ruleset: IRecipeRuleset
  onChange: (variant: IVariant) => void
  getSuggestedInstructions: (msg: string) => Promise<IInstructionSearchResult[]>
}

interface IState {
  expandedInstructionId?: string
}

export default class InstructionList extends React.PureComponent<
  IProps,
  IState
> {
  state: IState = {}

  setInstructions = (instructions: IInstruction[]) => {
    const { variant, onChange } = this.props
    onChange({
      ...variant,
      instructions
    })
  }

  handleDragEnd = (result: DropResult, _provided: HookProvided) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    this.setInstructions(
      Util.reorder(
        this.props.variant.instructions,
        result.source.index,
        result.destination.index
      )
    )
  }

  handleDelete = (deletedId: string) => {
    this.setInstructions(
      this.props.variant.instructions.filter(i => i.id != deletedId)
    )
  }

  handleChange = (updated: IInstruction) => {
    this.setInstructions(
      this.props.variant.instructions.map(i =>
        i.id == updated.id ? updated : i
      )
    )
  }

  handleAdd = () => {
    const newInstruction = Instruction.make()
    this.setInstructions(this.props.variant.instructions.concat(newInstruction))

    this.setState({
      expandedInstructionId: newInstruction.id
    })
  }

  handleToggleInstruction = (id: string, expanded: boolean) => {
    if (expanded) {
      this.setState({ expandedInstructionId: id })
    } else {
      this.setState({ expandedInstructionId: undefined })
    }
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <Container>
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="droppable-instruction">
            {provided => (
              <List ref={provided.innerRef}>
                {this.props.variant.instructions.map((instruction, index) => (
                  <Draggable
                    key={instruction.id}
                    draggableId={instruction.id.toString()}
                    index={index}
                  >
                    {provided => (
                      <InstructionView
                        key={instruction.id}
                        instruction={instruction}
                        ruleset={this.props.ruleset}
                        number={index + 1}
                        onDelete={this.handleDelete}
                        onChange={this.handleChange}
                        provided={provided}
                        isExpanded={
                          this.state.expandedInstructionId == instruction.id
                        }
                        onToggle={this.handleToggleInstruction}
                        getSuggestedInstructions={
                          this.props.getSuggestedInstructions
                        }
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>

          <AddButton onClick={this.handleAdd}>Add an instruction</AddButton>
        </DragDropContext>
      </Container>
    )
  }
}

const Container = styled.div`
  margin-bottom: 32px;
`

const AddButton = styled(M.Button).attrs({
  color: 'primary',
  variant: 'contained'
})`
  margin-top: 16px !important;
`

const List = styled.div`
  flex: 1;
`
