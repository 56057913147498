import { React, Navbar, IVariant, ISceneProps, Subscribe } from '../common'
import Content from './content'

interface IProps extends ISceneProps {
  params: { id: string }
}

export default class Container extends React.Component<IProps> {
  handleChangeVariant = (variant: IVariant) => {
    this.props.store.setVariant(variant)
    // Mark the recipe as updated
    this.props.store.touchRecipe(variant.recipeId)
  }

  renderContent = (isLoaded: boolean, variant: IVariant) => {
    const navbar = <Navbar />
    const loading = navbar
    const notFound = (
      <div>
        {navbar}
        <h2>Variant not found</h2>
      </div>
    )

    if (isLoaded) {
      if (variant) {
        return (
          <Subscribe
            subscription={this.props.store.subscribeToRecipe}
            param={variant.recipeId}
            render={(isRecipeLoaded, recipe) => {
              if (isRecipeLoaded) {
                if (recipe) {
                  return (
                    <Content
                      {...this.props}
                      variant={variant}
                      recipe={recipe}
                      onChange={this.handleChangeVariant}
                    />
                  )
                } else {
                  notFound
                }
              } else {
                return loading
              }
            }}
          />
        )
      } else {
        return notFound
      }
    } else {
      return loading
    }
  }

  render() {
    const { store, params } = this.props

    return (
      <Subscribe
        subscription={store.subscribeToVariant}
        param={params.id}
        render={this.renderContent}
      />
    )
  }
}
