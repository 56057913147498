import { IUnit } from './types'
import DB from './database'

export const ALL = DB.units

export function find(id: number): IUnit {
  const unit = ALL[id]

  if (!unit) {
    throw new Error('Unit not found: ' + id)
  }

  return unit
}
