import {
  React,
  M,
  Icon,
  Avatar,
  styled,
  withTargetValue,
  IComment,
  IUser
} from '../common'
import TimeAgo from './time-ago'

interface IProps {
  comment: IComment
  author: IUser
  currentUserId: string
  onChange: (comment: IComment) => void
  onDelete: (id: string) => void
}

interface IState {
  editedBody?: string
}

export default class Comment extends React.Component<IProps, IState> {
  state: IState = {}

  handleDelete = () => {
    this.props.onDelete(this.props.comment.id)
  }

  handleEdit = () => {
    this.setState({ editedBody: this.props.comment.body })
  }

  clearEditedBody = () => {
    this.setState({ editedBody: undefined })
  }

  handleCancelEditing = () => {
    this.clearEditedBody()
  }

  handleSave = () => {
    const { comment, onChange } = this.props
    const { editedBody } = this.state

    if (typeof editedBody == 'string') {
      onChange({
        ...comment,
        body: editedBody
      })
    }

    this.clearEditedBody()
  }

  handleChange = (editedBody: string) => {
    this.setState({ editedBody })
  }

  isPoster = (): boolean => {
    return this.props.comment.authorId == this.props.currentUserId
  }

  renderActions = () => {
    return (
      <>
        &nbsp;
        <Action onClick={this.handleEdit}>edit</Action>
        &nbsp;
        <Action onClick={this.handleDelete}>delete</Action>
      </>
    )
  }

  render() {
    const { comment, author } = this.props
    const { editedBody } = this.state

    if (typeof editedBody == 'string') {
      return (
        <EditingContainer>
          <M.TextField
            autoFocus
            multiline
            fullWidth
            value={editedBody}
            onChange={withTargetValue(this.handleChange)}
          />
          <div style={{ display: 'flex' }}>
            <Control onClick={this.handleSave}>
              <Icon.CheckCircle style={{ width: 20 }} color="primary" />
            </Control>
            <Control onClick={this.handleCancelEditing}>
              <Icon.Cancel style={{ width: 20 }} color="secondary" />
            </Control>
          </div>
        </EditingContainer>
      )
    } else {
      return (
        <Container>
          <Avatar user={author} size={30} />
          <Content>
            <Body>{comment.body}</Body>
            <Details>
              <Info>
                {this.isPoster() ? 'You' : author.displayName} said this{' '}
                <TimeAgo startTime={comment.createdAt} />
              </Info>
              {this.renderActions()}
            </Details>
          </Content>
        </Container>
      )
    }
  }
}

const Container = styled.div`
  margin-top: 16px;
  display: flex;
`

const Content = styled.div`
  width: 100%;
  margin-left: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ededed;
`

const Details = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 1.5;
  color: #666;
  align-items: center;
`

const Info = styled(M.Typography).attrs({
  variant: 'caption'
})``

const EditingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`

const Action = styled(M.Typography).attrs({
  variant: 'caption'
})`
  text-decoration: underline;
  cursor: pointer;
`

const Control = styled(M.IconButton)`
  width: 30px !important;
  height: 30px !important;
`

const Body = styled(M.Typography).attrs({})`
  max-width: 700px;
  white-space: pre-wrap;
`
