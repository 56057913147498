import * as Colors from '@material-ui/core/colors'

const SHADE = '500'

export const COLORS = [
  Colors.red,
  Colors.amber,
  Colors.blue,
  Colors.brown,
  Colors.cyan,
  Colors.deepOrange,
  Colors.deepPurple,
  Colors.green,
  Colors.grey,
  Colors.indigo,
  Colors.lime,
  Colors.red,
  Colors.teal
].map(c => c[SHADE])

export function pick(id: string): string {
  const idx = Math.abs(hashCode(id)) % COLORS.length
  return COLORS[idx]
}

function hashCode(s: string): number {
  return s.split('').reduce(function(a, b) {
    a = (a << 5) - a + b.charCodeAt(0)
    return a & a
  }, 0)
}
