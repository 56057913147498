import * as React from 'react'
import * as Kitchen from '../../../kitchen-support'
import * as AutoGlutenFree from '../../services/auto-gluten-free'
import {
  ISceneProps,
  Subscribe,
  LineItem,
  IVariant,
  IRecipe,
  ILineItem,
  IInstruction,
  IUnitFamily,
  Variant,
  M,
  styled,
  Icon
} from '../common'

interface IProps extends ISceneProps {
  params: { id: string }
}

interface IState {
  tab: string
  unitFamily: IUnitFamily
  convertToGlutenFree: boolean
}

export default class Preview extends React.Component<IProps, IState> {
  state: IState = {
    tab: 'ingredients',
    unitFamily: 'us',
    convertToGlutenFree: false
  }

  handleClose = () => {
    const { nav, params } = this.props
    nav.go('Variant', { id: params.id })
  }

  handleChangeTab = (_: React.ChangeEvent, tab: string) => {
    this.setState({ tab })
  }

  handleChangeUnitFamily = (event: React.ChangeEvent<HTMLInputElement>) => {
    const unitFamily = event.target.checked ? 'metric' : 'us'
    this.setState({ unitFamily })
  }

  handleToggleConvertToGlutenFree = () => {
    this.setState({ convertToGlutenFree: !this.state.convertToGlutenFree })
  }

  convertVariant = (variant: IVariant): IVariant => {
    const { unitFamily, convertToGlutenFree } = this.state

    if (convertToGlutenFree) {
      variant = AutoGlutenFree.convertVariantToGlutenFree(variant)
    }

    if (unitFamily == 'metric') {
      variant = Variant.convertToMetric(variant)
    }

    return variant
  }

  renderCurrentTab(variant: IVariant) {
    const items = LineItem.sortByIngredient(Variant.compileLineItems(variant))

    switch (this.state.tab) {
      case 'cookware':
        return (
          <TabContent>
            {variant.cookwareIds.map(id => (
              <Cookware key={id} id={id} />
            ))}
          </TabContent>
        )
      case 'ingredients':
        return (
          <TabContent>
            {items.map(item => (
              <LineItemRow
                key={item.id}
                item={item}
                unitFamily={this.state.unitFamily}
              />
            ))}
          </TabContent>
        )
      case 'instructions':
        return (
          <TabContent>
            {variant.instructions.map((instruction, i) => (
              <Instruction
                key={instruction.id}
                instruction={instruction}
                index={i + 1}
                unitFamily={this.state.unitFamily}
              />
            ))}
          </TabContent>
        )
    }
  }

  renderContent(rawVariant: IVariant, recipe: IRecipe) {
    const variant = this.convertVariant(rawVariant)

    const title = variant.title || recipe.title

    const showGlutenToggle =
      AutoGlutenFree.variantHasGluten(rawVariant) &&
      AutoGlutenFree.canMakeVariantGlutenFree(rawVariant)

    const previewImage = variant.imageUrl ? variant.imageUrl : recipe.imageUrl

    return (
      <Container>
        <Close>
          <M.IconButton onClick={this.handleClose}>
            <Icon.Cancel />
          </M.IconButton>
        </Close>
        <Phone>
          {previewImage && <img src={previewImage} width="100%" />}
          {!previewImage && (
            <PlaceholderImage>
              <Icon.Restaurant
                style={{ width: 128, height: 128, color: 'rgba(0,0,0,0.15)' }}
              />
            </PlaceholderImage>
          )}

          <Title>{title}</Title>

          <Description>
            {variant.cookingMinutes} min - {variant.servingCount} servings
          </Description>

          <M.Tabs
            value={this.state.tab}
            onChange={this.handleChangeTab}
            fullWidth={true}
          >
            <M.Tab label="Cookware" value="cookware" />
            <M.Tab label="Ingredients" value="ingredients" />
            <M.Tab label="Instructions" value="instructions" />
          </M.Tabs>

          {this.renderCurrentTab(variant)}

          <SwitchBox>
            <SwitchContainer>
              <M.Typography variant="body2">
                {this.state.unitFamily.toUpperCase()}
              </M.Typography>
              <M.Switch
                checked={this.state.unitFamily == 'metric'}
                onChange={this.handleChangeUnitFamily}
              />
            </SwitchContainer>
            {showGlutenToggle && (
              <SwitchContainer>
                <>
                  <M.Typography variant="body2">
                    {this.state.convertToGlutenFree
                      ? 'Gluten-free'
                      : 'Glutenous'}
                  </M.Typography>
                  <M.Switch
                    checked={this.state.convertToGlutenFree}
                    onChange={this.handleToggleConvertToGlutenFree}
                  />
                </>
              </SwitchContainer>
            )}
          </SwitchBox>
        </Phone>
      </Container>
    )
  }

  render() {
    return (
      <Subscribe
        subscription={this.props.store.subscribeToVariant}
        param={this.props.params.id}
        render={(isLoaded, variant) => {
          if (isLoaded && variant) {
            return (
              <Subscribe
                subscription={this.props.store.subscribeToRecipe}
                param={variant.recipeId}
                render={(isLoaded, recipe) => {
                  if (isLoaded && recipe) {
                    return this.renderContent(variant, recipe)
                  } else {
                    return null
                  }
                }}
              />
            )
          } else {
            return null
          }
        }}
      />
    )
  }
}

const LineItemRow = (props: { item: ILineItem; unitFamily: IUnitFamily }) => {
  return (
    <M.Typography variant="body2" style={{ marginBottom: 8 }}>
      {Variant.compileLineItemMessage(props.item, props.unitFamily)}
    </M.Typography>
  )
}

const InstructionLineItem = (props: {
  item: ILineItem
  unitFamily: IUnitFamily
}) => {
  return (
    <div
      style={{
        backgroundColor: 'rgb(238, 238, 238)',
        display: 'inline-block',
        marginRight: 4,
        marginBottom: 4,
        paddingLeft: 8,
        paddingRight: 8,
        borderRadius: 24
      }}
    >
      <M.Typography variant="body2">
        {Variant.compileLineItemMessage(props.item, props.unitFamily, true)}
      </M.Typography>
    </div>
  )
}

const Cookware = ({ id }: { id: number }) => {
  const cookware = Kitchen.findCookware(id)

  return (
    <M.Typography variant="body2" style={{ marginBottom: 8 }}>
      {cookware.name}
    </M.Typography>
  )
}

const Instruction = (props: {
  instruction: IInstruction
  index: number
  unitFamily: IUnitFamily
}) => {
  return (
    <div style={{ display: 'flex', paddingBottom: 12 }}>
      <M.Typography variant="body1">
        <strong style={{ marginRight: 8 }}>{props.index}</strong>
      </M.Typography>
      <div>
        <M.Typography variant="body1" style={{ marginBottom: 4 }}>
          {props.instruction.message}
        </M.Typography>
        {props.instruction.lineItems.map(item => (
          <InstructionLineItem
            key={item.id}
            item={item}
            unitFamily={props.unitFamily}
          />
        ))}
      </div>
    </div>
  )
}

const TabContent = styled.div`
  padding: 16px;
  min-height: 500px;
`

const Phone = styled.div`
  border: 5px solid #ccc;
  border-radius: 24px;
  box-shadow: 0 16px 60px -30px rgba(0, 0, 0, 0.5),
    inset 1px 1px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: transparent;
`

const Container = styled.div`
  width: 500px;
  margin: 32px auto 0;
`

const Title = styled(M.Typography).attrs({ variant: 'title' })`
  margin-top: 16px !important;
  text-align: center;
`

const Close = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
`

const PlaceholderImage = styled.div`
  width: 100%;
  height: 484px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9e9e9;
`

const Description = styled(M.Typography).attrs({ variant: 'body1' })`
  text-align: center;
`

const SwitchBox = styled.div`
  margin-right: 16px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`

const SwitchContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
`
