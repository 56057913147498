import { React, M, styled, IVariant, IInstruction } from '../common'
import * as VariantChecks from './variant-checks'
import InstructionReport from './instruction-report'
import CheckIcon from '@material-ui/icons/Check'

interface IProps {
  variant: IVariant
  report: VariantChecks.IVariantReport
}

export default class VariantReport extends React.PureComponent<IProps> {
  renderInstruction(
    index: number,
    instruction: IInstruction,
    report: VariantChecks.IVariantReport
  ) {
    const errors = report.instructions[instruction.id]

    return (
      <InstructionReport
        position={index + 1}
        instruction={instruction}
        errors={errors}
        key={index}
      />
    )
  }

  render() {
    const { variant, report } = this.props

    const isEmpty = VariantChecks.isEmptyReport(report)

    return (
      <>
        <VariantHeader>
          <VariantTitle>
            {variant.label} × {variant.servingCount}
          </VariantTitle>
          {isEmpty && (
            <SuccessMessage>
              <CheckIcon />
              <MessageText>looks good</MessageText>
            </SuccessMessage>
          )}

          {!isEmpty && (
            <SuccessMessage>
              <MessageText>has some possible issues</MessageText>
            </SuccessMessage>
          )}
        </VariantHeader>

        {!isEmpty && (
          <Card>
            <CardContent>
              <div>
                <List>
                  {report.errors.map(error => (
                    <ListItem key={error}>
                      <Text>{error}</Text>
                    </ListItem>
                  ))}
                </List>
              </div>
            </CardContent>
            {variant.instructions.map((instruction, index) =>
              this.renderInstruction(index, instruction, report)
            )}
          </Card>
        )}
      </>
    )
  }
}

const Card = styled(M.Card).attrs({})`
  margin-bottom: 32px;
`

const CardContent = styled(M.CardContent).attrs({})``

const VariantHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const VariantTitle = styled(M.Typography).attrs({
  gutterBottom: false
})`
  font-size: 24px !important;
  font-weight: 500 !important;
`

const SuccessMessage = styled.div`
  display: flex;
  flex-direction: 'row';
  justify-content: 'center';
  margin-left: auto;
`

const MessageText = styled(M.Typography).attrs({
  gutterBottom: false
})`
  margin-top 2px !important;
  margin-left: 4px !important;
`

const List = styled.ul`
  margin-top: 8px;
  padding-left: 18px;
  margin-bottom: 0;
`

const ListItem = styled.li`
  margin-bottom: 4px;
`

const Text = styled(M.Typography).attrs({
  variant: 'subheading'
})``
