import * as Kitchen from '../../../kitchen-support'
import * as Util from '../../services/util'
import {
  ICommonUnit,
  COMMON_UNITS,
  convertCommonToMl,
  convertMlToClosestCommon,
  BASE_COMMON_UNIT
} from '../../services/common-unit'
import { React, M, styled, ILineItem, withTargetValue, Icon } from '../common'

interface IProps {
  item: ILineItem
  dragHandleProps: any
  commonProps: {
    onChange: (item: ILineItem) => void
    onDelete: (id: string) => void
  }
}

interface IState {
  amount: string
  isEditing: boolean
  commonUnit: ICommonUnit
}

const INITIAL_STATE: IState = {
  amount: '',
  commonUnit: 'tsp',
  isEditing: false
}

export default class LineItem extends React.Component<IProps, IState> {
  state: IState = INITIAL_STATE

  handleDelete = () => {
    const { item, commonProps } = this.props
    commonProps.onDelete(item.id)
  }

  handleCancelEditing = () => {
    this.setState({ isEditing: false })
  }

  handleChangeIngredient = (ingredientId: number) => {
    const { item, commonProps } = this.props

    commonProps.onChange({
      ...item,
      ingredientId,
      amount: 1
    })

    this.handleCancelEditing()
  }

  updateAmount = (amount: number) => {
    const { item, commonProps } = this.props

    commonProps.onChange({
      ...item,
      amount
    })
  }

  handleChangeAmount = (amount: string) => {
    this.setState({ amount })
  }

  handleChangeCommonUnit = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const commonUnit = event.target.value as ICommonUnit
    this.setState({ commonUnit })
  }

  handleEdit = () => {
    const { item } = this.props

    if (this.isCommonUnit()) {
      const common = convertMlToClosestCommon(item.amount)

      this.setState({
        isEditing: true,
        amount: common.amount.toString(),
        commonUnit: common.unit
      })
    } else {
      this.setState({
        isEditing: true,
        amount: item.amount.toString()
      })
    }
  }

  handleSave = () => {
    const { amount, commonUnit } = this.state

    try {
      const amountNum = parseFraction(amount)

      if (this.isCommonUnit()) {
        this.updateAmount(convertCommonToMl(commonUnit, amountNum))
      } else {
        this.updateAmount(amountNum)
      }
    } catch (e) {
      alert('Invalid number')
    }

    this.setState(INITIAL_STATE)
  }

  handleCancel = () => {
    this.setState(INITIAL_STATE)
  }

  getIngredient() {
    return Kitchen.findIngredient(this.props.item.ingredientId)
  }

  getUnitName(): string {
    const { item } = this.props
    const ingredient = this.getIngredient()
    const unit =
      ingredient && Kitchen.findUnit(ingredient.unitAssignments.us.unitId)

    // NOTE: We never have "0" of an ingredient, so making this
    // > 1 works and handles fractions properly.
    return item.amount > 1 ? unit.pluralName : unit.name
  }

  isCommonUnit = () => {
    return this.getUnitName() == BASE_COMMON_UNIT
  }

  renderEditingUnit() {
    const unitName = this.getUnitName()
    const { commonUnit } = this.state

    if (this.isCommonUnit()) {
      return (
        <CommonSelector>
          <M.Select
            value={commonUnit}
            onChange={this.handleChangeCommonUnit}
            displayEmpty
          >
            {COMMON_UNITS.map(unit => (
              <M.MenuItem key={unit} value={unit}>
                {unit}
              </M.MenuItem>
            ))}
          </M.Select>
        </CommonSelector>
      )
    } else {
      return <Unit>{unitName}</Unit>
    }
  }

  renderContent() {
    const { item } = this.props
    const ingredient = this.getIngredient()

    let amount = item.amount
    let unit = this.getUnitName()

    if (unit == 'ml') {
      const common = convertMlToClosestCommon(amount)
      amount = common.amount
      unit = common.unit
    }

    return (
      <Container>
        <div style={DRAG_HANDLE_STYLE} {...this.props.dragHandleProps}>
          ·
        </div>
        <Name>{ingredient.name}</Name>
        <Amount>{Util.numberToFractionString(amount)}</Amount>
        <Unit>{unit}</Unit>
        <EditButton onClick={this.handleEdit}>
          <EditIcon />
        </EditButton>
        <DeleteButton onClick={this.handleDelete}>
          <DeleteIcon />
        </DeleteButton>
      </Container>
    )
  }

  renderEditing() {
    const { amount } = this.state
    const ingredient = this.getIngredient()

    const amountFloat = parseFloat(amount)
    const isValid = !Number.isNaN(amountFloat) && amountFloat > 0

    return (
      <Container>
        <div style={DRAG_HANDLE_STYLE} {...this.props.dragHandleProps}>
          ·
        </div>
        <Name>{ingredient.name}</Name>
        <AmountInput
          value={amount}
          onChange={withTargetValue(this.handleChangeAmount)}
        />
        {this.renderEditingUnit()}
        <SaveButton
          onClick={this.handleSave}
          disabled={!isValid}
          style={{ marginRight: -16 }}
        >
          <SaveIcon color={isValid ? 'primary' : 'secondary'} />
        </SaveButton>
        <CancelButton onClick={this.handleCancel}>
          <CancelIcon />
        </CancelButton>
      </Container>
    )
  }

  render() {
    const { isEditing } = this.state
    return isEditing ? this.renderEditing() : this.renderContent()
  }
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 28px;
`

const CommonSelector = styled.div`
  margin-left: 24px;
  background-color: #f3f3f3;
`

const AmountInput = styled(M.TextField).attrs({
  autoFocus: true,
  inputProps: {
    style: { textAlign: 'right' }
  }
})`
  width: 75px;
`

const DeleteButton = styled(M.IconButton)``

const DeleteIcon = styled(Icon.Delete)`
  width: 18px !important;
  height: 18px !important;
`

const EditButton = styled(M.IconButton)`
  margin-left: 8px !important;
  margin-right: -8px !important;
`

const EditIcon = styled(Icon.Edit)`
  width: 18px !important;
  height: 18px !important;
`

const SaveButton = styled(M.IconButton)`
  margin-left: 8px !important;
`

const SaveIcon = styled(Icon.CheckCircle)`
  width: 18px !important;
  height: 18px !important;
`

const CancelButton = styled(M.IconButton)`
  margin-left: 8px !important;
`

const CancelIcon = styled(Icon.Cancel)`
  width: 18px !important;
  height: 18px !important;
`

const Name = styled(M.Typography)`
  cursor: pointer;
  user-select: none;
  margin-right: 12px !important;
  flex: 1;
`

const Unit = styled(M.Typography).attrs({ variant: 'body1' })`
  margin-left: 4px !important;
`
const Amount = styled(M.Typography).attrs({ variant: 'body1' })``

const FRACTION_REGEX = /^(\d+)\/(\d+)$/

function parseFraction(x: string): number {
  if (!x.includes('/')) {
    const n = parseFloat(x)

    if (Number.isNaN(n)) {
      throw new Error('Invalid number')
    }

    return n
  }

  const matches = FRACTION_REGEX.exec(x)

  if (!matches) {
    throw new Error('Invalid number')
  }

  return parseInt(matches[1]) / parseInt(matches[2])
}

const DRAG_HANDLE_STYLE = {
  marginRight: 4,
  userSelect: 'none',
  cursor: 'grab',
  fontSize: 40,
  color: '#424242',
  position: 'relative',
  top: 0.5
} as any
