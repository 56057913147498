import * as React from 'react'
import * as M from '@material-ui/core'
import styled from 'styled-components'
import Icon from './icon'

export interface IAction {
  label: string
  icon: string
  onClick: () => void
}

interface IProps {
  onToggleDrawer?: () => void
  title?: string
  onBack?: () => void
  actions?: IAction[]
}

interface IState {
  accountMenu: boolean
  actionMenu: boolean
}

export default class Navbar extends React.Component<IProps, IState> {
  state: IState = {
    accountMenu: false,
    actionMenu: false
  }

  accountMenuAnchor: HTMLElement | null = null
  actionMenuAnchor: HTMLElement | null = null

  toggleAccountMenu = () => {
    this.setState({ accountMenu: !this.state.accountMenu })
  }

  toggleActionMenu = () => {
    this.setState({ actionMenu: !this.state.actionMenu })
  }

  renderActions() {
    const { actions } = this.props

    if (!actions) {
      return null
    }

    return (
      <div ref={ref => (this.actionMenuAnchor = ref)}>
        <M.IconButton onClick={this.toggleActionMenu} color="inherit">
          <Icon.More />
        </M.IconButton>
        <M.Menu
          anchorEl={this.actionMenuAnchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.actionMenu}
          onClose={this.toggleActionMenu}
        >
          {actions.map((action, i) => (
            <M.MenuItem
              key={i}
              onClick={() => {
                action.onClick()
                this.toggleActionMenu()
              }}
            >
              <M.ListItemIcon>
                {React.createElement(Icon[action.icon])}
              </M.ListItemIcon>
              <M.ListItemText>{action.label}</M.ListItemText>
            </M.MenuItem>
          ))}
        </M.Menu>
      </div>
    )
  }

  render() {
    return (
      <>
        <M.AppBar position="fixed" color="primary">
          <M.Toolbar>
            {this.props.onToggleDrawer && (
              <M.IconButton color="inherit" onClick={this.props.onToggleDrawer}>
                <Icon.Menu />
              </M.IconButton>
            )}

            {this.props.onBack && (
              <M.IconButton color="inherit" onClick={this.props.onBack}>
                <Icon.ArrowBack />
              </M.IconButton>
            )}

            <Title variant="title" color="inherit">
              {this.props.title}
            </Title>

            {this.renderActions()}
          </M.Toolbar>
        </M.AppBar>
      </>
    )
  }
}

const Title = styled(M.Typography)`
  flex: 1;
`
