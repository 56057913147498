import { IRecipe, IRecipeRuleset } from '../../types'
import { makeId, makeTimestamps } from './util'
import * as Palette from './palette'

interface IMakeParams {
  title: string
  ruleset: IRecipeRuleset
  authorId: string
}

type IRecipeState = 'draft' | 'unpublished-changes' | 'published'

export function make(params: IMakeParams): IRecipe {
  return {
    ...params,
    id: makeId(),
    isDeleted: false,
    hasNewImage: false,
    ...makeTimestamps()
  }
}

export function state(recipe: IRecipe): IRecipeState {
  const publishedAt = recipe.publishedAt

  if (!publishedAt) {
    return 'draft'
  }

  if (publishedAt < recipe.updatedAt) {
    return 'unpublished-changes'
  }

  return 'published'
}

export function getColor(recipe: IRecipe): string {
  return Palette.pick(recipe.id)
}

export function setImage(
  recipe: IRecipe,
  imageUrl: string,
  thumbnailUrl: string
): IRecipe {
  return {
    ...recipe,
    imageUrl: imageUrl,
    thumbnailUrl: thumbnailUrl,
    hasNewImage: true
  }
}

export function markPublished(recipe: IRecipe): IRecipe {
  return {
    ...recipe,
    publishedAt: Date.now(),
    hasNewImage: false
  }
}
