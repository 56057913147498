import * as Kitchen from '../../../kitchen-support'
import Multiselect from '../../views/multiselect'
import { React, IVariant, Util } from '../common'

const MENUS = Util.objectValues(Kitchen.MENUS)

interface IProps {
  variant: IVariant
  onChange: (variant: IVariant) => void
}

export default class AllowedMenusList extends React.PureComponent<IProps> {
  handleChange = (selectedNames: string[]) => {
    const { variant, onChange } = this.props

    const allowedMenuIds = MENUS.filter(
      m => selectedNames.indexOf(m.name) >= 0
    ).map(m => m.id)

    onChange({
      ...variant,
      allowedMenuIds
    })
  }

  render() {
    return (
      <Multiselect
        placeholder="Add allowed menus..."
        items={MENUS.map(m => m.name)}
        selected={this.props.variant.allowedMenuIds.map(
          id => Kitchen.findMenu(id).name
        )}
        onChange={this.handleChange}
      />
    )
  }
}
