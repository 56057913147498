import {
  React,
  Navbar,
  Variant,
  ISceneProps,
  IRecipe,
  IVariant
} from '../common'
import Content from './content'
import Subscribe from '../../views/subscribe'

interface IProps extends ISceneProps {
  params: {
    id: string
  }
}

export default class Recipe extends React.Component<IProps> {
  handleBack = () => {
    const { nav } = this.props
    nav.go('RecipeList')
  }

  renderContent = (
    isLoaded: boolean,
    recipe?: IRecipe,
    variants?: IVariant[]
  ) => {
    variants = variants
      ? Variant.sortByRank(variants.filter(v => !v.isDeleted))
      : []

    if (isLoaded) {
      if (recipe) {
        return (
          <Content
            {...this.props}
            recipe={recipe}
            variants={variants}
            onBack={this.handleBack}
          />
        )
      } else {
        return (
          <>
            <Navbar onBack={this.handleBack} />
            <h2>Recipe not found</h2>
          </>
        )
      }
    } else {
      return <Navbar onBack={this.handleBack} />
    }
  }

  render() {
    const { store, params } = this.props

    return (
      <Subscribe
        subscription={store.subscribeToRecipe}
        param={params.id}
        render={(isRecipeLoaded, recipe) => (
          <Subscribe
            subscription={store.subscribeToRecipeVariants}
            param={params.id}
            render={(isVariantsLoaded, variants) =>
              this.renderContent(
                isRecipeLoaded && isVariantsLoaded,
                recipe,
                variants
              )
            }
          />
        )}
      />
    )
  }
}
