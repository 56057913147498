import * as React from 'react'
import * as M from '@material-ui/core'
import { VERSION } from '../services/app-version'

export default function Footer() {
  return (
    <div style={{ marginTop: 16, marginBottom: 16, marginLeft: 24 }}>
      <M.Typography variant="caption">Using Chef v{VERSION}</M.Typography>
    </div>
  )
}
