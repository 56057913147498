import { React, M, styled, Recipe, IRecipe, Icon } from '../common'

interface IProps {
  recipe: IRecipe
  onClick: () => void
}

export default class Thumb extends React.Component<IProps> {
  renderImage() {
    const { recipe } = this.props
    if (recipe.thumbnailUrl) {
      return <Image src={recipe.thumbnailUrl} />
    } else {
      return (
        <PlaceholderImage style={{ backgroundColor: Recipe.getColor(recipe) }}>
          <Icon.Restaurant
            style={{ width: 64, height: 64, color: 'rgba(255,255,255,0.8)' }}
          />
        </PlaceholderImage>
      )
    }
  }

  render() {
    const { recipe, onClick } = this.props

    return (
      <Card onClick={onClick}>
        {this.renderImage()}
        <Content>
          <Title>{recipe.title}</Title>
          <ChipList>
            <Chip visible={recipe.isDeleted} label="archived" />
            <Chip visible={Recipe.state(recipe) == 'draft'} label="draft" />
            <Chip
              visible={Recipe.state(recipe) == 'unpublished-changes'}
              label="unpublished changes"
            />
            <Chip visible={recipe.isPro} label="pro" />
            <Chip visible={recipe.ruleset == 'breakfast'} label="breakfast" />
            <Chip visible={recipe.ruleset == 'snack'} label="snack" />
            <Chip visible={recipe.ruleset == 'dessert'} label="dessert" />
          </ChipList>
        </Content>
      </Card>
    )
  }
}

const PlaceholderImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  opacity: 0.4;
`

const Card = styled(M.Paper)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 200px;
  height: 360px;
`

const Title = styled(M.Typography).attrs({
  gutterBottom: true,
  variant: 'body2'
})``

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
`

const Image = styled.img`
  height: 200px;
  width: 200px;
`

const ChipList = styled.div`
  margin-top: auto;
`

function Chip(props: { visible?: boolean; label: string }) {
  if (props.visible) {
    return (
      <ChipBox>
        <M.Typography variant="caption">{props.label}</M.Typography>
      </ChipBox>
    )
  } else {
    return null
  }
}

const ChipBox = styled.div`
  padding: 4px 8px;
  background-color: #eee;
  border-radius: 20px;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
`
