import Router from '../../typerouter'
import * as React from 'react'
import * as M from '@material-ui/core'
import Icon from './icon'
import { IUser, IStore } from '../../types'

interface IProps {
  isOpen: boolean
  currentUser: IUser
  store: IStore
  nav: Router
  onClose: () => void
}

export default class Drawer extends React.Component<IProps> {
  navigateTo = (path: string) => () => {
    this.props.nav.go(path)
  }

  isCurrentPath = (path: string): boolean => {
    return this.props.nav.getCurrentRoute().path == path
  }

  render() {
    const { isOpen, onClose, currentUser, store } = this.props

    return (
      <M.Drawer open={isOpen} onClose={onClose}>
        <div tabIndex={0} role="button" onClick={onClose} onKeyDown={onClose}>
          <div>
            <M.List style={{ minWidth: 300, marginTop: 32 }}>
              <M.ListItem
                button
                selected={this.isCurrentPath('RecipeList')}
                onClick={this.navigateTo('RecipeList')}
              >
                <M.ListItemIcon>
                  <Icon.Restaurant />
                </M.ListItemIcon>
                <M.ListItemText primary="Recipes" />
              </M.ListItem>

              <M.ListItem
                button
                selected={this.isCurrentPath('Team')}
                onClick={this.navigateTo('Team')}
              >
                <M.ListItemIcon>
                  <Icon.Group />
                </M.ListItemIcon>
                <M.ListItemText primary="Team" />
              </M.ListItem>
            </M.List>

            <M.Divider />

            <M.List
              subheader={
                <M.ListSubheader>
                  Signed in as {currentUser.displayName}
                </M.ListSubheader>
              }
            >
              <M.ListItem
                button
                selected={this.isCurrentPath('Profile')}
                onClick={this.navigateTo('Profile')}
              >
                <M.ListItemIcon>
                  <Icon.AccountCircle />
                </M.ListItemIcon>
                <M.ListItemText primary="My Profile" />
              </M.ListItem>

              <M.ListItem button onClick={store.signOut}>
                <M.ListItemIcon>
                  <Icon.ExitToApp />
                </M.ListItemIcon>
                <M.ListItemText primary="Sign Out" />
              </M.ListItem>
            </M.List>
          </div>
        </div>
      </M.Drawer>
    )
  }
}
