import { ILineItem } from '../../types'
import * as Kitchen from '../../kitchen-support'
import { IIngredient } from '../../kitchen-support/types'

/**
 * Sort a list of line items in their default order:
 * - Alphabetically, with essential ingredients at the bottom.
 */
export function sortByIngredient(lineItems: ILineItem[]): ILineItem[] {
  return lineItems.sort(compareLineItems)
}

/**
 * Utility for comparing line items for sorting:
 * Returns -1 if "a" should be before "b",
 * and +1 if it should be after.
 */
function compareLineItems(a: ILineItem, b: ILineItem): number {
  return compareIngredients(findIngredient(a), findIngredient(b))
}

function compareIngredients(a: IIngredient, b: IIngredient): number {
  if (a.isEssential && !b.isEssential) {
    return 1
  }

  if (!a.isEssential && b.isEssential) {
    return -1
  }

  return a.name.localeCompare(b.name)
}

function findIngredient(lineItem: ILineItem): IIngredient {
  return Kitchen.findIngredient(lineItem.ingredientId)
}
