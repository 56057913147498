import * as React from 'react'

type IUnsubscribe = () => void
type ICallback<V> = (v: V) => void

type ISubscription<V, P> = (param: P) => (cb: ICallback<V>) => IUnsubscribe

interface IProps<V, P> {
  subscription: ISubscription<V, P>
  param: P
  render: (isLoaded: boolean, value?: V) => React.ReactNode
}

interface IState<T> {
  isLoaded: boolean
  value?: T
}

export default class Subscribe<V, P> extends React.Component<
  IProps<V, P>,
  IState<V>
> {
  unsubscribe: IUnsubscribe

  state: IState<V> = {
    isLoaded: false
  }

  componentDidMount() {
    this.subscribe(this.props)
  }

  UNSAFE_componentWillReceiveProps(newProps: IProps<V, P>) {
    if (newProps.param != this.props.param) {
      this.subscribe(newProps)
    }
  }

  componentWillUnmount() {
    this.clearSubscription()
  }

  subscribe(props: IProps<V, P>) {
    const { subscription, param } = props

    this.clearSubscription()

    this.unsubscribe = subscription(param)(value =>
      this.setState({ value, isLoaded: true })
    )
  }

  clearSubscription() {
    this.unsubscribe && this.unsubscribe()
  }

  render() {
    const { isLoaded, value } = this.state
    return this.props.render(isLoaded, value)
  }
}
