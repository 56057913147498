import { INGREDIENTS, findIngredient } from '../../../kitchen-support'
import { React, M, styled, withTargetValue, Icon } from '../common'
import { IRecipeRuleset } from '../../../types'
import { IIngredient } from '../../../kitchen-support/types'

const NUM_SUGGESTED_INGREDIENTS = 5

interface IProps {
  ruleset: IRecipeRuleset
  onSave: (ingredientId: number) => void
  onCancel: () => void
}

interface IState {
  name: string
  focusedSuggestionIndex: number
}

export default class LineItem extends React.Component<IProps, IState> {
  state: IState = {
    name: '',
    focusedSuggestionIndex: -1
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (event: KeyboardEvent) => {
    const { focusedSuggestionIndex } = this.state

    switch (event.which) {
      case 40: // down
        if (focusedSuggestionIndex < NUM_SUGGESTED_INGREDIENTS - 1) {
          this.setState({ focusedSuggestionIndex: focusedSuggestionIndex + 1 })
        }
        return
      case 38: // up
        if (focusedSuggestionIndex >= 0) {
          this.setState({ focusedSuggestionIndex: focusedSuggestionIndex - 1 })
        }
        return
      case 13: // enter
        const ingredientId = this.getSuggestedIngredients()[
          focusedSuggestionIndex
        ]
        if (ingredientId) {
          this.props.onSave(ingredientId)
        }
        return
    }
  }

  handleChangeName = (name: string) => {
    this.setState({
      name,
      focusedSuggestionIndex: -1
    })
  }

  isIngredientAllowed = (
    ingredient: IIngredient,
    ruleset: IRecipeRuleset
  ): boolean => {
    switch (ruleset) {
      case 'breakfast':
        return ingredient.isBreakfastAllowed
      case 'dinner':
        return ingredient.isDinnerAllowed
      case 'dessert':
        return ingredient.isDessertAllowed
      case 'simple':
        // All dinner ingredients are also allowed in super simple recipes
        return ingredient.isSimpleOnly || ingredient.isDinnerAllowed
      case 'snack':
        // All ingredients except cpgOnly are allowed in snack recipes.
        return !ingredient.isCpgOnly
      case 'cpg':
        // All ingredients are allowed in CPG recipes.
        return true
    }
  }

  getSuggestedIngredients = (): number[] => {
    const { ruleset } = this.props
    const { name } = this.state

    if (name.length < 2) {
      return []
    } else {
      const ids: number[] = []

      for (let id in INGREDIENTS) {
        const ing = findIngredient(+id)
        if (
          !ing.isDisabled &&
          this.isIngredientAllowed(ing, ruleset) &&
          ing.name.toLowerCase().includes(name.toLowerCase())
        ) {
          ids.push(+id)
        }
      }

      return ids.slice(0, NUM_SUGGESTED_INGREDIENTS)
    }
  }

  render() {
    const { onSave, onCancel } = this.props
    const { name, focusedSuggestionIndex } = this.state

    const suggestedIngredients = this.getSuggestedIngredients()

    return (
      <Container>
        <Field>
          <NameInput
            value={name}
            onChange={withTargetValue(this.handleChangeName)}
          />
          <M.IconButton onClick={onCancel}>
            <Icon.Cancel />
          </M.IconButton>
        </Field>

        {suggestedIngredients.length > 0 && (
          <SuggestionList>
            <M.MenuList>
              {suggestedIngredients.map((id, i) => (
                <SuggestedIngredient
                  key={id}
                  id={id}
                  onSelect={onSave}
                  isFocused={i == focusedSuggestionIndex}
                />
              ))}
            </M.MenuList>
          </SuggestionList>
        )}
      </Container>
    )
  }
}

class SuggestedIngredient extends React.PureComponent<{
  id: number
  isFocused: boolean
  onSelect: (id: number) => void
}> {
  handleSelect = () => {
    const { id, onSelect } = this.props
    onSelect(id)
  }

  render() {
    const { id } = this.props

    const ingredient = findIngredient(id)

    return (
      <M.MenuItem onClick={this.handleSelect}>{ingredient.name}</M.MenuItem>
    )
  }
}

const Field = styled.div`
  display: flex;
`

const Container = styled.div`
  margin-bottom: 8px;
  position: relative;
`

const NameInput = styled(M.TextField).attrs({
  fullWidth: true,
  autoFocus: true,
  spellCheck: false
})`
  margin-right: 8px;
  width: 300px;
`

const SuggestionList = styled(M.Paper)`
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0px;
  z-index: 500 !important;
`
