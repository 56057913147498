import { React, M, styled, IInstruction } from '../common'

interface IProps {
  position: number
  instruction: IInstruction
  errors: string[]
}

export default class InstructionReport extends React.PureComponent<IProps> {
  render() {
    const { position, instruction, errors } = this.props

    if (errors.length == 0) {
      return null
    }

    return (
      <div>
        <Divider />
        <CardContent>
          <Subtitle>Instruction {position}</Subtitle>
          <Title>"{instruction.message}"</Title>
          <List>
            {errors.map(error => (
              <ListItem key={error}>
                <Text>{error}</Text>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </div>
    )
  }
}

const CardContent = styled(M.CardContent).attrs({})``

const Text = styled(M.Typography).attrs({
  variant: 'subheading'
})``

const Title = styled(M.Typography).attrs({
  gutterBottom: false
})`
  font-size: 16px !important;
  font-style: italic;
  /* Ensure all whitespace is shown */
  white-space: pre-wrap;
`

const Subtitle = styled(M.Typography).attrs({
  variant: 'body1',
  color: 'textSecondary',
  gutterBottom: true
})``

const List = styled.ul`
  padding-left: 18px;
  margin-bottom: 0;
`

const ListItem = styled.li`
  margin-bottom: 4px;
`

const Divider = styled(M.Divider).attrs({})``
