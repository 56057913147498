import { IIngredient } from './types'
import DB from './database'

export const WATER: IIngredient = {
  id: 0,
  name: 'water',
  pluralName: 'water',
  description: '',
  sectionId: 0,
  subsectionId: 0,
  density: 1.0,
  isEssential: true,
  isPerishable: false,
  isDisabled: false,
  isDinnerAllowed: true,
  isBreakfastAllowed: false,
  isDessertAllowed: false,
  isSimpleOnly: true,
  isCpgOnly: false,
  unitAssignments: {
    metric: {
      unitId: 91,
      packageAmount: 1.0,
      amountInBase: null,
      equivalents: ''
    },
    us: {
      unitId: 91,
      packageAmount: 1.0,
      amountInBase: null,
      equivalents: ''
    }
  },
  nutrition: {
    energy: 0,
    ash: 0,
    water: 0,
    carbs: 0,
    sugars: 0,
    fructose: 0,
    glucose: 0,
    calcium: 0,
    copper: 0,
    iron: 0,
    magnesium: 0,
    manganese: 0,
    phosphorus: 0,
    potassium: 0,
    selenium: 0,
    sodium: 0,
    zinc: 0
  },
  restrictionWarnings: [],
  restrictionViolations: []
}

DB.ingredients[0] = WATER

export const ALL = DB.ingredients

export function find(id: number): IIngredient {
  const ingredient = ALL[id]

  if (!ingredient) {
    throw new Error('Ingredient not found: ' + id)
  }

  return ingredient
}
