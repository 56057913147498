import * as React from 'react'
import * as M from '@material-ui/core'
import styled from 'styled-components'

interface IProps {
  placeholder: string
  items: string[]
  selected: string[]
  onChange: (selected: string[]) => void
  popperProps?: object
}

interface IState {
  newItem: string
  suggestions: string[]
}

export default class Multiselect extends React.PureComponent<IProps, IState> {
  state: IState = {
    newItem: '',
    suggestions: []
  }

  inputRef: any = null

  getSuggestions = (text: string): string[] => {
    if (text.length > 0) {
      return this.getUnselectedItems().filter(i =>
        i.toLowerCase().includes(text.toLowerCase())
      )
    } else {
      return []
    }
  }

  getUnselectedItems = (): string[] => {
    const set = new Set(this.props.selected)
    return this.props.items.filter(i => !set.has(i))
  }

  handleNewItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newItem = event.target.value

    this.setState({
      newItem,
      suggestions: this.getSuggestions(newItem)
    })
  }

  handleSelect = (item: string) => {
    this.props.onChange(this.props.selected.concat(item))

    this.setState({
      newItem: '',
      suggestions: []
    })
  }

  handleDelete = (item: string) => {
    this.props.onChange(this.props.selected.filter(i => i != item))
  }

  handleNewItemKeyUp = (event: React.KeyboardEvent) => {
    if (event.which == 27) {
      // escape

      this.setState({
        newItem: '',
        suggestions: []
      })
    }
  }

  handleClickAway = () => {
    this.setState({ suggestions: [] })
  }

  handleClick = () => {
    this.setState({ suggestions: this.getUnselectedItems() })
  }

  render() {
    return (
      <Container >
        <Items>
          {this.props.selected.map(item => (
            <Chip
              key={item}
              label={item}
              onDelete={() => this.handleDelete(item)}
            />
          ))}
        </Items>

        <M.TextField
          inputRef={ref => (this.inputRef = ref)}
          placeholder={this.props.placeholder}
          style={{ marginTop: 0 }}
          fullWidth
          onChange={this.handleNewItemChange}
          onKeyUp={this.handleNewItemKeyUp}
          onClick={this.handleClick}
          value={this.state.newItem}
          margin="normal"
        />

        <M.Popper
          {...this.props.popperProps}
          popperOptions={{ positionFixed: true }}
          open={this.state.suggestions.length > 0}
          anchorEl={this.inputRef}
        >
          <M.ClickAwayListener onClickAway={this.handleClickAway}>
            <SuggestionList>
              {this.state.suggestions.map(item => (
                <Suggestion key={item} onClick={() => this.handleSelect(item)}>
                  {item}
                </Suggestion>
              ))}
            </SuggestionList>
          </M.ClickAwayListener>
        </M.Popper>
      </Container>
    )
  }
}

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Chip = styled(M.Chip)`
  margin: 4px !important;
`

const Container = styled.div`
  margin-bottom: 16px;
  z-index: 1000;
`

const SuggestionList = styled(M.Paper).attrs({
  square: true
})`
  background-color: white;
  max-width: 400px;
  max-height: 260px;
  overflow-y: auto;
`

const Suggestion = styled(M.MenuItem)`
  cursor: pointer;
`
