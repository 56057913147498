import { React, styled, M, IInstructionSearchResult } from '../common'

interface IProps {
  suggestion: IInstructionSearchResult
  onSelect: (message: string) => void
}

export default class extends React.PureComponent<IProps> {
  handleClick = () => {
    const { onSelect, suggestion } = this.props
    onSelect(suggestion.message)
  }

  render() {
    const { suggestion } = this.props

    return (
      <Container onClick={this.handleClick}>
        <Message
          key={suggestion.id}
          dangerouslySetInnerHTML={{ __html: suggestion.highlighted }}
        />
        <Count>{suggestion.count}</Count>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
`

const Message = styled(M.Typography).attrs({
  variant: 'body1'
})`
  em {
    font-weight: bold;
    font-style: normal;
  }
`

const Count = styled(M.Typography).attrs({
  variant: 'caption'
})`
  margin-left: 8px !important;
`
