import { React, styled, M } from '../common'

interface IProps {
  errors: string[]
}

export default class RecipeErrorList extends React.Component<IProps> {
  render() {
    const { errors } = this.props

    return (
      <Card>
        <CardContent>
          <List>
            {errors.map(error => (
              <ListItem key={error}>
                <ListItemText>
                  <SpanText dangerouslySetInnerHTML={{ __html: error }} />
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    )
  }
}

const Card = styled(M.Card).attrs({})`
  margin-bottom: 48px;
`
const CardContent = styled(M.CardContent).attrs({})``

const List = styled.ul`
  padding-left: 18px;
  margin-bottom: 0;
  margin-top: 0;
`

const ListItem = styled.li``
const ListItemText = styled(M.ListItemText).attrs({})``

const SpanText = styled('span').attrs({})``
