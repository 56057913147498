import { IInstruction, ILineItem } from '../../types'
import { formatFractions, makeId, reorder } from './util'

export function make(): IInstruction {
  return {
    id: makeId(),
    message: '',
    lineItems: []
  }
}

export function setMessage(instruction: IInstruction, message: string) {
  return {
    ...instruction,
    message: formatFractions(message)
  }
}

export function pushLineItem(
  instruction: IInstruction,
  ingredientId: number
): IInstruction {
  const lineItems = getLineItems(instruction).concat(makeLineItem(ingredientId))
  return setLineItems(instruction, lineItems)
}

export function sortLineItems(
  instruction: IInstruction,
  from: number,
  to: number
): IInstruction {
  const lineItems = reorder(getLineItems(instruction), from, to)
  return setLineItems(instruction, lineItems)
}

export function deleteLineItem(
  instruction: IInstruction,
  id: string
): IInstruction {
  const lineItems = getLineItems(instruction).filter(item => item.id != id)
  return setLineItems(instruction, lineItems)
}

export function setLineItem(
  instruction: IInstruction,
  updated: ILineItem
): IInstruction {
  const lineItems = getLineItems(instruction).map(i =>
    i.id == updated.id ? updated : i
  )
  return setLineItems(instruction, lineItems)
}

export function setLineItems(
  instruction: IInstruction,
  lineItems: ILineItem[]
): IInstruction {
  return {
    ...instruction,
    lineItems
  }
}

function makeLineItem(ingredientId: number): ILineItem {
  return {
    id: makeId(),
    ingredientId,
    amount: 1
  }
}

export function getLineItems(instruction: IInstruction): ILineItem[] {
  return instruction.lineItems || []
}
