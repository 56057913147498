import * as React from 'react'
import * as M from '@material-ui/core'
import * as Colors from '@material-ui/core/colors'
import styled from 'styled-components'
import Modal from './modal'
import * as Util from '../services/util'
import * as ImageUtil from '../services/image-util'

interface IProps {
  isVisible: boolean
  path: string
  onSubmit: (imageUrl: string, thumbnailUrl?: string) => void
  uploadFile: (path: string, file: File) => Promise<string>
  onClose: () => void
}

interface IState {
  isLoading: boolean
  error?: string
  urls?: {
    original: string
    thumbnail: string
  }
}

export default class ImageUploadModal extends React.Component<IProps, IState> {
  state: IState = {
    isLoading: false
  }

  handleFileSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isLoading: true })

    const files = event.target.files

    if (!files || files.length == 0) {
      return
    }

    if (files.length > 1) {
      this.setState({
        isLoading: false,
        error: `You selected ${files.length} images. Please choose just one.`
      })
      return
    }

    const origFile = files[0]

    if (origFile.type != 'image/jpeg') {
      this.setState({
        isLoading: false,
        error: 'Image must be a jpeg image'
      })
      return
    }

    const fileId = Util.makeId()
    const origPath = `${this.props.path}/${fileId}.jpeg`
    const thumbPath = `${this.props.path}/thumbnails/${fileId}.jpeg`

    const thumbFile = await ImageUtil.resizeImage(origFile)

    try {
      const originalUrl = await this.props.uploadFile(origPath, origFile)
      const thumbnailUrl = await this.props.uploadFile(thumbPath, thumbFile)
      this.setState({
        urls: {
          original: originalUrl,
          thumbnail: thumbnailUrl
        },
        isLoading: false,
        error: undefined
      })
    } catch (e) {
      this.setState({
        isLoading: false,
        error: 'There was an error uploading this image'
      })
    }
  }

  cancel = () => {
    this.props.onClose()
  }

  submit = () => {
    const { urls } = this.state
    if (urls) {
      this.props.onSubmit(urls.original, urls.thumbnail)
    }
  }

  handleLoadImage = (event: any) => {
    const img = event.target

    const w = img.naturalWidth
    const h = img.naturalHeight

    const dims = `${w}x${h}`

    if (w != h) {
      this.setState({
        error: `Image must be a square, but this one is ${dims}`
      })
    } else if (w < 1200) {
      this.setState({
        error: `Image must be at least 1200x1200, but this one is ${dims}`
      })
    }
  }

  render() {
    const { isVisible, onClose } = this.props
    const { urls, isLoading, error } = this.state

    return (
      <Modal open={isVisible} onClose={onClose}>
        <Container>
          {urls && <Image src={urls.original} onLoad={this.handleLoadImage} />}
          {!urls && (
            <PlaceholderImage>
              <UploadButton>
                <Input
                  autoFocus
                  type="file"
                  onChange={this.handleFileSelection}
                />
                {isLoading ? 'Loading...' : 'Choose an image'}
              </UploadButton>
            </PlaceholderImage>
          )}

          {error && <ErrorMessage>{error}</ErrorMessage>}

          <Actions>
            <CancelButton onClick={this.props.onClose}>Cancel</CancelButton>
            <SubmitButton disabled={!urls || !!error} onClick={this.submit}>
              OK
            </SubmitButton>
          </Actions>
        </Container>
      </Modal>
    )
  }
}

const Actions = styled.div`
  display: flex;
  margin-top: 16px;
`

const CancelButton = styled(M.Button).attrs({
  color: 'secondary',
  variant: 'contained'
})`
  margin: 0 8px !important;
`

const SubmitButton = styled(M.Button).attrs({
  color: 'primary',
  variant: 'contained'
})`
  margin: 0 8px !important;
`

const Container = styled(M.Paper).attrs({
  elevation: 4
})`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
`

const PlaceholderImage = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
`

const Image = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 3px;
`

const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const UploadButton = styled(M.Button).attrs({
  component: 'label'
})`
  display: inline-block;
`

const ErrorMessage = styled(M.Typography)`
  background-color: ${Colors.red[500]} !important;
  color: white !important;
  padding: 8px 16px;
  margin-top: 16px !important;
`
