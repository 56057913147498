import { ADMIN_SERVICE_ENDPOINT, ADMIN_SERVICE_PASSWORD } from '../../config'
import { IRecipeRuleset } from '../../types'

const BASE_URL = ADMIN_SERVICE_ENDPOINT + 'chef_integration/'

export interface IRecipe {
  chef_id: string
  name: string
  is_pro: boolean
  variety_tag_ids: number[]
  ruleset: IRecipeRuleset
  remote_image_url?: string
  chef_user_id: string
}

export interface IInstruction {
  primary_message: string
  secondary_message?: string
}

export interface ILineItem {
  ingredient_id: number
  amount: number
}

export interface IVariant {
  chef_id: string
  name?: string
  group_label: string
  unit_family_id: number
  serving_count: number
  position: number
  cooking_minutes: number
  cookware_ids: number[]
  allowed_type_ids: number[]
  recipe_category_id: number
  line_items_attributes: ILineItem[]
  instructions_attributes: IInstruction[]
  remote_image_url?: string
  cooking_tip?: string
}

export interface IPublishPayload {
  recipe: IRecipe
  variants: IVariant[]
  user_id: string
  note: string
}

export interface IFeedback {
  id: number
  recipe_id: number
  variant_id: number
  created_at: number
  body: string
  is_positive?: boolean
  star_rating?: number
  user?: {
    id: number
    email?: string
  }
}

export interface IFeedbackResult {
  total: number
  total_positive: number
  feedbacks: IFeedback[]
}

export interface IRecipeStats {
  rating: number
  rating_count: number
  favourite_count: number
}

export async function publishRecipe(payload: IPublishPayload): Promise<void> {
  return post('upload_recipe', { data: payload })
}

export function getFeedbacks(options: {
  recipeId: string
  maxId?: number
  limit?: number
  filter?: string
}): Promise<IFeedbackResult> {
  return post('get_recipe_feedback', {
    chef_id: options.recipeId,
    max_id: options.maxId,
    limit: options.limit || 30,
    filter: options.filter
  })
}

export function getStats(recipeId: string): Promise<IRecipeStats> {
  return post('get_recipe_stats', { chef_id: recipeId })
}

async function post(path: string, body: object) {
  const res = await fetch(BASE_URL + path, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa('chef:' + ADMIN_SERVICE_PASSWORD)
    },
    body: JSON.stringify(body)
  })

  if (!res.ok) {
    throw new Error('Request failed: ' + res.statusText)
  }

  return res.json()
}
