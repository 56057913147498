import { IRecipe, IVariant } from '../../types'
import * as Util from './util'

export function copyRecipe(
  original: IRecipe,
  opts: { authorId?: string }
): IRecipe {
  const newRecipe: IRecipe = {
    ...original,
    id: Util.makeId(),
    title: original.title + ' copy',
    authorId: opts.authorId || original.authorId,
    updatedAt: Date.now(),
    // Mark the image to be uploaded when published.
    hasNewImage: !!original.imageUrl
  }

  // Remove "publishedAt"
  // (undefined not allowed in firebase...)
  delete newRecipe.publishedAt

  return newRecipe
}

export function copyVariants(
  variants: IVariant[],
  recipeId: string
): IVariant[] {
  return variants.map(v => copyVariant(v, recipeId))
}

function copyVariant(variant: IVariant, recipeId: string): IVariant {
  return {
    ...variant,
    id: Util.makeId(),
    comments: [],
    recipeId,
    // Mark the image to be uploaded when published.
    hasNewImage: !!variant.imageUrl
  }
}
