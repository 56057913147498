import { IRecipe, IVariant } from '../../../types'
import * as RecipeChecks from './recipe-checks'
import * as VariantChecks from './variant-checks'
import { IVariantReport } from './variant-checks'

interface IReport {
  recipeErrors: string[]
  variantReports: { [id: string]: IVariantReport }
}

export function makeReport(recipe: IRecipe, variants: IVariant[]): IReport {
  const report: IReport = {
    recipeErrors: RecipeChecks.check(recipe, variants),
    variantReports: {}
  }

  for (const variant of variants) {
    report.variantReports[variant.id] = VariantChecks.check(
      variant,
      recipe.ruleset
    )
  }

  return report
}
