import * as React from 'react'
import * as M from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import { ISignInError } from '../types'

interface IProps {
  onSignIn: (email: string, password: string) => Promise<ISignInError>
}

interface IState {
  email: string
  password: string
  error: ISignInError
}

export default class SignIn extends React.Component<IProps, IState> {
  state: IState = {
    email: '',
    password: '',
    error: undefined
  }

  handleSignIn = async () => {
    const { onSignIn } = this.props
    const { email, password } = this.state

    const error = await onSignIn(email, password)

    this.setState({ error })
  }

  handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.currentTarget.value })
  }

  handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.currentTarget.value })
  }

  handleCloseError = () => {
    this.setState({ error: undefined })
  }

  getErrorMessage(): string | undefined {
    const { error } = this.state

    if (!error) {
      return
    }

    switch (error) {
      case 'invalid-email':
        return 'Please enter a valid email address'
      case 'wrong-password':
        return "That's not the correct password. Please check the password and email again."
      case 'unexpected-error':
        return "We aren't able to sign you in right now."
    }
  }

  renderError() {
    const msg = this.getErrorMessage()

    if (!msg) {
      return null
    }

    return (
      <M.Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={!!msg}
        onClose={this.handleCloseError}
        autoHideDuration={6000}
        message={<span>{msg}</span>}
        action={[
          <M.IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleCloseError}
          >
            <CloseIcon />
          </M.IconButton>
        ]}
      />
    )
  }

  render() {
    const { email, password } = this.state

    return (
      <Container>
        <M.Typography variant="display1">Sign In</M.Typography>
        <Input
          label="Email"
          margin="normal"
          autoFocus
          value={email}
          onChange={this.handleChangeEmail}
        />
        <Input
          type="password"
          label="Password"
          value={password}
          margin="normal"
          onChange={this.handleChangePassword}
        />

        <Button onClick={this.handleSignIn}>Sign In</Button>

        {this.renderError()}
      </Container>
    )
  }
}

const Input = styled(M.TextField)`
  width: 400px;
`

const Button = styled(M.Button).attrs({
  variant: 'contained',
  color: 'primary',
  style: {
    marginTop: 16
  }
})``

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`
