import { React, M, withTargetValue, IVariant } from '../common'

interface IProps {
  variant: IVariant
  onChange: (variant: IVariant) => void
}

export default class LabelField extends React.PureComponent<IProps> {
  handleChange = (label: string) => {
    const { variant, onChange } = this.props

    onChange({
      ...variant,
      label
    })
  }

  render() {
    const { label } = this.props.variant

    return (
      <M.TextField
        // NOTE: We use an uncontrolled input because the round-trip
        // to firebase seems to cause the cursor to jump to the end when editing.
        // Possible React issue. This fix means that the field won't update live.
        defaultValue={label}
        onChange={withTargetValue(this.handleChange)}
        label="Label"
        margin="normal"
        autoFocus={label.startsWith('Copy of')}
        fullWidth
      />
    )
  }
}
