import { IDatabase } from './types'

const db: IDatabase = {
  units: {
    '4': {
      id: 4,
      name: 'clove',
      pluralName: 'cloves',
      amountInBase: null,
      isVolumeBased: false
    },
    '23': {
      id: 23,
      name: '(6 oz) can',
      pluralName: '(6 oz) cans',
      amountInBase: null,
      isVolumeBased: false
    },
    '25': {
      id: 25,
      name: '(7.5 oz) can',
      pluralName: '(7.5 oz) cans',
      amountInBase: null,
      isVolumeBased: false
    },
    '30': {
      id: 30,
      name: '(15 oz) can',
      pluralName: '(15 oz) cans',
      amountInBase: null,
      isVolumeBased: false
    },
    '46': {
      id: 46,
      name: '(170 g) can',
      pluralName: '(170 g) cans',
      amountInBase: null,
      isVolumeBased: false
    },
    '49': {
      id: 49,
      name: '(213 g) can',
      pluralName: '(213 g) cans',
      amountInBase: null,
      isVolumeBased: false
    },
    '66': {
      id: 66,
      name: 'pint',
      pluralName: 'pints',
      amountInBase: null,
      isVolumeBased: false
    },
    '67': {
      id: 67,
      name: 'slice',
      pluralName: 'slices',
      amountInBase: null,
      isVolumeBased: false
    },
    '8': {
      id: 8,
      name: 'small pkg',
      pluralName: 'small pkgs',
      amountInBase: null,
      isVolumeBased: false
    },
    '44': {
      id: 44,
      name: '(425 g) pkg',
      pluralName: '(425 g) pkgs',
      amountInBase: null,
      isVolumeBased: false
    },
    '72': {
      id: 72,
      name: '(454 g) pkg',
      pluralName: '(454 g) pkgs',
      amountInBase: null,
      isVolumeBased: false
    },
    '16': {
      id: 16,
      name: '(6 oz) pkg',
      pluralName: '(6 oz) pkgs',
      amountInBase: null,
      isVolumeBased: false
    },
    '76': {
      id: 76,
      name: '(2 ½ cm) piece',
      pluralName: '(2 ½ cm) pieces',
      amountInBase: null,
      isVolumeBased: false
    },
    '75': {
      id: 75,
      name: '(1 inch) piece',
      pluralName: '(1 inch) pieces',
      amountInBase: null,
      isVolumeBased: false
    },
    '9': {
      id: 9,
      name: 'cup',
      pluralName: 'cups',
      amountInBase: 236.588,
      isVolumeBased: true
    },
    '28': {
      id: 28,
      name: '(13.5 fl oz) can',
      pluralName: '(13.5 fl oz) cans',
      amountInBase: null,
      isVolumeBased: false
    },
    '85': {
      id: 85,
      name: '(113 g) log',
      pluralName: '(113 g) logs',
      amountInBase: 113.0,
      isVolumeBased: false
    },
    '84': {
      id: 84,
      name: '(4 oz) log',
      pluralName: '(4 oz) logs',
      amountInBase: 113.0,
      isVolumeBased: false
    },
    '88': {
      id: 88,
      name: 'small (0.454 kg)',
      pluralName: 'small (0.454 kg)',
      amountInBase: 454.0,
      isVolumeBased: false
    },
    '87': {
      id: 87,
      name: 'small (1 lb)',
      pluralName: 'small (1 lb)',
      amountInBase: 454.0,
      isVolumeBased: false
    },
    '79': {
      id: 79,
      name: 'kg',
      pluralName: 'kg',
      amountInBase: 1000.0,
      isVolumeBased: false
    },
    '10': {
      id: 10,
      name: 'lb',
      pluralName: 'lb',
      amountInBase: 453.59237,
      isVolumeBased: false
    },
    '11': {
      id: 11,
      name: 'oz',
      pluralName: 'oz',
      amountInBase: 28.349523,
      isVolumeBased: false
    },
    '12': {
      id: 12,
      name: 'g',
      pluralName: 'g',
      amountInBase: 1.0,
      isVolumeBased: false
    },
    '60': {
      id: 60,
      name: 'fl oz',
      pluralName: 'fl oz',
      amountInBase: 29.57353,
      isVolumeBased: true
    },
    '68': {
      id: 68,
      name: '(8 oz) block',
      pluralName: '(8 oz) blocks',
      amountInBase: 227.0,
      isVolumeBased: false
    },
    '43': {
      id: 43,
      name: '(340 g) pkg',
      pluralName: '(340 g) pkgs',
      amountInBase: 340.0,
      isVolumeBased: false
    },
    '91': {
      id: 91,
      name: 'ml',
      pluralName: 'ml',
      amountInBase: 1.0,
      isVolumeBased: true
    },
    '198': {
      id: 198,
      name: 'serving',
      pluralName: 'servings',
      amountInBase: 26.0,
      isVolumeBased: false
    },
    '69': {
      id: 69,
      name: '(227 g) block',
      pluralName: '(227 g) blocks',
      amountInBase: 227.0,
      isVolumeBased: false
    },
    '53': {
      id: 53,
      name: '(398 ml) can',
      pluralName: '(398 ml) cans',
      amountInBase: null,
      isVolumeBased: false
    },
    '50': {
      id: 50,
      name: '(213 ml) can',
      pluralName: '(213 ml) cans',
      amountInBase: null,
      isVolumeBased: true
    },
    '26': {
      id: 26,
      name: '(8 oz) can',
      pluralName: '(8 oz) cans',
      amountInBase: null,
      isVolumeBased: true
    },
    '14': {
      id: 14,
      name: '(4 oz) pkg',
      pluralName: '(4 oz) pkgs',
      amountInBase: 113.0,
      isVolumeBased: false
    },
    '15': {
      id: 15,
      name: '(5 oz) pkg',
      pluralName: '(5 oz) pkgs',
      amountInBase: 142.0,
      isVolumeBased: false
    },
    '29': {
      id: 29,
      name: '(14.5 oz) can',
      pluralName: '(14.5 oz) cans',
      amountInBase: null,
      isVolumeBased: false
    },
    '94': {
      id: 94,
      name: 'pinch',
      pluralName: 'pinches',
      amountInBase: 0.3080575,
      isVolumeBased: true
    },
    '95': {
      id: 95,
      name: 'tsp',
      pluralName: 'tsp',
      amountInBase: 4.92892,
      isVolumeBased: true
    },
    '80': {
      id: 80,
      name: 'tbsp',
      pluralName: 'tbsp',
      amountInBase: 14.7868,
      isVolumeBased: true
    },
    '1': {
      id: 1,
      name: '-',
      pluralName: '-',
      amountInBase: null,
      isVolumeBased: false
    },
    '2': {
      id: 2,
      name: 'cap',
      pluralName: 'caps',
      amountInBase: null,
      isVolumeBased: false
    },
    '5': {
      id: 5,
      name: 'head',
      pluralName: 'heads',
      amountInBase: null,
      isVolumeBased: false
    },
    '6': {
      id: 6,
      name: 'small bunch',
      pluralName: 'small bunches',
      amountInBase: null,
      isVolumeBased: false
    },
    '7': {
      id: 7,
      name: 'ear',
      pluralName: 'ears',
      amountInBase: null,
      isVolumeBased: false
    },
    '59': {
      id: 59,
      name: 'crown',
      pluralName: 'crowns',
      amountInBase: null,
      isVolumeBased: false
    },
    '62': {
      id: 62,
      name: 'bunch',
      pluralName: 'bunches',
      amountInBase: null,
      isVolumeBased: false
    },
    '89': {
      id: 89,
      name: 'small head',
      pluralName: 'small heads',
      amountInBase: null,
      isVolumeBased: false
    },
    '3': {
      id: 3,
      name: 'stick',
      pluralName: 'sticks',
      amountInBase: null,
      isVolumeBased: false
    },
    '83': {
      id: 83,
      name: 'small',
      pluralName: 'small',
      amountInBase: null,
      isVolumeBased: false
    },
    '86': {
      id: 86,
      name: 'medium',
      pluralName: 'medium',
      amountInBase: null,
      isVolumeBased: false
    },
    '93': {
      id: 93,
      name: 'medium head',
      pluralName: 'medium heads',
      amountInBase: null,
      isVolumeBased: false
    },
    '97': {
      id: 97,
      name: '(6 oz) jar',
      pluralName: '(6 oz) jars',
      amountInBase: null,
      isVolumeBased: false
    },
    '96': {
      id: 96,
      name: '(170 ml) jar',
      pluralName: '(170 ml) jars',
      amountInBase: null,
      isVolumeBased: false
    },
    '98': {
      id: 98,
      name: '(16 oz) jar',
      pluralName: '(16 oz) jars',
      amountInBase: null,
      isVolumeBased: false
    },
    '99': {
      id: 99,
      name: '(454 g) jar',
      pluralName: '(454 g) jars',
      amountInBase: null,
      isVolumeBased: false
    },
    '100': {
      id: 100,
      name: '(8 oz) container',
      pluralName: '(8 oz) containers',
      amountInBase: null,
      isVolumeBased: false
    },
    '101': {
      id: 101,
      name: '(227 g) container',
      pluralName: '(227 g) containers',
      amountInBase: null,
      isVolumeBased: false
    },
    '137': {
      id: 137,
      name: 'heart',
      pluralName: 'hearts',
      amountInBase: null,
      isVolumeBased: false
    },
    '18': {
      id: 18,
      name: '(8 oz) pkg',
      pluralName: '(8 oz) pkgs',
      amountInBase: 227.0,
      isVolumeBased: false
    },
    '136': {
      id: 136,
      name: '(255 g) pkg',
      pluralName: '(255 g) pkgs',
      amountInBase: null,
      isVolumeBased: false
    },
    '139': {
      id: 139,
      name: '(284 ml) can',
      pluralName: '(284 ml) cans',
      amountInBase: null,
      isVolumeBased: false
    },
    '138': {
      id: 138,
      name: '(10 oz) can',
      pluralName: '(10 oz) cans',
      amountInBase: null,
      isVolumeBased: false
    },
    '140': {
      id: 140,
      name: '(24 oz) jar',
      pluralName: '(24 oz) jars',
      amountInBase: 680.0,
      isVolumeBased: false
    },
    '141': {
      id: 141,
      name: '(18 oz) tube',
      pluralName: '(18 oz) tubes',
      amountInBase: 510.0,
      isVolumeBased: false
    },
    '142': {
      id: 142,
      name: '(12 oz) bag',
      pluralName: '(12 oz) bags',
      amountInBase: 340.0,
      isVolumeBased: false
    },
    '143': {
      id: 143,
      name: '(18 oz) bag',
      pluralName: '(18 oz) bags',
      amountInBase: 510.0,
      isVolumeBased: false
    },
    '144': {
      id: 144,
      name: '(24 oz) bag',
      pluralName: '(24 oz) bags',
      amountInBase: 680.0,
      isVolumeBased: false
    },
    '145': {
      id: 145,
      name: '(10 oz) bag',
      pluralName: '(10 oz) bags',
      amountInBase: 283.0,
      isVolumeBased: false
    },
    '146': {
      id: 146,
      name: '(14 oz) bag',
      pluralName: '(14 oz) bags',
      amountInBase: 397.0,
      isVolumeBased: false
    },
    '147': {
      id: 147,
      name: '(10 oz) package',
      pluralName: '(10 oz) packages',
      amountInBase: 283.0,
      isVolumeBased: false
    },
    '148': {
      id: 148,
      name: '(8 oz) package',
      pluralName: '(8 oz) packages',
      amountInBase: 227.0,
      isVolumeBased: false
    },
    '149': {
      id: 149,
      name: '(12 oz) jar',
      pluralName: '(12 oz) jars',
      amountInBase: 340.0,
      isVolumeBased: false
    },
    '181': {
      id: 181,
      name: '(16oz) bag',
      pluralName: '(16oz) bags',
      amountInBase: 454.0,
      isVolumeBased: false
    },
    '182': {
      id: 182,
      name: '(680 g) jar',
      pluralName: '(680 g) jars',
      amountInBase: 680.0,
      isVolumeBased: true
    },
    '183': {
      id: 183,
      name: '(340 g) jar',
      pluralName: '(340 g) jars',
      amountInBase: 340.0,
      isVolumeBased: true
    },
    '184': {
      id: 184,
      name: '(510 g) tube',
      pluralName: '(510 g) tubes',
      amountInBase: 510.0,
      isVolumeBased: false
    },
    '185': {
      id: 185,
      name: '(340 g) bag',
      pluralName: '(340 g) bags',
      amountInBase: 340.0,
      isVolumeBased: false
    },
    '186': {
      id: 186,
      name: '(255 g) can',
      pluralName: '(255 g) cans',
      amountInBase: 255.0,
      isVolumeBased: false
    },
    '187': {
      id: 187,
      name: '(510 g) bag',
      pluralName: '(510 g) bags',
      amountInBase: 510.0,
      isVolumeBased: false
    },
    '188': {
      id: 188,
      name: '(283 g) bag',
      pluralName: '(283 g) bags',
      amountInBase: 283.0,
      isVolumeBased: false
    },
    '189': {
      id: 189,
      name: '(397 g) bag',
      pluralName: '(397 g) bags',
      amountInBase: 397.0,
      isVolumeBased: false
    },
    '190': {
      id: 190,
      name: '(454 g) bag',
      pluralName: '(454 g) bags',
      amountInBase: 454.0,
      isVolumeBased: false
    },
    '193': {
      id: 193,
      name: '(250 g) pkg',
      pluralName: '(250 g) pkgs',
      amountInBase: null,
      isVolumeBased: false
    },
    '192': {
      id: 192,
      name: '(8.8 oz) pkg',
      pluralName: '(8.8 oz) pkgs',
      amountInBase: 249.0,
      isVolumeBased: false
    },
    '133': {
      id: 133,
      name: '(30 oz) whole chicken',
      pluralName: '(30 oz) whole chickens',
      amountInBase: 850.0,
      isVolumeBased: false
    },
    '38': {
      id: 38,
      name: '(113 g) pkg',
      pluralName: '(113 g) pkgs',
      amountInBase: 113.0,
      isVolumeBased: false
    },
    '19': {
      id: 19,
      name: '(12 oz) pkg',
      pluralName: '(12 oz) pkgs',
      amountInBase: 340.0,
      isVolumeBased: false
    },
    '39': {
      id: 39,
      name: '(142 g) pkg',
      pluralName: '(142 g) pkgs',
      amountInBase: 142.0,
      isVolumeBased: false
    },
    '20': {
      id: 20,
      name: '(15 oz) pkg',
      pluralName: '(15 oz) pkgs',
      amountInBase: null,
      isVolumeBased: false
    },
    '42': {
      id: 42,
      name: '(227 g) pkg',
      pluralName: '(227 g) pkgs',
      amountInBase: null,
      isVolumeBased: false
    },
    '40': {
      id: 40,
      name: '(170 g) pkg',
      pluralName: '(170 g) pkgs',
      amountInBase: 170.0,
      isVolumeBased: false
    },
    '191': {
      id: 191,
      name: '(227g ) pkg',
      pluralName: '(227g ) pkgs',
      amountInBase: 227.0,
      isVolumeBased: false
    },
    '134': {
      id: 134,
      name: '(850 g) whole chicken',
      pluralName: '(850 g) whole chickens',
      amountInBase: 850.0,
      isVolumeBased: false
    },
    '135': {
      id: 135,
      name: '(9 oz) pkg',
      pluralName: '(9 oz) pkgs',
      amountInBase: null,
      isVolumeBased: false
    },
    '194': {
      id: 194,
      name: 'pkg',
      pluralName: 'pkgs',
      amountInBase: null,
      isVolumeBased: false
    },
    '196': {
      id: 196,
      name: '(108 g) can',
      pluralName: '(108 g) cans',
      amountInBase: 108.0,
      isVolumeBased: false
    },
    '70': {
      id: 70,
      name: '(16 oz) pkg',
      pluralName: '(16 oz) pkgs',
      amountInBase: 454.0,
      isVolumeBased: false
    },
    '199': {
      id: 199,
      name: 'Serving',
      pluralName: 'servings',
      amountInBase: 36.0,
      isVolumeBased: false
    },
    '195': {
      id: 195,
      name: '(3.8 oz) can',
      pluralName: '(3.8 oz) cans',
      amountInBase: 108.0,
      isVolumeBased: false
    },
    '197': {
      id: 197,
      name: '(3 oz) pkg',
      pluralName: '(3 oz) pkgs',
      amountInBase: null,
      isVolumeBased: false
    }
  },
  ingredients: {
    '358': {
      id: 358,
      name: 'vanilla ice cream',
      pluralName: 'vanilla ice cream',
      description: null,
      sectionId: 13,
      subsectionId: null,
      density: 0.57,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 60,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 207.0,
        ash: 0.9,
        water: 61.0,
        carbs: 23.6,
        fiber: 0.7,
        sugars: 21.22,
        fat: 11.0,
        monounsaturated: 2.969,
        polyunsaturated: 0.452,
        omega_3: 0.175,
        omega_6: 0.275,
        saturated: 6.79,
        cholesterol: 44.0,
        protein: 3.5,
        alanine: 0.121,
        arginine: 0.125,
        aspartic_acid: 0.25,
        cystine: 0.029,
        glutamic_acid: 0.681,
        glycine: 0.093,
        histidine: 0.088,
        isoleucine: 0.195,
        leucine: 0.316,
        lysine: 0.258,
        methionine: 0.081,
        phenylalanine: 0.157,
        proline: 0.326,
        serine: 0.177,
        threonine: 0.146,
        tryptophan: 0.045,
        tyrosine: 0.155,
        valine: 0.217,
        b1_thiamine: 0.041,
        b2_riboflavin: 0.24,
        b3_niacin: 0.116,
        b5_pantothenic_acid: 0.581,
        b6_pyridoxine: 0.048,
        b12_cobalamin: 0.39,
        choline: 26.0,
        folate: 5.0,
        vitamin_a: 421.0,
        vitamin_c: 0.6,
        vitamin_d: 0.2,
        vitamin_e: 0.3,
        vitamin_k: 0.3,
        calcium: 128.0,
        copper: 0.023,
        iron: 0.09,
        magnesium: 14.0,
        manganese: 0.008,
        phosphorus: 105.0,
        potassium: 199.0,
        selenium: 1.8,
        sodium: 80.0,
        zinc: 0.69
      },
      restrictionWarnings: [12, 9, 5, 6, 1],
      restrictionViolations: [2, 11],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '113': {
      id: 113,
      name: 'Granny Smith apple',
      pluralName: 'Granny Smith apples',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 182.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 182.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 52.0,
        ash: 0.19,
        water: 85.56,
        carbs: 13.81,
        fiber: 2.4,
        starch: 0.05,
        sugars: 10.39,
        fructose: 5.9,
        glucose: 2.43,
        sucrose: 2.07,
        fat: 0.17,
        monounsaturated: 0.007,
        polyunsaturated: 0.051,
        omega_3: 0.009,
        omega_6: 0.043,
        saturated: 0.028,
        protein: 0.26,
        alanine: 0.011,
        arginine: 0.006,
        aspartic_acid: 0.07,
        cystine: 0.001,
        glutamic_acid: 0.025,
        glycine: 0.009,
        histidine: 0.005,
        isoleucine: 0.006,
        leucine: 0.013,
        lysine: 0.012,
        methionine: 0.001,
        phenylalanine: 0.006,
        proline: 0.006,
        serine: 0.01,
        threonine: 0.006,
        tryptophan: 0.001,
        tyrosine: 0.001,
        valine: 0.012,
        b1_thiamine: 0.017,
        b2_riboflavin: 0.026,
        b3_niacin: 0.091,
        b5_pantothenic_acid: 0.061,
        b6_pyridoxine: 0.041,
        choline: 3.4,
        folate: 3.0,
        vitamin_a: 54.0,
        vitamin_c: 4.6,
        vitamin_e: 0.18,
        vitamin_k: 2.2,
        calcium: 6.0,
        copper: 0.027,
        iron: 0.12,
        magnesium: 5.0,
        manganese: 0.035,
        phosphorus: 11.0,
        potassium: 107.0,
        sodium: 1.0,
        zinc: 0.04
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '298': {
      id: 298,
      name: 'almonds, sliced',
      pluralName: 'almonds, sliced',
      description: '',
      sectionId: 12,
      subsectionId: 0,
      density: 0.388861261,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 579.0,
        ash: 2.97,
        water: 4.41,
        carbs: 21.55,
        fiber: 12.5,
        starch: 0.72,
        sugars: 4.35,
        fructose: 0.11,
        galactose: 0.07,
        glucose: 0.17,
        maltose: 0.04,
        sucrose: 3.95,
        fat: 49.93,
        monounsaturated: 31.551,
        polyunsaturated: 12.329,
        omega_3: 0.003,
        omega_6: 12.326,
        saturated: 3.802,
        transfats: 0.015,
        protein: 21.15,
        alanine: 0.999,
        arginine: 2.465,
        aspartic_acid: 2.639,
        cystine: 0.215,
        glutamic_acid: 6.206,
        glycine: 1.429,
        histidine: 0.539,
        isoleucine: 0.751,
        leucine: 1.473,
        lysine: 0.568,
        methionine: 0.157,
        phenylalanine: 1.132,
        proline: 0.969,
        serine: 0.912,
        threonine: 0.601,
        tryptophan: 0.211,
        tyrosine: 0.45,
        valine: 0.855,
        b1_thiamine: 0.205,
        b2_riboflavin: 1.138,
        b3_niacin: 3.618,
        b5_pantothenic_acid: 0.471,
        b6_pyridoxine: 0.137,
        choline: 52.1,
        folate: 44.0,
        vitamin_a: 2.0,
        vitamin_e: 25.63,
        calcium: 269.0,
        copper: 1.031,
        iron: 3.71,
        magnesium: 270.0,
        manganese: 2.179,
        phosphorus: 481.0,
        potassium: 733.0,
        selenium: 4.1,
        sodium: 1.0,
        zinc: 3.12
      },
      restrictionWarnings: [],
      restrictionViolations: [6],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '131': {
      id: 131,
      name: 'balsamic vinegar',
      pluralName: 'balsamic vinegar',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 1.077821974,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 88.0,
        ash: 0.37,
        water: 76.45,
        carbs: 17.03,
        sugars: 14.95,
        fructose: 7.38,
        glucose: 7.57,
        protein: 0.49,
        alanine: 0.11,
        arginine: 0.06,
        aspartic_acid: 0.03,
        glutamic_acid: 0.14,
        glycine: 0.02,
        leucine: 0.02,
        lysine: 0.01,
        phenylalanine: 0.02,
        proline: 0.02,
        serine: 0.02,
        threonine: 0.02,
        valine: 0.01,
        choline: 4.3,
        calcium: 27.0,
        copper: 0.026,
        iron: 0.72,
        magnesium: 12.0,
        manganese: 0.131,
        phosphorus: 19.0,
        potassium: 112.0,
        selenium: 0.1,
        sodium: 23.0,
        zinc: 0.08
      },
      restrictionWarnings: [],
      restrictionViolations: [14],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '228': {
      id: 228,
      name: 'lime',
      pluralName: 'limes',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 44.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 44.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 25.0,
        ash: 0.31,
        water: 90.79,
        carbs: 8.42,
        fiber: 0.4,
        sugars: 1.69,
        fructose: 0.61,
        glucose: 0.6,
        sucrose: 0.48,
        fat: 0.07,
        monounsaturated: 0.008,
        polyunsaturated: 0.023,
        omega_3: 0.008,
        omega_6: 0.015,
        saturated: 0.008,
        protein: 0.42,
        alanine: 0.024,
        arginine: 0.015,
        aspartic_acid: 0.114,
        cystine: 0.002,
        glutamic_acid: 0.067,
        glycine: 0.011,
        histidine: 0.002,
        isoleucine: 0.002,
        leucine: 0.016,
        lysine: 0.016,
        methionine: 0.002,
        phenylalanine: 0.011,
        proline: 0.03,
        serine: 0.035,
        threonine: 0.002,
        tryptophan: 0.002,
        tyrosine: 0.002,
        valine: 0.011,
        b1_thiamine: 0.025,
        b2_riboflavin: 0.015,
        b3_niacin: 0.142,
        b5_pantothenic_acid: 0.123,
        b6_pyridoxine: 0.038,
        choline: 5.1,
        folate: 10.0,
        vitamin_a: 50.0,
        vitamin_c: 30.0,
        vitamin_e: 0.22,
        vitamin_k: 0.6,
        calcium: 14.0,
        copper: 0.027,
        iron: 0.09,
        magnesium: 8.0,
        manganese: 0.018,
        phosphorus: 14.0,
        potassium: 117.0,
        selenium: 0.1,
        sodium: 2.0,
        zinc: 0.08
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '100': {
      id: 100,
      name: 'pure pumpkin purée',
      pluralName: 'pure pumpkin purée',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 53,
          packageAmount: 1.0,
          amountInBase: 425.0,
          equivalents: ''
        },
        us: {
          unitId: 30,
          packageAmount: 1.0,
          amountInBase: 425.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 34.0,
        ash: 0.56,
        water: 89.97,
        carbs: 8.09,
        fiber: 2.9,
        starch: 0.22,
        sugars: 3.3,
        fructose: 1.0,
        glucose: 1.3,
        sucrose: 1.0,
        fat: 0.28,
        monounsaturated: 0.037,
        polyunsaturated: 0.015,
        omega_3: 0.008,
        omega_6: 0.007,
        saturated: 0.146,
        protein: 1.1,
        alanine: 0.031,
        arginine: 0.059,
        aspartic_acid: 0.112,
        cystine: 0.003,
        glutamic_acid: 0.202,
        glycine: 0.029,
        histidine: 0.017,
        isoleucine: 0.034,
        leucine: 0.051,
        lysine: 0.06,
        methionine: 0.012,
        phenylalanine: 0.035,
        proline: 0.029,
        serine: 0.048,
        threonine: 0.032,
        tryptophan: 0.013,
        tyrosine: 0.046,
        valine: 0.038,
        b1_thiamine: 0.024,
        b2_riboflavin: 0.054,
        b3_niacin: 0.367,
        b5_pantothenic_acid: 0.4,
        b6_pyridoxine: 0.056,
        choline: 9.8,
        folate: 12.0,
        vitamin_a: 15.0,
        vitamin_c: 4.2,
        vitamin_e: 1.06,
        vitamin_k: 16.0,
        calcium: 26.0,
        copper: 0.107,
        iron: 1.39,
        magnesium: 23.0,
        manganese: 0.149,
        phosphorus: 35.0,
        potassium: 206.0,
        selenium: 0.4,
        sodium: 5.0,
        zinc: 0.17
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '304': {
      id: 304,
      name: 'pumpkin seeds',
      pluralName: 'pumpkin seeds',
      description: '',
      sectionId: 12,
      subsectionId: 0,
      density: 0.498756835,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 574.0,
        ash: 4.37,
        water: 2.03,
        carbs: 14.71,
        fiber: 6.5,
        starch: 0.74,
        sugars: 1.29,
        fructose: 0.07,
        glucose: 0.07,
        sucrose: 1.14,
        fat: 49.05,
        monounsaturated: 15.734,
        polyunsaturated: 19.856,
        omega_3: 0.111,
        omega_6: 19.73,
        saturated: 8.544,
        transfats: 0.042,
        protein: 29.84,
        alanine: 1.466,
        arginine: 5.284,
        aspartic_acid: 2.922,
        cystine: 0.327,
        glutamic_acid: 6.108,
        glycine: 1.819,
        histidine: 0.77,
        isoleucine: 1.265,
        leucine: 2.388,
        lysine: 1.22,
        methionine: 0.595,
        phenylalanine: 1.711,
        proline: 1.299,
        serine: 1.652,
        threonine: 0.985,
        tryptophan: 0.569,
        tyrosine: 1.079,
        valine: 1.559,
        b1_thiamine: 0.07,
        b2_riboflavin: 0.15,
        b3_niacin: 4.43,
        b5_pantothenic_acid: 0.57,
        b6_pyridoxine: 0.1,
        choline: 63.0,
        folate: 57.0,
        vitamin_a: 8.0,
        vitamin_c: 1.8,
        vitamin_e: 0.56,
        vitamin_k: 4.5,
        calcium: 52.0,
        copper: 1.275,
        iron: 8.07,
        magnesium: 550.0,
        manganese: 4.49,
        phosphorus: 1.0,
        potassium: 788.0,
        selenium: 9.4,
        sodium: 18.0,
        zinc: 7.64
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '201': {
      id: 201,
      name: 'chicken or vegetable broth',
      pluralName: 'chicken or vegetable broth',
      description: '',
      sectionId: 9,
      subsectionId: 0,
      density: 1.052461457,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 236.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 60,
          packageAmount: 8.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 16.0,
        ash: 0.2,
        water: 96.0,
        carbs: 1.2,
        starch: 0.09,
        sugars: 0.13,
        fructose: 0.01,
        glucose: 0.1,
        sucrose: 0.01,
        fat: 0.6,
        monounsaturated: 0.274,
        polyunsaturated: 0.126,
        omega_3: 0.011,
        omega_6: 0.116,
        saturated: 0.179,
        transfats: 0.02,
        protein: 2.0,
        alanine: 0.11,
        arginine: 0.12,
        aspartic_acid: 0.18,
        cystine: 0.03,
        glutamic_acid: 0.3,
        glycine: 0.1,
        histidine: 0.06,
        isoleucine: 0.11,
        leucine: 0.15,
        lysine: 0.17,
        methionine: 0.05,
        phenylalanine: 0.08,
        proline: 0.09,
        serine: 0.07,
        threonine: 0.09,
        tryptophan: 0.02,
        tyrosine: 0.07,
        valine: 0.1,
        b2_riboflavin: 0.03,
        b3_niacin: 1.37,
        b5_pantothenic_acid: 0.13,
        b6_pyridoxine: 0.01,
        b12_cobalamin: 0.1,
        choline: 6.2,
        calcium: 4.0,
        copper: 0.05,
        iron: 0.21,
        magnesium: 1.0,
        manganese: 0.06,
        phosphorus: 30.0,
        potassium: 86.0,
        sodium: 30.0,
        zinc: 0.1
      },
      restrictionWarnings: [14, 13, 12, 11, 10, 9, 2, 1],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '187': {
      id: 187,
      name: 'large flour tortilla',
      pluralName: 'large flour tortillas',
      description: '',
      sectionId: 6,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 62.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 62.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 301.0,
        ash: 2.5,
        water: 31.58,
        carbs: 51.21,
        fiber: 2.3,
        starch: 42.51,
        sugars: 4.39,
        fructose: 0.03,
        glucose: 0.08,
        maltose: 3.63,
        sucrose: 0.66,
        fat: 6.7,
        monounsaturated: 3.53,
        polyunsaturated: 1.43,
        omega_3: 0.05,
        omega_6: 1.32,
        saturated: 1.63,
        transfats: 1.22,
        protein: 8.0,
        alanine: 0.29,
        arginine: 0.36,
        aspartic_acid: 0.39,
        cystine: 0.18,
        glutamic_acid: 2.85,
        glycine: 0.32,
        histidine: 0.2,
        isoleucine: 0.31,
        leucine: 0.6,
        lysine: 0.21,
        methionine: 0.16,
        phenylalanine: 0.44,
        proline: 0.98,
        serine: 0.43,
        threonine: 0.25,
        tryptophan: 0.11,
        tyrosine: 0.27,
        valine: 0.36,
        b1_thiamine: 0.49,
        b2_riboflavin: 0.13,
        b3_niacin: 3.63,
        b5_pantothenic_acid: 0.16,
        b6_pyridoxine: 0.05,
        choline: 7.9,
        folate: 123.0,
        vitamin_e: 0.19,
        vitamin_k: 3.4,
        calcium: 106.0,
        copper: 0.11,
        iron: 3.32,
        magnesium: 20.0,
        manganese: 0.49,
        phosphorus: 190.0,
        potassium: 153.0,
        selenium: 24.0,
        sodium: 686.0,
        zinc: 0.54
      },
      restrictionWarnings: [14, 13, 12, 11, 10, 9, 6, 2],
      restrictionViolations: [1],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '71': {
      id: 71,
      name: 'cayenne pepper',
      pluralName: 'cayenne pepper',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.358428641,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 318.0,
        ash: 6.04,
        water: 8.05,
        carbs: 56.63,
        fiber: 27.2,
        sugars: 10.34,
        fructose: 5.66,
        glucose: 4.68,
        fat: 17.27,
        monounsaturated: 2.75,
        polyunsaturated: 8.37,
        omega_3: 0.66,
        omega_6: 7.71,
        saturated: 3.26,
        protein: 12.01,
        alanine: 0.37,
        arginine: 0.26,
        aspartic_acid: 0.51,
        cystine: 0.04,
        glutamic_acid: 0.65,
        glycine: 0.03,
        histidine: 0.24,
        isoleucine: 0.14,
        leucine: 0.23,
        lysine: 0.07,
        methionine: 0.09,
        phenylalanine: 0.13,
        proline: 0.45,
        serine: 0.02,
        threonine: 0.1,
        tryptophan: 0.11,
        tyrosine: 0.04,
        valine: 0.23,
        b1_thiamine: 0.328,
        b2_riboflavin: 0.919,
        b3_niacin: 8.701,
        b5_pantothenic_acid: 0.37,
        b6_pyridoxine: 2.45,
        choline: 51.5,
        folate: 106.0,
        vitamin_a: 41.0,
        vitamin_c: 76.4,
        vitamin_e: 29.83,
        vitamin_k: 80.3,
        calcium: 148.0,
        copper: 0.373,
        iron: 7.8,
        magnesium: 152.0,
        manganese: 2.0,
        phosphorus: 293.0,
        potassium: 2.0,
        selenium: 8.8,
        sodium: 30.0,
        zinc: 2.48
      },
      restrictionWarnings: [],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '293': {
      id: 293,
      name: 'brown sugar',
      pluralName: 'brown sugar',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.929890015,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 380.0,
        ash: 0.45,
        water: 1.34,
        carbs: 98.09,
        sugars: 97.02,
        fructose: 1.11,
        glucose: 1.35,
        sucrose: 94.56,
        protein: 0.12,
        b3_niacin: 0.11,
        b5_pantothenic_acid: 0.132,
        b6_pyridoxine: 0.041,
        choline: 2.3,
        folate: 1.0,
        calcium: 83.0,
        copper: 0.047,
        iron: 0.71,
        magnesium: 9.0,
        manganese: 0.064,
        phosphorus: 4.0,
        potassium: 133.0,
        selenium: 1.2,
        sodium: 28.0,
        zinc: 0.03
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '108': {
      id: 108,
      name: 'cashews, roasted unsalted',
      pluralName: 'cashews, roasted unsalted',
      description: '',
      sectionId: 12,
      subsectionId: 0,
      density: 0.579065139,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 574.0,
        ash: 3.95,
        water: 1.7,
        carbs: 32.69,
        fiber: 3.0,
        starch: 18.76,
        sugars: 5.01,
        glucose: 0.16,
        sucrose: 4.86,
        fat: 46.35,
        monounsaturated: 27.317,
        polyunsaturated: 7.836,
        omega_3: 0.161,
        omega_6: 7.66,
        saturated: 9.157,
        protein: 15.31,
        alanine: 0.702,
        arginine: 1.741,
        aspartic_acid: 1.505,
        cystine: 0.283,
        glutamic_acid: 3.624,
        glycine: 0.803,
        histidine: 0.399,
        isoleucine: 0.731,
        leucine: 1.285,
        lysine: 0.817,
        methionine: 0.274,
        phenylalanine: 0.791,
        proline: 0.69,
        serine: 0.849,
        threonine: 0.592,
        tryptophan: 0.237,
        tyrosine: 0.491,
        valine: 1.04,
        b1_thiamine: 0.2,
        b2_riboflavin: 0.2,
        b3_niacin: 1.4,
        b5_pantothenic_acid: 1.217,
        b6_pyridoxine: 0.256,
        choline: 61.0,
        folate: 69.0,
        vitamin_e: 0.92,
        vitamin_k: 34.7,
        calcium: 45.0,
        copper: 2.22,
        iron: 6.0,
        magnesium: 260.0,
        manganese: 0.826,
        phosphorus: 490.0,
        potassium: 565.0,
        selenium: 11.7,
        sodium: 16.0,
        zinc: 5.6
      },
      restrictionWarnings: [],
      restrictionViolations: [6],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '217': {
      id: 217,
      name: 'peanuts, roasted unsalted',
      pluralName: 'peanuts, roasted unsalted',
      description: '',
      sectionId: 12,
      subsectionId: 0,
      density: 0.617105915,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 587.0,
        ash: 2.92,
        water: 1.81,
        carbs: 21.26,
        fiber: 8.4,
        starch: 4.39,
        sugars: 4.9,
        sucrose: 4.9,
        fat: 49.66,
        monounsaturated: 26.181,
        polyunsaturated: 9.773,
        omega_3: 0.037,
        omega_6: 9.735,
        saturated: 7.723,
        transfats: 0.027,
        protein: 24.35,
        alanine: 0.941,
        arginine: 2.832,
        aspartic_acid: 2.888,
        cystine: 0.304,
        glutamic_acid: 4.949,
        glycine: 1.427,
        histidine: 0.599,
        isoleucine: 0.833,
        leucine: 1.535,
        lysine: 0.85,
        methionine: 0.291,
        phenylalanine: 1.227,
        proline: 1.045,
        serine: 1.167,
        threonine: 0.811,
        tryptophan: 0.23,
        tyrosine: 0.963,
        valine: 0.993,
        b1_thiamine: 0.152,
        b2_riboflavin: 0.197,
        b3_niacin: 14.355,
        b5_pantothenic_acid: 1.011,
        b6_pyridoxine: 0.466,
        choline: 64.6,
        folate: 97.0,
        vitamin_e: 4.93,
        calcium: 58.0,
        copper: 0.428,
        iron: 1.58,
        magnesium: 178.0,
        manganese: 1.786,
        phosphorus: 363.0,
        potassium: 634.0,
        selenium: 9.3,
        sodium: 6.0,
        zinc: 2.77
      },
      restrictionWarnings: [],
      restrictionViolations: [5],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '272': {
      id: 272,
      name: 'walnuts',
      pluralName: 'walnuts',
      description: '',
      sectionId: 12,
      subsectionId: 0,
      density: 0.422675284,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 654.0,
        ash: 1.78,
        water: 4.07,
        carbs: 13.71,
        fiber: 6.7,
        starch: 0.06,
        sugars: 2.61,
        fructose: 0.09,
        glucose: 0.08,
        sucrose: 2.43,
        fat: 65.21,
        monounsaturated: 8.933,
        polyunsaturated: 47.174,
        omega_3: 9.08,
        omega_6: 38.093,
        saturated: 6.126,
        protein: 15.23,
        alanine: 0.696,
        arginine: 2.278,
        aspartic_acid: 1.829,
        cystine: 0.208,
        glutamic_acid: 2.816,
        glycine: 0.816,
        histidine: 0.391,
        isoleucine: 0.625,
        leucine: 1.17,
        lysine: 0.424,
        methionine: 0.236,
        phenylalanine: 0.711,
        proline: 0.706,
        serine: 0.934,
        threonine: 0.596,
        tryptophan: 0.17,
        tyrosine: 0.406,
        valine: 0.753,
        b1_thiamine: 0.341,
        b2_riboflavin: 0.15,
        b3_niacin: 1.125,
        b5_pantothenic_acid: 0.57,
        b6_pyridoxine: 0.537,
        choline: 39.2,
        folate: 98.0,
        vitamin_a: 20.0,
        vitamin_c: 1.3,
        vitamin_e: 0.7,
        vitamin_k: 2.7,
        calcium: 98.0,
        copper: 1.586,
        iron: 2.91,
        magnesium: 158.0,
        manganese: 3.414,
        phosphorus: 346.0,
        potassium: 441.0,
        selenium: 4.9,
        sodium: 2.0,
        zinc: 3.09
      },
      restrictionWarnings: [],
      restrictionViolations: [6],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '191': {
      id: 191,
      name: 'whole grain bread',
      pluralName: 'whole grain bread',
      description: '',
      sectionId: 6,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 67,
          packageAmount: 1.0,
          amountInBase: 32.0,
          equivalents: ''
        },
        us: {
          unitId: 67,
          packageAmount: 1.0,
          amountInBase: 32.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 252.0,
        ash: 2.32,
        water: 39.01,
        carbs: 42.71,
        fiber: 6.0,
        starch: 28.73,
        sugars: 4.34,
        fructose: 2.21,
        glucose: 1.07,
        lactose: 0.01,
        maltose: 1.02,
        sucrose: 0.03,
        fat: 3.5,
        monounsaturated: 0.62,
        polyunsaturated: 1.592,
        omega_3: 0.138,
        omega_6: 1.454,
        saturated: 0.722,
        transfats: 0.02,
        protein: 12.45,
        b1_thiamine: 0.394,
        b2_riboflavin: 0.167,
        b3_niacin: 4.438,
        b5_pantothenic_acid: 0.646,
        b6_pyridoxine: 0.215,
        choline: 27.2,
        folate: 42.0,
        vitamin_a: 3.0,
        vitamin_e: 2.66,
        vitamin_k: 7.8,
        calcium: 161.0,
        copper: 0.227,
        iron: 2.47,
        magnesium: 75.0,
        manganese: 2.174,
        phosphorus: 212.0,
        potassium: 254.0,
        selenium: 25.7,
        sodium: 455.0,
        zinc: 1.77
      },
      restrictionWarnings: [14, 13, 12, 11, 10, 9, 6, 5, 2],
      restrictionViolations: [1],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '128': {
      id: 128,
      name: 'whole grain bun or roll',
      pluralName: 'whole grain buns or rolls',
      description: '',
      sectionId: 6,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 43.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 43.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 246.0,
        ash: 3.82,
        water: 38.58,
        carbs: 41.29,
        fiber: 6.71,
        starch: 22.76,
        sugars: 10.08,
        fructose: 3.82,
        glucose: 2.88,
        maltose: 3.38,
        fat: 3.35,
        monounsaturated: 1.6,
        polyunsaturated: 2.161,
        omega_3: 0.06,
        omega_6: 0.54,
        saturated: 0.75,
        transfats: 0.83,
        protein: 12.95,
        alanine: 0.21,
        arginine: 0.25,
        aspartic_acid: 0.31,
        cystine: 0.14,
        glutamic_acid: 1.89,
        glycine: 0.24,
        histidine: 0.14,
        isoleucine: 0.22,
        leucine: 0.41,
        lysine: 0.17,
        methionine: 0.09,
        phenylalanine: 0.28,
        proline: 0.63,
        serine: 0.28,
        threonine: 0.17,
        tryptophan: 0.09,
        tyrosine: 0.18,
        valine: 0.27,
        b1_thiamine: 0.35,
        b2_riboflavin: 0.22,
        b3_niacin: 4.71,
        b5_pantothenic_acid: 0.69,
        b6_pyridoxine: 0.21,
        choline: 26.5,
        folate: 50.0,
        vitamin_a: 3.0,
        vitamin_e: 0.55,
        vitamin_k: 7.8,
        calcium: 107.0,
        copper: 0.38,
        iron: 2.43,
        magnesium: 82.0,
        manganese: 2.14,
        phosphorus: 202.0,
        potassium: 248.0,
        selenium: 40.3,
        sodium: 472.0,
        zinc: 1.8
      },
      restrictionWarnings: [14, 13, 12, 11, 10, 9, 6, 5, 2],
      restrictionViolations: [1],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '280': {
      id: 280,
      name: 'small gluten-free tortilla',
      pluralName: 'small gluten-free tortillas',
      description: '',
      sectionId: 6,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 49.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 49.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 228.07,
        carbs: 42.11,
        fiber: 3.51,
        fat: 4.39,
        protein: 3.51,
        b3_niacin: 3.51,
        b5_pantothenic_acid: 1.05,
        b6_pyridoxine: 0.35,
        copper: 0.14,
        iron: 1.23,
        magnesium: 70.18,
        phosphorus: 175.44,
        potassium: 166.67,
        sodium: 280.7,
        zinc: 1.05
      },
      restrictionWarnings: [14, 13, 12, 9, 6, 5, 2],
      restrictionViolations: [11, 10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '87': {
      id: 87,
      name: 'sage, dried',
      pluralName: 'sage, dried',
      description: '',
      sectionId: 8,
      subsectionId: null,
      density: 0.135256091,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 315.0,
        ash: 7.95,
        water: 7.96,
        carbs: 60.73,
        fiber: 40.3,
        starch: 18.72,
        sugars: 1.71,
        fat: 12.75,
        monounsaturated: 1.87,
        polyunsaturated: 1.76,
        omega_3: 1.23,
        omega_6: 0.53,
        saturated: 7.03,
        protein: 10.63,
        alanine: 0.55,
        arginine: 0.49,
        aspartic_acid: 1.26,
        cystine: 0.12,
        glutamic_acid: 1.16,
        glycine: 0.51,
        histidine: 0.21,
        isoleucine: 0.44,
        leucine: 0.8,
        lysine: 0.46,
        methionine: 0.15,
        phenylalanine: 0.54,
        proline: 0.44,
        serine: 0.42,
        threonine: 0.44,
        tryptophan: 0.16,
        tyrosine: 0.32,
        valine: 0.53,
        b1_thiamine: 0.754,
        b2_riboflavin: 0.336,
        b3_niacin: 5.72,
        b6_pyridoxine: 2.69,
        choline: 43.6,
        folate: 274.0,
        vitamin_a: 5.0,
        vitamin_c: 32.4,
        vitamin_e: 7.48,
        vitamin_k: 1.0,
        calcium: 1.0,
        copper: 0.757,
        iron: 28.12,
        magnesium: 428.0,
        manganese: 3.133,
        phosphorus: 91.0,
        potassium: 1.0,
        selenium: 3.7,
        sodium: 11.0,
        zinc: 4.7
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '89': {
      id: 89,
      name: 'thyme, dried',
      pluralName: 'thyme, dried',
      description: '',
      sectionId: 8,
      subsectionId: null,
      density: 0.182595723,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 276.0,
        ash: 11.74,
        water: 7.79,
        carbs: 63.94,
        fiber: 37.0,
        starch: 25.23,
        sugars: 1.71,
        fat: 7.43,
        monounsaturated: 0.47,
        polyunsaturated: 1.19,
        omega_3: 0.69,
        omega_6: 0.5,
        saturated: 2.73,
        protein: 9.11,
        isoleucine: 0.468,
        leucine: 0.43,
        lysine: 0.207,
        threonine: 0.252,
        tryptophan: 0.186,
        valine: 0.502,
        b1_thiamine: 0.513,
        b2_riboflavin: 0.399,
        b3_niacin: 4.94,
        b6_pyridoxine: 0.55,
        choline: 43.6,
        folate: 274.0,
        vitamin_a: 3.0,
        vitamin_c: 50.0,
        vitamin_e: 7.48,
        vitamin_k: 1.0,
        calcium: 1.0,
        copper: 0.86,
        iron: 123.6,
        magnesium: 220.0,
        manganese: 7.867,
        phosphorus: 201.0,
        potassium: 814.0,
        selenium: 4.6,
        sodium: 55.0,
        zinc: 6.18
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '117': {
      id: 117,
      name: 'Dijon mustard',
      pluralName: 'Dijon mustard',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 1.052461457,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 102.78,
        alcohol: 0.13,
        ash: 7.01,
        water: 72.31,
        carbs: 6.27,
        fiber: 3.18,
        starch: 1.59,
        sugars: 1.04,
        fructose: 0.23,
        galactose: 0.02,
        glucose: 0.43,
        sucrose: 0.35,
        fat: 5.92,
        monounsaturated: 3.64,
        polyunsaturated: 1.32,
        omega_3: 0.52,
        omega_6: 0.71,
        saturated: 0.47,
        protein: 4.35,
        alanine: 0.2,
        arginine: 0.28,
        aspartic_acid: 0.33,
        cystine: 0.09,
        glutamic_acid: 0.79,
        glycine: 0.21,
        histidine: 0.12,
        isoleucine: 0.17,
        leucine: 0.29,
        lysine: 0.24,
        methionine: 0.08,
        phenylalanine: 0.17,
        proline: 0.31,
        serine: 0.17,
        threonine: 0.17,
        tryptophan: 0.08,
        tyrosine: 0.12,
        valine: 0.21,
        b1_thiamine: 0.09,
        b2_riboflavin: 0.08,
        b3_niacin: 1.35,
        b5_pantothenic_acid: 0.02,
        b6_pyridoxine: 0.12,
        choline: 17.93,
        folate: 23.02,
        vitamin_a: 892.22,
        vitamin_c: 6.39,
        vitamin_e: 1.2,
        vitamin_k: 5.52,
        calcium: 61.42,
        copper: 0.06,
        iron: 2.32,
        magnesium: 44.84,
        manganese: 1.66,
        phosphorus: 112.42,
        potassium: 206.52,
        selenium: 25.73,
        sodium: 2.0,
        zinc: 0.99
      },
      restrictionWarnings: [12, 10, 9],
      restrictionViolations: [14, 13],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '313': {
      id: 313,
      name: 'pork sausage',
      pluralName: 'pork sausages',
      description: '',
      sectionId: 1,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 91.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 91.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 339.0,
        ash: 2.76,
        water: 49.78,
        fat: 28.36,
        monounsaturated: 12.38,
        polyunsaturated: 3.73,
        omega_6: 3.4,
        saturated: 9.15,
        transfats: 0.11,
        cholesterol: 84.0,
        protein: 19.43,
        alanine: 1.09,
        arginine: 1.15,
        aspartic_acid: 1.62,
        cystine: 0.2,
        glutamic_acid: 2.69,
        glycine: 1.18,
        histidine: 0.56,
        isoleucine: 0.71,
        leucine: 1.3,
        lysine: 1.48,
        methionine: 0.47,
        phenylalanine: 0.65,
        proline: 0.9,
        serine: 0.75,
        threonine: 0.77,
        tryptophan: 0.16,
        tyrosine: 0.56,
        valine: 0.78,
        b1_thiamine: 0.29,
        b2_riboflavin: 0.2,
        b3_niacin: 6.26,
        b5_pantothenic_acid: 0.72,
        b6_pyridoxine: 0.33,
        b12_cobalamin: 1.18,
        choline: 66.9,
        folate: 3.0,
        vitamin_a: 41.0,
        vitamin_c: 0.7,
        vitamin_d: 27.0,
        vitamin_e: 0.55,
        vitamin_k: 0.4,
        calcium: 13.0,
        copper: 0.09,
        iron: 1.36,
        magnesium: 17.0,
        phosphorus: 163.0,
        potassium: 294.0,
        sodium: 749.0,
        zinc: 2.08
      },
      restrictionWarnings: [14, 13, 12, 11, 10, 9, 2, 1],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '267': {
      id: 267,
      name: 'mayonnaise',
      pluralName: 'mayonnaise',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 0.929885625,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 731.56,
        ash: 0.72,
        water: 13.43,
        carbs: 2.55,
        fiber: 0.11,
        sugars: 1.84,
        fructose: 0.13,
        glucose: 0.13,
        sucrose: 1.56,
        fat: 81.14,
        monounsaturated: 18.93,
        polyunsaturated: 46.08,
        omega_3: 5.42,
        omega_6: 40.66,
        saturated: 13.0,
        transfats: 0.42,
        cholesterol: 65.5,
        protein: 1.18,
        alanine: 0.06,
        arginine: 0.08,
        aspartic_acid: 0.11,
        cystine: 0.02,
        glutamic_acid: 0.15,
        glycine: 0.04,
        histidine: 0.03,
        isoleucine: 0.06,
        leucine: 0.1,
        lysine: 0.08,
        methionine: 0.03,
        phenylalanine: 0.05,
        proline: 0.05,
        serine: 0.09,
        threonine: 0.05,
        tryptophan: 0.01,
        tyrosine: 0.05,
        valine: 0.07,
        b1_thiamine: 0.02,
        b2_riboflavin: 0.04,
        b3_niacin: 0.07,
        b5_pantothenic_acid: 0.19,
        b6_pyridoxine: 0.03,
        b12_cobalamin: 0.12,
        choline: 50.9,
        folate: 10.81,
        vitamin_a: 88.88,
        vitamin_c: 2.87,
        vitamin_d: 13.16,
        vitamin_e: 6.67,
        vitamin_k: 145.85,
        calcium: 10.79,
        copper: 0.01,
        iron: 0.27,
        magnesium: 2.61,
        manganese: 0.02,
        phosphorus: 28.85,
        potassium: 21.79,
        selenium: 40.87,
        sodium: 219.64,
        zinc: 0.19
      },
      restrictionWarnings: [14, 13, 12, 10, 9],
      restrictionViolations: [11],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '81': {
      id: 81,
      name: 'Italian seasoning',
      pluralName: 'Italian seasoning',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.2,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        },
        us: {
          unitId: 91,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        }
      },
      nutrition: {
        energy: 272.0,
        ash: 10.9,
        water: 8.73,
        carbs: 60.38,
        fiber: 39.56,
        starch: 6.13,
        sugars: 2.66,
        fructose: 0.38,
        galactose: 0.07,
        glucose: 0.53,
        sucrose: 0.19,
        fat: 7.11,
        monounsaturated: 1.05,
        polyunsaturated: 1.85,
        omega_3: 1.21,
        omega_6: 0.63,
        saturated: 2.8,
        protein: 12.88,
        alanine: 0.45,
        arginine: 0.43,
        aspartic_acid: 1.06,
        cystine: 0.08,
        glutamic_acid: 0.94,
        glycine: 0.46,
        histidine: 0.16,
        isoleucine: 0.48,
        leucine: 0.77,
        lysine: 0.46,
        methionine: 0.12,
        phenylalanine: 0.45,
        proline: 0.97,
        serine: 0.25,
        threonine: 0.35,
        tryptophan: 0.16,
        tyrosine: 0.27,
        valine: 0.61,
        b1_thiamine: 0.36,
        b2_riboflavin: 0.56,
        b3_niacin: 4.86,
        b5_pantothenic_acid: 0.35,
        b6_pyridoxine: 1.36,
        choline: 43.6,
        folate: 273.8,
        vitamin_a: 4.0,
        vitamin_c: 27.38,
        vitamin_e: 9.12,
        vitamin_k: 1.0,
        calcium: 1.0,
        copper: 1.1,
        iron: 72.21,
        magnesium: 395.0,
        manganese: 6.25,
        phosphorus: 204.0,
        potassium: 1.0,
        selenium: 4.06,
        sodium: 48.8,
        zinc: 4.85
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '279': {
      id: 279,
      name: 'striploin (New York strip) steak',
      pluralName: 'striploin (New York strip) steak',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '1 striploin steak = 0.17-0.34 kg'
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '1 striploin steak = 6-12 oz / 0.38-0.75 lb'
        }
      },
      nutrition: {
        energy: 205.0,
        ash: 0.94,
        water: 64.65,
        fat: 13.36,
        monounsaturated: 6.455,
        polyunsaturated: 0.671,
        omega_3: 0.035,
        omega_6: 0.636,
        saturated: 5.685,
        transfats: 0.796,
        cholesterol: 61.0,
        protein: 21.13,
        alanine: 1.306,
        arginine: 1.426,
        aspartic_acid: 2.041,
        cystine: 0.213,
        glutamic_acid: 3.437,
        glycine: 1.042,
        histidine: 0.778,
        isoleucine: 0.982,
        leucine: 1.805,
        lysine: 1.998,
        methionine: 0.569,
        phenylalanine: 0.843,
        proline: 0.942,
        serine: 0.863,
        threonine: 0.991,
        tryptophan: 0.237,
        tyrosine: 0.781,
        valine: 1.055,
        b1_thiamine: 0.049,
        b2_riboflavin: 0.184,
        b3_niacin: 6.286,
        b5_pantothenic_acid: 0.344,
        b6_pyridoxine: 0.543,
        b12_cobalamin: 1.63,
        choline: 53.3,
        folate: 3.0,
        vitamin_a: 14.0,
        vitamin_d: 4.0,
        vitamin_e: 0.23,
        vitamin_k: 1.5,
        calcium: 16.0,
        copper: 0.038,
        iron: 1.88,
        magnesium: 10.0,
        manganese: 0.002,
        phosphorus: 187.0,
        potassium: 253.0,
        selenium: 19.0,
        sodium: 44.0,
        zinc: 3.35
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '203': {
      id: 203,
      name: 'Kalamata olives, pitted',
      pluralName: 'Kalamata olives, pitted',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 0.61,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: { energy: 267.0, carbs: 6.67, fat: 26.67, sodium: 1600.0 },
      restrictionWarnings: [13],
      restrictionViolations: [14],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '120': {
      id: 120,
      name: 'Parmesan cheese',
      pluralName: 'Parmesan cheese',
      description: '',
      sectionId: 7,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 392.0,
        ash: 6.04,
        water: 29.16,
        carbs: 3.22,
        sugars: 0.8,
        lactose: 0.8,
        fat: 25.83,
        monounsaturated: 7.515,
        polyunsaturated: 0.569,
        omega_3: 0.297,
        omega_6: 0.272,
        saturated: 16.41,
        transfats: 0.62,
        cholesterol: 68.0,
        protein: 35.75,
        alanine: 1.048,
        arginine: 1.317,
        aspartic_acid: 2.236,
        cystine: 0.235,
        glutamic_acid: 8.209,
        glycine: 0.622,
        histidine: 1.384,
        isoleucine: 1.894,
        leucine: 3.452,
        lysine: 3.306,
        methionine: 0.958,
        phenylalanine: 1.922,
        proline: 4.18,
        serine: 2.068,
        threonine: 1.317,
        tryptophan: 0.482,
        tyrosine: 1.995,
        valine: 2.454,
        b1_thiamine: 0.039,
        b2_riboflavin: 0.332,
        b3_niacin: 0.271,
        b5_pantothenic_acid: 0.453,
        b6_pyridoxine: 0.091,
        b12_cobalamin: 1.2,
        choline: 15.4,
        folate: 7.0,
        vitamin_a: 781.0,
        vitamin_d: 19.0,
        vitamin_e: 0.22,
        vitamin_k: 1.7,
        calcium: 1.0,
        copper: 0.032,
        iron: 0.82,
        magnesium: 44.0,
        manganese: 0.02,
        phosphorus: 694.0,
        potassium: 92.0,
        selenium: 22.5,
        sodium: 1.0,
        zinc: 2.75
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '51': {
      id: 51,
      name: 'toasted sesame oil',
      pluralName: 'toasted sesame oil',
      description: '',
      sectionId: 5,
      subsectionId: 0,
      density: 0.921432119,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 884.0,
        fat: 100.0,
        monounsaturated: 39.7,
        polyunsaturated: 41.7,
        omega_3: 0.3,
        omega_6: 41.3,
        saturated: 14.2,
        choline: 0.2,
        vitamin_e: 1.4,
        vitamin_k: 13.6,
        zinc: 0.16
      },
      restrictionWarnings: [],
      restrictionViolations: [12],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '115': {
      id: 115,
      name: 'butter, unsalted',
      pluralName: 'butter, unsalted',
      description: '',
      sectionId: 2,
      subsectionId: 0,
      density: 0.959472895,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 717.0,
        ash: 0.04,
        water: 17.94,
        carbs: 0.06,
        sugars: 0.06,
        lactose: 0.6,
        fat: 81.11,
        monounsaturated: 21.021,
        polyunsaturated: 3.043,
        omega_3: 0.315,
        omega_6: 2.728,
        saturated: 51.368,
        transfats: 3.278,
        cholesterol: 215.0,
        protein: 0.85,
        alanine: 0.029,
        arginine: 0.031,
        aspartic_acid: 0.064,
        cystine: 0.008,
        glutamic_acid: 0.178,
        glycine: 0.018,
        histidine: 0.023,
        isoleucine: 0.051,
        leucine: 0.083,
        lysine: 0.067,
        methionine: 0.067,
        phenylalanine: 0.041,
        proline: 0.082,
        serine: 0.046,
        threonine: 0.038,
        tryptophan: 0.012,
        tyrosine: 0.041,
        valine: 0.057,
        b1_thiamine: 0.005,
        b2_riboflavin: 0.034,
        b3_niacin: 0.042,
        b5_pantothenic_acid: 0.11,
        b6_pyridoxine: 0.003,
        b12_cobalamin: 0.17,
        choline: 18.8,
        folate: 3.0,
        vitamin_a: 2.0,
        vitamin_d: 60.0,
        vitamin_e: 2.32,
        vitamin_k: 7.0,
        calcium: 24.0,
        copper: 0.016,
        iron: 0.02,
        magnesium: 2.0,
        manganese: 0.004,
        phosphorus: 24.0,
        potassium: 24.0,
        selenium: 1.0,
        sodium: 11.0,
        zinc: 0.09
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '47': {
      id: 47,
      name: 'extra virgin olive oil',
      pluralName: 'extra virgin olive oil',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 0.912978613,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 884.0,
        fat: 100.0,
        monounsaturated: 72.961,
        polyunsaturated: 10.523,
        omega_3: 0.761,
        omega_6: 9.762,
        saturated: 13.808,
        transfats: 0.16,
        choline: 0.3,
        vitamin_e: 14.35,
        vitamin_k: 60.2,
        calcium: 1.0,
        iron: 0.56,
        potassium: 1.0,
        sodium: 2.0
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '212': {
      id: 212,
      name: 'flatbread or naan',
      pluralName: 'flatbreads or naan',
      description: '',
      sectionId: 6,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 125.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 125.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 311.0,
        ash: 1.46,
        water: 44.34,
        carbs: 42.73,
        fiber: 1.62,
        starch: 39.17,
        sugars: 1.93,
        galactose: 0.48,
        lactose: 1.27,
        sucrose: 0.15,
        fat: 11.62,
        monounsaturated: 2.89,
        polyunsaturated: 0.79,
        omega_3: 0.07,
        omega_6: 0.72,
        saturated: 6.88,
        transfats: 0.41,
        cholesterol: 29.78,
        protein: 8.51,
        alanine: 0.29,
        arginine: 0.3,
        aspartic_acid: 0.42,
        cystine: 0.17,
        glutamic_acid: 2.69,
        glycine: 0.28,
        histidine: 0.19,
        isoleucine: 0.34,
        leucine: 0.63,
        lysine: 0.29,
        methionine: 0.16,
        phenylalanine: 0.42,
        proline: 0.97,
        serine: 0.43,
        threonine: 0.26,
        tryptophan: 0.09,
        tyrosine: 0.27,
        valine: 0.42,
        b1_thiamine: 0.58,
        b2_riboflavin: 0.38,
        b3_niacin: 4.67,
        b5_pantothenic_acid: 0.54,
        b6_pyridoxine: 0.05,
        b12_cobalamin: 0.16,
        choline: 14.02,
        folate: 98.43,
        vitamin_a: 327.67,
        vitamin_c: 0.19,
        vitamin_d: 7.68,
        vitamin_e: 0.53,
        vitamin_k: 1.06,
        calcium: 57.06,
        copper: 0.11,
        iron: 2.51,
        magnesium: 19.29,
        manganese: 0.45,
        phosphorus: 99.15,
        potassium: 126.62,
        selenium: 23.22,
        sodium: 342.25,
        zinc: 0.79
      },
      restrictionWarnings: [14, 13, 12, 10, 9, 6, 5],
      restrictionViolations: [11, 2, 1],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '116': {
      id: 116,
      name: 'all-purpose flour',
      pluralName: 'all-purpose flour',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.528344105,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 364.0,
        ash: 0.47,
        water: 11.92,
        carbs: 76.31,
        fiber: 3.04,
        starch: 73.0,
        sugars: 0.27,
        maltose: 0.09,
        sucrose: 0.22,
        fat: 0.98,
        monounsaturated: 0.087,
        polyunsaturated: 0.413,
        omega_3: 0.022,
        omega_6: 0.391,
        saturated: 0.155,
        protein: 10.33,
        alanine: 0.332,
        arginine: 0.417,
        aspartic_acid: 0.435,
        cystine: 0.219,
        glutamic_acid: 3.479,
        glycine: 0.371,
        histidine: 0.23,
        isoleucine: 0.357,
        leucine: 0.71,
        lysine: 0.228,
        methionine: 0.183,
        phenylalanine: 0.52,
        proline: 1.198,
        serine: 0.516,
        threonine: 0.281,
        tryptophan: 0.127,
        tyrosine: 0.312,
        valine: 0.415,
        b1_thiamine: 0.785,
        b2_riboflavin: 0.494,
        b3_niacin: 5.904,
        b5_pantothenic_acid: 0.438,
        b6_pyridoxine: 0.044,
        choline: 10.4,
        folate: 183.0,
        vitamin_a: 2.0,
        vitamin_e: 0.23,
        vitamin_k: 0.3,
        calcium: 15.0,
        copper: 0.144,
        iron: 4.64,
        magnesium: 22.0,
        manganese: 0.682,
        phosphorus: 108.0,
        potassium: 107.0,
        selenium: 33.9,
        sodium: 2.0,
        zinc: 0.7
      },
      restrictionWarnings: [],
      restrictionViolations: [1],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '253': {
      id: 253,
      name: 'almond meal/flour',
      pluralName: 'almond meal/flour',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.40254237,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 590.0,
        ash: 2.91,
        water: 4.51,
        carbs: 18.67,
        fiber: 9.9,
        starch: 1.0,
        sugars: 4.63,
        glucose: 0.03,
        maltose: 0.14,
        sucrose: 4.46,
        fat: 52.52,
        monounsaturated: 33.415,
        polyunsaturated: 12.368,
        omega_3: 0.004,
        omega_6: 12.365,
        saturated: 3.953,
        transfats: 0.019,
        protein: 21.4,
        alanine: 1.007,
        arginine: 2.483,
        aspartic_acid: 2.751,
        cystine: 0.284,
        glutamic_acid: 5.206,
        glycine: 1.478,
        histidine: 0.596,
        isoleucine: 0.696,
        leucine: 1.479,
        lysine: 0.605,
        methionine: 0.19,
        phenylalanine: 1.156,
        proline: 0.975,
        serine: 1.012,
        threonine: 0.682,
        tryptophan: 0.193,
        tyrosine: 0.533,
        valine: 0.805,
        b1_thiamine: 0.191,
        b2_riboflavin: 0.711,
        b3_niacin: 3.5,
        b5_pantothenic_acid: 0.314,
        b6_pyridoxine: 0.115,
        choline: 52.1,
        folate: 49.0,
        vitamin_a: 7.0,
        vitamin_e: 23.75,
        calcium: 236.0,
        copper: 1.027,
        iron: 3.28,
        magnesium: 268.0,
        manganese: 1.835,
        phosphorus: 481.0,
        potassium: 659.0,
        selenium: 3.2,
        sodium: 19.0,
        zinc: 2.97
      },
      restrictionWarnings: [],
      restrictionViolations: [6],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '359': {
      id: 359,
      name: 'water',
      pluralName: 'water',
      description: null,
      sectionId: 17,
      subsectionId: null,
      density: 1.0,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 60,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        water: 99.9,
        calcium: 3.0,
        copper: 0.01,
        magnesium: 1.0,
        sodium: 4.0,
        zinc: 0.01
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '397': {
      id: 397,
      name: 'frozen mixed berries',
      pluralName: 'frozen mixed berries',
      description: null,
      sectionId: 13,
      subsectionId: null,
      density: 0.83,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 35.0,
        ash: 0.37,
        water: 89.97,
        carbs: 9.13,
        fiber: 2.1,
        sugars: 4.56,
        fructose: 2.17,
        glucose: 2.02,
        sucrose: 0.37,
        fat: 0.11,
        monounsaturated: 0.015,
        polyunsaturated: 0.054,
        omega_3: 0.023,
        omega_6: 0.031,
        saturated: 0.006,
        protein: 0.43,
        alanine: 0.021,
        arginine: 0.018,
        aspartic_acid: 0.096,
        cystine: 0.004,
        glutamic_acid: 0.063,
        glycine: 0.017,
        histidine: 0.008,
        isoleucine: 0.01,
        leucine: 0.022,
        lysine: 0.017,
        methionine: 0.001,
        phenylalanine: 0.012,
        proline: 0.013,
        serine: 0.016,
        threonine: 0.013,
        tryptophan: 0.005,
        tyrosine: 0.014,
        valine: 0.012,
        b1_thiamine: 0.022,
        b2_riboflavin: 0.037,
        b3_niacin: 0.462,
        b5_pantothenic_acid: 0.108,
        b6_pyridoxine: 0.028,
        choline: 5.7,
        folate: 17.0,
        vitamin_a: 45.0,
        vitamin_c: 41.2,
        vitamin_e: 0.29,
        vitamin_k: 2.2,
        calcium: 16.0,
        copper: 0.049,
        iron: 0.75,
        magnesium: 11.0,
        manganese: 0.29,
        phosphorus: 13.0,
        potassium: 148.0,
        selenium: 0.7,
        sodium: 2.0,
        zinc: 0.13
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '398': {
      id: 398,
      name: 'granola',
      pluralName: 'granola',
      description: null,
      sectionId: 22,
      subsectionId: null,
      density: 0.46,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 489.0,
        ash: 2.31,
        water: 5.84,
        carbs: 53.88,
        fiber: 8.9,
        sugars: 19.8,
        fat: 24.31,
        monounsaturated: 10.951,
        polyunsaturated: 7.809,
        omega_3: 0.611,
        omega_6: 7.194,
        saturated: 3.957,
        transfats: 0.023,
        protein: 13.67,
        alanine: 0.694,
        arginine: 1.279,
        aspartic_acid: 1.396,
        cystine: 0.302,
        glutamic_acid: 3.111,
        glycine: 0.812,
        histidine: 0.349,
        isoleucine: 0.546,
        leucine: 0.977,
        lysine: 0.631,
        methionine: 0.225,
        phenylalanine: 0.668,
        proline: 0.658,
        serine: 0.668,
        threonine: 0.479,
        tryptophan: 0.186,
        tyrosine: 0.379,
        valine: 0.698,
        b1_thiamine: 0.548,
        b2_riboflavin: 0.354,
        b3_niacin: 2.739,
        b5_pantothenic_acid: 0.752,
        b6_pyridoxine: 0.37,
        choline: 49.2,
        folate: 84.0,
        vitamin_a: 19.0,
        vitamin_c: 1.2,
        vitamin_e: 11.1,
        vitamin_k: 5.3,
        calcium: 76.0,
        copper: 0.647,
        iron: 3.95,
        magnesium: 168.0,
        manganese: 3.996,
        phosphorus: 431.0,
        potassium: 539.0,
        selenium: 25.4,
        sodium: 26.0,
        zinc: 4.17
      },
      restrictionWarnings: [14, 12, 11, 9, 2, 5, 1],
      restrictionViolations: [6],
      isBreakfastAllowed: true,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '348': {
      id: 348,
      name: 'baking soda',
      pluralName: 'baking soda',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.97,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: { ash: 36.9, water: 0.2, selenium: 0.2, sodium: 27360.0 },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '402': {
      id: 402,
      name: 'Thai curry paste, green',
      pluralName: 'Thai curry paste, green',
      description: null,
      sectionId: 5,
      subsectionId: null,
      density: 1.22,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 140.0,
        carbs: 20.93,
        fiber: 2.3,
        sugars: 2.33,
        fat: 5.81,
        saturated: 1.16,
        protein: 2.33,
        vitamin_c: 27.9,
        calcium: 47.0,
        iron: 2.51,
        sodium: 4000.0
      },
      restrictionWarnings: [14, 13, 12, 9, 2, 5, 6, 1, 3, 4],
      restrictionViolations: [10],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '400': {
      id: 400,
      name: 'hoisin sauce',
      pluralName: 'hoisin sauce',
      description: null,
      sectionId: 5,
      subsectionId: null,
      density: 1.22,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 220.0,
        water: 44.23,
        carbs: 44.08,
        fiber: 2.8,
        sugars: 27.26,
        fat: 3.39,
        monounsaturated: 0.963,
        polyunsaturated: 1.698,
        omega_3: 0.175,
        omega_6: 1.524,
        saturated: 0.568,
        cholesterol: 3.0,
        protein: 3.31,
        b1_thiamine: 0.004,
        b2_riboflavin: 0.217,
        b3_niacin: 1.17,
        b6_pyridoxine: 0.062,
        choline: 8.0,
        folate: 23.0,
        vitamin_c: 0.4,
        vitamin_e: 0.28,
        vitamin_k: 0.5,
        calcium: 32.0,
        copper: 0.128,
        iron: 1.01,
        magnesium: 24.0,
        phosphorus: 38.0,
        potassium: 119.0,
        selenium: 1.8,
        sodium: 1615.0,
        zinc: 0.32
      },
      restrictionWarnings: [14, 13, 11, 2, 5, 6, 3, 4],
      restrictionViolations: [12, 9, 1],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '396': {
      id: 396,
      name: 'hard-cooked egg, peeled',
      pluralName: 'hard-cooked eggs, peeled',
      description: null,
      sectionId: 2,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 42.5,
          equivalents: '1 egg equals 42.5 grams'
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 42.5,
          equivalents: '1 egg equals 1.5 oz'
        }
      },
      nutrition: {
        energy: 155.0,
        ash: 1.08,
        water: 74.62,
        carbs: 1.12,
        sugars: 1.12,
        fat: 10.61,
        monounsaturated: 4.077,
        polyunsaturated: 1.414,
        omega_3: 0.035,
        omega_6: 1.188,
        saturated: 3.267,
        cholesterol: 373.0,
        protein: 12.58,
        alanine: 0.7,
        arginine: 0.755,
        aspartic_acid: 1.264,
        cystine: 0.292,
        glutamic_acid: 1.644,
        glycine: 0.423,
        histidine: 0.298,
        isoleucine: 0.686,
        leucine: 1.075,
        lysine: 0.904,
        methionine: 0.392,
        phenylalanine: 0.668,
        proline: 0.501,
        serine: 0.936,
        threonine: 0.604,
        tryptophan: 0.153,
        tyrosine: 0.513,
        valine: 0.767,
        b1_thiamine: 0.066,
        b2_riboflavin: 0.513,
        b3_niacin: 0.064,
        b5_pantothenic_acid: 1.398,
        b6_pyridoxine: 0.121,
        b12_cobalamin: 1.11,
        choline: 293.8,
        folate: 44.0,
        vitamin_a: 520.0,
        vitamin_d: 2.2,
        vitamin_e: 1.03,
        vitamin_k: 0.3,
        calcium: 50.0,
        copper: 0.013,
        iron: 1.19,
        magnesium: 10.0,
        manganese: 0.026,
        phosphorus: 172.0,
        potassium: 126.0,
        selenium: 30.8,
        sodium: 124.0,
        zinc: 1.05
      },
      restrictionWarnings: [14],
      restrictionViolations: [11],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '393': {
      id: 393,
      name: 'salsa',
      pluralName: 'salsa',
      description: null,
      sectionId: 19,
      subsectionId: null,
      density: 1.89,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 29.0,
        ash: 2.3,
        water: 89.3,
        carbs: 6.74,
        fiber: 1.8,
        fructose: 1.98,
        glucose: 1.63,
        sucrose: 0.2,
        fat: 0.19,
        protein: 1.44,
        b1_thiamine: 0.035,
        b3_niacin: 1.12,
        b5_pantothenic_acid: 0.202,
        b6_pyridoxine: 0.176,
        vitamin_e: 1.22,
        vitamin_k: 4.2,
        calcium: 28.0,
        copper: 0.065,
        iron: 0.42,
        magnesium: 15.2,
        manganese: 0.11,
        phosphorus: 32.0,
        potassium: 258.0,
        sodium: 656.0,
        zinc: 0.2
      },
      restrictionWarnings: [14, 13, 12, 11, 9, 2, 5, 6, 1, 3, 4],
      restrictionViolations: [10],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '349': {
      id: 349,
      name: 'nutmeg, ground',
      pluralName: 'nutmeg, ground',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.57,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 525.0,
        ash: 2.34,
        water: 6.23,
        carbs: 49.29,
        fiber: 20.8,
        sugars: 2.99,
        fat: 36.31,
        monounsaturated: 3.22,
        polyunsaturated: 0.35,
        omega_6: 0.35,
        saturated: 25.94,
        protein: 5.84,
        b1_thiamine: 0.346,
        b2_riboflavin: 0.057,
        b3_niacin: 1.299,
        b6_pyridoxine: 0.16,
        choline: 8.8,
        folate: 76.0,
        vitamin_a: 102.0,
        vitamin_c: 3.0,
        calcium: 184.0,
        copper: 1.027,
        iron: 3.04,
        magnesium: 183.0,
        manganese: 2.9,
        phosphorus: 213.0,
        potassium: 350.0,
        selenium: 1.6,
        sodium: 16.0,
        zinc: 2.15
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '75': {
      id: 75,
      name: 'chili powder',
      pluralName: 'chili powder',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.541024364,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 282.0,
        ash: 11.81,
        water: 10.75,
        carbs: 49.7,
        fiber: 34.8,
        starch: 7.71,
        sugars: 7.19,
        fructose: 4.29,
        glucose: 2.14,
        sucrose: 0.76,
        fat: 14.28,
        monounsaturated: 3.211,
        polyunsaturated: 8.006,
        omega_3: 0.519,
        omega_6: 7.487,
        saturated: 2.462,
        protein: 13.46,
        alanine: 0.45,
        arginine: 0.49,
        aspartic_acid: 1.69,
        cystine: 0.18,
        glutamic_acid: 1.59,
        glycine: 0.6,
        histidine: 0.18,
        isoleucine: 0.39,
        leucine: 0.63,
        lysine: 0.36,
        methionine: 0.13,
        phenylalanine: 0.37,
        proline: 1.25,
        serine: 0.23,
        threonine: 0.27,
        tryptophan: 0.07,
        tyrosine: 0.19,
        valine: 0.54,
        b1_thiamine: 0.25,
        b2_riboflavin: 0.94,
        b3_niacin: 11.6,
        b5_pantothenic_acid: 0.888,
        b6_pyridoxine: 2.094,
        choline: 66.5,
        folate: 28.0,
        vitamin_a: 29.0,
        vitamin_c: 0.7,
        vitamin_e: 38.14,
        vitamin_k: 105.7,
        calcium: 330.0,
        copper: 1.0,
        iron: 17.3,
        magnesium: 149.0,
        manganese: 1.7,
        phosphorus: 300.0,
        potassium: 1.0,
        selenium: 20.4,
        sodium: 1.0,
        zinc: 4.3
      },
      restrictionWarnings: [13, 12],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '256': {
      id: 256,
      name: 'pistachios, unsalted',
      pluralName: 'pistachios, unsalted',
      description: '',
      sectionId: 12,
      subsectionId: 0,
      density: 0.519890599,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 567.0,
        ash: 3.0,
        water: 1.85,
        carbs: 29.38,
        fiber: 9.9,
        starch: 1.38,
        sugars: 7.74,
        fructose: 0.22,
        glucose: 0.26,
        maltose: 0.13,
        sucrose: 7.12,
        fat: 44.82,
        monounsaturated: 23.676,
        polyunsaturated: 13.448,
        omega_3: 0.243,
        omega_6: 13.206,
        saturated: 5.456,
        protein: 20.95,
        alanine: 0.955,
        arginine: 2.203,
        aspartic_acid: 1.907,
        cystine: 0.36,
        glutamic_acid: 4.062,
        glycine: 0.992,
        histidine: 0.513,
        isoleucine: 0.932,
        leucine: 1.599,
        lysine: 1.195,
        methionine: 0.343,
        phenylalanine: 1.107,
        proline: 0.844,
        serine: 1.263,
        threonine: 0.703,
        tryptophan: 0.284,
        tyrosine: 0.507,
        valine: 1.262,
        b1_thiamine: 0.695,
        b2_riboflavin: 0.234,
        b3_niacin: 1.373,
        b5_pantothenic_acid: 0.513,
        b6_pyridoxine: 1.122,
        choline: 71.4,
        folate: 51.0,
        vitamin_a: 259.0,
        vitamin_c: 3.0,
        vitamin_e: 2.42,
        vitamin_k: 13.2,
        calcium: 107.0,
        copper: 1.293,
        iron: 4.03,
        magnesium: 109.0,
        manganese: 1.243,
        phosphorus: 469.0,
        potassium: 1.0,
        selenium: 10.0,
        sodium: 6.0,
        zinc: 2.34
      },
      restrictionWarnings: [],
      restrictionViolations: [6],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '103': {
      id: 103,
      name: 'honey',
      pluralName: 'honey',
      description: '',
      sectionId: 11,
      subsectionId: 0,
      density: 1.432869213,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 304.0,
        ash: 0.2,
        water: 17.1,
        carbs: 82.4,
        fiber: 0.2,
        starch: 82.12,
        sugars: 82.12,
        fructose: 40.94,
        galactose: 3.1,
        glucose: 35.75,
        lactose: 0.01,
        maltose: 1.44,
        sucrose: 0.89,
        protein: 0.3,
        alanine: 0.006,
        arginine: 0.005,
        aspartic_acid: 0.027,
        cystine: 0.003,
        glutamic_acid: 0.018,
        glycine: 0.007,
        histidine: 0.001,
        isoleucine: 0.008,
        leucine: 0.01,
        lysine: 0.008,
        methionine: 0.001,
        phenylalanine: 0.011,
        proline: 0.09,
        serine: 0.006,
        threonine: 0.004,
        tryptophan: 0.004,
        tyrosine: 0.008,
        valine: 0.009,
        b2_riboflavin: 0.038,
        b3_niacin: 0.121,
        b5_pantothenic_acid: 0.068,
        b6_pyridoxine: 0.024,
        choline: 2.2,
        folate: 2.0,
        vitamin_c: 0.5,
        calcium: 6.0,
        copper: 0.036,
        iron: 0.42,
        magnesium: 2.0,
        manganese: 0.08,
        phosphorus: 4.0,
        potassium: 52.0,
        selenium: 0.8,
        sodium: 4.0,
        zinc: 0.22
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '102': {
      id: 102,
      name: 'natural almond butter',
      pluralName: 'natural almond butter',
      description: '',
      sectionId: 11,
      subsectionId: 0,
      density: 1.05668821,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 614.0,
        ash: 3.09,
        water: 1.64,
        carbs: 18.82,
        fiber: 10.3,
        starch: 0.08,
        sugars: 4.43,
        glucose: 0.02,
        maltose: 0.07,
        sucrose: 4.34,
        fat: 55.5,
        monounsaturated: 32.445,
        polyunsaturated: 13.613,
        omega_3: 0.007,
        omega_6: 13.605,
        saturated: 4.152,
        protein: 20.96,
        alanine: 0.99,
        arginine: 2.382,
        aspartic_acid: 2.397,
        cystine: 0.242,
        glutamic_acid: 5.912,
        glycine: 1.472,
        histidine: 0.55,
        isoleucine: 0.813,
        leucine: 1.483,
        lysine: 0.612,
        methionine: 0.122,
        phenylalanine: 1.149,
        proline: 0.915,
        serine: 0.926,
        threonine: 0.555,
        tryptophan: 0.159,
        tyrosine: 0.595,
        valine: 0.937,
        b1_thiamine: 0.041,
        b2_riboflavin: 0.939,
        b3_niacin: 3.155,
        b5_pantothenic_acid: 0.318,
        b6_pyridoxine: 0.103,
        choline: 52.1,
        folate: 53.0,
        vitamin_a: 1.0,
        vitamin_e: 24.21,
        calcium: 347.0,
        copper: 0.934,
        iron: 3.49,
        magnesium: 279.0,
        manganese: 2.131,
        phosphorus: 508.0,
        potassium: 748.0,
        selenium: 2.4,
        sodium: 7.0,
        zinc: 3.29
      },
      restrictionWarnings: [9],
      restrictionViolations: [6],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '82': {
      id: 82,
      name: 'oregano, dried',
      pluralName: 'oregano, dried',
      description: '',
      sectionId: 8,
      subsectionId: null,
      density: 0.202884136,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 265.0,
        ash: 7.87,
        water: 9.93,
        carbs: 68.92,
        fiber: 42.5,
        starch: 22.33,
        sugars: 4.09,
        fructose: 1.13,
        galactose: 0.15,
        glucose: 1.9,
        sucrose: 0.91,
        fat: 4.28,
        monounsaturated: 0.716,
        polyunsaturated: 1.369,
        omega_3: 0.621,
        omega_6: 0.748,
        saturated: 1.551,
        protein: 9.0,
        alanine: 0.5,
        arginine: 0.449,
        aspartic_acid: 1.009,
        cystine: 0.11,
        glutamic_acid: 0.975,
        glycine: 0.517,
        histidine: 0.144,
        isoleucine: 0.441,
        leucine: 0.78,
        lysine: 0.5,
        methionine: 0.127,
        phenylalanine: 0.449,
        proline: 1.712,
        serine: 0.314,
        threonine: 0.322,
        tryptophan: 0.203,
        tyrosine: 0.297,
        valine: 0.585,
        b1_thiamine: 0.177,
        b2_riboflavin: 0.528,
        b3_niacin: 4.64,
        b5_pantothenic_acid: 0.921,
        b6_pyridoxine: 1.044,
        choline: 32.3,
        folate: 237.0,
        vitamin_a: 1.0,
        vitamin_c: 2.3,
        vitamin_e: 18.26,
        vitamin_k: 621.7,
        calcium: 1.0,
        copper: 0.633,
        iron: 36.8,
        magnesium: 270.0,
        manganese: 4.99,
        phosphorus: 148.0,
        potassium: 1.0,
        selenium: 4.5,
        sodium: 25.0,
        zinc: 2.69
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '129': {
      id: 129,
      name: 'virgin coconut oil',
      pluralName: 'virgin coconut oil',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 0.921432119,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 862.0,
        starch: 8.0,
        fat: 100.0,
        monounsaturated: 5.8,
        polyunsaturated: 1.8,
        omega_6: 1.8,
        saturated: 86.5,
        choline: 0.3,
        vitamin_e: 0.09,
        vitamin_k: 0.5,
        iron: 0.04,
        magnesium: 0.2,
        zinc: 0.13
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '118': {
      id: 118,
      name: 'whole milk',
      pluralName: 'whole milk',
      description: '',
      sectionId: 2,
      subsectionId: 0,
      density: 1.031327693,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 60,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 61.0,
        ash: 0.67,
        water: 88.13,
        carbs: 4.8,
        sugars: 5.05,
        lactose: 5.05,
        fat: 3.25,
        monounsaturated: 0.812,
        polyunsaturated: 0.195,
        omega_3: 0.075,
        omega_6: 0.12,
        saturated: 1.865,
        transfats: 0.12,
        cholesterol: 10.0,
        protein: 3.15,
        alanine: 0.107,
        arginine: 0.09,
        aspartic_acid: 0.27,
        cystine: 0.019,
        glutamic_acid: 0.708,
        glycine: 0.062,
        histidine: 0.095,
        isoleucine: 0.163,
        leucine: 0.299,
        lysine: 0.264,
        methionine: 0.083,
        phenylalanine: 0.163,
        proline: 0.311,
        serine: 0.19,
        threonine: 0.134,
        tryptophan: 0.04,
        tyrosine: 0.159,
        valine: 0.206,
        b1_thiamine: 0.046,
        b2_riboflavin: 0.169,
        b3_niacin: 0.089,
        b5_pantothenic_acid: 0.373,
        b6_pyridoxine: 0.036,
        b12_cobalamin: 0.45,
        choline: 14.3,
        folate: 5.0,
        vitamin_a: 162.0,
        vitamin_d: 51.0,
        vitamin_e: 0.07,
        vitamin_k: 0.3,
        calcium: 113.0,
        copper: 0.025,
        iron: 0.03,
        magnesium: 10.0,
        manganese: 0.004,
        phosphorus: 84.0,
        potassium: 132.0,
        selenium: 3.7,
        sodium: 43.0,
        zinc: 0.37
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '316': {
      id: 316,
      name: 'heavy whipping cream',
      pluralName: 'heavy whipping cream',
      description: '',
      sectionId: 2,
      subsectionId: null,
      density: 1.005967173,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 236.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 60,
          packageAmount: 8.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 345.0,
        ash: 0.45,
        water: 57.71,
        carbs: 2.79,
        sugars: 2.79,
        lactose: 2.79,
        fat: 37.0,
        monounsaturated: 10.686,
        polyunsaturated: 1.374,
        omega_3: 0.538,
        omega_6: 0.836,
        saturated: 23.032,
        transfats: 1.32,
        cholesterol: 137.0,
        protein: 2.05,
        alanine: 0.071,
        arginine: 0.074,
        aspartic_acid: 0.156,
        cystine: 0.019,
        glutamic_acid: 0.429,
        glycine: 0.043,
        histidine: 0.056,
        isoleucine: 0.124,
        leucine: 0.201,
        lysine: 0.163,
        methionine: 0.051,
        phenylalanine: 0.099,
        proline: 0.199,
        serine: 0.111,
        threonine: 0.093,
        tryptophan: 0.029,
        tyrosine: 0.099,
        valine: 0.137,
        b1_thiamine: 0.022,
        b2_riboflavin: 0.11,
        b3_niacin: 0.039,
        b5_pantothenic_acid: 0.255,
        b6_pyridoxine: 0.026,
        b12_cobalamin: 0.18,
        choline: 16.8,
        folate: 4.0,
        vitamin_a: 1470.0,
        vitamin_c: 0.6,
        vitamin_d: 27.0,
        vitamin_e: 1.06,
        vitamin_k: 3.2,
        calcium: 65.0,
        copper: 0.006,
        iron: 0.03,
        magnesium: 7.0,
        manganese: 0.001,
        phosphorus: 62.0,
        potassium: 75.0,
        selenium: 0.5,
        sodium: 38.0,
        zinc: 0.23
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '76': {
      id: 76,
      name: 'coriander, ground',
      pluralName: 'coriander, ground',
      description: '',
      sectionId: 8,
      subsectionId: null,
      density: 0.338140227,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 298.0,
        ash: 6.02,
        water: 8.86,
        carbs: 54.99,
        fiber: 41.9,
        starch: 1.96,
        sugars: 10.98,
        fructose: 4.36,
        glucose: 6.62,
        fat: 17.77,
        monounsaturated: 13.71,
        polyunsaturated: 2.01,
        omega_3: 0.26,
        omega_6: 1.75,
        saturated: 0.99,
        protein: 12.37,
        b1_thiamine: 0.239,
        b2_riboflavin: 0.29,
        b3_niacin: 2.13,
        b5_pantothenic_acid: 6.67,
        b6_pyridoxine: 1.74,
        choline: 65.8,
        vitamin_c: 21.0,
        vitamin_e: 1.53,
        calcium: 709.0,
        copper: 0.975,
        iron: 16.32,
        magnesium: 330.0,
        manganese: 1.9,
        phosphorus: 409.0,
        potassium: 1.0,
        selenium: 26.2,
        sodium: 35.0,
        zinc: 4.7
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '186': {
      id: 186,
      name: 'cumin, ground',
      pluralName: 'cumin, ground',
      description: '',
      sectionId: 8,
      subsectionId: null,
      density: 0.34745763,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 441.18,
        ash: 8.97,
        water: 9.48,
        carbs: 52.04,
        fiber: 12.35,
        sugars: 2.65,
        fat: 26.2,
        monounsaturated: 16.52,
        polyunsaturated: 3.86,
        omega_3: 0.21,
        omega_6: 3.65,
        saturated: 1.81,
        protein: 20.95,
        alanine: 0.17,
        arginine: 0.51,
        aspartic_acid: 0.27,
        cystine: 0.05,
        glutamic_acid: 0.52,
        glycine: 0.35,
        histidine: 0.17,
        isoleucine: 0.12,
        leucine: 0.15,
        lysine: 0.23,
        methionine: 0.46,
        phenylalanine: 0.09,
        proline: 0.12,
        serine: 0.18,
        threonine: 0.1,
        tryptophan: 0.04,
        tyrosine: 0.06,
        valine: 0.15,
        b1_thiamine: 0.74,
        b2_riboflavin: 0.39,
        b3_niacin: 5.39,
        b6_pyridoxine: 0.51,
        choline: 29.06,
        folate: 11.77,
        vitamin_a: 1.0,
        vitamin_c: 9.06,
        vitamin_e: 3.92,
        vitamin_k: 6.35,
        calcium: 1.0,
        copper: 1.02,
        iron: 78.07,
        magnesium: 430.59,
        manganese: 3.92,
        phosphorus: 587.06,
        potassium: 2.0,
        selenium: 6.12,
        sodium: 197.65,
        zinc: 5.65
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '70': {
      id: 70,
      name: 'basil, dried',
      pluralName: 'basil, dried',
      description: '',
      sectionId: 8,
      subsectionId: null,
      density: 0.142018895,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 233.0,
        ash: 14.85,
        water: 10.35,
        carbs: 47.75,
        fiber: 37.7,
        starch: 8.34,
        sugars: 1.71,
        fructose: 0.75,
        galactose: 0.19,
        glucose: 0.75,
        sucrose: 0.02,
        fat: 4.07,
        monounsaturated: 1.238,
        polyunsaturated: 0.498,
        omega_3: 0.296,
        omega_6: 0.203,
        saturated: 2.157,
        protein: 22.98,
        alanine: 1.18,
        arginine: 1.23,
        aspartic_acid: 3.02,
        cystine: 0.19,
        glutamic_acid: 2.57,
        glycine: 1.25,
        histidine: 0.45,
        isoleucine: 1.06,
        leucine: 1.85,
        lysine: 1.15,
        methionine: 0.32,
        phenylalanine: 1.25,
        proline: 2.7,
        serine: 0.53,
        threonine: 0.76,
        tryptophan: 0.26,
        tyrosine: 0.75,
        valine: 1.45,
        b1_thiamine: 0.08,
        b2_riboflavin: 1.2,
        b3_niacin: 4.9,
        b5_pantothenic_acid: 0.838,
        b6_pyridoxine: 1.34,
        choline: 54.9,
        folate: 310.0,
        vitamin_a: 744.0,
        vitamin_c: 0.8,
        vitamin_e: 10.7,
        vitamin_k: 1.0,
        calcium: 2.0,
        copper: 2.1,
        iron: 89.8,
        magnesium: 711.0,
        manganese: 9.8,
        phosphorus: 274.0,
        potassium: 2.0,
        selenium: 3.0,
        sodium: 76.0,
        zinc: 7.1
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '83': {
      id: 83,
      name: 'paprika',
      pluralName: 'paprika',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.459870709,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 282.0,
        ash: 7.74,
        water: 11.24,
        carbs: 53.99,
        fiber: 34.9,
        starch: 8.75,
        sugars: 10.34,
        fructose: 6.71,
        galactose: 0.19,
        glucose: 2.63,
        sucrose: 0.81,
        fat: 12.89,
        monounsaturated: 1.695,
        polyunsaturated: 7.766,
        omega_3: 0.453,
        omega_6: 7.314,
        saturated: 2.14,
        protein: 14.14,
        alanine: 0.64,
        arginine: 0.89,
        aspartic_acid: 2.85,
        cystine: 0.23,
        glutamic_acid: 2.26,
        glycine: 0.78,
        histidine: 0.25,
        isoleucine: 0.57,
        leucine: 0.92,
        lysine: 0.69,
        methionine: 0.2,
        phenylalanine: 0.61,
        proline: 2.31,
        serine: 0.61,
        threonine: 0.49,
        tryptophan: 0.07,
        tyrosine: 0.38,
        valine: 0.75,
        b1_thiamine: 0.33,
        b2_riboflavin: 1.23,
        b3_niacin: 10.06,
        b5_pantothenic_acid: 2.51,
        b6_pyridoxine: 2.141,
        choline: 51.5,
        folate: 49.0,
        vitamin_a: 49.0,
        vitamin_c: 0.9,
        vitamin_e: 29.1,
        vitamin_k: 80.3,
        calcium: 229.0,
        copper: 0.713,
        iron: 21.14,
        magnesium: 178.0,
        manganese: 1.59,
        phosphorus: 314.0,
        potassium: 2.0,
        selenium: 6.3,
        sodium: 68.0,
        zinc: 4.33
      },
      restrictionWarnings: [14],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '73': {
      id: 73,
      name: 'curry powder',
      pluralName: 'curry powder',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.426056686,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 325.0,
        ash: 5.57,
        water: 9.52,
        carbs: 58.15,
        fiber: 33.2,
        starch: 22.19,
        sugars: 2.76,
        fructose: 0.79,
        galactose: 0.21,
        glucose: 1.14,
        sucrose: 0.62,
        fat: 13.81,
        monounsaturated: 5.5,
        polyunsaturated: 2.56,
        omega_3: 0.43,
        omega_6: 2.12,
        saturated: 2.24,
        protein: 12.66,
        alanine: 0.16,
        arginine: 0.19,
        aspartic_acid: 0.29,
        cystine: 0.04,
        glutamic_acid: 0.44,
        glycine: 0.18,
        histidine: 0.08,
        isoleucine: 0.11,
        leucine: 0.2,
        lysine: 0.13,
        methionine: 0.08,
        phenylalanine: 0.12,
        proline: 0.23,
        serine: 0.11,
        threonine: 0.1,
        tryptophan: 0.04,
        tyrosine: 0.09,
        valine: 0.15,
        b1_thiamine: 0.25,
        b2_riboflavin: 0.28,
        b3_niacin: 3.47,
        b6_pyridoxine: 1.15,
        choline: 64.2,
        folate: 154.0,
        vitamin_a: 986.0,
        vitamin_c: 11.4,
        vitamin_e: 21.99,
        vitamin_k: 99.8,
        calcium: 478.0,
        copper: 0.82,
        iron: 29.59,
        magnesium: 254.0,
        manganese: 4.29,
        phosphorus: 349.0,
        potassium: 1.0,
        selenium: 17.1,
        sodium: 52.0,
        zinc: 4.05
      },
      restrictionWarnings: [13, 12],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '321': {
      id: 321,
      name: 'small corn tortilla',
      pluralName: 'small corn tortillas',
      description: '',
      sectionId: 6,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 24.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 24.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 218.0,
        ash: 0.93,
        water: 45.89,
        carbs: 44.64,
        fiber: 6.3,
        starch: 38.26,
        sugars: 0.88,
        maltose: 0.33,
        sucrose: 0.55,
        fat: 2.85,
        monounsaturated: 0.692,
        polyunsaturated: 1.419,
        omega_3: 0.034,
        omega_6: 1.385,
        saturated: 0.453,
        protein: 5.7,
        alanine: 0.434,
        arginine: 0.289,
        aspartic_acid: 0.403,
        cystine: 0.105,
        glutamic_acid: 1.087,
        glycine: 0.238,
        histidine: 0.177,
        isoleucine: 0.208,
        leucine: 0.711,
        lysine: 0.163,
        methionine: 0.121,
        phenylalanine: 0.285,
        proline: 0.506,
        serine: 0.275,
        threonine: 0.218,
        tryptophan: 0.042,
        tyrosine: 0.236,
        valine: 0.294,
        b1_thiamine: 0.094,
        b2_riboflavin: 0.065,
        b3_niacin: 1.498,
        b5_pantothenic_acid: 0.109,
        b6_pyridoxine: 0.219,
        choline: 13.3,
        folate: 5.0,
        vitamin_a: 2.0,
        vitamin_e: 0.28,
        calcium: 81.0,
        copper: 0.154,
        iron: 1.23,
        magnesium: 72.0,
        manganese: 0.326,
        phosphorus: 314.0,
        potassium: 186.0,
        selenium: 6.1,
        sodium: 45.0,
        zinc: 1.31
      },
      restrictionWarnings: [10, 9, 1],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '162': {
      id: 162,
      name: 'sun-dried tomatoes, oil-packed',
      pluralName: 'sun-dried tomatoes, oil-packed',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: 0.464942812,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 3.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 3.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 213.0,
        ash: 3.7,
        water: 53.83,
        carbs: 23.33,
        fiber: 5.8,
        sugars: 13.92,
        fructose: 9.85,
        galactose: 0.02,
        glucose: 4.05,
        fat: 14.08,
        monounsaturated: 8.663,
        polyunsaturated: 2.06,
        omega_3: 0.078,
        omega_6: 1.982,
        saturated: 1.893,
        transfats: 0.01,
        protein: 5.06,
        alanine: 0.144,
        arginine: 0.123,
        aspartic_acid: 0.702,
        cystine: 0.066,
        glutamic_acid: 1.865,
        glycine: 0.125,
        histidine: 0.077,
        isoleucine: 0.121,
        leucine: 0.185,
        lysine: 0.186,
        methionine: 0.044,
        phenylalanine: 0.131,
        proline: 0.096,
        serine: 0.134,
        threonine: 0.128,
        tryptophan: 0.037,
        tyrosine: 0.087,
        valine: 0.13,
        b1_thiamine: 0.193,
        b2_riboflavin: 0.383,
        b3_niacin: 3.63,
        b5_pantothenic_acid: 0.479,
        b6_pyridoxine: 0.319,
        choline: 43.6,
        folate: 23.0,
        vitamin_a: 1.0,
        vitamin_c: 101.8,
        vitamin_e: 1.85,
        vitamin_k: 25.72,
        calcium: 47.0,
        copper: 0.473,
        iron: 2.68,
        magnesium: 81.0,
        manganese: 0.466,
        phosphorus: 139.0,
        potassium: 1.0,
        selenium: 3.0,
        sodium: 266.0,
        zinc: 0.78
      },
      restrictionWarnings: [13],
      restrictionViolations: [14, 10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '91': {
      id: 91,
      name: 'cannellini (white kidney) beans',
      pluralName: 'cannellini (white kidney) beans',
      description: '',
      sectionId: 9,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 53,
          packageAmount: 1.0,
          amountInBase: 266.0,
          equivalents: ''
        },
        us: {
          unitId: 30,
          packageAmount: 1.0,
          amountInBase: 266.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 124.0,
        ash: 1.36,
        water: 68.11,
        carbs: 21.49,
        fiber: 5.5,
        starch: 12.19,
        sugars: 3.8,
        fructose: 0.1,
        glucose: 0.23,
        sucrose: 3.47,
        fat: 1.05,
        monounsaturated: 0.03,
        polyunsaturated: 0.19,
        omega_3: 0.07,
        omega_6: 0.12,
        saturated: 0.18,
        protein: 7.98,
        alanine: 0.36,
        arginine: 0.44,
        aspartic_acid: 1.0,
        cystine: 0.08,
        glutamic_acid: 1.28,
        glycine: 0.32,
        histidine: 0.22,
        isoleucine: 0.38,
        leucine: 0.68,
        lysine: 0.56,
        methionine: 0.1,
        phenylalanine: 0.47,
        proline: 0.46,
        serine: 0.5,
        threonine: 0.29,
        tryptophan: 0.1,
        tyrosine: 0.19,
        valine: 0.46,
        b1_thiamine: 0.07,
        b2_riboflavin: 0.02,
        b3_niacin: 0.46,
        b5_pantothenic_acid: 0.21,
        b6_pyridoxine: 0.11,
        choline: 28.7,
        folate: 28.0,
        vitamin_c: 0.2,
        vitamin_e: 0.03,
        vitamin_k: 7.9,
        calcium: 57.0,
        copper: 0.27,
        iron: 1.5,
        magnesium: 30.0,
        manganese: 0.38,
        phosphorus: 121.0,
        potassium: 250.0,
        selenium: 1.6,
        sodium: 208.0,
        zinc: 0.75
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '92': {
      id: 92,
      name: 'garbanzo beans (chickpeas)',
      pluralName: 'garbanzo beans (chickpeas)',
      description: '',
      sectionId: 9,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 53,
          packageAmount: 1.0,
          amountInBase: 254.0,
          equivalents: ''
        },
        us: {
          unitId: 30,
          packageAmount: 1.0,
          amountInBase: 254.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 139.0,
        ash: 0.91,
        water: 66.72,
        carbs: 22.53,
        fiber: 6.19,
        starch: 13.79,
        sugars: 0.44,
        sucrose: 0.44,
        fat: 2.77,
        monounsaturated: 0.49,
        polyunsaturated: 0.97,
        omega_3: 0.04,
        omega_6: 0.93,
        saturated: 0.21,
        protein: 7.05,
        alanine: 0.3,
        arginine: 0.67,
        aspartic_acid: 0.84,
        cystine: 0.1,
        glutamic_acid: 1.24,
        glycine: 0.3,
        histidine: 0.2,
        isoleucine: 0.3,
        leucine: 0.51,
        lysine: 0.48,
        methionine: 0.09,
        phenylalanine: 0.38,
        proline: 0.29,
        serine: 0.36,
        threonine: 0.26,
        tryptophan: 0.07,
        tyrosine: 0.18,
        valine: 0.3,
        b1_thiamine: 0.03,
        b2_riboflavin: 0.02,
        b3_niacin: 0.14,
        b5_pantothenic_acid: 0.24,
        b6_pyridoxine: 0.12,
        choline: 35.8,
        folate: 48.0,
        vitamin_a: 23.0,
        vitamin_c: 0.1,
        vitamin_e: 0.37,
        vitamin_k: 4.3,
        calcium: 45.0,
        copper: 0.25,
        iron: 0.98,
        magnesium: 26.0,
        manganese: 0.85,
        phosphorus: 85.0,
        potassium: 109.0,
        selenium: 3.1,
        sodium: 212.0,
        zinc: 0.63
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '97': {
      id: 97,
      name: 'diced tomatoes',
      pluralName: 'diced tomatoes',
      description: '',
      sectionId: 9,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 53,
          packageAmount: 1.0,
          amountInBase: 411.0,
          equivalents: ''
        },
        us: {
          unitId: 29,
          packageAmount: 1.0,
          amountInBase: 411.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 16.0,
        ash: 0.74,
        water: 94.75,
        carbs: 3.47,
        fiber: 1.9,
        sugars: 2.55,
        fructose: 1.31,
        glucose: 1.24,
        fat: 0.25,
        monounsaturated: 0.04,
        polyunsaturated: 0.101,
        omega_3: 0.004,
        omega_6: 0.097,
        saturated: 0.034,
        protein: 0.79,
        alanine: 0.032,
        arginine: 0.023,
        aspartic_acid: 0.153,
        cystine: 0.008,
        glutamic_acid: 0.451,
        glycine: 0.019,
        histidine: 0.015,
        isoleucine: 0.022,
        leucine: 0.027,
        lysine: 0.028,
        methionine: 0.008,
        phenylalanine: 0.027,
        proline: 0.015,
        serine: 0.024,
        threonine: 0.042,
        tryptophan: 0.008,
        tyrosine: 0.018,
        valine: 0.016,
        b1_thiamine: 0.575,
        b2_riboflavin: 0.055,
        b3_niacin: 0.712,
        b5_pantothenic_acid: 0.117,
        b6_pyridoxine: 0.111,
        choline: 7.0,
        folate: 8.0,
        vitamin_a: 441.0,
        vitamin_c: 12.6,
        vitamin_e: 0.59,
        vitamin_k: 2.9,
        calcium: 33.0,
        copper: 0.052,
        iron: 0.57,
        magnesium: 10.0,
        manganese: 0.068,
        phosphorus: 17.0,
        potassium: 191.0,
        selenium: 0.7,
        sodium: 10.0,
        zinc: 0.12
      },
      restrictionWarnings: [14, 13, 12],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '99': {
      id: 99,
      name: 'tomato sauce',
      pluralName: 'tomato sauce',
      description: '',
      sectionId: 9,
      subsectionId: 0,
      density: 1.035554446,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 50,
          packageAmount: 1.0,
          amountInBase: 213.0,
          equivalents: ''
        },
        us: {
          unitId: 26,
          packageAmount: 1.0,
          amountInBase: 213.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 24.0,
        ash: 1.92,
        water: 91.28,
        carbs: 5.31,
        fiber: 1.5,
        starch: 0.25,
        sugars: 3.56,
        fructose: 1.67,
        glucose: 1.82,
        sucrose: 0.07,
        fat: 0.3,
        monounsaturated: 0.044,
        polyunsaturated: 0.121,
        omega_3: 0.006,
        omega_6: 0.115,
        saturated: 0.041,
        protein: 1.2,
        alanine: 0.037,
        arginine: 0.028,
        aspartic_acid: 0.183,
        cystine: 0.013,
        glutamic_acid: 0.586,
        glycine: 0.026,
        histidine: 0.02,
        isoleucine: 0.025,
        leucine: 0.034,
        lysine: 0.037,
        methionine: 0.008,
        phenylalanine: 0.036,
        proline: 0.021,
        serine: 0.035,
        threonine: 0.037,
        tryptophan: 0.009,
        tyrosine: 0.018,
        valine: 0.025,
        b1_thiamine: 0.024,
        b2_riboflavin: 0.065,
        b3_niacin: 0.991,
        b5_pantothenic_acid: 0.309,
        b6_pyridoxine: 0.098,
        choline: 9.9,
        folate: 9.0,
        vitamin_a: 435.0,
        vitamin_c: 7.0,
        vitamin_e: 1.44,
        vitamin_k: 2.8,
        calcium: 14.0,
        copper: 0.115,
        iron: 0.96,
        magnesium: 15.0,
        manganese: 0.113,
        phosphorus: 27.0,
        potassium: 297.0,
        selenium: 0.6,
        sodium: 11.0,
        zinc: 0.22
      },
      restrictionWarnings: [14, 13],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '53': {
      id: 53,
      name: 'soy sauce',
      pluralName: 'soy sauce',
      description: '',
      sectionId: 5,
      subsectionId: 0,
      density: 1.217304818,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 53.0,
        ash: 15.21,
        water: 71.15,
        carbs: 4.93,
        fiber: 0.8,
        starch: 3.73,
        sugars: 0.4,
        glucose: 0.3,
        sucrose: 0.1,
        fat: 0.57,
        monounsaturated: 0.088,
        polyunsaturated: 0.263,
        omega_3: 0.029,
        omega_6: 0.234,
        saturated: 0.073,
        protein: 8.14,
        alanine: 0.294,
        arginine: 0.463,
        aspartic_acid: 0.719,
        cystine: 0.118,
        glutamic_acid: 1.579,
        glycine: 0.297,
        histidine: 0.174,
        isoleucine: 0.318,
        leucine: 0.537,
        lysine: 0.381,
        methionine: 0.097,
        phenylalanine: 0.353,
        proline: 0.493,
        serine: 0.388,
        threonine: 0.271,
        tryptophan: 0.096,
        tyrosine: 0.244,
        valine: 0.332,
        b1_thiamine: 0.033,
        b2_riboflavin: 0.165,
        b3_niacin: 2.196,
        b5_pantothenic_acid: 0.297,
        b6_pyridoxine: 0.148,
        choline: 18.3,
        folate: 14.0,
        calcium: 33.0,
        copper: 0.043,
        iron: 1.45,
        magnesium: 74.0,
        manganese: 1.018,
        phosphorus: 166.0,
        potassium: 435.0,
        selenium: 0.5,
        sodium: 5.0,
        zinc: 0.87
      },
      restrictionWarnings: [14, 10],
      restrictionViolations: [9, 1],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '249': {
      id: 249,
      name: 'quinoa',
      pluralName: 'quinoa',
      description: '',
      sectionId: 3,
      subsectionId: 0,
      density: 0.718547983,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 368.0,
        ash: 2.38,
        water: 13.28,
        carbs: 64.16,
        fiber: 7.0,
        starch: 52.22,
        sugars: 6.12,
        fructose: 0.62,
        glucose: 2.77,
        sucrose: 2.73,
        fat: 6.07,
        monounsaturated: 1.613,
        polyunsaturated: 3.292,
        omega_3: 0.307,
        omega_6: 2.986,
        saturated: 0.706,
        protein: 14.12,
        alanine: 0.588,
        arginine: 1.091,
        aspartic_acid: 1.134,
        cystine: 0.203,
        glutamic_acid: 1.865,
        glycine: 0.694,
        histidine: 0.407,
        isoleucine: 0.504,
        leucine: 0.84,
        lysine: 0.766,
        methionine: 0.309,
        phenylalanine: 0.593,
        proline: 0.773,
        serine: 0.567,
        threonine: 0.421,
        tryptophan: 0.167,
        tyrosine: 0.267,
        valine: 0.594,
        b1_thiamine: 0.36,
        b2_riboflavin: 0.318,
        b3_niacin: 1.52,
        b5_pantothenic_acid: 0.772,
        b6_pyridoxine: 0.487,
        choline: 70.2,
        folate: 184.0,
        vitamin_a: 14.0,
        vitamin_e: 2.44,
        calcium: 47.0,
        copper: 0.59,
        iron: 4.57,
        magnesium: 197.0,
        manganese: 2.033,
        phosphorus: 457.0,
        potassium: 563.0,
        selenium: 8.5,
        sodium: 5.0,
        zinc: 3.1
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '133': {
      id: 133,
      name: 'apple cider vinegar',
      pluralName: 'apple cider vinegar',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 1.010193929,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 21.0,
        ash: 0.17,
        water: 93.81,
        carbs: 0.93,
        sugars: 0.4,
        fructose: 0.3,
        glucose: 0.1,
        calcium: 7.0,
        copper: 0.008,
        iron: 0.2,
        magnesium: 5.0,
        manganese: 0.249,
        phosphorus: 8.0,
        potassium: 73.0,
        selenium: 0.1,
        sodium: 5.0,
        zinc: 0.04
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '72': {
      id: 72,
      name: 'black pepper',
      pluralName: 'black pepper',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.466633514,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 251.0,
        ash: 4.49,
        water: 12.46,
        carbs: 63.95,
        fiber: 25.3,
        starch: 40.6,
        sugars: 0.64,
        fructose: 0.23,
        galactose: 0.15,
        glucose: 0.24,
        sucrose: 0.02,
        fat: 3.26,
        monounsaturated: 0.739,
        polyunsaturated: 0.998,
        omega_3: 0.304,
        omega_6: 0.694,
        saturated: 1.392,
        protein: 10.39,
        alanine: 0.616,
        arginine: 0.308,
        aspartic_acid: 1.413,
        cystine: 0.138,
        glutamic_acid: 1.413,
        glycine: 0.441,
        histidine: 0.159,
        isoleucine: 0.366,
        leucine: 1.014,
        lysine: 0.244,
        methionine: 0.096,
        phenylalanine: 0.446,
        proline: 1.413,
        serine: 0.409,
        threonine: 0.244,
        tryptophan: 0.058,
        tyrosine: 0.483,
        valine: 0.547,
        b1_thiamine: 0.108,
        b2_riboflavin: 0.18,
        b3_niacin: 1.143,
        b5_pantothenic_acid: 1.399,
        b6_pyridoxine: 0.291,
        choline: 11.3,
        folate: 17.0,
        vitamin_a: 547.0,
        vitamin_e: 1.04,
        vitamin_k: 163.7,
        calcium: 443.0,
        copper: 1.33,
        iron: 9.71,
        magnesium: 171.0,
        manganese: 12.753,
        phosphorus: 158.0,
        potassium: 1.0,
        selenium: 4.9,
        sodium: 20.0,
        zinc: 1.19
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '30': {
      id: 30,
      name: 'dried cranberries',
      pluralName: 'dried cranberries',
      description: '',
      sectionId: 12,
      subsectionId: 0,
      density: 0.512333678,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 308.0,
        ash: 0.2,
        water: 16.0,
        carbs: 82.36,
        fiber: 5.7,
        sugars: 65.0,
        fructose: 1.09,
        glucose: 5.43,
        sucrose: 65.82,
        fat: 1.37,
        monounsaturated: 0.198,
        polyunsaturated: 0.658,
        omega_3: 0.032,
        omega_6: 0.626,
        saturated: 0.103,
        protein: 0.07,
        arginine: 0.01,
        aspartic_acid: 0.03,
        glutamic_acid: 0.03,
        leucine: 0.01,
        b1_thiamine: 0.007,
        b2_riboflavin: 0.016,
        b3_niacin: 0.99,
        b5_pantothenic_acid: 0.217,
        b6_pyridoxine: 0.038,
        choline: 4.0,
        vitamin_c: 0.2,
        vitamin_e: 1.07,
        vitamin_k: 3.8,
        calcium: 10.0,
        copper: 0.08,
        iron: 0.53,
        magnesium: 5.0,
        manganese: 0.265,
        phosphorus: 8.0,
        potassium: 40.0,
        selenium: 0.5,
        sodium: 3.0,
        zinc: 0.11
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '224': {
      id: 224,
      name: 'red grapes',
      pluralName: 'red grapes',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 69.0,
        ash: 0.48,
        water: 80.54,
        carbs: 18.1,
        fiber: 0.9,
        sugars: 15.48,
        fructose: 8.13,
        glucose: 7.2,
        sucrose: 0.15,
        fat: 0.16,
        monounsaturated: 0.007,
        polyunsaturated: 0.048,
        omega_3: 0.011,
        omega_6: 0.037,
        saturated: 0.054,
        protein: 0.72,
        alanine: 0.022,
        arginine: 0.13,
        aspartic_acid: 0.038,
        cystine: 0.01,
        glutamic_acid: 0.081,
        glycine: 0.016,
        histidine: 0.022,
        isoleucine: 0.011,
        leucine: 0.022,
        lysine: 0.027,
        methionine: 0.009,
        phenylalanine: 0.019,
        proline: 0.08,
        serine: 0.022,
        threonine: 0.022,
        tryptophan: 0.011,
        tyrosine: 0.01,
        valine: 0.022,
        b1_thiamine: 0.069,
        b2_riboflavin: 0.07,
        b3_niacin: 0.188,
        b5_pantothenic_acid: 0.05,
        b6_pyridoxine: 0.086,
        choline: 5.6,
        folate: 2.0,
        vitamin_a: 66.0,
        vitamin_c: 3.2,
        vitamin_e: 0.19,
        vitamin_k: 14.6,
        calcium: 10.0,
        copper: 0.127,
        iron: 0.36,
        magnesium: 7.0,
        manganese: 0.071,
        phosphorus: 20.0,
        potassium: 191.0,
        selenium: 0.1,
        sodium: 2.0,
        zinc: 0.07
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '468': {
      id: 468,
      name: 'apricot',
      pluralName: 'apricots',
      description: null,
      sectionId: 4,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 45.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 45.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 48.0,
        water: 86.4,
        carbs: 11.1,
        fiber: 2.0,
        sugars: 9.24,
        fat: 0.39,
        monounsaturated: 0.17,
        polyunsaturated: 0.077,
        omega_6: 0.077,
        saturated: 0.027,
        protein: 1.4,
        b1_thiamine: 0.03,
        b2_riboflavin: 0.04,
        b3_niacin: 0.6,
        b6_pyridoxine: 0.054,
        choline: 2.8,
        folate: 9.0,
        vitamin_c: 10.0,
        vitamin_e: 0.89,
        vitamin_k: 3.3,
        calcium: 13.0,
        copper: 0.078,
        iron: 0.39,
        magnesium: 10.0,
        phosphorus: 23.0,
        potassium: 259.0,
        selenium: 0.1,
        sodium: 1.0,
        zinc: 0.2
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '104': {
      id: 104,
      name: 'pure maple syrup',
      pluralName: 'pure maple syrup',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 1.331427145,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 260.0,
        ash: 0.47,
        water: 32.39,
        carbs: 67.04,
        sugars: 60.46,
        fructose: 0.52,
        glucose: 1.6,
        sucrose: 58.32,
        fat: 0.06,
        monounsaturated: 0.011,
        polyunsaturated: 0.017,
        omega_6: 0.017,
        saturated: 0.007,
        protein: 0.04,
        b1_thiamine: 0.066,
        b2_riboflavin: 1.27,
        b3_niacin: 0.081,
        b5_pantothenic_acid: 0.036,
        b6_pyridoxine: 0.002,
        choline: 1.6,
        calcium: 102.0,
        copper: 0.018,
        iron: 0.11,
        magnesium: 21.0,
        manganese: 2.908,
        phosphorus: 2.0,
        potassium: 212.0,
        selenium: 0.6,
        sodium: 12.0,
        zinc: 1.47
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '37': {
      id: 37,
      name: 'ricotta cheese',
      pluralName: 'ricotta cheese',
      description: '',
      sectionId: 7,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 44,
          packageAmount: 1.0,
          amountInBase: 425.0,
          equivalents: ''
        },
        us: {
          unitId: 20,
          packageAmount: 1.0,
          amountInBase: 425.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 174.0,
        ash: 1.02,
        water: 71.7,
        carbs: 3.04,
        sugars: 2.08,
        galactose: 0.01,
        lactose: 2.07,
        fat: 12.98,
        monounsaturated: 3.627,
        polyunsaturated: 0.385,
        omega_3: 0.112,
        omega_6: 0.273,
        saturated: 8.295,
        transfats: 0.39,
        cholesterol: 51.0,
        protein: 11.26,
        alanine: 0.499,
        arginine: 0.632,
        aspartic_acid: 0.995,
        cystine: 0.099,
        glutamic_acid: 2.446,
        glycine: 0.295,
        histidine: 0.459,
        isoleucine: 0.589,
        leucine: 1.221,
        lysine: 1.338,
        methionine: 0.281,
        phenylalanine: 0.556,
        proline: 1.066,
        serine: 0.575,
        threonine: 0.517,
        tryptophan: 0.125,
        tyrosine: 0.589,
        valine: 0.692,
        b1_thiamine: 0.013,
        b2_riboflavin: 0.195,
        b3_niacin: 0.104,
        b5_pantothenic_acid: 0.213,
        b6_pyridoxine: 0.043,
        b12_cobalamin: 0.34,
        choline: 17.5,
        folate: 12.0,
        vitamin_a: 445.0,
        vitamin_d: 10.0,
        vitamin_e: 0.11,
        vitamin_k: 1.1,
        calcium: 207.0,
        copper: 0.021,
        iron: 0.38,
        magnesium: 11.0,
        manganese: 0.006,
        phosphorus: 158.0,
        potassium: 105.0,
        selenium: 14.5,
        sodium: 84.0,
        zinc: 1.16
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '88': {
      id: 88,
      name: 'salt',
      pluralName: 'salt',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 1.234211829,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        ash: 99.8,
        water: 0.2,
        calcium: 24.0,
        copper: 0.03,
        iron: 0.33,
        magnesium: 1.0,
        manganese: 0.1,
        potassium: 8.0,
        selenium: 0.1,
        sodium: 38.0,
        zinc: 0.1
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '181': {
      id: 181,
      name: 'sesame seeds',
      pluralName: 'sesame seeds',
      description: '',
      sectionId: 5,
      subsectionId: 0,
      density: 0.634012926,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 631.0,
        ash: 2.86,
        water: 3.75,
        carbs: 11.73,
        fiber: 11.6,
        starch: 0.07,
        sugars: 0.48,
        fructose: 0.07,
        glucose: 0.1,
        sucrose: 0.31,
        fat: 61.21,
        monounsaturated: 23.924,
        polyunsaturated: 25.491,
        omega_3: 0.263,
        omega_6: 25.227,
        saturated: 9.055,
        protein: 20.45,
        alanine: 0.99,
        arginine: 3.25,
        aspartic_acid: 2.07,
        cystine: 0.44,
        glutamic_acid: 4.6,
        glycine: 1.09,
        histidine: 0.55,
        isoleucine: 0.75,
        leucine: 1.5,
        lysine: 0.65,
        methionine: 0.88,
        phenylalanine: 0.94,
        proline: 1.04,
        serine: 1.2,
        threonine: 0.73,
        tryptophan: 0.33,
        tyrosine: 0.79,
        valine: 0.98,
        b1_thiamine: 0.699,
        b2_riboflavin: 0.09,
        b3_niacin: 5.8,
        b5_pantothenic_acid: 0.29,
        b6_pyridoxine: 0.4,
        choline: 25.6,
        folate: 115.0,
        vitamin_a: 66.0,
        vitamin_e: 1.68,
        calcium: 60.0,
        copper: 1.4,
        iron: 6.36,
        magnesium: 345.0,
        manganese: 1.44,
        phosphorus: 667.0,
        potassium: 370.0,
        selenium: 34.4,
        sodium: 47.0,
        zinc: 6.73
      },
      restrictionWarnings: [],
      restrictionViolations: [12],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '288': {
      id: 288,
      name: 'shredded unsweetened coconut',
      pluralName: 'shredded unsweetened coconut',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.352,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 660.0,
        ash: 1.94,
        water: 3.0,
        carbs: 23.65,
        fiber: 16.3,
        sugars: 7.35,
        fructose: 2.94,
        glucose: 4.2,
        sucrose: 0.21,
        fat: 64.53,
        monounsaturated: 2.745,
        polyunsaturated: 0.706,
        omega_6: 0.706,
        saturated: 57.218,
        protein: 6.88,
        alanine: 0.352,
        arginine: 1.13,
        aspartic_acid: 0.673,
        cystine: 0.136,
        glutamic_acid: 1.574,
        glycine: 0.326,
        histidine: 0.158,
        isoleucine: 0.27,
        leucine: 0.511,
        lysine: 0.304,
        methionine: 0.129,
        phenylalanine: 0.349,
        proline: 0.284,
        serine: 0.356,
        threonine: 0.251,
        tryptophan: 0.081,
        tyrosine: 0.213,
        valine: 0.417,
        b1_thiamine: 0.06,
        b2_riboflavin: 0.1,
        b3_niacin: 0.603,
        b5_pantothenic_acid: 0.8,
        b6_pyridoxine: 0.3,
        choline: 22.1,
        folate: 9.0,
        vitamin_c: 1.5,
        vitamin_e: 0.44,
        vitamin_k: 0.3,
        calcium: 26.0,
        copper: 0.796,
        iron: 3.32,
        magnesium: 90.0,
        manganese: 2.745,
        phosphorus: 206.0,
        potassium: 543.0,
        selenium: 18.5,
        sodium: 37.0,
        zinc: 2.01
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '107': {
      id: 107,
      name: 'fresh mozzarella cheese',
      pluralName: 'fresh mozzarella cheese',
      description: '',
      sectionId: 7,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 42,
          packageAmount: 1.0,
          amountInBase: 227.0,
          equivalents: ''
        },
        us: {
          unitId: 18,
          packageAmount: 1.0,
          amountInBase: 227.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 300.0,
        ash: 3.28,
        water: 50.01,
        carbs: 2.19,
        sugars: 1.03,
        fructose: 0.15,
        galactose: 0.15,
        glucose: 0.56,
        lactose: 0.07,
        maltose: 0.08,
        sucrose: 0.03,
        fat: 22.35,
        monounsaturated: 6.573,
        polyunsaturated: 0.765,
        omega_3: 0.372,
        omega_6: 0.393,
        saturated: 13.152,
        transfats: 0.535,
        cholesterol: 79.0,
        protein: 22.17,
        alanine: 0.707,
        arginine: 0.515,
        aspartic_acid: 1.633,
        cystine: 0.115,
        glutamic_acid: 4.458,
        glycine: 0.515,
        histidine: 0.515,
        isoleucine: 1.135,
        leucine: 1.826,
        lysine: 0.965,
        methionine: 0.515,
        phenylalanine: 1.011,
        proline: 2.351,
        serine: 0.735,
        threonine: 0.983,
        tryptophan: 0.515,
        tyrosine: 1.043,
        valine: 1.322,
        b1_thiamine: 0.03,
        b2_riboflavin: 0.283,
        b3_niacin: 0.104,
        b5_pantothenic_acid: 0.141,
        b6_pyridoxine: 0.037,
        b12_cobalamin: 2.28,
        choline: 15.4,
        folate: 7.0,
        vitamin_a: 676.0,
        vitamin_d: 16.0,
        vitamin_e: 0.19,
        vitamin_k: 2.3,
        calcium: 505.0,
        copper: 0.011,
        iron: 0.44,
        magnesium: 20.0,
        manganese: 0.03,
        phosphorus: 354.0,
        potassium: 76.0,
        selenium: 17.0,
        sodium: 627.0,
        zinc: 2.92
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '90': {
      id: 90,
      name: 'turmeric, ground',
      pluralName: 'turmeric, ground',
      description: '',
      sectionId: 8,
      subsectionId: null,
      density: 0.635703627,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 312.0,
        ash: 7.08,
        water: 12.85,
        carbs: 67.14,
        fiber: 22.7,
        starch: 41.23,
        sugars: 3.21,
        fructose: 0.45,
        glucose: 0.38,
        sucrose: 2.38,
        fat: 3.25,
        monounsaturated: 0.449,
        polyunsaturated: 0.756,
        omega_3: 0.003,
        omega_6: 0.753,
        saturated: 1.838,
        transfats: 0.056,
        protein: 9.68,
        alanine: 0.33,
        arginine: 0.54,
        aspartic_acid: 1.86,
        cystine: 0.15,
        glutamic_acid: 1.14,
        glycine: 0.47,
        histidine: 0.15,
        isoleucine: 0.47,
        leucine: 0.81,
        lysine: 0.38,
        methionine: 0.14,
        phenylalanine: 0.53,
        proline: 0.48,
        serine: 0.28,
        threonine: 0.33,
        tryptophan: 0.17,
        tyrosine: 0.32,
        valine: 0.66,
        b1_thiamine: 0.058,
        b2_riboflavin: 0.15,
        b3_niacin: 1.35,
        b5_pantothenic_acid: 0.542,
        b6_pyridoxine: 0.107,
        choline: 49.2,
        folate: 20.0,
        vitamin_c: 0.7,
        vitamin_e: 4.43,
        vitamin_k: 13.4,
        calcium: 168.0,
        copper: 1.3,
        iron: 55.0,
        magnesium: 208.0,
        manganese: 19.8,
        phosphorus: 299.0,
        potassium: 2.0,
        selenium: 6.2,
        sodium: 27.0,
        zinc: 4.5
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '59': {
      id: 59,
      name: 'spaghetti pasta',
      pluralName: 'spaghetti pasta',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 371.0,
        ash: 0.88,
        water: 9.9,
        carbs: 74.67,
        fiber: 3.2,
        starch: 62.45,
        sugars: 2.67,
        fructose: 0.11,
        glucose: 0.12,
        maltose: 1.96,
        sucrose: 0.48,
        fat: 1.51,
        monounsaturated: 0.171,
        polyunsaturated: 0.564,
        omega_3: 0.024,
        omega_6: 0.54,
        saturated: 0.277,
        protein: 13.04,
        alanine: 0.438,
        arginine: 0.474,
        aspartic_acid: 0.624,
        cystine: 0.255,
        glutamic_acid: 4.596,
        glycine: 0.441,
        histidine: 0.298,
        isoleucine: 0.511,
        leucine: 0.988,
        lysine: 0.298,
        methionine: 0.147,
        phenylalanine: 0.668,
        proline: 1.569,
        serine: 0.617,
        threonine: 0.462,
        tryptophan: 0.185,
        tyrosine: 0.243,
        valine: 0.588,
        b1_thiamine: 0.891,
        b2_riboflavin: 0.4,
        b3_niacin: 7.177,
        b5_pantothenic_acid: 0.431,
        b6_pyridoxine: 0.142,
        choline: 15.1,
        folate: 237.0,
        vitamin_e: 0.11,
        vitamin_k: 0.1,
        calcium: 21.0,
        copper: 0.289,
        iron: 3.3,
        magnesium: 53.0,
        manganese: 0.917,
        phosphorus: 189.0,
        potassium: 223.0,
        selenium: 63.2,
        sodium: 6.0,
        zinc: 1.41
      },
      restrictionWarnings: [11],
      restrictionViolations: [1],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '311': {
      id: 311,
      name: 'poppy seeds',
      pluralName: 'poppy seeds',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.5951268,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 525.0,
        ash: 6.37,
        water: 5.95,
        carbs: 28.13,
        fiber: 19.5,
        starch: 5.64,
        sugars: 2.99,
        fructose: 0.29,
        glucose: 0.37,
        sucrose: 2.33,
        fat: 41.56,
        monounsaturated: 5.982,
        polyunsaturated: 28.569,
        omega_3: 0.273,
        omega_6: 28.295,
        saturated: 4.517,
        protein: 17.99,
        alanine: 0.839,
        arginine: 1.945,
        aspartic_acid: 2.365,
        cystine: 0.297,
        glutamic_acid: 4.299,
        glycine: 0.952,
        histidine: 0.471,
        isoleucine: 0.819,
        leucine: 1.321,
        lysine: 0.952,
        methionine: 0.502,
        phenylalanine: 0.758,
        proline: 2.754,
        serine: 0.952,
        threonine: 0.686,
        tryptophan: 0.184,
        tyrosine: 0.727,
        valine: 1.095,
        b1_thiamine: 0.854,
        b2_riboflavin: 0.1,
        b3_niacin: 0.896,
        b5_pantothenic_acid: 0.324,
        b6_pyridoxine: 0.247,
        choline: 8.8,
        folate: 82.0,
        vitamin_c: 1.0,
        vitamin_e: 1.77,
        calcium: 1.0,
        copper: 1.627,
        iron: 9.76,
        magnesium: 347.0,
        manganese: 6.707,
        phosphorus: 870.0,
        potassium: 719.0,
        selenium: 13.5,
        sodium: 26.0,
        zinc: 7.9
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '406': {
      id: 406,
      name: 'ranch dressing',
      pluralName: 'ranch dressing',
      description: null,
      sectionId: 14,
      subsectionId: null,
      density: 1.01,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 430.0,
        ash: 2.55,
        water: 45.68,
        carbs: 5.9,
        starch: 0.57,
        sugars: 4.69,
        fructose: 0.68,
        glucose: 0.78,
        lactose: 1.35,
        sucrose: 1.86,
        fat: 44.54,
        monounsaturated: 9.202,
        polyunsaturated: 25.796,
        omega_3: 3.415,
        omega_6: 22.324,
        saturated: 6.964,
        transfats: 0.182,
        cholesterol: 26.0,
        protein: 1.32,
        b1_thiamine: 0.015,
        b2_riboflavin: 0.087,
        b3_niacin: 0.054,
        b5_pantothenic_acid: 0.272,
        b6_pyridoxine: 0.03,
        b12_cobalamin: 0.17,
        choline: 32.8,
        folate: 4.0,
        vitamin_a: 69.0,
        vitamin_d: 0.1,
        vitamin_e: 2.22,
        vitamin_k: 134.3,
        calcium: 28.0,
        copper: 0.019,
        iron: 0.3,
        magnesium: 5.0,
        manganese: 0.033,
        phosphorus: 186.0,
        potassium: 64.0,
        selenium: 3.5,
        sodium: 901.0,
        zinc: 0.17
      },
      restrictionWarnings: [14, 13, 12, 9, 5, 6, 1],
      restrictionViolations: [11, 2],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '401': {
      id: 401,
      name: 'tzatziki sauce',
      pluralName: 'tzatziki sauce',
      description: null,
      sectionId: 7,
      subsectionId: null,
      density: 1.035554446,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 92.0,
        water: 83.08,
        carbs: 3.95,
        fiber: 0.2,
        sugars: 2.92,
        fat: 6.31,
        monounsaturated: 3.724,
        polyunsaturated: 0.641,
        omega_3: 0.041,
        omega_6: 0.467,
        saturated: 1.838,
        cholesterol: 7.0,
        protein: 5.35,
        b1_thiamine: 0.025,
        b2_riboflavin: 0.169,
        b3_niacin: 0.16,
        b6_pyridoxine: 0.06,
        b12_cobalamin: 0.42,
        choline: 11.0,
        folate: 6.0,
        vitamin_c: 2.0,
        vitamin_e: 0.52,
        vitamin_k: 8.2,
        calcium: 64.0,
        copper: 0.027,
        iron: 0.14,
        magnesium: 11.0,
        phosphorus: 86.0,
        potassium: 139.0,
        selenium: 5.7,
        sodium: 307.0,
        zinc: 0.38
      },
      restrictionWarnings: [14, 13, 9, 5, 6, 1],
      restrictionViolations: [2],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '404': {
      id: 404,
      name: 'hummus',
      pluralName: 'hummus',
      description: null,
      sectionId: 7,
      subsectionId: null,
      density: 0.95,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 260.0,
        water: 51.5,
        carbs: 19.86,
        fiber: 5.9,
        sugars: 2.91,
        fat: 17.78,
        monounsaturated: 8.554,
        polyunsaturated: 5.845,
        omega_3: 0.148,
        omega_6: 5.692,
        saturated: 2.429,
        protein: 8.18,
        b1_thiamine: 0.305,
        b2_riboflavin: 0.129,
        b3_niacin: 1.363,
        b6_pyridoxine: 0.122,
        choline: 28.8,
        folate: 113.0,
        vitamin_c: 4.5,
        vitamin_e: 1.09,
        vitamin_k: 5.7,
        calcium: 113.0,
        copper: 0.509,
        iron: 3.36,
        magnesium: 45.0,
        phosphorus: 236.0,
        potassium: 251.0,
        selenium: 8.9,
        sodium: 481.0,
        zinc: 1.74
      },
      restrictionWarnings: [14, 13, 9, 5, 6, 1],
      restrictionViolations: [12],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '79': {
      id: 79,
      name: 'garlic powder',
      pluralName: 'garlic powder',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.655992041,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 331.0,
        ash: 3.54,
        water: 6.45,
        carbs: 72.73,
        fiber: 9.0,
        starch: 51.72,
        sugars: 2.43,
        fructose: 0.31,
        glucose: 0.07,
        sucrose: 2.05,
        fat: 0.73,
        monounsaturated: 0.115,
        polyunsaturated: 0.178,
        omega_3: 0.012,
        omega_6: 0.165,
        saturated: 0.249,
        protein: 16.55,
        alanine: 0.485,
        arginine: 3.365,
        aspartic_acid: 1.89,
        cystine: 0.253,
        glutamic_acid: 3.658,
        glycine: 0.525,
        histidine: 0.263,
        isoleucine: 0.414,
        leucine: 0.728,
        lysine: 0.768,
        methionine: 0.111,
        phenylalanine: 0.525,
        proline: 1.354,
        serine: 0.505,
        threonine: 0.374,
        tryptophan: 0.121,
        tyrosine: 0.445,
        valine: 0.667,
        b1_thiamine: 0.435,
        b2_riboflavin: 0.141,
        b3_niacin: 0.796,
        b5_pantothenic_acid: 0.743,
        b6_pyridoxine: 1.654,
        choline: 67.5,
        folate: 47.0,
        vitamin_c: 1.2,
        vitamin_e: 0.67,
        vitamin_k: 0.4,
        calcium: 79.0,
        copper: 0.533,
        iron: 5.65,
        magnesium: 77.0,
        manganese: 0.979,
        phosphorus: 414.0,
        potassium: 1.0,
        selenium: 23.9,
        sodium: 60.0,
        zinc: 2.99
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '19': {
      id: 19,
      name: 'white mushrooms',
      pluralName: 'white mushrooms',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '0.454 kg = 19 large, 25 medium, or 44 small mushrooms'
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '1 lb = 19 large, 25 medium, or 44 small mushrooms'
        }
      },
      nutrition: {
        energy: 22.0,
        ash: 0.85,
        water: 92.45,
        carbs: 3.26,
        fiber: 1.0,
        starch: 0.59,
        sugars: 1.98,
        fructose: 0.17,
        glucose: 1.48,
        fat: 0.34,
        polyunsaturated: 0.16,
        omega_6: 0.16,
        saturated: 0.05,
        protein: 3.09,
        alanine: 0.199,
        arginine: 0.078,
        aspartic_acid: 0.195,
        cystine: 0.012,
        glutamic_acid: 0.343,
        glycine: 0.092,
        histidine: 0.057,
        isoleucine: 0.076,
        leucine: 0.12,
        lysine: 0.107,
        methionine: 0.031,
        phenylalanine: 0.085,
        proline: 0.076,
        serine: 0.094,
        threonine: 0.107,
        tryptophan: 0.035,
        tyrosine: 0.044,
        valine: 0.232,
        b1_thiamine: 0.081,
        b2_riboflavin: 0.402,
        b3_niacin: 3.607,
        b5_pantothenic_acid: 1.497,
        b6_pyridoxine: 0.104,
        b12_cobalamin: 0.04,
        choline: 17.3,
        folate: 17.0,
        vitamin_c: 2.1,
        vitamin_d: 7.0,
        vitamin_e: 0.01,
        calcium: 3.0,
        copper: 0.318,
        iron: 0.5,
        magnesium: 9.0,
        manganese: 0.047,
        phosphorus: 86.0,
        potassium: 318.0,
        selenium: 9.3,
        sodium: 5.0,
        zinc: 0.52
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '469': {
      id: 469,
      name: 'plum',
      pluralName: 'plums',
      description: null,
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 75.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 75.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 46.0,
        water: 87.2,
        carbs: 11.4,
        fiber: 1.4,
        sugars: 9.92,
        fat: 0.28,
        monounsaturated: 0.134,
        polyunsaturated: 0.044,
        omega_6: 0.044,
        saturated: 0.017,
        protein: 0.7,
        b1_thiamine: 0.028,
        b2_riboflavin: 0.026,
        b3_niacin: 0.417,
        b6_pyridoxine: 0.029,
        choline: 1.9,
        folate: 5.0,
        vitamin_c: 9.5,
        vitamin_e: 0.26,
        vitamin_k: 6.4,
        calcium: 6.0,
        copper: 0.057,
        iron: 0.17,
        magnesium: 7.0,
        phosphorus: 16.0,
        potassium: 157.0,
        zinc: 0.1
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '24': {
      id: 24,
      name: 'garlic',
      pluralName: 'garlic',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 4,
          packageAmount: 1.0,
          amountInBase: 3.0,
          equivalents: ''
        },
        us: {
          unitId: 4,
          packageAmount: 1.0,
          amountInBase: 3.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 149.0,
        ash: 1.5,
        water: 58.58,
        carbs: 33.06,
        fiber: 2.1,
        starch: 25.13,
        sugars: 1.0,
        fructose: 0.38,
        glucose: 0.25,
        sucrose: 0.36,
        fat: 0.5,
        monounsaturated: 0.011,
        polyunsaturated: 0.249,
        omega_3: 0.02,
        omega_6: 0.229,
        saturated: 0.089,
        protein: 6.36,
        alanine: 0.132,
        arginine: 0.634,
        aspartic_acid: 0.489,
        cystine: 0.065,
        glutamic_acid: 0.805,
        glycine: 0.2,
        histidine: 0.113,
        isoleucine: 0.217,
        leucine: 0.308,
        lysine: 0.273,
        methionine: 0.076,
        phenylalanine: 0.183,
        proline: 0.1,
        serine: 0.19,
        threonine: 0.157,
        tryptophan: 0.066,
        tyrosine: 0.081,
        valine: 0.291,
        b1_thiamine: 0.2,
        b2_riboflavin: 0.11,
        b3_niacin: 0.7,
        b5_pantothenic_acid: 0.596,
        b6_pyridoxine: 1.235,
        choline: 23.2,
        folate: 3.0,
        vitamin_a: 9.0,
        vitamin_c: 31.2,
        vitamin_e: 0.08,
        vitamin_k: 1.7,
        calcium: 181.0,
        copper: 0.299,
        iron: 1.7,
        magnesium: 25.0,
        manganese: 1.672,
        phosphorus: 153.0,
        potassium: 401.0,
        selenium: 14.2,
        sodium: 17.0,
        zinc: 1.16
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '38': {
      id: 38,
      name: 'chunk light tuna in water',
      pluralName: 'chunk light tuna in water',
      description: '',
      sectionId: 9,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 46,
          packageAmount: 1.0,
          amountInBase: 120.0,
          equivalents: ''
        },
        us: {
          unitId: 23,
          packageAmount: 1.0,
          amountInBase: 120.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 86.0,
        ash: 0.95,
        water: 78.14,
        fat: 0.96,
        monounsaturated: 0.107,
        polyunsaturated: 0.277,
        omega_3: 0.233,
        omega_6: 0.043,
        saturated: 0.211,
        transfats: 0.002,
        cholesterol: 36.0,
        protein: 19.44,
        alanine: 1.127,
        arginine: 1.115,
        aspartic_acid: 1.907,
        cystine: 0.2,
        glutamic_acid: 2.78,
        glycine: 0.894,
        histidine: 0.548,
        isoleucine: 0.858,
        leucine: 1.514,
        lysine: 1.711,
        methionine: 0.551,
        phenylalanine: 0.727,
        proline: 0.659,
        serine: 0.76,
        threonine: 0.817,
        tryptophan: 0.209,
        tyrosine: 0.629,
        valine: 0.959,
        b1_thiamine: 0.03,
        b2_riboflavin: 0.084,
        b3_niacin: 10.136,
        b5_pantothenic_acid: 0.148,
        b6_pyridoxine: 0.319,
        b12_cobalamin: 2.55,
        choline: 29.3,
        folate: 4.0,
        vitamin_a: 57.0,
        vitamin_d: 47.0,
        vitamin_e: 0.33,
        vitamin_k: 0.2,
        calcium: 17.0,
        copper: 0.05,
        iron: 1.63,
        magnesium: 23.0,
        manganese: 0.019,
        phosphorus: 139.0,
        potassium: 179.0,
        selenium: 70.6,
        sodium: 247.0,
        zinc: 0.69
      },
      restrictionWarnings: [14, 13, 12, 11, 10, 9, 2, 1],
      restrictionViolations: [3],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '470': {
      id: 470,
      name: 'fennel bulb',
      pluralName: 'fennel bulbs',
      description: null,
      sectionId: 4,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 235.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 235.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 31.0,
        water: 90.2,
        carbs: 7.3,
        fiber: 3.1,
        sugars: 3.93,
        fat: 0.2,
        monounsaturated: 0.068,
        polyunsaturated: 0.169,
        omega_6: 0.169,
        saturated: 0.09,
        protein: 1.24,
        b1_thiamine: 0.01,
        b2_riboflavin: 0.032,
        b3_niacin: 0.64,
        b6_pyridoxine: 0.047,
        choline: 13.2,
        folate: 27.0,
        vitamin_c: 12.0,
        vitamin_e: 0.58,
        vitamin_k: 62.8,
        calcium: 49.0,
        copper: 0.066,
        iron: 0.73,
        magnesium: 17.0,
        phosphorus: 50.0,
        potassium: 414.0,
        selenium: 0.7,
        sodium: 52.0,
        zinc: 0.2
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '33': {
      id: 33,
      name: 'baby spinach',
      pluralName: 'baby spinach',
      description: '',
      sectionId: 4,
      subsectionId: 5,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 39,
          packageAmount: 1.0,
          amountInBase: 142.0,
          equivalents: ''
        },
        us: {
          unitId: 15,
          packageAmount: 1.0,
          amountInBase: 142.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 23.0,
        ash: 1.72,
        water: 91.4,
        carbs: 3.63,
        fiber: 2.2,
        starch: 0.1,
        sugars: 0.42,
        fructose: 0.15,
        galactose: 0.1,
        glucose: 0.11,
        sucrose: 0.07,
        fat: 0.39,
        monounsaturated: 0.01,
        polyunsaturated: 0.165,
        omega_3: 0.138,
        omega_6: 0.026,
        saturated: 0.063,
        protein: 2.86,
        alanine: 0.142,
        arginine: 0.162,
        aspartic_acid: 0.24,
        cystine: 0.035,
        glutamic_acid: 0.343,
        glycine: 0.134,
        histidine: 0.064,
        isoleucine: 0.147,
        leucine: 0.223,
        lysine: 0.174,
        methionine: 0.053,
        phenylalanine: 0.129,
        proline: 0.112,
        serine: 0.104,
        threonine: 0.122,
        tryptophan: 0.039,
        tyrosine: 0.108,
        valine: 0.161,
        b1_thiamine: 0.078,
        b2_riboflavin: 0.189,
        b3_niacin: 0.724,
        b5_pantothenic_acid: 0.065,
        b6_pyridoxine: 0.195,
        choline: 19.3,
        folate: 194.0,
        vitamin_a: 9.0,
        vitamin_c: 28.1,
        vitamin_e: 2.03,
        vitamin_k: 482.9,
        calcium: 99.0,
        copper: 0.13,
        iron: 2.71,
        magnesium: 79.0,
        manganese: 0.897,
        phosphorus: 49.0,
        potassium: 558.0,
        selenium: 1.0,
        sodium: 79.0,
        zinc: 0.53
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '106': {
      id: 106,
      name: 'Italian (flat-leaf) parsley',
      pluralName: 'Italian (flat-leaf) parsley',
      description: '',
      sectionId: 4,
      subsectionId: 4,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 6,
          packageAmount: 1.0,
          amountInBase: 60.0,
          equivalents: ''
        },
        us: {
          unitId: 6,
          packageAmount: 1.0,
          amountInBase: 60.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 36.0,
        ash: 2.2,
        water: 87.71,
        carbs: 6.33,
        fiber: 3.3,
        starch: 0.4,
        sugars: 0.85,
        fructose: 0.3,
        glucose: 0.1,
        sucrose: 0.2,
        fat: 0.79,
        monounsaturated: 0.295,
        polyunsaturated: 0.124,
        omega_3: 0.008,
        omega_6: 0.115,
        saturated: 0.132,
        protein: 2.97,
        alanine: 0.195,
        arginine: 0.122,
        aspartic_acid: 0.294,
        cystine: 0.014,
        glutamic_acid: 0.249,
        glycine: 0.145,
        histidine: 0.061,
        isoleucine: 0.118,
        leucine: 0.204,
        lysine: 0.181,
        methionine: 0.042,
        phenylalanine: 0.145,
        proline: 0.213,
        serine: 0.136,
        threonine: 0.122,
        tryptophan: 0.045,
        tyrosine: 0.082,
        valine: 0.172,
        b1_thiamine: 0.086,
        b2_riboflavin: 0.098,
        b3_niacin: 1.313,
        b5_pantothenic_acid: 0.4,
        b6_pyridoxine: 0.09,
        choline: 12.8,
        folate: 152.0,
        vitamin_a: 8.0,
        vitamin_c: 133.0,
        vitamin_e: 0.75,
        vitamin_k: 1.0,
        calcium: 138.0,
        copper: 0.149,
        iron: 6.2,
        magnesium: 50.0,
        manganese: 0.16,
        phosphorus: 58.0,
        potassium: 554.0,
        selenium: 0.1,
        sodium: 56.0,
        zinc: 1.07
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '139': {
      id: 139,
      name: 'baby arugula',
      pluralName: 'baby arugula',
      description: '',
      sectionId: 4,
      subsectionId: 5,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 39,
          packageAmount: 1.0,
          amountInBase: 142.0,
          equivalents: ''
        },
        us: {
          unitId: 15,
          packageAmount: 1.0,
          amountInBase: 142.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 25.0,
        ash: 1.4,
        water: 91.71,
        carbs: 3.65,
        fiber: 1.6,
        sugars: 2.05,
        fructose: 1.02,
        glucose: 0.9,
        sucrose: 0.13,
        fat: 0.66,
        monounsaturated: 0.049,
        polyunsaturated: 0.319,
        omega_3: 0.17,
        omega_6: 0.15,
        saturated: 0.086,
        protein: 2.58,
        b1_thiamine: 0.044,
        b2_riboflavin: 0.086,
        b3_niacin: 0.305,
        b5_pantothenic_acid: 0.437,
        b6_pyridoxine: 0.073,
        choline: 15.3,
        folate: 97.0,
        vitamin_a: 2.0,
        vitamin_c: 15.0,
        vitamin_e: 0.43,
        vitamin_k: 108.6,
        calcium: 160.0,
        copper: 0.076,
        iron: 1.46,
        magnesium: 47.0,
        manganese: 0.321,
        phosphorus: 52.0,
        potassium: 369.0,
        selenium: 0.3,
        sodium: 27.0,
        zinc: 0.47
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '145': {
      id: 145,
      name: 'spring mix (mixed greens)',
      pluralName: 'spring mix (mixed greens)',
      description: '',
      sectionId: 4,
      subsectionId: 5,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 39,
          packageAmount: 1.0,
          amountInBase: 142.0,
          equivalents: ''
        },
        us: {
          unitId: 15,
          packageAmount: 1.0,
          amountInBase: 142.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 17.2,
        ash: 0.94,
        water: 94.08,
        carbs: 3.22,
        fiber: 1.98,
        starch: 0.02,
        sugars: 0.92,
        fructose: 0.5,
        galactose: 0.03,
        glucose: 0.37,
        sucrose: 0.03,
        fat: 0.24,
        monounsaturated: 0.01,
        polyunsaturated: 0.11,
        omega_3: 0.08,
        omega_6: 0.04,
        saturated: 0.04,
        protein: 1.52,
        alanine: 0.07,
        arginine: 0.07,
        aspartic_acid: 0.16,
        cystine: 0.01,
        glutamic_acid: 0.21,
        glycine: 0.06,
        histidine: 0.03,
        isoleucine: 0.07,
        leucine: 0.1,
        lysine: 0.08,
        methionine: 0.02,
        phenylalanine: 0.07,
        proline: 0.06,
        serine: 0.05,
        threonine: 0.06,
        tryptophan: 0.01,
        tyrosine: 0.04,
        valine: 0.08,
        b1_thiamine: 0.07,
        b2_riboflavin: 0.09,
        b3_niacin: 0.39,
        b5_pantothenic_acid: 0.27,
        b6_pyridoxine: 0.08,
        choline: 13.26,
        folate: 107.8,
        vitamin_a: 5.0,
        vitamin_c: 10.12,
        vitamin_e: 0.59,
        vitamin_k: 193.36,
        calcium: 47.6,
        copper: 0.07,
        iron: 1.16,
        magnesium: 25.6,
        manganese: 0.37,
        phosphorus: 31.2,
        potassium: 290.8,
        selenium: 0.46,
        sodium: 29.4,
        zinc: 0.38
      },
      restrictionWarnings: [13],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '152': {
      id: 152,
      name: 'yellow onion',
      pluralName: 'yellow onions',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 110.0,
          equivalents: '1 medium yellow onion = 122 g'
        },
        us: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 110.0,
          equivalents: '1 medium yellow onion = 4.3 oz / 0.27 lb'
        }
      },
      nutrition: {
        energy: 40.0,
        ash: 0.35,
        water: 89.11,
        carbs: 9.34,
        fiber: 1.7,
        sugars: 4.24,
        fructose: 1.29,
        glucose: 1.97,
        sucrose: 0.99,
        fat: 0.1,
        monounsaturated: 0.013,
        polyunsaturated: 0.017,
        omega_3: 0.004,
        omega_6: 0.013,
        saturated: 0.042,
        protein: 1.1,
        alanine: 0.021,
        arginine: 0.104,
        aspartic_acid: 0.091,
        cystine: 0.004,
        glutamic_acid: 0.258,
        glycine: 0.025,
        histidine: 0.014,
        isoleucine: 0.014,
        leucine: 0.025,
        lysine: 0.039,
        methionine: 0.002,
        phenylalanine: 0.025,
        proline: 0.012,
        serine: 0.021,
        threonine: 0.021,
        tryptophan: 0.014,
        tyrosine: 0.014,
        valine: 0.021,
        b1_thiamine: 0.046,
        b2_riboflavin: 0.027,
        b3_niacin: 0.116,
        b5_pantothenic_acid: 0.123,
        b6_pyridoxine: 0.12,
        choline: 6.1,
        folate: 19.0,
        vitamin_a: 2.0,
        vitamin_c: 7.4,
        vitamin_e: 0.02,
        vitamin_k: 0.4,
        calcium: 23.0,
        copper: 0.039,
        iron: 0.21,
        magnesium: 10.0,
        manganese: 0.129,
        phosphorus: 29.0,
        potassium: 146.0,
        selenium: 0.5,
        sodium: 4.0,
        zinc: 0.17
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '155': {
      id: 155,
      name: 'English cucumber',
      pluralName: 'English cucumbers',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 301.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 301.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 15.0,
        ash: 0.38,
        water: 95.23,
        carbs: 3.63,
        fiber: 1.14,
        starch: 0.83,
        sugars: 1.67,
        fructose: 0.87,
        glucose: 0.76,
        maltose: 0.01,
        sucrose: 0.03,
        fat: 0.11,
        monounsaturated: 0.005,
        polyunsaturated: 0.032,
        omega_3: 0.005,
        omega_6: 0.028,
        saturated: 0.037,
        protein: 0.65,
        alanine: 0.024,
        arginine: 0.044,
        aspartic_acid: 0.041,
        cystine: 0.004,
        glutamic_acid: 0.196,
        glycine: 0.024,
        histidine: 0.01,
        isoleucine: 0.021,
        leucine: 0.029,
        lysine: 0.029,
        methionine: 0.006,
        phenylalanine: 0.019,
        proline: 0.015,
        serine: 0.02,
        threonine: 0.019,
        tryptophan: 0.005,
        tyrosine: 0.011,
        valine: 0.022,
        b1_thiamine: 0.027,
        b2_riboflavin: 0.033,
        b3_niacin: 0.098,
        b5_pantothenic_acid: 0.259,
        b6_pyridoxine: 0.04,
        choline: 6.0,
        folate: 7.0,
        vitamin_a: 105.0,
        vitamin_c: 2.8,
        vitamin_e: 0.03,
        vitamin_k: 16.4,
        calcium: 16.0,
        copper: 0.041,
        iron: 0.28,
        magnesium: 13.0,
        manganese: 0.079,
        phosphorus: 24.0,
        potassium: 147.0,
        selenium: 0.3,
        sodium: 2.0,
        zinc: 0.2
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '184': {
      id: 184,
      name: 'red onion',
      pluralName: 'red onions',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 110.0,
          equivalents: '1 medium red onion = 122 g'
        },
        us: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 110.0,
          equivalents: '1 medium red onion = 4.3 oz / 0.27 lb'
        }
      },
      nutrition: {
        energy: 40.0,
        ash: 0.35,
        water: 89.11,
        carbs: 9.34,
        fiber: 1.7,
        sugars: 4.24,
        fructose: 1.29,
        glucose: 1.97,
        sucrose: 0.99,
        fat: 0.1,
        monounsaturated: 0.013,
        polyunsaturated: 0.017,
        omega_3: 0.004,
        omega_6: 0.013,
        saturated: 0.042,
        protein: 1.1,
        alanine: 0.021,
        arginine: 0.104,
        aspartic_acid: 0.091,
        cystine: 0.004,
        glutamic_acid: 0.258,
        glycine: 0.025,
        histidine: 0.014,
        isoleucine: 0.014,
        leucine: 0.025,
        lysine: 0.039,
        methionine: 0.002,
        phenylalanine: 0.025,
        proline: 0.012,
        serine: 0.021,
        threonine: 0.021,
        tryptophan: 0.014,
        tyrosine: 0.014,
        valine: 0.021,
        b1_thiamine: 0.046,
        b2_riboflavin: 0.027,
        b3_niacin: 0.116,
        b5_pantothenic_acid: 0.123,
        b6_pyridoxine: 0.12,
        choline: 6.1,
        folate: 19.0,
        vitamin_a: 2.0,
        vitamin_c: 7.4,
        vitamin_e: 0.02,
        vitamin_k: 0.4,
        calcium: 23.0,
        copper: 0.039,
        iron: 0.21,
        magnesium: 10.0,
        manganese: 0.129,
        phosphorus: 29.0,
        potassium: 146.0,
        selenium: 0.5,
        sodium: 4.0,
        zinc: 0.17
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '188': {
      id: 188,
      name: 'cilantro',
      pluralName: 'cilantro',
      description: '',
      sectionId: 4,
      subsectionId: 4,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 6,
          packageAmount: 1.0,
          amountInBase: 80.0,
          equivalents: ''
        },
        us: {
          unitId: 6,
          packageAmount: 1.0,
          amountInBase: 80.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 23.0,
        ash: 1.47,
        water: 92.21,
        carbs: 3.67,
        fiber: 2.8,
        starch: 0.13,
        sugars: 0.87,
        fructose: 0.29,
        glucose: 0.44,
        fat: 0.52,
        monounsaturated: 0.275,
        polyunsaturated: 0.04,
        omega_6: 0.04,
        saturated: 0.014,
        protein: 2.13,
        b1_thiamine: 0.067,
        b2_riboflavin: 0.162,
        b3_niacin: 1.114,
        b5_pantothenic_acid: 0.57,
        b6_pyridoxine: 0.149,
        choline: 12.8,
        folate: 62.0,
        vitamin_a: 6.0,
        vitamin_c: 27.0,
        vitamin_e: 2.5,
        vitamin_k: 310.0,
        calcium: 67.0,
        copper: 0.225,
        iron: 1.77,
        magnesium: 26.0,
        manganese: 0.426,
        phosphorus: 48.0,
        potassium: 521.0,
        selenium: 0.9,
        sodium: 46.0,
        zinc: 0.5
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '221': {
      id: 221,
      name: 'ginger root',
      pluralName: 'ginger root',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 76,
          packageAmount: 1.0,
          amountInBase: 13.0,
          equivalents: ''
        },
        us: {
          unitId: 75,
          packageAmount: 1.0,
          amountInBase: 13.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 80.0,
        ash: 0.77,
        water: 78.89,
        carbs: 17.77,
        fiber: 2.0,
        starch: 9.46,
        sugars: 1.7,
        fructose: 0.92,
        glucose: 0.78,
        fat: 0.75,
        monounsaturated: 0.154,
        polyunsaturated: 0.154,
        omega_3: 0.034,
        omega_6: 0.12,
        saturated: 0.203,
        protein: 1.82,
        alanine: 0.031,
        arginine: 0.043,
        aspartic_acid: 0.208,
        cystine: 0.008,
        glutamic_acid: 0.162,
        glycine: 0.043,
        histidine: 0.03,
        isoleucine: 0.051,
        leucine: 0.074,
        lysine: 0.057,
        methionine: 0.013,
        phenylalanine: 0.045,
        proline: 0.041,
        serine: 0.045,
        threonine: 0.036,
        tryptophan: 0.012,
        tyrosine: 0.02,
        valine: 0.073,
        b1_thiamine: 0.025,
        b2_riboflavin: 0.034,
        b3_niacin: 0.75,
        b5_pantothenic_acid: 0.203,
        b6_pyridoxine: 0.16,
        choline: 28.8,
        folate: 11.0,
        vitamin_c: 5.0,
        vitamin_e: 0.26,
        vitamin_k: 0.1,
        calcium: 16.0,
        copper: 0.226,
        iron: 0.6,
        magnesium: 43.0,
        manganese: 0.229,
        phosphorus: 34.0,
        potassium: 415.0,
        selenium: 0.7,
        sodium: 13.0,
        zinc: 0.34
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '126': {
      id: 126,
      name: 'goat cheese',
      pluralName: 'goat cheese',
      description: '',
      sectionId: 7,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 85,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 84,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 264.0,
        ash: 1.58,
        water: 60.75,
        fat: 21.08,
        monounsaturated: 4.807,
        polyunsaturated: 0.501,
        omega_6: 0.501,
        saturated: 14.575,
        transfats: 0.71,
        cholesterol: 46.0,
        protein: 18.52,
        alanine: 0.319,
        arginine: 0.549,
        aspartic_acid: 0.92,
        cystine: 0.084,
        glutamic_acid: 3.452,
        glycine: 0.209,
        histidine: 0.505,
        isoleucine: 0.766,
        leucine: 1.597,
        lysine: 1.33,
        methionine: 0.494,
        phenylalanine: 0.737,
        proline: 2.241,
        serine: 0.711,
        threonine: 0.691,
        tryptophan: 0.195,
        tyrosine: 0.723,
        valine: 1.274,
        b1_thiamine: 0.07,
        b2_riboflavin: 0.38,
        b3_niacin: 0.43,
        b5_pantothenic_acid: 0.68,
        b6_pyridoxine: 0.25,
        b12_cobalamin: 0.19,
        choline: 15.4,
        folate: 12.0,
        vitamin_a: 1.0,
        vitamin_d: 15.0,
        vitamin_e: 0.18,
        vitamin_k: 1.8,
        calcium: 140.0,
        copper: 0.732,
        iron: 1.9,
        magnesium: 16.0,
        manganese: 0.1,
        phosphorus: 256.0,
        potassium: 26.0,
        selenium: 2.8,
        sodium: 368.0,
        zinc: 0.92
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '101': {
      id: 101,
      name: 'natural peanut butter',
      pluralName: 'natural peanut butter',
      description: '',
      sectionId: 11,
      subsectionId: 0,
      density: 1.090502233,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 585.0,
        ash: 3.6,
        water: 1.55,
        carbs: 21.51,
        fiber: 8.0,
        starch: 3.43,
        sugars: 4.18,
        glucose: 0.24,
        sucrose: 3.94,
        fat: 49.66,
        monounsaturated: 24.64,
        polyunsaturated: 15.694,
        omega_3: 0.003,
        omega_6: 15.69,
        saturated: 6.893,
        protein: 23.68,
        alanine: 0.941,
        arginine: 2.832,
        aspartic_acid: 2.888,
        cystine: 0.304,
        glutamic_acid: 4.949,
        glycine: 1.427,
        histidine: 0.599,
        isoleucine: 0.833,
        leucine: 1.535,
        lysine: 0.85,
        methionine: 0.291,
        phenylalanine: 1.227,
        proline: 1.045,
        serine: 1.167,
        threonine: 0.811,
        tryptophan: 0.23,
        tyrosine: 0.963,
        valine: 0.993,
        b1_thiamine: 0.438,
        b2_riboflavin: 0.098,
        b3_niacin: 13.525,
        b5_pantothenic_acid: 1.395,
        b6_pyridoxine: 0.256,
        choline: 63.0,
        folate: 145.0,
        vitamin_e: 9.1,
        vitamin_k: 0.6,
        calcium: 54.0,
        copper: 0.671,
        iron: 2.26,
        magnesium: 176.0,
        manganese: 2.083,
        phosphorus: 358.0,
        potassium: 658.0,
        selenium: 7.5,
        sodium: 6.0,
        zinc: 3.31
      },
      restrictionWarnings: [9],
      restrictionViolations: [5],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '122': {
      id: 122,
      name: 'plain Greek yogurt',
      pluralName: 'plain Greek yogurt',
      description: '',
      sectionId: 2,
      subsectionId: 0,
      density: 1.035554446,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 59.0,
        ash: 0.72,
        water: 85.1,
        carbs: 3.6,
        sugars: 3.24,
        galactose: 0.7,
        lactose: 2.54,
        fat: 0.39,
        monounsaturated: 0.053,
        polyunsaturated: 0.012,
        omega_3: 0.001,
        omega_6: 0.01,
        saturated: 0.117,
        transfats: 0.006,
        cholesterol: 5.0,
        protein: 10.19,
        alanine: 0.42,
        arginine: 0.29,
        aspartic_acid: 0.97,
        cystine: 0.16,
        glutamic_acid: 1.93,
        glycine: 0.19,
        histidine: 0.23,
        isoleucine: 0.51,
        leucine: 0.97,
        lysine: 0.87,
        methionine: 0.21,
        phenylalanine: 0.38,
        proline: 0.7,
        serine: 0.5,
        threonine: 0.49,
        tryptophan: 0.19,
        tyrosine: 0.32,
        valine: 0.54,
        b1_thiamine: 0.023,
        b2_riboflavin: 0.278,
        b3_niacin: 0.208,
        b5_pantothenic_acid: 0.331,
        b6_pyridoxine: 0.063,
        b12_cobalamin: 0.75,
        choline: 15.1,
        folate: 7.0,
        vitamin_a: 4.0,
        vitamin_e: 0.01,
        calcium: 110.0,
        copper: 0.017,
        iron: 0.07,
        magnesium: 11.0,
        manganese: 0.009,
        phosphorus: 135.0,
        potassium: 141.0,
        selenium: 9.7,
        sodium: 36.0,
        zinc: 0.52,
        sugar_alcohol: 0.03
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '143': {
      id: 143,
      name: 'sweet potato',
      pluralName: 'sweet potatoes',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '1 medium sweet potato = 130 g / 0.13 kg'
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '1 medium sweet potato = 4.6 oz / 0.29 lb'
        }
      },
      nutrition: {
        energy: 86.0,
        ash: 0.99,
        water: 77.28,
        carbs: 20.12,
        fiber: 3.0,
        starch: 12.65,
        sugars: 4.18,
        fructose: 0.7,
        glucose: 0.96,
        sucrose: 2.52,
        fat: 0.05,
        monounsaturated: 0.001,
        polyunsaturated: 0.014,
        omega_3: 0.001,
        omega_6: 0.013,
        saturated: 0.018,
        protein: 1.57,
        alanine: 0.077,
        arginine: 0.055,
        aspartic_acid: 0.382,
        cystine: 0.022,
        glutamic_acid: 0.155,
        glycine: 0.063,
        histidine: 0.031,
        isoleucine: 0.055,
        leucine: 0.092,
        lysine: 0.066,
        methionine: 0.029,
        phenylalanine: 0.089,
        proline: 0.052,
        serine: 0.088,
        threonine: 0.083,
        tryptophan: 0.031,
        tyrosine: 0.034,
        valine: 0.086,
        b1_thiamine: 0.078,
        b2_riboflavin: 0.061,
        b3_niacin: 0.557,
        b5_pantothenic_acid: 0.8,
        b6_pyridoxine: 0.209,
        choline: 12.3,
        folate: 11.0,
        vitamin_a: 14187.0,
        vitamin_c: 2.4,
        vitamin_e: 0.26,
        vitamin_k: 1.8,
        calcium: 30.0,
        copper: 0.151,
        iron: 0.61,
        magnesium: 25.0,
        manganese: 0.258,
        phosphorus: 47.0,
        potassium: 337.0,
        selenium: 0.6,
        sodium: 55.0,
        zinc: 0.3,
        sugar_alcohol: 0.04
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '74': {
      id: 74,
      name: 'cinnamon, ground',
      pluralName: 'cinnamon, ground',
      description: '',
      sectionId: 8,
      subsectionId: null,
      density: 0.527498754,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 247.0,
        ash: 3.6,
        water: 10.58,
        carbs: 80.59,
        fiber: 53.1,
        starch: 25.32,
        sugars: 2.17,
        fructose: 1.11,
        glucose: 1.04,
        sucrose: 0.02,
        fat: 1.24,
        monounsaturated: 0.246,
        polyunsaturated: 0.068,
        omega_3: 0.011,
        omega_6: 0.044,
        saturated: 0.345,
        protein: 3.99,
        alanine: 0.166,
        arginine: 0.166,
        aspartic_acid: 0.438,
        cystine: 0.058,
        glutamic_acid: 0.37,
        glycine: 0.195,
        histidine: 0.117,
        isoleucine: 0.146,
        leucine: 0.253,
        lysine: 0.243,
        methionine: 0.078,
        phenylalanine: 0.146,
        proline: 0.419,
        serine: 0.195,
        threonine: 0.136,
        tryptophan: 0.049,
        tyrosine: 0.136,
        valine: 0.224,
        b1_thiamine: 0.022,
        b2_riboflavin: 0.041,
        b3_niacin: 1.332,
        b5_pantothenic_acid: 0.358,
        b6_pyridoxine: 0.158,
        choline: 11.0,
        folate: 6.0,
        vitamin_a: 295.0,
        vitamin_c: 3.8,
        vitamin_e: 2.32,
        vitamin_k: 31.2,
        calcium: 1.0,
        copper: 0.339,
        iron: 8.32,
        magnesium: 60.0,
        manganese: 17.466,
        phosphorus: 64.0,
        potassium: 431.0,
        selenium: 3.1,
        sodium: 10.0,
        zinc: 1.83
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '277': {
      id: 277,
      name: 'grape tomatoes',
      pluralName: 'grape tomatoes',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 8,
          packageAmount: 1.0,
          amountInBase: 283.0,
          equivalents: '1 small pkg = about 283 g'
        },
        us: {
          unitId: 66,
          packageAmount: 1.0,
          amountInBase: 283.0,
          equivalents: '1 pint = about 10 oz'
        }
      },
      nutrition: {
        energy: 18.0,
        ash: 0.5,
        water: 94.52,
        carbs: 3.89,
        fiber: 1.2,
        sugars: 2.63,
        fructose: 1.37,
        glucose: 1.25,
        fat: 0.2,
        monounsaturated: 0.031,
        polyunsaturated: 0.083,
        omega_3: 0.003,
        omega_6: 0.08,
        saturated: 0.028,
        protein: 0.88,
        alanine: 0.027,
        arginine: 0.021,
        aspartic_acid: 0.135,
        cystine: 0.009,
        glutamic_acid: 0.431,
        glycine: 0.019,
        histidine: 0.014,
        isoleucine: 0.018,
        leucine: 0.025,
        lysine: 0.027,
        methionine: 0.006,
        phenylalanine: 0.027,
        proline: 0.015,
        serine: 0.026,
        threonine: 0.027,
        tryptophan: 0.006,
        tyrosine: 0.014,
        valine: 0.018,
        b1_thiamine: 0.037,
        b2_riboflavin: 0.019,
        b3_niacin: 0.594,
        b5_pantothenic_acid: 0.089,
        b6_pyridoxine: 0.08,
        choline: 6.7,
        folate: 15.0,
        vitamin_a: 833.0,
        vitamin_c: 13.7,
        vitamin_e: 0.54,
        vitamin_k: 7.9,
        calcium: 10.0,
        copper: 0.059,
        iron: 0.27,
        magnesium: 11.0,
        manganese: 0.114,
        phosphorus: 24.0,
        potassium: 237.0,
        sodium: 5.0,
        zinc: 0.17
      },
      restrictionWarnings: [],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '320': {
      id: 320,
      name: 'fresh dill',
      pluralName: 'fresh dill',
      description: '',
      sectionId: 4,
      subsectionId: 4,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 6,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: ''
        },
        us: {
          unitId: 6,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 43.0,
        ash: 2.45,
        water: 85.95,
        carbs: 7.02,
        fiber: 2.1,
        starch: 0.49,
        sugars: 4.26,
        fructose: 1.09,
        glucose: 1.64,
        sucrose: 1.53,
        fat: 1.12,
        monounsaturated: 0.802,
        polyunsaturated: 0.095,
        omega_3: 0.013,
        omega_6: 0.082,
        saturated: 0.06,
        protein: 3.46,
        alanine: 0.227,
        arginine: 0.142,
        aspartic_acid: 0.343,
        cystine: 0.017,
        glutamic_acid: 0.29,
        glycine: 0.169,
        histidine: 0.071,
        isoleucine: 0.195,
        leucine: 0.159,
        lysine: 0.246,
        methionine: 0.011,
        phenylalanine: 0.065,
        proline: 0.248,
        serine: 0.158,
        threonine: 0.068,
        tryptophan: 0.014,
        tyrosine: 0.096,
        valine: 0.154,
        b1_thiamine: 0.058,
        b2_riboflavin: 0.296,
        b3_niacin: 1.57,
        b5_pantothenic_acid: 0.397,
        b6_pyridoxine: 0.185,
        choline: 9.3,
        folate: 150.0,
        vitamin_a: 7718.0,
        vitamin_c: 85.0,
        vitamin_e: 1.7,
        vitamin_k: 280.0,
        calcium: 208.0,
        copper: 0.146,
        iron: 6.59,
        magnesium: 55.0,
        manganese: 1.264,
        phosphorus: 66.0,
        potassium: 738.0,
        selenium: 2.0,
        sodium: 61.0,
        zinc: 0.91
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '26': {
      id: 26,
      name: 'asparagus',
      pluralName: 'asparagus',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 6,
          packageAmount: 1.0,
          amountInBase: 240.0,
          equivalents:
            '1 small bunch = about 0.454 kg (10 large, 15 medium, or 20 small spears)'
        },
        us: {
          unitId: 6,
          packageAmount: 1.0,
          amountInBase: 240.0,
          equivalents:
            '1 small bunch = about 1 lb (10 large, 15 medium, or 20 small spears)'
        }
      },
      nutrition: {
        energy: 20.0,
        ash: 0.58,
        water: 93.22,
        carbs: 3.88,
        fiber: 2.1,
        starch: 0.08,
        sugars: 1.88,
        fructose: 1.0,
        glucose: 0.65,
        sucrose: 0.23,
        fat: 0.12,
        polyunsaturated: 0.05,
        omega_3: 0.01,
        omega_6: 0.04,
        saturated: 0.04,
        protein: 2.2,
        alanine: 0.115,
        arginine: 0.091,
        aspartic_acid: 0.508,
        cystine: 0.031,
        glutamic_acid: 0.233,
        glycine: 0.093,
        histidine: 0.049,
        isoleucine: 0.075,
        leucine: 0.128,
        lysine: 0.104,
        methionine: 0.031,
        phenylalanine: 0.075,
        proline: 0.071,
        serine: 0.106,
        threonine: 0.084,
        tryptophan: 0.027,
        tyrosine: 0.052,
        valine: 0.115,
        b1_thiamine: 0.143,
        b2_riboflavin: 0.141,
        b3_niacin: 0.978,
        b5_pantothenic_acid: 0.274,
        b6_pyridoxine: 0.091,
        choline: 16.0,
        folate: 52.0,
        vitamin_a: 756.0,
        vitamin_c: 5.6,
        vitamin_e: 1.13,
        vitamin_k: 41.6,
        calcium: 24.0,
        copper: 0.189,
        iron: 2.14,
        magnesium: 14.0,
        manganese: 0.158,
        phosphorus: 52.0,
        potassium: 202.0,
        selenium: 2.3,
        sodium: 2.0,
        zinc: 0.54
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '140': {
      id: 140,
      name: 'zucchini squash',
      pluralName: 'zucchini squash',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 196.0,
          equivalents: '1 medium zucchini squash = 206 g'
        },
        us: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 196.0,
          equivalents: '1 medium zucchini squash = 7.3 oz / 0.45 lb'
        }
      },
      nutrition: {
        energy: 17.0,
        ash: 0.58,
        water: 94.79,
        carbs: 3.11,
        fiber: 1.0,
        sugars: 2.5,
        fructose: 1.38,
        glucose: 1.07,
        sucrose: 0.05,
        fat: 0.32,
        monounsaturated: 0.011,
        polyunsaturated: 0.091,
        omega_3: 0.061,
        omega_6: 0.03,
        saturated: 0.084,
        protein: 1.21,
        alanine: 0.063,
        arginine: 0.051,
        aspartic_acid: 0.147,
        cystine: 0.012,
        glutamic_acid: 0.129,
        glycine: 0.046,
        histidine: 0.026,
        isoleucine: 0.044,
        leucine: 0.071,
        lysine: 0.067,
        methionine: 0.018,
        phenylalanine: 0.043,
        proline: 0.037,
        serine: 0.049,
        threonine: 0.029,
        tryptophan: 0.01,
        tyrosine: 0.032,
        valine: 0.054,
        b1_thiamine: 0.045,
        b2_riboflavin: 0.094,
        b3_niacin: 0.451,
        b5_pantothenic_acid: 0.204,
        b6_pyridoxine: 0.163,
        choline: 9.5,
        folate: 24.0,
        vitamin_a: 200.0,
        vitamin_c: 17.9,
        vitamin_e: 0.12,
        vitamin_k: 4.3,
        calcium: 16.0,
        copper: 0.053,
        iron: 0.37,
        magnesium: 18.0,
        manganese: 0.177,
        phosphorus: 38.0,
        potassium: 261.0,
        selenium: 0.2,
        sodium: 8.0,
        zinc: 0.32
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '144': {
      id: 144,
      name: 'shallot',
      pluralName: 'shallots',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 72.0,
        ash: 0.8,
        water: 79.8,
        carbs: 16.8,
        fiber: 3.2,
        sugars: 7.87,
        glucose: 0.98,
        sucrose: 6.15,
        fat: 0.1,
        monounsaturated: 0.014,
        polyunsaturated: 0.039,
        omega_3: 0.002,
        omega_6: 0.037,
        saturated: 0.017,
        protein: 2.5,
        alanine: 0.113,
        arginine: 0.181,
        aspartic_acid: 0.231,
        glutamic_acid: 0.517,
        glycine: 0.124,
        histidine: 0.043,
        isoleucine: 0.106,
        leucine: 0.149,
        lysine: 0.125,
        methionine: 0.027,
        phenylalanine: 0.081,
        proline: 0.165,
        serine: 0.113,
        threonine: 0.098,
        tryptophan: 0.028,
        tyrosine: 0.072,
        valine: 0.11,
        b1_thiamine: 0.06,
        b2_riboflavin: 0.02,
        b3_niacin: 0.2,
        b5_pantothenic_acid: 0.29,
        b6_pyridoxine: 0.345,
        choline: 11.3,
        folate: 34.0,
        vitamin_a: 4.0,
        vitamin_c: 8.0,
        vitamin_e: 0.04,
        vitamin_k: 0.8,
        calcium: 37.0,
        copper: 0.088,
        iron: 1.2,
        magnesium: 21.0,
        manganese: 0.292,
        phosphorus: 60.0,
        potassium: 334.0,
        selenium: 1.2,
        sodium: 12.0,
        zinc: 0.4
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '148': {
      id: 148,
      name: 'yellow potato',
      pluralName: 'yellow potatoes',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 213.0,
          equivalents: '1 medium yellow potato = 213 g'
        },
        us: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 213.0,
          equivalents: '1 medium yellow potato = 7.5 oz / 0.5 lb'
        }
      },
      nutrition: {
        energy: 69.0,
        ash: 0.94,
        water: 81.58,
        carbs: 15.71,
        fiber: 2.4,
        starch: 13.49,
        sugars: 1.15,
        fructose: 0.34,
        glucose: 0.53,
        sucrose: 0.28,
        fat: 0.1,
        monounsaturated: 0.002,
        polyunsaturated: 0.043,
        omega_3: 0.01,
        omega_6: 0.032,
        saturated: 0.026,
        protein: 1.68,
        alanine: 0.051,
        arginine: 0.082,
        aspartic_acid: 0.391,
        cystine: 0.02,
        glutamic_acid: 0.286,
        glycine: 0.046,
        histidine: 0.028,
        isoleucine: 0.054,
        leucine: 0.08,
        lysine: 0.087,
        methionine: 0.026,
        phenylalanine: 0.066,
        proline: 0.051,
        serine: 0.061,
        threonine: 0.055,
        tryptophan: 0.017,
        tyrosine: 0.039,
        valine: 0.084,
        b1_thiamine: 0.071,
        b2_riboflavin: 0.034,
        b3_niacin: 1.066,
        b5_pantothenic_acid: 0.281,
        b6_pyridoxine: 0.203,
        choline: 11.0,
        folate: 18.0,
        vitamin_a: 8.0,
        vitamin_c: 9.1,
        vitamin_e: 0.01,
        vitamin_k: 1.6,
        calcium: 9.0,
        copper: 0.116,
        iron: 0.52,
        magnesium: 21.0,
        manganese: 0.145,
        phosphorus: 62.0,
        potassium: 407.0,
        selenium: 0.3,
        sodium: 16.0,
        zinc: 0.29
      },
      restrictionWarnings: [],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '150': {
      id: 150,
      name: 'red bell pepper',
      pluralName: 'red bell peppers',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 119.0,
          equivalents: '1 medium pepper = 145 g'
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 119.0,
          equivalents: '1 medium pepper = 5 oz / 0.32 lb'
        }
      },
      nutrition: {
        energy: 31.0,
        ash: 0.47,
        water: 92.21,
        carbs: 6.03,
        fiber: 2.1,
        sugars: 4.2,
        fructose: 2.26,
        glucose: 1.94,
        fat: 0.3,
        monounsaturated: 0.003,
        polyunsaturated: 0.07,
        omega_3: 0.025,
        omega_6: 0.045,
        saturated: 0.027,
        protein: 0.99,
        alanine: 0.026,
        arginine: 0.036,
        aspartic_acid: 0.284,
        cystine: 0.019,
        glutamic_acid: 0.211,
        glycine: 0.028,
        histidine: 0.017,
        isoleucine: 0.021,
        leucine: 0.036,
        lysine: 0.036,
        methionine: 0.006,
        phenylalanine: 0.05,
        proline: 0.024,
        serine: 0.05,
        threonine: 0.04,
        tryptophan: 0.012,
        tyrosine: 0.009,
        valine: 0.031,
        b1_thiamine: 0.054,
        b2_riboflavin: 0.085,
        b3_niacin: 0.979,
        b5_pantothenic_acid: 0.317,
        b6_pyridoxine: 0.291,
        choline: 5.6,
        folate: 46.0,
        vitamin_a: 3.0,
        vitamin_c: 127.7,
        vitamin_e: 1.58,
        vitamin_k: 4.9,
        calcium: 7.0,
        copper: 0.017,
        iron: 0.43,
        magnesium: 12.0,
        manganese: 0.112,
        phosphorus: 26.0,
        potassium: 211.0,
        selenium: 0.1,
        sodium: 4.0,
        zinc: 0.25
      },
      restrictionWarnings: [],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '151': {
      id: 151,
      name: 'jalapeño pepper',
      pluralName: 'jalapeño peppers',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 14.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 14.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 29.0,
        ash: 0.53,
        water: 91.69,
        carbs: 6.5,
        fiber: 2.8,
        sugars: 4.12,
        fructose: 2.63,
        glucose: 1.48,
        fat: 0.37,
        monounsaturated: 0.029,
        polyunsaturated: 0.112,
        omega_3: 0.05,
        omega_6: 0.062,
        saturated: 0.092,
        protein: 0.91,
        alanine: 0.06,
        arginine: 0.06,
        aspartic_acid: 0.19,
        cystine: 0.03,
        glutamic_acid: 0.18,
        glycine: 0.05,
        histidine: 0.03,
        isoleucine: 0.04,
        leucine: 0.07,
        lysine: 0.06,
        methionine: 0.02,
        phenylalanine: 0.04,
        proline: 0.06,
        serine: 0.05,
        threonine: 0.05,
        tryptophan: 0.02,
        tyrosine: 0.03,
        valine: 0.06,
        b1_thiamine: 0.04,
        b2_riboflavin: 0.07,
        b3_niacin: 1.28,
        b5_pantothenic_acid: 0.315,
        b6_pyridoxine: 0.419,
        choline: 7.5,
        folate: 27.0,
        vitamin_a: 1.0,
        vitamin_c: 118.6,
        vitamin_e: 3.58,
        vitamin_k: 18.5,
        calcium: 12.0,
        copper: 0.046,
        iron: 0.25,
        magnesium: 15.0,
        manganese: 0.097,
        phosphorus: 26.0,
        potassium: 248.0,
        selenium: 0.4,
        sodium: 3.0,
        zinc: 0.14
      },
      restrictionWarnings: [],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '157': {
      id: 157,
      name: 'celery',
      pluralName: 'celery',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 3,
          packageAmount: 1.0,
          amountInBase: 40.0,
          equivalents: ''
        },
        us: {
          unitId: 3,
          packageAmount: 1.0,
          amountInBase: 40.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 16.0,
        ash: 0.75,
        water: 95.43,
        carbs: 2.97,
        fiber: 1.6,
        sugars: 1.34,
        fructose: 0.37,
        galactose: 0.48,
        glucose: 0.4,
        sucrose: 0.08,
        fat: 0.17,
        monounsaturated: 0.032,
        polyunsaturated: 0.079,
        omega_6: 0.079,
        saturated: 0.042,
        protein: 0.69,
        alanine: 0.023,
        arginine: 0.02,
        aspartic_acid: 0.117,
        cystine: 0.004,
        glutamic_acid: 0.09,
        glycine: 0.021,
        histidine: 0.012,
        isoleucine: 0.021,
        leucine: 0.032,
        lysine: 0.027,
        methionine: 0.005,
        phenylalanine: 0.02,
        proline: 0.018,
        serine: 0.02,
        threonine: 0.02,
        tryptophan: 0.009,
        tyrosine: 0.009,
        valine: 0.027,
        b1_thiamine: 0.021,
        b2_riboflavin: 0.057,
        b3_niacin: 0.32,
        b5_pantothenic_acid: 0.246,
        b6_pyridoxine: 0.074,
        choline: 6.1,
        folate: 36.0,
        vitamin_a: 449.0,
        vitamin_c: 3.1,
        vitamin_e: 0.27,
        vitamin_k: 29.3,
        calcium: 40.0,
        copper: 0.035,
        iron: 0.2,
        magnesium: 11.0,
        manganese: 0.103,
        phosphorus: 24.0,
        potassium: 260.0,
        selenium: 0.4,
        sodium: 80.0,
        zinc: 0.13
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '226': {
      id: 226,
      name: 'kale',
      pluralName: 'kale',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 62,
          packageAmount: 1.0,
          amountInBase: 160.0,
          equivalents: ''
        },
        us: {
          unitId: 62,
          packageAmount: 1.0,
          amountInBase: 160.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 50.0,
        ash: 1.53,
        water: 84.46,
        carbs: 10.01,
        fiber: 2.0,
        starch: 0.51,
        sugars: 1.07,
        fructose: 0.2,
        glucose: 0.3,
        sucrose: 0.57,
        fat: 0.7,
        monounsaturated: 0.05,
        polyunsaturated: 0.34,
        omega_3: 0.18,
        omega_6: 0.14,
        saturated: 0.09,
        protein: 3.3,
        alanine: 0.17,
        arginine: 0.18,
        aspartic_acid: 0.3,
        cystine: 0.04,
        glutamic_acid: 0.37,
        glycine: 0.16,
        histidine: 0.07,
        isoleucine: 0.2,
        leucine: 0.23,
        lysine: 0.2,
        methionine: 0.03,
        phenylalanine: 0.17,
        proline: 0.2,
        serine: 0.14,
        threonine: 0.15,
        tryptophan: 0.04,
        tyrosine: 0.12,
        valine: 0.18,
        b1_thiamine: 0.11,
        b2_riboflavin: 0.13,
        b3_niacin: 1.0,
        b5_pantothenic_acid: 0.091,
        b6_pyridoxine: 0.271,
        choline: 0.7,
        folate: 29.0,
        vitamin_a: 15.0,
        vitamin_c: 120.0,
        vitamin_e: 1.7,
        vitamin_k: 817.0,
        calcium: 135.0,
        copper: 0.29,
        iron: 1.7,
        magnesium: 34.0,
        manganese: 0.77,
        phosphorus: 56.0,
        potassium: 447.0,
        selenium: 0.9,
        sodium: 43.0,
        zinc: 0.44
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '183': {
      id: 183,
      name: 'avocado',
      pluralName: 'avocados',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 201.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 201.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 160.0,
        ash: 1.58,
        water: 73.23,
        carbs: 8.53,
        fiber: 6.7,
        starch: 0.11,
        sugars: 0.66,
        fructose: 0.12,
        galactose: 0.1,
        glucose: 0.37,
        sucrose: 0.06,
        fat: 14.66,
        monounsaturated: 9.799,
        polyunsaturated: 1.816,
        omega_3: 0.141,
        omega_6: 1.689,
        saturated: 2.126,
        protein: 2.0,
        alanine: 0.109,
        arginine: 0.088,
        aspartic_acid: 0.236,
        cystine: 0.027,
        glutamic_acid: 0.287,
        glycine: 0.104,
        histidine: 0.049,
        isoleucine: 0.084,
        leucine: 0.143,
        lysine: 0.132,
        methionine: 0.038,
        phenylalanine: 0.097,
        proline: 0.098,
        serine: 0.114,
        threonine: 0.073,
        tryptophan: 0.025,
        tyrosine: 0.049,
        valine: 0.107,
        b1_thiamine: 0.067,
        b2_riboflavin: 0.13,
        b3_niacin: 1.738,
        b5_pantothenic_acid: 1.389,
        b6_pyridoxine: 0.257,
        choline: 14.2,
        folate: 81.0,
        vitamin_a: 146.0,
        vitamin_c: 10.0,
        vitamin_e: 2.07,
        vitamin_k: 21.0,
        calcium: 12.0,
        copper: 0.19,
        iron: 0.55,
        magnesium: 29.0,
        manganese: 0.142,
        phosphorus: 52.0,
        potassium: 485.0,
        selenium: 0.4,
        sodium: 7.0,
        zinc: 0.64
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '317': {
      id: 317,
      name: 'banana',
      pluralName: 'bananas',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 118.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 118.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 89.0,
        ash: 0.82,
        water: 74.91,
        carbs: 22.84,
        fiber: 2.6,
        sugars: 12.23,
        fructose: 4.85,
        glucose: 4.98,
        maltose: 0.01,
        sucrose: 2.39,
        fat: 0.33,
        monounsaturated: 0.032,
        polyunsaturated: 0.073
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '34': {
      id: 34,
      name: 'blackberries',
      pluralName: 'blackberries',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 40,
          packageAmount: 1.0,
          amountInBase: 170.0,
          equivalents: ''
        },
        us: {
          unitId: 16,
          packageAmount: 1.0,
          amountInBase: 170.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 43.0,
        ash: 0.37,
        water: 88.15,
        carbs: 9.61,
        fiber: 5.3,
        sugars: 4.88,
        fructose: 2.4,
        galactose: 0.03,
        glucose: 2.31,
        maltose: 0.07,
        sucrose: 0.07,
        fat: 0.49,
        monounsaturated: 0.047,
        polyunsaturated: 0.28,
        omega_3: 0.094,
        omega_6: 0.186,
        saturated: 0.014,
        protein: 1.39,
        alanine: 0.08,
        aspartic_acid: 0.06,
        glutamic_acid: 0.02,
        proline: 0.01,
        serine: 0.02,
        threonine: 0.01,
        valine: 0.01,
        b1_thiamine: 0.02,
        b2_riboflavin: 0.026,
        b3_niacin: 0.646,
        b5_pantothenic_acid: 0.276,
        b6_pyridoxine: 0.03,
        choline: 8.5,
        folate: 25.0,
        vitamin_a: 214.0,
        vitamin_c: 21.0,
        vitamin_e: 1.43,
        vitamin_k: 19.8,
        calcium: 29.0,
        copper: 0.165,
        iron: 0.62,
        magnesium: 20.0,
        manganese: 0.646,
        phosphorus: 22.0,
        potassium: 162.0,
        selenium: 0.4,
        sodium: 1.0,
        zinc: 0.53
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '159': {
      id: 159,
      name: 'carrot',
      pluralName: 'carrots',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 61.0,
          equivalents: '1 medium carrot = 69 g'
        },
        us: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 61.0,
          equivalents: '1 medium carrot = 2.4 oz / 0.15 lb'
        }
      },
      nutrition: {
        energy: 41.0,
        ash: 0.97,
        water: 88.29,
        carbs: 9.58,
        fiber: 2.8,
        starch: 1.43,
        sugars: 4.74,
        fructose: 0.55,
        glucose: 0.59,
        sucrose: 3.59,
        fat: 0.24,
        monounsaturated: 0.014,
        polyunsaturated: 0.117,
        omega_3: 0.002,
        omega_6: 0.115,
        saturated: 0.037,
        protein: 0.93,
        alanine: 0.113,
        arginine: 0.091,
        aspartic_acid: 0.19,
        cystine: 0.083,
        glutamic_acid: 0.366,
        glycine: 0.047,
        histidine: 0.04,
        isoleucine: 0.077,
        leucine: 0.102,
        lysine: 0.101,
        methionine: 0.02,
        phenylalanine: 0.061,
        proline: 0.054,
        serine: 0.054,
        threonine: 0.191,
        tryptophan: 0.012,
        tyrosine: 0.043,
        valine: 0.069,
        b1_thiamine: 0.066,
        b2_riboflavin: 0.058,
        b3_niacin: 0.983,
        b5_pantothenic_acid: 0.273,
        b6_pyridoxine: 0.138,
        choline: 8.8,
        folate: 19.0,
        vitamin_a: 16.0,
        vitamin_c: 5.9,
        vitamin_e: 0.66,
        vitamin_k: 13.2,
        calcium: 33.0,
        copper: 0.045,
        iron: 0.3,
        magnesium: 12.0,
        manganese: 0.143,
        phosphorus: 35.0,
        potassium: 320.0,
        selenium: 0.1,
        sodium: 69.0,
        zinc: 0.24
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '408': {
      id: 408,
      name: 'stir-fry sauce',
      pluralName: 'stir-fry sauce',
      description: null,
      sectionId: 5,
      subsectionId: null,
      density: 1.2,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 167.0,
        carbs: 33.33,
        sugars: 27.78,
        fat: 2.78,
        vitamin_a: 556.0,
        vitamin_c: 6.7,
        iron: 2.0,
        sodium: 1000.0
      },
      restrictionWarnings: [14, 13, 12, 5, 6, 3, 4],
      restrictionViolations: [10, 9, 1],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '405': {
      id: 405,
      name: 'Caesar dressing',
      pluralName: 'Caesar dressing',
      description: null,
      sectionId: 14,
      subsectionId: null,
      density: 1.01,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 542.0,
        water: 34.3,
        carbs: 3.3,
        fiber: 0.5,
        sugars: 2.81,
        fat: 57.85,
        monounsaturated: 13.504,
        polyunsaturated: 32.857,
        omega_3: 3.853,
        omega_6: 28.97,
        saturated: 8.789,
        cholesterol: 39.0,
        protein: 2.17,
        b1_thiamine: 0.01,
        b2_riboflavin: 0.01,
        b3_niacin: 0.04,
        b6_pyridoxine: 0.025,
        b12_cobalamin: 0.03,
        choline: 15.6,
        folate: 2.0,
        vitamin_c: 0.3,
        vitamin_d: 0.1,
        vitamin_e: 4.74,
        vitamin_k: 104.8,
        calcium: 48.0,
        copper: 0.01,
        iron: 1.08,
        magnesium: 2.0,
        phosphorus: 19.0,
        potassium: 29.0,
        selenium: 1.6,
        sodium: 1209.0,
        zinc: 0.11
      },
      restrictionWarnings: [10, 14, 12, 9, 5, 6, 1],
      restrictionViolations: [13, 11, 2, 3, 4],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '287': {
      id: 287,
      name: 'fresh thyme',
      pluralName: 'fresh thyme',
      description: '',
      sectionId: 4,
      subsectionId: 4,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 8,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: '1 small pkg = 28 g'
        },
        us: {
          unitId: 8,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: '1 small pkg = 1 oz'
        }
      },
      nutrition: {
        energy: 101.0,
        ash: 3.2,
        water: 65.11,
        carbs: 24.45,
        fiber: 14.0,
        starch: 9.8,
        sugars: 0.65,
        fat: 1.68,
        monounsaturated: 0.081,
        polyunsaturated: 0.532,
        omega_3: 0.447,
        omega_6: 0.085,
        saturated: 0.467,
        protein: 5.56,
        isoleucine: 0.285,
        leucine: 0.262,
        lysine: 0.126,
        threonine: 0.154,
        tryptophan: 0.114,
        valine: 0.307,
        b1_thiamine: 0.048,
        b2_riboflavin: 0.471,
        b3_niacin: 1.824,
        b5_pantothenic_acid: 0.409,
        b6_pyridoxine: 0.348,
        choline: 11.4,
        folate: 45.0,
        vitamin_a: 4.0,
        vitamin_c: 160.1,
        vitamin_e: 0.38,
        calcium: 405.0,
        copper: 0.555,
        iron: 17.45,
        magnesium: 160.0,
        manganese: 1.719,
        phosphorus: 106.0,
        potassium: 609.0,
        selenium: 1.74,
        sodium: 9.0,
        zinc: 1.81
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '227': {
      id: 227,
      name: 'kiwifruit',
      pluralName: 'kiwifruit',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 69.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 69.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 61.0,
        ash: 0.61,
        water: 83.07,
        carbs: 14.66,
        fiber: 3.0,
        sugars: 8.99,
        fructose: 4.35,
        galactose: 0.17,
        glucose: 4.11,
        maltose: 0.19,
        sucrose: 0.15,
        fat: 0.52,
        monounsaturated: 0.047,
        polyunsaturated: 0.287,
        omega_3: 0.042,
        omega_6: 0.246,
        saturated: 0.029,
        protein: 1.14,
        alanine: 0.053,
        arginine: 0.081,
        aspartic_acid: 0.126,
        cystine: 0.031,
        glutamic_acid: 0.184,
        glycine: 0.06,
        histidine: 0.027,
        isoleucine: 0.051,
        leucine: 0.066,
        lysine: 0.061,
        methionine: 0.024,
        phenylalanine: 0.044,
        proline: 0.044,
        serine: 0.053,
        threonine: 0.047,
        tryptophan: 0.015,
        tyrosine: 0.034,
        valine: 0.057,
        b1_thiamine: 0.027,
        b2_riboflavin: 0.025,
        b3_niacin: 0.341,
        b5_pantothenic_acid: 0.183,
        b6_pyridoxine: 0.063,
        choline: 7.8,
        folate: 25.0,
        vitamin_a: 87.0,
        vitamin_c: 92.7,
        vitamin_e: 1.46,
        vitamin_k: 40.3,
        calcium: 34.0,
        copper: 0.13,
        iron: 0.31,
        magnesium: 17.0,
        manganese: 0.098,
        phosphorus: 34.0,
        potassium: 312.0,
        selenium: 0.2,
        sodium: 3.0,
        zinc: 0.14
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '192': {
      id: 192,
      name: 'lemon',
      pluralName: 'lemons',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 48.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 48.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 22.0,
        ash: 0.21,
        water: 92.31,
        carbs: 6.9,
        fiber: 0.3,
        sugars: 2.52,
        fructose: 1.1,
        glucose: 0.99,
        sucrose: 0.43,
        fat: 0.24,
        monounsaturated: 0.006,
        polyunsaturated: 0.021,
        omega_3: 0.009,
        omega_6: 0.012,
        saturated: 0.04,
        protein: 0.35,
        b1_thiamine: 0.024,
        b2_riboflavin: 0.015,
        b3_niacin: 0.091,
        b5_pantothenic_acid: 0.131,
        b6_pyridoxine: 0.046,
        choline: 5.1,
        folate: 20.0,
        vitamin_a: 6.0,
        vitamin_c: 38.7,
        vitamin_e: 0.15,
        calcium: 6.0,
        copper: 0.016,
        iron: 0.08,
        magnesium: 6.0,
        manganese: 0.012,
        phosphorus: 8.0,
        potassium: 103.0,
        selenium: 0.1,
        sodium: 1.0,
        zinc: 0.05
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '473': {
      id: 473,
      name: 'flank steak',
      pluralName: 'flank steaks',
      description: null,
      sectionId: 1,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 243.0,
        water: 55.6,
        fat: 14.1,
        monounsaturated: 6.23,
        polyunsaturated: 0.611,
        omega_3: 0.011,
        omega_6: 0.497,
        saturated: 5.02,
        cholesterol: 91.0,
        protein: 29.1,
        b1_thiamine: 0.082,
        b2_riboflavin: 0.345,
        b3_niacin: 6.29,
        b6_pyridoxine: 0.479,
        b12_cobalamin: 2.89,
        choline: 59.8,
        folate: 7.0,
        vitamin_d: 0.1,
        vitamin_e: 0.1,
        vitamin_k: 1.6,
        calcium: 7.0,
        copper: 0.107,
        iron: 2.42,
        magnesium: 23.0,
        phosphorus: 167.0,
        potassium: 296.0,
        selenium: 33.0,
        sodium: 370.0,
        zinc: 8.0
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '243': {
      id: 243,
      name: 'fresh mint',
      pluralName: 'fresh mint',
      description: '',
      sectionId: 4,
      subsectionId: 4,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 8,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: '1 small pkg = 28 g'
        },
        us: {
          unitId: 8,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: '1 small pkg = 1 oz'
        }
      },
      nutrition: {
        energy: 44.0,
        ash: 2.03,
        water: 85.55,
        carbs: 8.41,
        fiber: 6.8,
        sugars: 1.62,
        fructose: 0.54,
        glucose: 0.54,
        sucrose: 0.54,
        fat: 0.73,
        monounsaturated: 0.025,
        polyunsaturated: 0.394,
        omega_3: 0.338,
        omega_6: 0.054,
        saturated: 0.191,
        protein: 3.29,
        alanine: 0.171,
        arginine: 0.151,
        aspartic_acid: 0.388,
        cystine: 0.036,
        glutamic_acid: 0.358,
        glycine: 0.158,
        histidine: 0.066,
        isoleucine: 0.135,
        leucine: 0.247,
        lysine: 0.141,
        methionine: 0.046,
        phenylalanine: 0.168,
        proline: 0.135,
        serine: 0.128,
        threonine: 0.135,
        tryptophan: 0.05,
        tyrosine: 0.099,
        valine: 0.164,
        b1_thiamine: 0.078,
        b2_riboflavin: 0.175,
        b3_niacin: 0.948,
        b5_pantothenic_acid: 0.25,
        b6_pyridoxine: 0.158,
        choline: 11.4,
        folate: 105.0,
        vitamin_a: 4.0,
        vitamin_c: 13.3,
        vitamin_e: 4.86,
        vitamin_k: 230.0,
        calcium: 199.0,
        copper: 0.24,
        iron: 11.87,
        magnesium: 63.0,
        manganese: 1.118,
        phosphorus: 60.0,
        potassium: 458.0,
        selenium: 16.18,
        sodium: 30.0,
        zinc: 1.09
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '236': {
      id: 236,
      name: 'pineapple',
      pluralName: 'pineapples',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 83,
          packageAmount: 1.0,
          amountInBase: 463.0,
          equivalents: '1 small pineapple = about 907 g'
        },
        us: {
          unitId: 83,
          packageAmount: 1.0,
          amountInBase: 463.0,
          equivalents: '1 small pineapple = about 2 lb'
        }
      },
      nutrition: {
        energy: 50.0,
        ash: 0.22,
        water: 86.0,
        carbs: 13.12,
        fiber: 1.4,
        sugars: 9.85,
        fructose: 2.12,
        glucose: 1.73,
        sucrose: 5.99,
        fat: 0.12,
        monounsaturated: 0.013,
        polyunsaturated: 0.04,
        omega_3: 0.017,
        omega_6: 0.023,
        saturated: 0.009,
        protein: 0.54,
        alanine: 0.033,
        arginine: 0.019,
        aspartic_acid: 0.121,
        cystine: 0.014,
        glutamic_acid: 0.079,
        glycine: 0.024,
        histidine: 0.01,
        isoleucine: 0.019,
        leucine: 0.024,
        lysine: 0.026,
        methionine: 0.012,
        phenylalanine: 0.021,
        proline: 0.017,
        serine: 0.035,
        threonine: 0.019,
        tryptophan: 0.005,
        tyrosine: 0.019,
        valine: 0.024,
        b1_thiamine: 0.079,
        b2_riboflavin: 0.032,
        b3_niacin: 0.5,
        b5_pantothenic_acid: 0.213,
        b6_pyridoxine: 0.112,
        choline: 5.5,
        folate: 18.0,
        vitamin_a: 58.0,
        vitamin_c: 47.8,
        vitamin_e: 0.02,
        vitamin_k: 0.7,
        calcium: 13.0,
        copper: 0.11,
        iron: 0.29,
        magnesium: 12.0,
        manganese: 0.927,
        phosphorus: 8.0,
        potassium: 109.0,
        selenium: 0.1,
        sodium: 1.0,
        zinc: 0.12
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '239': {
      id: 239,
      name: 'raspberries',
      pluralName: 'raspberries',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 40,
          packageAmount: 1.0,
          amountInBase: 170.0,
          equivalents: ''
        },
        us: {
          unitId: 16,
          packageAmount: 1.0,
          amountInBase: 170.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 52.0,
        ash: 0.46,
        water: 85.75,
        carbs: 11.94,
        fiber: 6.5,
        sugars: 4.42,
        fructose: 2.35,
        glucose: 1.86,
        sucrose: 0.2,
        fat: 0.65,
        monounsaturated: 0.064,
        polyunsaturated: 0.375,
        omega_3: 0.126,
        omega_6: 0.249,
        saturated: 0.019,
        protein: 1.2,
        b1_thiamine: 0.032,
        b2_riboflavin: 0.038,
        b3_niacin: 0.598,
        b5_pantothenic_acid: 0.329,
        b6_pyridoxine: 0.055,
        choline: 12.3,
        folate: 21.0,
        vitamin_a: 33.0,
        vitamin_c: 26.2,
        vitamin_e: 0.87,
        vitamin_k: 7.8,
        calcium: 25.0,
        copper: 0.09,
        iron: 0.69,
        magnesium: 22.0,
        manganese: 0.67,
        phosphorus: 29.0,
        potassium: 151.0,
        selenium: 0.2,
        sodium: 1.0,
        zinc: 0.42
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '114': {
      id: 114,
      name: 'blueberries',
      pluralName: 'blueberries',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 40,
          packageAmount: 1.0,
          amountInBase: 170.0,
          equivalents: ''
        },
        us: {
          unitId: 16,
          packageAmount: 1.0,
          amountInBase: 170.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 57.0,
        ash: 0.24,
        water: 84.21,
        carbs: 14.49,
        fiber: 2.4,
        starch: 0.03,
        sugars: 9.96,
        fructose: 4.97,
        glucose: 4.88,
        sucrose: 0.11,
        fat: 0.33,
        monounsaturated: 0.047,
        polyunsaturated: 0.146,
        omega_3: 0.058,
        omega_6: 0.088,
        saturated: 0.028,
        protein: 0.74,
        alanine: 0.031,
        arginine: 0.037,
        aspartic_acid: 0.057,
        cystine: 0.008,
        glutamic_acid: 0.091,
        glycine: 0.031,
        histidine: 0.011,
        isoleucine: 0.023,
        leucine: 0.044,
        lysine: 0.013,
        methionine: 0.012,
        phenylalanine: 0.026,
        proline: 0.028,
        serine: 0.022,
        threonine: 0.02,
        tryptophan: 0.003,
        tyrosine: 0.009,
        valine: 0.031,
        b1_thiamine: 0.037,
        b2_riboflavin: 0.041,
        b3_niacin: 0.418,
        b5_pantothenic_acid: 0.124,
        b6_pyridoxine: 0.052,
        choline: 6.0,
        folate: 6.0,
        vitamin_a: 54.0,
        vitamin_c: 9.7,
        vitamin_e: 0.57,
        vitamin_k: 19.3,
        calcium: 6.0,
        copper: 0.057,
        iron: 0.28,
        magnesium: 6.0,
        manganese: 0.336,
        phosphorus: 12.0,
        potassium: 77.0,
        selenium: 0.1,
        sodium: 1.0,
        zinc: 0.16
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '308': {
      id: 308,
      name: 'butter (Boston) lettuce',
      pluralName: 'butter (Boston) lettuce',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 5,
          packageAmount: 1.0,
          amountInBase: 163.0,
          equivalents: ''
        },
        us: {
          unitId: 5,
          packageAmount: 1.0,
          amountInBase: 163.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 13.0,
        ash: 0.57,
        water: 95.63,
        carbs: 2.23,
        fiber: 1.1,
        sugars: 0.94,
        fructose: 0.51,
        glucose: 0.43,
        fat: 0.22,
        monounsaturated: 0.008,
        polyunsaturated: 0.117,
        omega_3: 0.083,
        omega_6: 0.034,
        saturated: 0.029,
        protein: 1.35,
        alanine: 0.054,
        arginine: 0.051,
        aspartic_acid: 0.142,
        cystine: 0.009,
        glutamic_acid: 0.206,
        glycine: 0.044,
        histidine: 0.017,
        isoleucine: 0.039,
        leucine: 0.071,
        lysine: 0.056,
        methionine: 0.014,
        phenylalanine: 0.053,
        proline: 0.037,
        serine: 0.046,
        threonine: 0.041,
        tryptophan: 0.013,
        tyrosine: 0.019,
        valine: 0.054,
        b1_thiamine: 0.057,
        b2_riboflavin: 0.062,
        b3_niacin: 0.357,
        b5_pantothenic_acid: 0.15,
        b6_pyridoxine: 0.082,
        choline: 8.4,
        folate: 73.0,
        vitamin_a: 3.0,
        vitamin_c: 3.7,
        vitamin_e: 0.23,
        vitamin_k: 102.3,
        calcium: 35.0,
        copper: 0.016,
        iron: 1.24,
        magnesium: 13.0,
        manganese: 0.179,
        phosphorus: 33.0,
        potassium: 238.0,
        selenium: 0.6,
        sodium: 5.0,
        zinc: 0.2
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '309': {
      id: 309,
      name: 'leek',
      pluralName: 'leeks',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 2.0,
          amountInBase: 89.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 89.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 61.0,
        ash: 1.05,
        water: 83.0,
        carbs: 14.15,
        fiber: 1.8,
        starch: 1.15,
        sugars: 3.9,
        fructose: 1.6,
        glucose: 1.42,
        sucrose: 0.84,
        fat: 0.3,
        monounsaturated: 0.004,
        polyunsaturated: 0.166,
        omega_3: 0.099,
        omega_6: 0.067,
        saturated: 0.04,
        protein: 1.5,
        alanine: 0.074,
        arginine: 0.078,
        aspartic_acid: 0.14,
        cystine: 0.025,
        glutamic_acid: 0.226,
        glycine: 0.069,
        histidine: 0.025,
        isoleucine: 0.052,
        leucine: 0.096,
        lysine: 0.078,
        methionine: 0.018,
        phenylalanine: 0.055,
        proline: 0.066,
        serine: 0.092,
        threonine: 0.063,
        tryptophan: 0.012,
        tyrosine: 0.041,
        valine: 0.056,
        b1_thiamine: 0.06,
        b2_riboflavin: 0.03,
        b3_niacin: 0.4,
        b5_pantothenic_acid: 0.14,
        b6_pyridoxine: 0.233,
        choline: 9.5,
        folate: 64.0,
        vitamin_a: 1.0,
        vitamin_c: 12.0,
        vitamin_e: 0.92,
        vitamin_k: 47.0,
        calcium: 59.0,
        copper: 0.12,
        iron: 2.1,
        magnesium: 28.0,
        manganese: 0.481,
        phosphorus: 35.0,
        potassium: 180.0,
        selenium: 1.0,
        sodium: 20.0,
        zinc: 0.12
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '329': {
      id: 329,
      name: 'prosciutto',
      pluralName: 'prosciutto',
      description: null,
      sectionId: 7,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 67,
          packageAmount: 1.0,
          amountInBase: 14.0,
          equivalents: '8 slices = 100 g'
        },
        us: {
          unitId: 67,
          packageAmount: 1.0,
          amountInBase: 14.0,
          equivalents: '4 slices = 0.1 lb'
        }
      },
      nutrition: {
        energy: 214.0,
        carbs: 3.57,
        sugars: 3.57,
        fat: 12.5,
        saturated: 5.36,
        cholesterol: 71.0,
        protein: 25.0,
        iron: 1.29,
        sodium: 1857.0
      },
      restrictionWarnings: [14, 1],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '340': {
      id: 340,
      name: 'vegan mayonnaise',
      pluralName: 'vegan mayonnaise',
      description: null,
      sectionId: 14,
      subsectionId: null,
      density: 0.9333,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 717.0,
        ash: 1.5,
        water: 15.3,
        carbs: 2.7,
        sugars: 0.48,
        fat: 79.4,
        monounsaturated: 13.0,
        polyunsaturated: 55.0,
        omega_3: 3.0,
        omega_6: 52.0,
        saturated: 8.6,
        cholesterol: 59.0,
        protein: 1.1,
        alanine: 0.061,
        arginine: 0.072,
        aspartic_acid: 0.094,
        cystine: 0.023,
        glutamic_acid: 0.143,
        glycine: 0.037,
        histidine: 0.026,
        isoleucine: 0.065,
        leucine: 0.095,
        lysine: 0.072,
        methionine: 0.035,
        phenylalanine: 0.057,
        proline: 0.044,
        serine: 0.088,
        threonine: 0.055,
        tryptophan: 0.017,
        tyrosine: 0.046,
        valine: 0.074,
        b3_niacin: 0.005,
        b5_pantothenic_acid: 0.297,
        b6_pyridoxine: 0.577,
        b12_cobalamin: 0.26,
        folate: 8.0,
        vitamin_a: 280.0,
        vitamin_e: 22.0,
        vitamin_k: 24.7,
        calcium: 18.0,
        iron: 0.5,
        magnesium: 1.0,
        phosphorus: 28.0,
        potassium: 34.0,
        selenium: 1.6,
        sodium: 568.0,
        zinc: 0.12
      },
      restrictionWarnings: [10, 14, 13, 12, 9],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '306': {
      id: 306,
      name: 'chili-garlic sauce',
      pluralName: 'chili-garlic sauce',
      description: '',
      sectionId: 5,
      subsectionId: null,
      density: 0.953555441,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 104.0,
        ash: 9.47,
        water: 68.0,
        carbs: 19.79,
        fiber: 5.9,
        starch: 0.01,
        sugars: 10.54,
        fructose: 3.09,
        glucose: 2.84,
        sucrose: 4.59,
        fat: 0.3,
        monounsaturated: 0.04,
        polyunsaturated: 0.15,
        omega_3: 0.01,
        omega_6: 0.14,
        saturated: 0.05,
        protein: 2.5,
        alanine: 0.07,
        arginine: 0.07,
        aspartic_acid: 0.34,
        cystine: 0.03,
        glutamic_acid: 0.91,
        glycine: 0.06,
        histidine: 0.04,
        isoleucine: 0.06,
        leucine: 0.09,
        lysine: 0.09,
        methionine: 0.02,
        phenylalanine: 0.07,
        proline: 0.05,
        serine: 0.07,
        threonine: 0.06,
        tryptophan: 0.02,
        tyrosine: 0.04,
        valine: 0.07,
        b1_thiamine: 0.09,
        b2_riboflavin: 0.07,
        b3_niacin: 1.6,
        b5_pantothenic_acid: 0.72,
        b6_pyridoxine: 0.16,
        choline: 13.7,
        folate: 9.0,
        vitamin_a: 680.0,
        vitamin_c: 16.0,
        vitamin_e: 1.92,
        vitamin_k: 5.2,
        calcium: 20.0,
        copper: 0.13,
        iron: 0.8,
        magnesium: 12.0,
        manganese: 0.32,
        phosphorus: 52.0,
        potassium: 370.0,
        selenium: 1.1,
        sodium: 1.0,
        zinc: 0.16
      },
      restrictionWarnings: [],
      restrictionViolations: [14, 10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '343': {
      id: 343,
      name: 'radish',
      pluralName: 'radish',
      description: null,
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 62,
          packageAmount: 1.0,
          amountInBase: 227.0,
          equivalents: '1 bunch = 10-12 radishes'
        },
        us: {
          unitId: 62,
          packageAmount: 1.0,
          amountInBase: 227.0,
          equivalents: '1 bunch = 10-12 radishes'
        }
      },
      nutrition: {
        energy: 16.0,
        ash: 0.55,
        water: 95.27,
        carbs: 3.4,
        fiber: 1.6,
        sugars: 1.86,
        fructose: 0.71,
        glucose: 1.05,
        sucrose: 0.1,
        fat: 0.1,
        monounsaturated: 0.017,
        polyunsaturated: 0.048,
        omega_3: 0.031,
        omega_6: 0.017,
        saturated: 0.032,
        protein: 0.68,
        alanine: 0.026,
        arginine: 0.038,
        aspartic_acid: 0.064,
        cystine: 0.01,
        glutamic_acid: 0.157,
        glycine: 0.026,
        histidine: 0.013,
        isoleucine: 0.02,
        leucine: 0.031,
        lysine: 0.033,
        methionine: 0.01,
        phenylalanine: 0.036,
        proline: 0.022,
        serine: 0.027,
        threonine: 0.023,
        tryptophan: 0.009,
        tyrosine: 0.009,
        valine: 0.035,
        b1_thiamine: 0.012,
        b2_riboflavin: 0.039,
        b3_niacin: 0.254,
        b5_pantothenic_acid: 0.165,
        b6_pyridoxine: 0.071,
        choline: 6.5,
        folate: 25.0,
        vitamin_a: 7.0,
        vitamin_c: 14.8,
        vitamin_k: 1.3,
        calcium: 25.0,
        copper: 0.05,
        iron: 0.34,
        magnesium: 10.0,
        manganese: 0.069,
        phosphorus: 20.0,
        potassium: 233.0,
        selenium: 0.6,
        sodium: 39.0,
        zinc: 0.28
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '342': {
      id: 342,
      name: 'smoked salmon',
      pluralName: 'smoked salmon',
      description: null,
      sectionId: 1,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 38,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 14,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 117.0,
        ash: 2.62,
        water: 72.0,
        fat: 4.32,
        monounsaturated: 2.023,
        polyunsaturated: 0.995,
        omega_6: 0.472,
        saturated: 0.929,
        cholesterol: 23.0,
        protein: 18.28,
        alanine: 1.106,
        arginine: 1.094,
        aspartic_acid: 1.872,
        cystine: 0.196,
        glutamic_acid: 2.729,
        glycine: 0.878,
        histidine: 0.538,
        isoleucine: 0.842,
        leucine: 1.486,
        lysine: 1.679,
        methionine: 0.541,
        phenylalanine: 0.714,
        proline: 0.646,
        serine: 0.746,
        threonine: 0.801,
        tryptophan: 0.205,
        tyrosine: 0.617,
        valine: 0.942,
        b1_thiamine: 0.023,
        b2_riboflavin: 0.101,
        b3_niacin: 4.72,
        b5_pantothenic_acid: 0.87,
        b6_pyridoxine: 0.278,
        b12_cobalamin: 3.26,
        folate: 2.0,
        vitamin_a: 88.0,
        calcium: 11.0,
        copper: 0.23,
        iron: 0.85,
        magnesium: 18.0,
        manganese: 0.017,
        phosphorus: 164.0,
        potassium: 175.0,
        selenium: 38.1,
        sodium: 2000.0,
        zinc: 0.31
      },
      restrictionWarnings: [],
      restrictionViolations: [3],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '296': {
      id: 296,
      name: 'Gala apple',
      pluralName: 'Gala apples',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 182.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 182.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 52.0,
        ash: 0.19,
        water: 85.56,
        carbs: 13.81,
        fiber: 2.4,
        starch: 0.05,
        sugars: 10.39,
        fructose: 5.9,
        glucose: 2.43,
        sucrose: 2.07,
        fat: 0.17,
        monounsaturated: 0.007,
        polyunsaturated: 0.051,
        omega_3: 0.009,
        omega_6: 0.043,
        saturated: 0.028,
        protein: 0.26,
        alanine: 0.011,
        arginine: 0.006,
        aspartic_acid: 0.07,
        cystine: 0.001,
        glutamic_acid: 0.025,
        glycine: 0.009,
        histidine: 0.005,
        isoleucine: 0.006,
        leucine: 0.013,
        lysine: 0.012,
        methionine: 0.001,
        phenylalanine: 0.006,
        proline: 0.006,
        serine: 0.01,
        threonine: 0.006,
        tryptophan: 0.001,
        tyrosine: 0.001,
        valine: 0.012,
        b1_thiamine: 0.017,
        b2_riboflavin: 0.026,
        b3_niacin: 0.091,
        b5_pantothenic_acid: 0.061,
        b6_pyridoxine: 0.041,
        choline: 3.4,
        folate: 3.0,
        vitamin_a: 54.0,
        vitamin_c: 4.6,
        vitamin_e: 0.18,
        vitamin_k: 2.2,
        calcium: 6.0,
        copper: 0.027,
        iron: 0.12,
        magnesium: 5.0,
        manganese: 0.035,
        phosphorus: 11.0,
        potassium: 107.0,
        sodium: 1.0,
        zinc: 0.04
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '328': {
      id: 328,
      name: 'almond milk, unsweetened',
      pluralName: 'almond milk, unsweetened',
      description: null,
      sectionId: 2,
      subsectionId: null,
      density: 1.11,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 60,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 15.0,
        ash: 0.79,
        water: 96.54,
        carbs: 1.31,
        fiber: 0.2,
        starch: 0.07,
        sugars: 0.81,
        sucrose: 0.81,
        fat: 0.96,
        monounsaturated: 0.59,
        polyunsaturated: 0.24,
        omega_6: 0.252,
        saturated: 0.08,
        protein: 0.4,
        b2_riboflavin: 0.01,
        b3_niacin: 0.07,
        b5_pantothenic_acid: 0.01,
        choline: 3.1,
        folate: 1.0,
        vitamin_d: 41.0,
        vitamin_e: 6.33,
        calcium: 184.0,
        copper: 0.02,
        iron: 0.28,
        magnesium: 6.0,
        manganese: 0.04,
        phosphorus: 9.0,
        potassium: 67.0,
        selenium: 0.1,
        sodium: 72.0,
        zinc: 0.06
      },
      restrictionWarnings: [],
      restrictionViolations: [6],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '229': {
      id: 229,
      name: 'mango',
      pluralName: 'mangoes',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 336.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 336.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 60.0,
        ash: 0.36,
        water: 83.46,
        carbs: 14.98,
        fiber: 1.6,
        starch: 0.23,
        sugars: 13.66,
        fructose: 4.68,
        glucose: 2.01,
        sucrose: 6.97,
        fat: 0.38,
        monounsaturated: 0.14,
        polyunsaturated: 0.071,
        omega_3: 0.051,
        omega_6: 0.019,
        saturated: 0.092,
        protein: 0.82,
        alanine: 0.082,
        arginine: 0.031,
        aspartic_acid: 0.068,
        glutamic_acid: 0.096,
        glycine: 0.034,
        histidine: 0.019,
        isoleucine: 0.029,
        leucine: 0.05,
        lysine: 0.066,
        methionine: 0.008,
        phenylalanine: 0.027,
        proline: 0.029,
        serine: 0.035,
        threonine: 0.031,
        tryptophan: 0.013,
        tyrosine: 0.016,
        valine: 0.042,
        b1_thiamine: 0.028,
        b2_riboflavin: 0.038,
        b3_niacin: 0.669,
        b5_pantothenic_acid: 0.197,
        b6_pyridoxine: 0.119,
        choline: 7.6,
        folate: 43.0,
        vitamin_a: 1.0,
        vitamin_c: 36.4,
        vitamin_e: 0.9,
        vitamin_k: 4.2,
        calcium: 11.0,
        copper: 0.111,
        iron: 0.16,
        magnesium: 10.0,
        manganese: 0.063,
        phosphorus: 14.0,
        potassium: 168.0,
        selenium: 0.6,
        sodium: 1.0,
        zinc: 0.09
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '232': {
      id: 232,
      name: 'orange',
      pluralName: 'oranges',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 131.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 131.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 47.0,
        ash: 0.44,
        water: 86.75,
        carbs: 11.75,
        fiber: 2.4,
        sugars: 9.35,
        fructose: 2.03,
        glucose: 1.88,
        sucrose: 4.46,
        fat: 0.12,
        monounsaturated: 0.023,
        polyunsaturated: 0.025,
        omega_3: 0.007,
        omega_6: 0.018,
        saturated: 0.015,
        protein: 0.94,
        alanine: 0.05,
        arginine: 0.065,
        aspartic_acid: 0.114,
        cystine: 0.01,
        glutamic_acid: 0.094,
        glycine: 0.094,
        histidine: 0.018,
        isoleucine: 0.025,
        leucine: 0.023,
        lysine: 0.047,
        methionine: 0.02,
        phenylalanine: 0.031,
        proline: 0.046,
        serine: 0.032,
        threonine: 0.015,
        tryptophan: 0.009,
        tyrosine: 0.016,
        valine: 0.04,
        b1_thiamine: 0.087,
        b2_riboflavin: 0.04,
        b3_niacin: 0.282,
        b5_pantothenic_acid: 0.25,
        b6_pyridoxine: 0.06,
        choline: 8.4,
        folate: 30.0,
        vitamin_a: 225.0,
        vitamin_c: 53.2,
        vitamin_e: 0.18,
        calcium: 40.0,
        copper: 0.045,
        iron: 0.1,
        magnesium: 10.0,
        manganese: 0.025,
        phosphorus: 14.0,
        potassium: 181.0,
        selenium: 0.5,
        zinc: 0.07
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '234': {
      id: 234,
      name: 'peach',
      pluralName: 'peaches',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 150.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 150.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 39.0,
        ash: 0.43,
        water: 88.87,
        carbs: 9.54,
        fiber: 1.5,
        sugars: 8.39,
        fructose: 1.53,
        galactose: 0.06,
        glucose: 1.95,
        maltose: 0.08,
        sucrose: 4.76,
        fat: 0.25,
        monounsaturated: 0.067,
        polyunsaturated: 0.086,
        omega_3: 0.002,
        omega_6: 0.084,
        saturated: 0.019,
        protein: 0.91,
        alanine: 0.028,
        arginine: 0.018,
        aspartic_acid: 0.418,
        cystine: 0.012,
        glutamic_acid: 0.056,
        glycine: 0.021,
        histidine: 0.013,
        isoleucine: 0.017,
        leucine: 0.027,
        lysine: 0.03,
        methionine: 0.01,
        phenylalanine: 0.019,
        proline: 0.018,
        serine: 0.032,
        threonine: 0.016,
        tryptophan: 0.01,
        tyrosine: 0.014,
        valine: 0.022,
        b1_thiamine: 0.024,
        b2_riboflavin: 0.031,
        b3_niacin: 0.806,
        b5_pantothenic_acid: 0.153,
        b6_pyridoxine: 0.025,
        choline: 6.1,
        folate: 4.0,
        vitamin_a: 326.0,
        vitamin_c: 6.6,
        vitamin_e: 0.73,
        vitamin_k: 2.6,
        calcium: 6.0,
        copper: 0.068,
        iron: 0.25,
        magnesium: 9.0,
        manganese: 0.061,
        phosphorus: 20.0,
        potassium: 190.0,
        selenium: 0.1,
        zinc: 0.17
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '247': {
      id: 247,
      name: 'pear',
      pluralName: 'pears',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 178.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 178.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 57.0,
        ash: 0.32,
        water: 83.96,
        carbs: 15.23,
        fiber: 3.1,
        starch: 2.38,
        sugars: 9.75,
        fructose: 6.42,
        glucose: 2.6,
        sucrose: 0.71,
        fat: 0.14,
        monounsaturated: 0.084,
        polyunsaturated: 0.094,
        omega_3: 0.001,
        omega_6: 0.093,
        saturated: 0.022,
        protein: 0.36,
        alanine: 0.014,
        arginine: 0.01,
        aspartic_acid: 0.105,
        cystine: 0.002,
        glutamic_acid: 0.03,
        glycine: 0.013,
        histidine: 0.002,
        isoleucine: 0.011,
        leucine: 0.019,
        lysine: 0.017,
        methionine: 0.002,
        phenylalanine: 0.011,
        proline: 0.021,
        serine: 0.015,
        threonine: 0.011,
        tryptophan: 0.002,
        tyrosine: 0.002,
        valine: 0.017,
        b1_thiamine: 0.012,
        b2_riboflavin: 0.026,
        b3_niacin: 0.161,
        b5_pantothenic_acid: 0.049,
        b6_pyridoxine: 0.029,
        choline: 5.1,
        folate: 7.0,
        vitamin_a: 25.0,
        vitamin_c: 4.3,
        vitamin_e: 0.12,
        vitamin_k: 4.4,
        calcium: 9.0,
        copper: 0.082,
        iron: 0.18,
        magnesium: 7.0,
        manganese: 0.048,
        phosphorus: 12.0,
        potassium: 116.0,
        selenium: 0.1,
        sodium: 1.0,
        zinc: 0.1
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '242': {
      id: 242,
      name: 'strawberries',
      pluralName: 'strawberries',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 72,
          packageAmount: 1.0,
          amountInBase: 454.0,
          equivalents: '0.454 kg = 12-36 strawberries (depending on size)'
        },
        us: {
          unitId: 70,
          packageAmount: 1.0,
          amountInBase: 454.0,
          equivalents: '16 oz = 12-36 strawberries (depending on size)'
        }
      },
      nutrition: {
        energy: 32.0,
        ash: 0.4,
        water: 90.95,
        carbs: 7.68,
        fiber: 2.0,
        starch: 0.04,
        sugars: 4.89,
        fructose: 2.44,
        galactose: 0.01,
        glucose: 1.99,
        sucrose: 0.47,
        fat: 0.3,
        monounsaturated: 0.043,
        polyunsaturated: 0.155,
        omega_3: 0.065,
        omega_6: 0.09,
        saturated: 0.015,
        protein: 0.67,
        alanine: 0.033,
        arginine: 0.028,
        aspartic_acid: 0.149,
        cystine: 0.006,
        glutamic_acid: 0.098,
        glycine: 0.026,
        histidine: 0.012,
        isoleucine: 0.016,
        leucine: 0.034,
        lysine: 0.026,
        methionine: 0.002,
        phenylalanine: 0.019,
        proline: 0.02,
        serine: 0.025,
        threonine: 0.02,
        tryptophan: 0.008,
        tyrosine: 0.022,
        valine: 0.019,
        b1_thiamine: 0.024,
        b2_riboflavin: 0.022,
        b3_niacin: 0.386,
        b5_pantothenic_acid: 0.125,
        b6_pyridoxine: 0.047,
        choline: 5.7,
        folate: 24.0,
        vitamin_a: 12.0,
        vitamin_c: 58.8,
        vitamin_e: 0.29,
        vitamin_k: 2.2,
        calcium: 16.0,
        copper: 0.048,
        iron: 0.41,
        magnesium: 13.0,
        manganese: 0.386,
        phosphorus: 24.0,
        potassium: 153.0,
        selenium: 0.4,
        sodium: 1.0,
        zinc: 0.14
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '57': {
      id: 57,
      name: 'mozzarella cheese',
      pluralName: 'mozzarella cheese',
      description: '',
      sectionId: 2,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 69,
          packageAmount: 0.5,
          amountInBase: 227.0,
          equivalents: ''
        },
        us: {
          unitId: 68,
          packageAmount: 0.5,
          amountInBase: 227.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 300.0,
        ash: 3.28,
        water: 50.01,
        carbs: 2.19,
        sugars: 1.03,
        fructose: 0.15,
        galactose: 0.15,
        glucose: 0.56,
        lactose: 0.07,
        maltose: 0.08,
        sucrose: 0.03,
        fat: 22.35,
        monounsaturated: 6.573,
        polyunsaturated: 0.765,
        omega_3: 0.372,
        omega_6: 0.393,
        saturated: 13.152,
        transfats: 0.535,
        cholesterol: 79.0,
        protein: 22.17,
        alanine: 0.707,
        arginine: 0.515,
        aspartic_acid: 1.633,
        cystine: 0.115,
        glutamic_acid: 4.458,
        glycine: 0.515,
        histidine: 0.515,
        isoleucine: 1.135,
        leucine: 1.826,
        lysine: 0.965,
        methionine: 0.515,
        phenylalanine: 1.011,
        proline: 2.351,
        serine: 0.735,
        threonine: 0.983,
        tryptophan: 0.515,
        tyrosine: 1.043,
        valine: 1.322,
        b1_thiamine: 0.03,
        b2_riboflavin: 0.283,
        b3_niacin: 0.104,
        b5_pantothenic_acid: 0.141,
        b6_pyridoxine: 0.037,
        b12_cobalamin: 2.28,
        choline: 15.4,
        folate: 7.0,
        vitamin_a: 676.0,
        vitamin_d: 16.0,
        vitamin_e: 0.19,
        vitamin_k: 2.3,
        calcium: 505.0,
        copper: 0.011,
        iron: 0.44,
        magnesium: 20.0,
        manganese: 0.03,
        phosphorus: 354.0,
        potassium: 76.0,
        selenium: 17.0,
        sodium: 627.0,
        zinc: 2.92
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '330': {
      id: 330,
      name: 'edamame, shelled',
      pluralName: 'edamame',
      description: null,
      sectionId: 13,
      subsectionId: null,
      density: 0.655,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 121.0,
        ash: 1.21,
        water: 72.77,
        carbs: 8.91,
        fiber: 5.2,
        starch: 1.51,
        sugars: 2.18,
        fructose: 0.12,
        maltose: 0.95,
        sucrose: 1.12,
        fat: 5.2,
        monounsaturated: 1.282,
        polyunsaturated: 2.156,
        omega_3: 0.358,
        omega_6: 1.792,
        saturated: 0.62,
        transfats: 0.009,
        protein: 11.91,
        alanine: 0.446,
        arginine: 0.724,
        aspartic_acid: 1.348,
        cystine: 0.124,
        glutamic_acid: 2.02,
        glycine: 0.434,
        histidine: 0.267,
        isoleucine: 0.3,
        leucine: 0.745,
        lysine: 0.745,
        methionine: 0.141,
        phenylalanine: 0.488,
        proline: 0.686,
        serine: 0.665,
        threonine: 0.331,
        tryptophan: 0.126,
        tyrosine: 0.336,
        valine: 0.324,
        b1_thiamine: 0.2,
        b2_riboflavin: 0.155,
        b3_niacin: 0.915,
        b5_pantothenic_acid: 0.395,
        b6_pyridoxine: 0.1,
        choline: 56.3,
        folate: 311.0,
        vitamin_a: 298.0,
        vitamin_c: 6.1,
        vitamin_e: 0.68,
        vitamin_k: 26.7,
        calcium: 63.0,
        copper: 0.345,
        iron: 2.27,
        magnesium: 64.0,
        manganese: 1.024,
        phosphorus: 169.0,
        potassium: 436.0,
        selenium: 0.8,
        sodium: 6.0,
        zinc: 1.37
      },
      restrictionWarnings: [],
      restrictionViolations: [9],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '346': {
      id: 346,
      name: 'granulated sugar',
      pluralName: 'granulated sugar',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.81,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 387.0,
        ash: 0.01,
        water: 0.02,
        carbs: 99.98,
        sugars: 99.8,
        sucrose: 99.8,
        b2_riboflavin: 0.019,
        calcium: 1.0,
        copper: 0.007,
        iron: 0.05,
        manganese: 0.004,
        potassium: 2.0,
        selenium: 0.6,
        sodium: 1.0,
        zinc: 0.01
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '11': {
      id: 11,
      name: 'whole wheat couscous',
      pluralName: 'whole wheat couscous',
      description: '',
      sectionId: 3,
      subsectionId: 0,
      density: 0.731228241,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 345.45,
        carbs: 78.18,
        fiber: 12.73,
        fat: 0.64,
        monounsaturated: 0.089,
        polyunsaturated: 0.252,
        omega_3: 0.012,
        omega_6: 0.24,
        saturated: 0.117,
        protein: 12.76,
        alanine: 0.374,
        arginine: 0.47,
        aspartic_acid: 0.521,
        cystine: 0.36,
        glutamic_acid: 4.6,
        glycine: 0.403,
        histidine: 0.259,
        isoleucine: 0.493,
        leucine: 0.872,
        lysine: 0.245,
        methionine: 0.199,
        phenylalanine: 0.62,
        proline: 1.404,
        serine: 0.601,
        threonine: 0.337,
        tryptophan: 0.163,
        tyrosine: 0.335,
        valine: 0.544,
        b1_thiamine: 0.51,
        b2_riboflavin: 0.16,
        b3_niacin: 5.05,
        b5_pantothenic_acid: 1.243,
        b6_pyridoxine: 0.4,
        folate: 44.93,
        vitamin_e: 0.72,
        calcium: 24.0,
        copper: 0.42,
        iron: 1.96,
        magnesium: 139.89,
        manganese: 0.78,
        phosphorus: 364.53,
        potassium: 370.65,
        sodium: 9.09,
        zinc: 2.65
      },
      restrictionWarnings: [],
      restrictionViolations: [1],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '93': {
      id: 93,
      name: 'kidney beans',
      pluralName: 'kidney beans',
      description: '',
      sectionId: 9,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 53,
          packageAmount: 1.0,
          amountInBase: 266.0,
          equivalents: ''
        },
        us: {
          unitId: 30,
          packageAmount: 1.0,
          amountInBase: 266.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 124.0,
        ash: 1.36,
        water: 68.11,
        carbs: 21.49,
        fiber: 5.5,
        starch: 12.19,
        sugars: 3.8,
        fructose: 0.1,
        glucose: 0.23,
        sucrose: 3.47,
        fat: 1.05,
        monounsaturated: 0.03,
        polyunsaturated: 0.19,
        omega_3: 0.07,
        omega_6: 0.12,
        saturated: 0.18,
        protein: 7.98,
        alanine: 0.36,
        arginine: 0.44,
        aspartic_acid: 1.0,
        cystine: 0.08,
        glutamic_acid: 1.28,
        glycine: 0.32,
        histidine: 0.22,
        isoleucine: 0.38,
        leucine: 0.68,
        lysine: 0.56,
        methionine: 0.1,
        phenylalanine: 0.47,
        proline: 0.46,
        serine: 0.5,
        threonine: 0.29,
        tryptophan: 0.1,
        tyrosine: 0.19,
        valine: 0.46,
        b1_thiamine: 0.07,
        b2_riboflavin: 0.02,
        b3_niacin: 0.46,
        b5_pantothenic_acid: 0.21,
        b6_pyridoxine: 0.11,
        choline: 28.7,
        folate: 28.0,
        vitamin_c: 0.2,
        vitamin_e: 0.03,
        vitamin_k: 7.9,
        calcium: 57.0,
        copper: 0.27,
        iron: 1.5,
        magnesium: 30.0,
        manganese: 0.38,
        phosphorus: 121.0,
        potassium: 250.0,
        selenium: 1.6,
        sodium: 208.0,
        zinc: 0.75
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '429': {
      id: 429,
      name: 'cauliflower florets',
      pluralName: 'cauliflower florets',
      description: null,
      sectionId: 4,
      subsectionId: null,
      density: 0.36,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 188,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 145,
          packageAmount: 1.0,
          amountInBase: 283.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 25.0,
        ash: 0.76,
        water: 92.07,
        carbs: 4.97,
        fiber: 2.0,
        sugars: 1.91,
        fructose: 0.97,
        glucose: 0.94,
        fat: 0.28,
        monounsaturated: 0.034,
        polyunsaturated: 0.031,
        omega_3: 0.015,
        omega_6: 0.016,
        saturated: 0.13,
        protein: 1.92,
        alanine: 0.116,
        arginine: 0.086,
        aspartic_acid: 0.177,
        cystine: 0.02,
        glutamic_acid: 0.257,
        glycine: 0.071,
        histidine: 0.056,
        isoleucine: 0.071,
        leucine: 0.106,
        lysine: 0.217,
        methionine: 0.02,
        phenylalanine: 0.065,
        proline: 0.071,
        serine: 0.086,
        threonine: 0.076,
        tryptophan: 0.02,
        tyrosine: 0.051,
        valine: 0.125,
        b1_thiamine: 0.05,
        b2_riboflavin: 0.06,
        b3_niacin: 0.507,
        b5_pantothenic_acid: 0.667,
        b6_pyridoxine: 0.184,
        choline: 44.3,
        folate: 57.0,
        vitamin_c: 48.2,
        vitamin_e: 0.08,
        vitamin_k: 15.5,
        calcium: 22.0,
        copper: 0.039,
        iron: 0.42,
        magnesium: 15.0,
        manganese: 0.155,
        phosphorus: 44.0,
        potassium: 299.0,
        selenium: 0.6,
        sodium: 30.0,
        zinc: 0.27
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '428': {
      id: 428,
      name: 'broccoli florets',
      pluralName: 'broccoli florets',
      description: null,
      sectionId: 4,
      subsectionId: null,
      density: 0.36,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 187,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 143,
          packageAmount: 1.0,
          amountInBase: 510.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 34.0,
        ash: 0.87,
        water: 89.3,
        carbs: 6.64,
        fiber: 2.6,
        sugars: 1.7,
        fructose: 0.68,
        glucose: 0.49,
        lactose: 0.21,
        maltose: 0.21,
        sucrose: 0.1,
        fat: 0.37,
        monounsaturated: 0.031,
        polyunsaturated: 0.112,
        omega_3: 0.063,
        omega_6: 0.049,
        saturated: 0.114,
        protein: 2.82,
        alanine: 0.104,
        arginine: 0.191,
        aspartic_acid: 0.325,
        cystine: 0.028,
        glutamic_acid: 0.542,
        glycine: 0.089,
        histidine: 0.059,
        isoleucine: 0.079,
        leucine: 0.129,
        lysine: 0.135,
        methionine: 0.038,
        phenylalanine: 0.117,
        proline: 0.11,
        serine: 0.121,
        threonine: 0.088,
        tryptophan: 0.033,
        tyrosine: 0.05,
        valine: 0.125,
        b1_thiamine: 0.071,
        b2_riboflavin: 0.117,
        b3_niacin: 0.639,
        b5_pantothenic_acid: 0.573,
        b6_pyridoxine: 0.175,
        choline: 18.7,
        folate: 63.0,
        vitamin_a: 623.0,
        vitamin_c: 89.2,
        vitamin_e: 0.78,
        vitamin_k: 101.6,
        calcium: 47.0,
        copper: 0.049,
        iron: 0.73,
        magnesium: 21.0,
        manganese: 0.21,
        phosphorus: 66.0,
        potassium: 316.0,
        selenium: 2.5,
        sodium: 33.0,
        zinc: 0.41
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '426': {
      id: 426,
      name: 'shredded cheese, Italian blend',
      pluralName: 'shredded cheese, Italian blend',
      description: null,
      sectionId: 2,
      subsectionId: null,
      density: 0.47,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 304.0,
        ash: 3.78,
        water: 44.81,
        carbs: 8.06,
        sugars: 2.24,
        galactose: 0.75,
        lactose: 1.45,
        fat: 19.72,
        monounsaturated: 5.104,
        polyunsaturated: 0.861,
        omega_3: 0.076,
        omega_6: 0.706,
        saturated: 11.472,
        transfats: 0.709,
        cholesterol: 65.0,
        protein: 23.63,
        alanine: 0.816,
        arginine: 1.056,
        aspartic_acid: 1.923,
        cystine: 0.14,
        glutamic_acid: 5.53,
        glycine: 0.472,
        histidine: 0.692,
        isoleucine: 1.256,
        leucine: 2.372,
        lysine: 1.985,
        methionine: 0.572,
        phenylalanine: 1.227,
        proline: 2.612,
        serine: 1.358,
        threonine: 1.063,
        tryptophan: 0.312,
        tyrosine: 1.283,
        valine: 1.589,
        b1_thiamine: 0.029,
        b2_riboflavin: 0.367,
        b3_niacin: 0.151,
        b5_pantothenic_acid: 0.508,
        b6_pyridoxine: 0.111,
        b12_cobalamin: 1.33,
        choline: 14.2,
        folate: 27.0,
        vitamin_a: 846.0,
        vitamin_d: 0.4,
        vitamin_e: 0.49,
        vitamin_k: 1.3,
        calcium: 716.0,
        copper: 0.034,
        iron: 0.23,
        magnesium: 29.0,
        manganese: 0.041,
        phosphorus: 537.0,
        potassium: 131.0,
        selenium: 26.8,
        sodium: 682.0,
        zinc: 3.61
      },
      restrictionWarnings: [14, 12, 11, 9, 5, 6, 1],
      restrictionViolations: [2],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '471': {
      id: 471,
      name: 'Swiss chard',
      pluralName: 'Swiss chard',
      description: null,
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 62,
          packageAmount: 1.0,
          amountInBase: 340.0,
          equivalents: ''
        },
        us: {
          unitId: 62,
          packageAmount: 1.0,
          amountInBase: 340.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 19.0,
        ash: 1.6,
        water: 92.66,
        carbs: 3.74,
        fiber: 1.6,
        sugars: 1.1,
        fat: 0.2,
        monounsaturated: 0.04,
        polyunsaturated: 0.07,
        omega_3: 0.007,
        omega_6: 0.063,
        saturated: 0.03,
        protein: 1.8,
        arginine: 0.117,
        histidine: 0.036,
        isoleucine: 0.147,
        leucine: 0.13,
        lysine: 0.099,
        methionine: 0.019,
        phenylalanine: 0.11,
        threonine: 0.083,
        tryptophan: 0.017,
        valine: 0.11,
        b1_thiamine: 0.04,
        b2_riboflavin: 0.09,
        b3_niacin: 0.4,
        b5_pantothenic_acid: 0.172,
        b6_pyridoxine: 0.099,
        choline: 18.0,
        folate: 14.0,
        vitamin_a: 6116.0,
        vitamin_c: 30.0,
        vitamin_e: 1.89,
        vitamin_k: 830.0,
        calcium: 51.0,
        copper: 0.179,
        iron: 1.8,
        magnesium: 81.0,
        manganese: 0.366,
        phosphorus: 46.0,
        potassium: 379.0,
        selenium: 0.9,
        sodium: 213.0,
        zinc: 0.36
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '424': {
      id: 424,
      name: 'honey mustard dressing',
      pluralName: 'honey mustard dressing',
      description: null,
      sectionId: 14,
      subsectionId: null,
      density: 1.01,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 464.0,
        ash: 2.32,
        water: 32.64,
        carbs: 23.33,
        fiber: 0.4,
        sugars: 15.84,
        fat: 40.83,
        monounsaturated: 11.458,
        polyunsaturated: 22.839,
        omega_3: 2.697,
        omega_6: 20.121,
        saturated: 5.0,
        cholesterol: 29.0,
        protein: 0.87,
        b1_thiamine: 0.039,
        b2_riboflavin: 0.023,
        b3_niacin: 0.061,
        b5_pantothenic_acid: 0.115,
        b6_pyridoxine: 0.017,
        b12_cobalamin: 0.05,
        choline: 20.7,
        folate: 5.0,
        vitamin_a: 70.0,
        vitamin_c: 0.2,
        vitamin_d: 0.1,
        vitamin_e: 3.53,
        vitamin_k: 70.0,
        calcium: 12.0,
        copper: 0.018,
        iron: 0.29,
        magnesium: 5.0,
        manganese: 0.066,
        phosphorus: 22.0,
        potassium: 20.0,
        selenium: 5.0,
        sodium: 512.0,
        zinc: 0.14
      },
      restrictionWarnings: [14, 12, 2, 5, 6, 1],
      restrictionViolations: [10, 13, 11, 9],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '423': {
      id: 423,
      name: 'balsamic vinaigrette',
      pluralName: 'balsamic vinaigrette',
      description: null,
      sectionId: 14,
      subsectionId: null,
      density: 1.01,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 0.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 300.0,
        carbs: 6.67,
        sugars: 6.67,
        fat: 30.0,
        saturated: 3.33,
        sodium: 967.0
      },
      restrictionWarnings: [14, 13, 12, 11, 9, 2, 5, 6, 1],
      restrictionViolations: [10],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '422': {
      id: 422,
      name: 'miso paste, white',
      pluralName: 'miso paste, white',
      description: null,
      sectionId: 5,
      subsectionId: null,
      density: 1.01,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 198.0,
        ash: 12.81,
        water: 43.02,
        carbs: 25.37,
        fiber: 5.4,
        sugars: 6.2,
        fructose: 6.0,
        maltose: 0.2,
        fat: 6.01,
        monounsaturated: 1.118,
        polyunsaturated: 2.884,
        omega_3: 0.405,
        omega_6: 2.479,
        saturated: 1.025,
        protein: 12.79,
        alanine: 0.5,
        arginine: 0.784,
        aspartic_acid: 1.171,
        glutamic_acid: 1.915,
        glycine: 0.447,
        histidine: 0.243,
        isoleucine: 0.508,
        leucine: 0.82,
        lysine: 0.478,
        methionine: 0.129,
        phenylalanine: 0.486,
        proline: 0.619,
        serine: 0.601,
        threonine: 0.479,
        tryptophan: 0.155,
        tyrosine: 0.352,
        valine: 0.547,
        b1_thiamine: 0.098,
        b2_riboflavin: 0.233,
        b3_niacin: 0.906,
        b5_pantothenic_acid: 0.337,
        b6_pyridoxine: 0.199,
        b12_cobalamin: 0.08,
        choline: 72.2,
        folate: 19.0,
        vitamin_a: 87.0,
        vitamin_e: 0.01,
        vitamin_k: 29.3,
        calcium: 57.0,
        copper: 0.42,
        iron: 2.49,
        magnesium: 48.0,
        manganese: 0.859,
        phosphorus: 159.0,
        potassium: 210.0,
        selenium: 7.0,
        sodium: 3728.0,
        zinc: 2.56
      },
      restrictionWarnings: [14, 12, 5, 6, 1],
      restrictionViolations: [9],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '421': {
      id: 421,
      name: 'sun-dried tomato pesto',
      pluralName: 'sun-dried tomato pesto',
      description: null,
      sectionId: 15,
      subsectionId: null,
      density: 1.01,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 333.0,
        carbs: 6.67,
        sugars: 3.33,
        fat: 33.33,
        saturated: 6.67,
        protein: 6.67,
        vitamin_c: 16.0,
        iron: 2.4,
        sodium: 500.0
      },
      restrictionWarnings: [14, 12, 11, 9, 1],
      restrictionViolations: [10, 2, 5, 6],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '420': {
      id: 420,
      name: 'lentils',
      pluralName: 'lentils',
      description: null,
      sectionId: 3,
      subsectionId: null,
      density: 1.1,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 186,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 30,
          packageAmount: 1.0,
          amountInBase: 255.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 115.0,
        water: 69.23,
        carbs: 20.01,
        fiber: 7.9,
        sugars: 1.79,
        fat: 0.38,
        monounsaturated: 0.064,
        polyunsaturated: 0.174,
        omega_3: 0.037,
        omega_6: 0.136,
        saturated: 0.053,
        protein: 8.97,
        b1_thiamine: 0.16,
        b2_riboflavin: 0.069,
        b3_niacin: 1.001,
        b6_pyridoxine: 0.168,
        choline: 32.5,
        folate: 171.0,
        vitamin_c: 1.4,
        vitamin_e: 0.11,
        vitamin_k: 1.7,
        calcium: 19.0,
        copper: 0.25,
        iron: 3.31,
        magnesium: 36.0,
        phosphorus: 179.0,
        potassium: 367.0,
        selenium: 2.8,
        sodium: 233.0,
        zinc: 1.26
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '419': {
      id: 419,
      name: 'frozen, riced cauliflower',
      pluralName: 'frozen, riced cauliflower',
      description: null,
      sectionId: 13,
      subsectionId: null,
      density: 0.47,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 185,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 142,
          packageAmount: 1.0,
          amountInBase: 340.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 25.0,
        water: 92.07,
        carbs: 4.97,
        fiber: 2.0,
        sugars: 1.91,
        fat: 0.28,
        monounsaturated: 0.034,
        polyunsaturated: 0.031,
        omega_3: 0.015,
        omega_6: 0.016,
        saturated: 0.13,
        protein: 1.92,
        b1_thiamine: 0.05,
        b2_riboflavin: 0.06,
        b3_niacin: 0.507,
        b6_pyridoxine: 0.184,
        choline: 44.3,
        folate: 57.0,
        vitamin_c: 48.2,
        vitamin_e: 0.08,
        vitamin_k: 15.5,
        calcium: 22.0,
        copper: 0.039,
        iron: 0.42,
        magnesium: 15.0,
        phosphorus: 44.0,
        potassium: 299.0,
        selenium: 0.6,
        sodium: 30.0,
        zinc: 0.27
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '418': {
      id: 418,
      name: 'miso paste, red',
      pluralName: 'miso paste, red',
      description: null,
      sectionId: 5,
      subsectionId: null,
      density: 1.01,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 198.0,
        ash: 12.81,
        water: 43.02,
        carbs: 25.37,
        fiber: 5.4,
        sugars: 6.2,
        fructose: 6.0,
        maltose: 0.2,
        fat: 6.01,
        monounsaturated: 1.118,
        polyunsaturated: 2.884,
        omega_3: 0.405,
        omega_6: 2.479,
        saturated: 1.025,
        protein: 12.79,
        alanine: 0.5,
        arginine: 0.784,
        aspartic_acid: 1.171,
        glutamic_acid: 1.915,
        glycine: 0.447,
        histidine: 0.243,
        isoleucine: 0.508,
        leucine: 0.82,
        lysine: 0.478,
        methionine: 0.129,
        phenylalanine: 0.486,
        proline: 0.619,
        serine: 0.601,
        threonine: 0.479,
        tryptophan: 0.155,
        tyrosine: 0.352,
        valine: 0.547,
        b1_thiamine: 0.098,
        b2_riboflavin: 0.233,
        b3_niacin: 0.906,
        b5_pantothenic_acid: 0.337,
        b6_pyridoxine: 0.199,
        b12_cobalamin: 0.08,
        choline: 72.2,
        folate: 19.0,
        vitamin_a: 87.0,
        vitamin_e: 0.01,
        vitamin_k: 29.3,
        calcium: 57.0,
        copper: 0.42,
        iron: 2.49,
        magnesium: 48.0,
        manganese: 0.859,
        phosphorus: 159.0,
        potassium: 210.0,
        selenium: 7.0,
        sodium: 3728.0,
        zinc: 2.56
      },
      restrictionWarnings: [14, 12, 5, 6, 1],
      restrictionViolations: [9],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '417': {
      id: 417,
      name: 'ham steak, boneless',
      pluralName: 'ham steak, boneless',
      description: null,
      sectionId: 1,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 227.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 8.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 122.0,
        ash: 3.95,
        water: 72.24,
        fat: 4.25,
        monounsaturated: 1.96,
        polyunsaturated: 0.47,
        omega_3: 0.04,
        omega_6: 0.43,
        saturated: 1.44,
        cholesterol: 45.0,
        protein: 19.56,
        alanine: 1.154,
        arginine: 1.27,
        aspartic_acid: 1.852,
        cystine: 0.295,
        glutamic_acid: 3.189,
        glycine: 1.017,
        histidine: 0.701,
        isoleucine: 0.858,
        leucine: 1.552,
        lysine: 1.658,
        methionine: 0.516,
        phenylalanine: 0.845,
        proline: 0.836,
        serine: 0.801,
        threonine: 0.87,
        tryptophan: 0.235,
        tyrosine: 0.641,
        valine: 0.848,
        b1_thiamine: 0.8,
        b2_riboflavin: 0.2,
        b3_niacin: 5.08,
        b5_pantothenic_acid: 0.62,
        b6_pyridoxine: 0.37,
        b12_cobalamin: 0.79,
        folate: 4.0,
        vitamin_c: 32.3,
        calcium: 4.0,
        copper: 0.08,
        iron: 1.0,
        magnesium: 19.0,
        manganese: 0.037,
        phosphorus: 260.0,
        potassium: 325.0,
        selenium: 15.5,
        sodium: 1269.0,
        zinc: 2.02
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '414': {
      id: 414,
      name: 'cheese tortellini, fresh or frozen',
      pluralName: 'cheese tortellini, fresh or frozen',
      description: null,
      sectionId: 15,
      subsectionId: null,
      density: 0.49,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 236.0,
        water: 46.54,
        carbs: 36.15,
        fiber: 1.5,
        sugars: 0.73,
        fat: 5.56,
        monounsaturated: 1.589,
        polyunsaturated: 0.355,
        omega_3: 0.065,
        omega_6: 0.286,
        saturated: 2.769,
        cholesterol: 32.0,
        protein: 10.38,
        b1_thiamine: 0.156,
        b2_riboflavin: 0.179,
        b3_niacin: 1.348,
        b6_pyridoxine: 0.026,
        b12_cobalamin: 0.12,
        choline: 3.9,
        folate: 40.0,
        vitamin_e: 0.12,
        vitamin_k: 1.0,
        calcium: 111.0,
        copper: 0.055,
        iron: 0.86,
        magnesium: 14.0,
        phosphorus: 139.0,
        potassium: 21.0,
        selenium: 18.4,
        sodium: 156.0,
        zinc: 0.78
      },
      restrictionWarnings: [14, 13, 12, 5, 6, 3, 4],
      restrictionViolations: [11, 9, 2, 1],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '415': {
      id: 415,
      name: 'cheese ravioli, fresh or frozen',
      pluralName: 'cheese ravioli, fresh or frozen',
      description: null,
      sectionId: 15,
      subsectionId: null,
      density: 0.48,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 567.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 25.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 172.0,
        water: 62.87,
        carbs: 21.55,
        fiber: 0.7,
        sugars: 0.24,
        fat: 6.01,
        monounsaturated: 1.692,
        polyunsaturated: 0.575,
        omega_3: 0.029,
        omega_6: 0.507,
        saturated: 3.13,
        cholesterol: 90.0,
        protein: 7.26,
        b1_thiamine: 0.179,
        b2_riboflavin: 0.281,
        b3_niacin: 1.381,
        b6_pyridoxine: 0.071,
        b12_cobalamin: 0.4,
        choline: 53.8,
        folate: 40.0,
        vitamin_d: 0.5,
        vitamin_e: 0.3,
        vitamin_k: 0.3,
        calcium: 98.0,
        copper: 0.046,
        iron: 1.46,
        magnesium: 14.0,
        phosphorus: 120.0,
        potassium: 122.0,
        selenium: 15.6,
        sodium: 648.0,
        zinc: 0.62
      },
      restrictionWarnings: [14, 13, 12, 5, 6, 3, 4],
      restrictionViolations: [11, 9, 2, 1],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '413': {
      id: 413,
      name: 'polenta, pre-cooked',
      pluralName: 'polenta, pre-cooked',
      description: null,
      sectionId: 3,
      subsectionId: null,
      density: 1.01,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 184,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 141,
          packageAmount: 1.0,
          amountInBase: 510.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 70.0,
        carbs: 15.0,
        fiber: 1.0,
        fat: 0.5,
        protein: 1.0,
        sodium: 280.0
      },
      restrictionWarnings: [14, 13, 12, 11, 9, 2, 5, 6, 1, 3, 4],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '411': {
      id: 411,
      name: 'garam masala',
      pluralName: 'garam masala',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.49,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 260.0,
        carbs: 40.0,
        fat: 20.0,
        protein: 20.0,
        vitamin_a: 1500.0,
        calcium: 140.0
      },
      restrictionWarnings: [14, 13],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '409': {
      id: 409,
      name: 'marinara sauce',
      pluralName: 'marinara sauce',
      description: null,
      sectionId: 15,
      subsectionId: null,
      density: 1.01,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 182,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 140,
          packageAmount: 1.0,
          amountInBase: 680.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 45.0,
        ash: 1.66,
        water: 87.4,
        carbs: 8.05,
        fiber: 1.8,
        fructose: 2.35,
        glucose: 2.3,
        sucrose: 0.85,
        fat: 1.48,
        monounsaturated: 0.376,
        polyunsaturated: 0.504,
        saturated: 0.17,
        transfats: 0.005,
        protein: 1.41,
        calcium: 27.0,
        copper: 0.081,
        iron: 0.78,
        magnesium: 18.5,
        manganese: 0.137,
        phosphorus: 34.0,
        potassium: 319.0,
        sodium: 419.0,
        zinc: 0.2
      },
      restrictionWarnings: [14, 13, 1],
      restrictionViolations: [10],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '360': {
      id: 360,
      name: 'basil pesto',
      pluralName: 'basil pesto',
      description: null,
      sectionId: 15,
      subsectionId: null,
      density: 1.048,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: 1.0,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: 1.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 418.0,
        ash: 3.5,
        water: 38.98,
        carbs: 10.09,
        fiber: 1.8,
        starch: 2.2,
        sugars: 6.33,
        galactose: 0.15,
        glucose: 0.23,
        lactose: 5.94,
        fat: 37.6,
        monounsaturated: 22.155,
        polyunsaturated: 6.546,
        omega_3: 1.534,
        omega_6: 4.88,
        saturated: 7.07,
        transfats: 0.398,
        protein: 9.83,
        b1_thiamine: 0.17,
        b2_riboflavin: 0.717,
        b3_niacin: 0.523,
        b5_pantothenic_acid: 1.7,
        b6_pyridoxine: 0.133,
        folate: 16.0,
        vitamin_a: 1544.0,
        vitamin_e: 7.27,
        vitamin_k: 151.1,
        calcium: 306.0,
        copper: 0.08,
        iron: 0.57,
        magnesium: 47.0,
        manganese: 0.586,
        phosphorus: 273.0,
        potassium: 560.0,
        sodium: 603.0,
        zinc: 1.33
      },
      restrictionWarnings: [14, 12, 11, 9, 1],
      restrictionViolations: [2, 5, 6],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '21': {
      id: 21,
      name: 'water chestnuts',
      pluralName: 'water chestnuts',
      description: '',
      sectionId: 9,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 49.0,
        ash: 0.4,
        water: 85.6,
        carbs: 13.1,
        fiber: 2.2,
        starch: 5.3,
        fat: 0.06,
        monounsaturated: 0.001,
        polyunsaturated: 0.026,
        omega_3: 0.006,
        omega_6: 0.019,
        saturated: 0.016,
        protein: 0.9,
        b1_thiamine: 0.01,
        b2_riboflavin: 0.03,
        b3_niacin: 0.3,
        b5_pantothenic_acid: 0.22,
        b6_pyridoxine: 0.16,
        choline: 19.6,
        folate: 5.8,
        vitamin_c: 6.0,
        vitamin_e: 0.5,
        vitamin_k: 0.2,
        calcium: 18.0,
        copper: 0.03,
        iron: 0.5,
        magnesium: 3.01,
        manganese: 0.03,
        phosphorus: 72.0,
        potassium: 154.0,
        selenium: 0.7,
        sodium: 14.0,
        zinc: 0.17
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '332': {
      id: 332,
      name: 'oats, quick cooking',
      pluralName: 'oats, quick cooking',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.34,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 379.0,
        ash: 1.77,
        water: 10.84,
        carbs: 67.7,
        fiber: 10.1,
        starch: 57.92,
        sugars: 0.99,
        sucrose: 0.99,
        fat: 6.52,
        monounsaturated: 1.98,
        polyunsaturated: 2.3,
        omega_3: 0.1,
        omega_6: 2.2,
        saturated: 1.11,
        protein: 13.15,
        alanine: 0.563,
        arginine: 0.85,
        aspartic_acid: 1.217,
        cystine: 0.455,
        glutamic_acid: 2.83,
        glycine: 0.642,
        histidine: 0.275,
        isoleucine: 0.503,
        leucine: 0.98,
        lysine: 0.637,
        methionine: 0.207,
        phenylalanine: 0.665,
        proline: 0.451,
        serine: 0.705,
        threonine: 0.382,
        tryptophan: 0.182,
        tyrosine: 0.395,
        valine: 0.688,
        b1_thiamine: 0.46,
        b2_riboflavin: 0.155,
        b3_niacin: 1.125,
        b5_pantothenic_acid: 1.12,
        b6_pyridoxine: 0.1,
        choline: 40.4,
        folate: 32.0,
        vitamin_e: 0.42,
        vitamin_k: 2.0,
        calcium: 52.0,
        copper: 0.391,
        iron: 4.25,
        magnesium: 138.0,
        manganese: 3.63,
        phosphorus: 410.0,
        potassium: 362.0,
        selenium: 28.9,
        sodium: 6.0,
        zinc: 3.64
      },
      restrictionWarnings: [1],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '333': {
      id: 333,
      name: 'cocoa powder',
      pluralName: 'cocoa powder',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.37,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 228.0,
        ash: 5.8,
        caffeine: 230.0,
        water: 3.0,
        carbs: 57.9,
        fiber: 37.0,
        sugars: 1.75,
        fat: 13.7,
        monounsaturated: 4.57,
        polyunsaturated: 0.44,
        omega_6: 0.44,
        saturated: 8.07,
        protein: 19.6,
        alanine: 0.904,
        arginine: 1.111,
        aspartic_acid: 1.953,
        cystine: 0.239,
        glutamic_acid: 2.948,
        glycine: 0.879,
        histidine: 0.339,
        isoleucine: 0.76,
        leucine: 1.189,
        lysine: 0.983,
        methionine: 0.202,
        phenylalanine: 0.941,
        proline: 0.838,
        serine: 0.846,
        threonine: 0.776,
        tryptophan: 0.293,
        tyrosine: 0.735,
        valine: 1.177,
        b1_thiamine: 0.078,
        b2_riboflavin: 0.241,
        b3_niacin: 2.185,
        b5_pantothenic_acid: 0.254,
        b6_pyridoxine: 0.118,
        choline: 12.0,
        folate: 32.0,
        vitamin_e: 0.1,
        vitamin_k: 2.5,
        calcium: 128.0,
        copper: 3.788,
        iron: 13.86,
        magnesium: 499.0,
        manganese: 3.837,
        phosphorus: 734.0,
        potassium: 1524.0,
        selenium: 14.3,
        sodium: 21.0,
        zinc: 6.81
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '336': {
      id: 336,
      name: 'frozen blueberries',
      pluralName: 'frozen blueberries',
      description: null,
      sectionId: 13,
      subsectionId: null,
      density: 0.59,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 85.0,
        ash: 0.12,
        water: 77.4,
        carbs: 21.95,
        fiber: 2.2,
        sugars: 19.72,
        fat: 0.13,
        monounsaturated: 0.018,
        polyunsaturated: 0.057,
        omega_3: 0.023,
        omega_6: 0.034,
        saturated: 0.011,
        protein: 0.4,
        alanine: 0.017,
        arginine: 0.02,
        aspartic_acid: 0.031,
        cystine: 0.004,
        glutamic_acid: 0.049,
        glycine: 0.016,
        histidine: 0.006,
        isoleucine: 0.012,
        leucine: 0.023,
        lysine: 0.007,
        methionine: 0.006,
        phenylalanine: 0.014,
        proline: 0.015,
        serine: 0.012,
        threonine: 0.011,
        tryptophan: 0.002,
        tyrosine: 0.005,
        valine: 0.017,
        b1_thiamine: 0.02,
        b2_riboflavin: 0.052,
        b3_niacin: 0.253,
        b5_pantothenic_acid: 0.125,
        b6_pyridoxine: 0.059,
        choline: 5.5,
        folate: 7.0,
        vitamin_a: 49.0,
        vitamin_c: 1.0,
        vitamin_e: 0.52,
        vitamin_k: 17.7,
        calcium: 6.0,
        copper: 0.039,
        iron: 0.39,
        magnesium: 2.0,
        manganese: 0.262,
        phosphorus: 7.0,
        potassium: 60.0,
        selenium: 0.2,
        sodium: 1.0,
        zinc: 0.06
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '335': {
      id: 335,
      name: 'frozen raspberries',
      pluralName: 'frozen raspberries',
      description: null,
      sectionId: 13,
      subsectionId: null,
      density: 0.59,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 56.0,
        ash: 0.48,
        water: 85.01,
        carbs: 12.55,
        fiber: 4.3,
        starch: 0.19,
        sugars: 6.54,
        fructose: 3.24,
        glucose: 2.87,
        sucrose: 0.44,
        fat: 0.81,
        monounsaturated: 0.025,
        polyunsaturated: 0.034,
        omega_3: 0.019,
        omega_6: 0.015,
        saturated: 0.064,
        protein: 1.15,
        b1_thiamine: 0.089,
        b2_riboflavin: 0.1,
        b3_niacin: 0.675,
        b5_pantothenic_acid: 0.402,
        b6_pyridoxine: 0.062,
        folate: 28.0,
        vitamin_a: 74.0,
        vitamin_c: 17.6,
        vitamin_e: 0.75,
        calcium: 24.0,
        copper: 0.09,
        iron: 0.76,
        magnesium: 23.0,
        manganese: 0.61,
        phosphorus: 30.0,
        potassium: 184.0,
        sodium: 4.0,
        zinc: 0.31
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '337': {
      id: 337,
      name: 'frozen mango',
      pluralName: 'frozen mangoes',
      description: null,
      sectionId: 13,
      subsectionId: null,
      density: 0.59,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 64.0,
        carbs: 15.0,
        fiber: 1.4,
        sugars: 13.57,
        fat: 0.36,
        protein: 0.71,
        vitamin_a: 1071.0,
        vitamin_c: 34.3,
        calcium: 14.0,
        potassium: 171.0
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '410': {
      id: 410,
      name: 'Alfredo sauce',
      pluralName: 'Alfredo sauce',
      description: null,
      sectionId: 15,
      subsectionId: null,
      density: 1.05,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 99,
          packageAmount: 1.0,
          amountInBase: 454.0,
          equivalents: ''
        },
        us: {
          unitId: 98,
          packageAmount: 1.0,
          amountInBase: 454.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 149.0,
        water: 79.26,
        carbs: 1.48,
        sugars: 0.88,
        fat: 15.12,
        monounsaturated: 3.8,
        polyunsaturated: 0.64,
        omega_3: 0.055,
        omega_6: 0.544,
        saturated: 9.518,
        cholesterol: 46.0,
        protein: 2.37,
        b1_thiamine: 0.008,
        b2_riboflavin: 0.075,
        b3_niacin: 0.025,
        b6_pyridoxine: 0.015,
        b12_cobalamin: 0.12,
        choline: 6.4,
        folate: 2.0,
        vitamin_c: 0.2,
        vitamin_d: 0.5,
        vitamin_e: 0.38,
        vitamin_k: 1.3,
        calcium: 67.0,
        copper: 0.011,
        iron: 0.05,
        magnesium: 5.0,
        phosphorus: 50.0,
        potassium: 39.0,
        selenium: 2.7,
        sodium: 558.0,
        zinc: 0.3
      },
      restrictionWarnings: [14, 13, 12, 11, 9, 5, 6, 3, 4],
      restrictionViolations: [2, 1],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '29': {
      id: 29,
      name: 'almonds',
      pluralName: 'almonds',
      description: '',
      sectionId: 12,
      subsectionId: 0,
      density: 0.604425656,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 579.0,
        ash: 2.97,
        water: 4.41,
        carbs: 21.55,
        fiber: 12.5,
        starch: 0.72,
        sugars: 4.35,
        fructose: 0.11,
        galactose: 0.07,
        glucose: 0.17,
        maltose: 0.04,
        sucrose: 3.95,
        fat: 49.93,
        monounsaturated: 31.551,
        polyunsaturated: 12.329,
        omega_3: 0.003,
        omega_6: 12.326,
        saturated: 3.802,
        transfats: 0.015,
        protein: 21.15,
        alanine: 0.999,
        arginine: 2.465,
        aspartic_acid: 2.639,
        cystine: 0.215,
        glutamic_acid: 6.206,
        glycine: 1.429,
        histidine: 0.539,
        isoleucine: 0.751,
        leucine: 1.473,
        lysine: 0.568,
        methionine: 0.157,
        phenylalanine: 1.132,
        proline: 0.969,
        serine: 0.912,
        threonine: 0.601,
        tryptophan: 0.211,
        tyrosine: 0.45,
        valine: 0.855,
        b1_thiamine: 0.205,
        b2_riboflavin: 1.138,
        b3_niacin: 3.618,
        b5_pantothenic_acid: 0.471,
        b6_pyridoxine: 0.137,
        choline: 52.1,
        folate: 44.0,
        vitamin_a: 2.0,
        vitamin_e: 25.63,
        calcium: 269.0,
        copper: 1.031,
        iron: 3.71,
        magnesium: 270.0,
        manganese: 2.179,
        phosphorus: 481.0,
        potassium: 733.0,
        selenium: 4.1,
        sodium: 1.0,
        zinc: 3.12
      },
      restrictionWarnings: [],
      restrictionViolations: [6],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '64': {
      id: 64,
      name: 'gluten-free penne pasta',
      pluralName: 'gluten-free penne pasta',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 352.73,
        carbs: 77.6,
        fiber: 3.53,
        fat: 2.65,
        monounsaturated: 0.88,
        polyunsaturated: 0.88,
        protein: 7.05,
        iron: 0.63
      },
      restrictionWarnings: [14, 12, 11, 10, 9, 6, 5, 2],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '67': {
      id: 67,
      name: 'gluten-free rotini pasta',
      pluralName: 'gluten-free rotini pasta',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 352.73,
        carbs: 77.6,
        fiber: 3.53,
        fat: 2.65,
        monounsaturated: 0.88,
        polyunsaturated: 0.88,
        protein: 7.05,
        iron: 0.63
      },
      restrictionWarnings: [14, 12, 11, 10, 9, 6, 5, 2],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '58': {
      id: 58,
      name: 'linguine pasta',
      pluralName: 'linguine pasta',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 371.0,
        ash: 0.88,
        water: 9.9,
        carbs: 74.67,
        fiber: 3.2,
        starch: 62.45,
        sugars: 2.67,
        fructose: 0.11,
        glucose: 0.12,
        maltose: 1.96,
        sucrose: 0.48,
        fat: 1.51,
        monounsaturated: 0.171,
        polyunsaturated: 0.564,
        omega_3: 0.024,
        omega_6: 0.54,
        saturated: 0.277,
        protein: 13.04,
        alanine: 0.438,
        arginine: 0.474,
        aspartic_acid: 0.624,
        cystine: 0.255,
        glutamic_acid: 4.596,
        glycine: 0.441,
        histidine: 0.298,
        isoleucine: 0.511,
        leucine: 0.988,
        lysine: 0.298,
        methionine: 0.147,
        phenylalanine: 0.668,
        proline: 1.569,
        serine: 0.617,
        threonine: 0.462,
        tryptophan: 0.185,
        tyrosine: 0.243,
        valine: 0.588,
        b1_thiamine: 0.891,
        b2_riboflavin: 0.4,
        b3_niacin: 7.177,
        b5_pantothenic_acid: 0.431,
        b6_pyridoxine: 0.142,
        choline: 15.1,
        folate: 237.0,
        vitamin_e: 0.11,
        vitamin_k: 0.1,
        calcium: 21.0,
        copper: 0.289,
        iron: 3.3,
        magnesium: 53.0,
        manganese: 0.917,
        phosphorus: 189.0,
        potassium: 223.0,
        selenium: 63.2,
        sodium: 6.0,
        zinc: 1.41
      },
      restrictionWarnings: [11],
      restrictionViolations: [1],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '66': {
      id: 66,
      name: 'rotini pasta',
      pluralName: 'rotini pasta',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 371.0,
        ash: 0.88,
        water: 9.9,
        carbs: 74.67,
        fiber: 3.2,
        starch: 62.45,
        sugars: 2.67,
        fructose: 0.11,
        glucose: 0.12,
        maltose: 1.96,
        sucrose: 0.48,
        fat: 1.51,
        monounsaturated: 0.171,
        polyunsaturated: 0.564,
        omega_3: 0.024,
        omega_6: 0.54,
        saturated: 0.277,
        protein: 13.04,
        alanine: 0.438,
        arginine: 0.474,
        aspartic_acid: 0.624,
        cystine: 0.255,
        glutamic_acid: 4.596,
        glycine: 0.441,
        histidine: 0.298,
        isoleucine: 0.511,
        leucine: 0.988,
        lysine: 0.298,
        methionine: 0.147,
        phenylalanine: 0.668,
        proline: 1.569,
        serine: 0.617,
        threonine: 0.462,
        tryptophan: 0.185,
        tyrosine: 0.243,
        valine: 0.588,
        b1_thiamine: 0.891,
        b2_riboflavin: 0.4,
        b3_niacin: 7.177,
        b5_pantothenic_acid: 0.431,
        b6_pyridoxine: 0.142,
        choline: 15.1,
        folate: 237.0,
        vitamin_e: 0.11,
        vitamin_k: 0.1,
        calcium: 21.0,
        copper: 0.289,
        iron: 3.3,
        magnesium: 53.0,
        manganese: 0.917,
        phosphorus: 189.0,
        potassium: 223.0,
        selenium: 63.2,
        sodium: 6.0,
        zinc: 1.41
      },
      restrictionWarnings: [11],
      restrictionViolations: [1],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '60': {
      id: 60,
      name: 'gluten-free spaghetti pasta',
      pluralName: 'gluten-free spaghetti pasta',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        },
        us: {
          unitId: 11,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        }
      },
      nutrition: {
        energy: 352.73,
        carbs: 77.6,
        fiber: 3.53,
        fat: 2.65,
        monounsaturated: 0.88,
        polyunsaturated: 0.88,
        protein: 7.05,
        iron: 0.63
      },
      restrictionWarnings: [14, 12, 11, 10, 9, 6, 5, 2],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '62': {
      id: 62,
      name: 'gluten-free macaroni (elbow) pasta',
      pluralName: 'gluten-free macaroni (elbow) pasta',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        },
        us: {
          unitId: 11,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        }
      },
      nutrition: {
        energy: 352.73,
        carbs: 77.6,
        fiber: 3.53,
        fat: 2.65,
        monounsaturated: 0.88,
        polyunsaturated: 0.88,
        protein: 7.05,
        iron: 0.63
      },
      restrictionWarnings: [14, 12, 11, 10, 9, 6, 5, 2],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '6': {
      id: 6,
      name: 'salmon fillet',
      pluralName: 'salmon fillet',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 146.0,
        ash: 1.21,
        water: 72.66,
        fat: 5.93,
        monounsaturated: 2.134,
        polyunsaturated: 1.992,
        omega_3: 1.593,
        omega_6: 0.339,
        saturated: 1.26,
        cholesterol: 45.0,
        protein: 21.62,
        alanine: 1.307,
        arginine: 1.294,
        aspartic_acid: 2.214,
        cystine: 0.232,
        glutamic_acid: 3.227,
        glycine: 1.038,
        histidine: 0.636,
        isoleucine: 0.996,
        leucine: 1.757,
        lysine: 1.985,
        methionine: 0.64,
        phenylalanine: 0.844,
        proline: 0.764,
        serine: 0.882,
        threonine: 0.948,
        tryptophan: 0.242,
        tyrosine: 0.73,
        valine: 1.114,
        b1_thiamine: 0.113,
        b2_riboflavin: 0.14,
        b3_niacin: 7.23,
        b5_pantothenic_acid: 0.823,
        b6_pyridoxine: 0.549,
        b12_cobalamin: 4.17,
        choline: 109.4,
        folate: 9.0,
        vitamin_a: 135.0,
        vitamin_c: 1.0,
        vitamin_d: 361.0,
        vitamin_e: 0.73,
        vitamin_k: 0.1,
        calcium: 36.0,
        copper: 0.051,
        iron: 0.56,
        magnesium: 31.0,
        manganese: 0.014,
        phosphorus: 262.0,
        potassium: 423.0,
        selenium: 36.5,
        sodium: 46.0,
        zinc: 0.41
      },
      restrictionWarnings: [],
      restrictionViolations: [3],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '52': {
      id: 52,
      name: 'fish sauce',
      pluralName: 'fish sauce',
      description: '',
      sectionId: 5,
      subsectionId: 0,
      density: 1.217304818,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        },
        us: {
          unitId: 91,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        }
      },
      nutrition: {
        energy: 35.0,
        ash: 20.22,
        water: 71.07,
        carbs: 3.64,
        sugars: 3.64,
        sucrose: 3.64,
        fat: 0.01,
        monounsaturated: 0.002,
        polyunsaturated: 0.003,
        omega_3: 0.003,
        saturated: 0.003,
        protein: 5.06,
        alanine: 0.54,
        arginine: 0.07,
        aspartic_acid: 0.45,
        cystine: 0.03,
        glutamic_acid: 0.84,
        glycine: 0.25,
        histidine: 0.17,
        isoleucine: 0.27,
        leucine: 0.37,
        lysine: 0.61,
        methionine: 0.16,
        phenylalanine: 0.24,
        proline: 0.14,
        serine: 0.18,
        threonine: 0.27,
        tryptophan: 0.01,
        tyrosine: 0.08,
        valine: 0.38,
        b1_thiamine: 0.012,
        b2_riboflavin: 0.057,
        b3_niacin: 2.313,
        b5_pantothenic_acid: 0.118,
        b6_pyridoxine: 0.396,
        b12_cobalamin: 0.48,
        choline: 13.2,
        folate: 51.0,
        vitamin_a: 12.0,
        vitamin_c: 0.5,
        calcium: 43.0,
        copper: 0.05,
        iron: 0.78,
        magnesium: 175.0,
        manganese: 0.233,
        phosphorus: 7.0,
        potassium: 288.0,
        selenium: 9.1,
        sodium: 7.0,
        zinc: 0.2
      },
      restrictionWarnings: [],
      restrictionViolations: [3],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '7': {
      id: 7,
      name: 'raw peeled shrimp, fresh or frozen',
      pluralName: 'raw peeled shrimp, fresh or frozen',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 71.0,
        ash: 1.86,
        water: 83.01,
        carbs: 0.91,
        fat: 1.01,
        monounsaturated: 0.181,
        polyunsaturated: 0.295,
        omega_3: 0.15,
        omega_6: 0.144,
        saturated: 0.261,
        transfats: 0.018,
        cholesterol: 126.0,
        protein: 13.61,
        alanine: 0.842,
        arginine: 1.342,
        aspartic_acid: 1.517,
        cystine: 0.162,
        glutamic_acid: 2.39,
        glycine: 0.801,
        histidine: 0.3,
        isoleucine: 0.627,
        leucine: 1.165,
        lysine: 1.297,
        methionine: 0.397,
        phenylalanine: 0.593,
        proline: 0.626,
        serine: 0.555,
        threonine: 0.54,
        tryptophan: 0.155,
        tyrosine: 0.515,
        valine: 0.637,
        b1_thiamine: 0.02,
        b2_riboflavin: 0.015,
        b3_niacin: 1.778,
        b5_pantothenic_acid: 0.31,
        b6_pyridoxine: 0.161,
        b12_cobalamin: 1.11,
        choline: 80.9,
        folate: 19.0,
        vitamin_a: 180.0,
        vitamin_d: 2.0,
        vitamin_e: 1.32,
        vitamin_k: 0.3,
        calcium: 54.0,
        copper: 0.182,
        iron: 0.21,
        magnesium: 22.0,
        manganese: 0.029,
        phosphorus: 244.0,
        potassium: 113.0,
        selenium: 29.6,
        sodium: 566.0,
        zinc: 0.97
      },
      restrictionWarnings: [14],
      restrictionViolations: [4],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '2': {
      id: 2,
      name: 'lamb rib chops',
      pluralName: 'lamb rib chops',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 63.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 6.0,
          amountInBase: 63.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 237.0,
        ash: 0.86,
        water: 61.86,
        fat: 16.89,
        monounsaturated: 6.833,
        polyunsaturated: 1.03,
        omega_3: 0.248,
        omega_6: 0.781,
        saturated: 8.128,
        transfats: 1.145,
        cholesterol: 69.0,
        protein: 21.26,
        alanine: 1.21,
        arginine: 1.268,
        aspartic_acid: 1.745,
        cystine: 0.217,
        glutamic_acid: 2.838,
        glycine: 1.122,
        histidine: 0.556,
        isoleucine: 0.885,
        leucine: 1.518,
        lysine: 1.622,
        methionine: 0.514,
        phenylalanine: 0.791,
        proline: 0.901,
        serine: 0.756,
        threonine: 0.869,
        tryptophan: 0.221,
        tyrosine: 0.664,
        valine: 0.952,
        b1_thiamine: 0.109,
        b2_riboflavin: 0.328,
        b3_niacin: 6.482,
        b5_pantothenic_acid: 0.577,
        b6_pyridoxine: 0.483,
        b12_cobalamin: 0.89,
        vitamin_a: 12.0,
        vitamin_e: 0.18,
        calcium: 7.0,
        copper: 0.091,
        iron: 1.55,
        magnesium: 28.0,
        manganese: 0.004,
        phosphorus: 132.0,
        potassium: 189.0,
        selenium: 10.9,
        sodium: 69.0,
        zinc: 1.94
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '41': {
      id: 41,
      name: 'coconut milk',
      pluralName: 'coconut milk',
      description: '',
      sectionId: 5,
      subsectionId: 0,
      density: 0.955246142,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 53,
          packageAmount: 1.0,
          amountInBase: 380.0,
          equivalents: ''
        },
        us: {
          unitId: 28,
          packageAmount: 1.0,
          amountInBase: 380.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 202.0,
        ash: 0.59,
        water: 71.42,
        carbs: 5.58,
        fiber: 0.91,
        sugars: 4.45,
        glucose: 0.29,
        sucrose: 4.16,
        fat: 20.8,
        monounsaturated: 0.89,
        polyunsaturated: 0.23,
        omega_6: 0.23,
        saturated: 18.45,
        protein: 1.61,
        alanine: 0.08,
        arginine: 0.26,
        aspartic_acid: 0.16,
        cystine: 0.03,
        glutamic_acid: 0.37,
        glycine: 0.08,
        histidine: 0.04,
        isoleucine: 0.06,
        leucine: 0.12,
        lysine: 0.07,
        methionine: 0.03,
        phenylalanine: 0.08,
        proline: 0.07,
        serine: 0.08,
        threonine: 0.06,
        tryptophan: 0.02,
        tyrosine: 0.05,
        valine: 0.1,
        b1_thiamine: 0.02,
        b3_niacin: 0.67,
        b5_pantothenic_acid: 0.16,
        b6_pyridoxine: 0.03,
        choline: 8.5,
        folate: 14.0,
        vitamin_c: 1.1,
        vitamin_k: 0.1,
        calcium: 4.0,
        copper: 0.24,
        iron: 0.81,
        magnesium: 32.0,
        manganese: 0.81,
        phosphorus: 59.0,
        potassium: 232.0,
        selenium: 3.7,
        sodium: 12.0,
        zinc: 0.59
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '56': {
      id: 56,
      name: 'extra firm tofu',
      pluralName: 'extra firm tofu',
      description: '',
      sectionId: 7,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 43,
          packageAmount: 1.0,
          amountInBase: 340.0,
          equivalents: ''
        },
        us: {
          unitId: 19,
          packageAmount: 1.0,
          amountInBase: 340.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 91.0,
        ash: 1.0,
        water: 81.28,
        carbs: 2.0,
        fiber: 0.4,
        starch: 0.34,
        sugars: 0.5,
        sucrose: 0.5,
        fat: 5.83,
        monounsaturated: 4.338,
        polyunsaturated: 0.542,
        omega_3: 0.06,
        omega_6: 0.48,
        saturated: 0.542,
        protein: 9.89,
        alanine: 0.406,
        arginine: 0.657,
        aspartic_acid: 1.093,
        cystine: 0.137,
        glutamic_acid: 1.709,
        glycine: 0.386,
        histidine: 0.288,
        isoleucine: 0.49,
        leucine: 0.751,
        lysine: 0.651,
        methionine: 0.126,
        phenylalanine: 0.481,
        proline: 0.533,
        serine: 0.466,
        threonine: 0.404,
        tryptophan: 0.154,
        tyrosine: 0.331,
        valine: 0.499,
        b1_thiamine: 0.048,
        b2_riboflavin: 0.045,
        b3_niacin: 0.288,
        b5_pantothenic_acid: 0.453,
        b6_pyridoxine: 0.063,
        choline: 28.1,
        folate: 17.0,
        vitamin_c: 0.5,
        vitamin_e: 0.01,
        vitamin_k: 2.0,
        calcium: 175.0,
        copper: 0.191,
        iron: 1.84,
        magnesium: 53.0,
        manganese: 0.759,
        phosphorus: 136.0,
        potassium: 132.0,
        selenium: 13.0,
        sodium: 8.0,
        zinc: 1.1
      },
      restrictionWarnings: [14],
      restrictionViolations: [9],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '9': {
      id: 9,
      name: 'crumbled blue cheese',
      pluralName: 'crumbled blue cheese',
      description: '',
      sectionId: 7,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 38,
          packageAmount: 1.0,
          amountInBase: 113.0,
          equivalents: ''
        },
        us: {
          unitId: 14,
          packageAmount: 1.0,
          amountInBase: 113.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 353.0,
        ash: 5.11,
        water: 42.41,
        carbs: 2.34,
        sugars: 0.5,
        lactose: 0.5,
        fat: 28.74,
        monounsaturated: 7.778,
        polyunsaturated: 0.8,
        omega_3: 0.264,
        omega_6: 0.536,
        saturated: 18.669,
        transfats: 0.69,
        cholesterol: 75.0,
        protein: 21.4,
        alanine: 0.644,
        arginine: 0.711,
        aspartic_acid: 1.436,
        cystine: 0.107,
        glutamic_acid: 5.179,
        glycine: 0.406,
        histidine: 0.758,
        isoleucine: 1.124,
        leucine: 1.919,
        lysine: 1.852,
        methionine: 0.584,
        phenylalanine: 1.087,
        proline: 2.1,
        serine: 1.12,
        threonine: 0.785,
        tryptophan: 0.312,
        tyrosine: 1.295,
        valine: 1.556,
        b1_thiamine: 0.029,
        b2_riboflavin: 0.382,
        b3_niacin: 1.016,
        b5_pantothenic_acid: 1.729,
        b6_pyridoxine: 0.166,
        b12_cobalamin: 1.22,
        choline: 15.4,
        folate: 36.0,
        vitamin_a: 763.0,
        vitamin_d: 21.0,
        vitamin_e: 0.25,
        vitamin_k: 2.4,
        calcium: 528.0,
        copper: 0.04,
        iron: 0.31,
        magnesium: 23.0,
        manganese: 0.009,
        phosphorus: 387.0,
        potassium: 256.0,
        selenium: 14.5,
        sodium: 1.0,
        zinc: 2.66
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '43': {
      id: 43,
      name: 'cheddar cheese',
      pluralName: 'cheddar cheese',
      description: '',
      sectionId: 2,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 69,
          packageAmount: 0.5,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 68,
          packageAmount: 0.5,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 406.0,
        ash: 3.71,
        water: 37.1,
        carbs: 1.33,
        sugars: 0.28,
        galactose: 0.1,
        lactose: 0.18,
        fat: 33.82,
        monounsaturated: 8.428,
        polyunsaturated: 1.433,
        omega_3: 0.148,
        omega_6: 1.281,
        saturated: 19.368,
        transfats: 1.179,
        cholesterol: 102.0,
        protein: 24.04,
        alanine: 0.767,
        arginine: 0.558,
        aspartic_acid: 1.77,
        cystine: 0.125,
        glutamic_acid: 4.834,
        glycine: 0.558,
        histidine: 0.558,
        isoleucine: 1.231,
        leucine: 1.98,
        lysine: 1.046,
        methionine: 0.558,
        phenylalanine: 1.096,
        proline: 2.549,
        serine: 0.796,
        threonine: 1.066,
        tryptophan: 0.558,
        tyrosine: 1.131,
        valine: 1.433,
        b1_thiamine: 0.027,
        b2_riboflavin: 0.434,
        b3_niacin: 0.039,
        b5_pantothenic_acid: 0.481,
        b6_pyridoxine: 0.049,
        b12_cobalamin: 0.88,
        choline: 16.5,
        folate: 26.0,
        vitamin_a: 994.0,
        vitamin_d: 24.0,
        vitamin_e: 0.78,
        vitamin_k: 2.9,
        calcium: 675.0,
        copper: 0.056,
        iron: 0.16,
        magnesium: 27.0,
        manganese: 0.033,
        phosphorus: 473.0,
        potassium: 76.0,
        selenium: 28.3,
        sodium: 644.0,
        zinc: 3.43
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '94': {
      id: 94,
      name: 'pinto beans',
      pluralName: 'pinto beans',
      description: '',
      sectionId: 9,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 53,
          packageAmount: 1,
          amountInBase: 258.0,
          equivalents: null
        },
        us: {
          unitId: 30,
          packageAmount: 1,
          amountInBase: 258.0,
          equivalents: null
        }
      },
      nutrition: {
        energy: 114.0,
        ash: 1.3,
        water: 70.6,
        carbs: 20.22,
        fiber: 5.5,
        starch: 10.21,
        sugars: 0.54,
        sucrose: 0.54,
        fat: 0.9,
        monounsaturated: 0.15,
        polyunsaturated: 0.27,
        omega_3: 0.16,
        omega_6: 0.12,
        saturated: 0.16,
        protein: 6.99,
        alanine: 0.29,
        arginine: 0.36,
        aspartic_acid: 0.75,
        cystine: 0.06,
        glutamic_acid: 1.0,
        glycine: 0.26,
        histidine: 0.18,
        isoleucine: 0.29,
        leucine: 0.51,
        lysine: 0.45,
        methionine: 0.09,
        phenylalanine: 0.36,
        proline: 0.35,
        serine: 0.39,
        threonine: 0.27,
        tryptophan: 0.08,
        tyrosine: 0.14,
        valine: 0.33,
        b1_thiamine: 0.05,
        b2_riboflavin: 0.02,
        b3_niacin: 0.27,
        b5_pantothenic_acid: 0.16,
        b6_pyridoxine: 0.18,
        choline: 27.4,
        folate: 24.0,
        vitamin_c: 0.1,
        vitamin_e: 0.73,
        vitamin_k: 2.7,
        calcium: 63.0,
        copper: 0.26,
        iron: 1.33,
        magnesium: 32.0,
        manganese: 0.38,
        phosphorus: 101.0,
        potassium: 234.0,
        selenium: 4.8,
        sodium: 212.0,
        zinc: 0.61
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '3': {
      id: 3,
      name: 'pork chops, bone-in',
      pluralName: 'pork chops, bone-in',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 201.0,
          equivalents: '1 pork chop = about 201 g'
        },
        us: {
          unitId: 1,
          packageAmount: 2.0,
          amountInBase: 201.0,
          equivalents: '1 pork chop = about 7 oz'
        }
      },
      nutrition: {
        energy: 186.0,
        ash: 0.95,
        water: 67.83,
        fat: 11.04,
        monounsaturated: 2.756,
        polyunsaturated: 0.964,
        omega_3: 0.064,
        omega_6: 1.375,
        saturated: 2.365,
        transfats: 0.074,
        cholesterol: 58.0,
        protein: 20.28,
        alanine: 1.191,
        arginine: 1.35,
        aspartic_acid: 1.984,
        cystine: 0.234,
        glutamic_acid: 3.238,
        glycine: 0.914,
        histidine: 0.876,
        isoleucine: 0.998,
        leucine: 1.728,
        lysine: 1.881,
        methionine: 0.559,
        phenylalanine: 0.853,
        proline: 0.82,
        serine: 0.877,
        threonine: 0.911,
        tryptophan: 0.214,
        tyrosine: 0.772,
        valine: 1.06,
        b1_thiamine: 0.456,
        b2_riboflavin: 0.177,
        b3_niacin: 6.331,
        b5_pantothenic_acid: 0.693,
        b6_pyridoxine: 0.682,
        b12_cobalamin: 0.54,
        choline: 54.8,
        vitamin_a: 8.0,
        vitamin_d: 26.0,
        vitamin_e: 0.12,
        calcium: 25.0,
        copper: 0.065,
        iron: 0.59,
        magnesium: 23.0,
        manganese: 0.008,
        phosphorus: 203.0,
        potassium: 337.0,
        selenium: 35.5,
        sodium: 56.0,
        zinc: 1.85
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '180': {
      id: 180,
      name: 'pork chops, boneless',
      pluralName: 'pork chops, boneless',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 145.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 2.0,
          amountInBase: 145.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 201.0,
        ash: 1.03,
        water: 65.15,
        fat: 12.96,
        monounsaturated: 5.349,
        polyunsaturated: 2.05,
        omega_3: 0.092,
        omega_6: 1.956,
        saturated: 4.413,
        transfats: 0.081,
        cholesterol: 59.0,
        protein: 21.14,
        alanine: 1.211,
        arginine: 1.362,
        aspartic_acid: 1.977,
        cystine: 0.238,
        glutamic_acid: 3.229,
        glycine: 0.955,
        histidine: 0.86,
        isoleucine: 1.001,
        leucine: 1.74,
        lysine: 1.88,
        methionine: 0.579,
        phenylalanine: 0.878,
        proline: 0.855,
        serine: 0.883,
        threonine: 0.929,
        tryptophan: 0.252,
        tyrosine: 0.837,
        valine: 1.069,
        b1_thiamine: 0.615,
        b2_riboflavin: 0.203,
        b3_niacin: 7.413,
        b5_pantothenic_acid: 0.67,
        b6_pyridoxine: 0.336,
        b12_cobalamin: 0.43,
        choline: 58.4,
        vitamin_a: 16.0,
        vitamin_d: 11.0,
        vitamin_e: 0.16,
        calcium: 9.0,
        copper: 0.054,
        iron: 0.48,
        magnesium: 23.0,
        phosphorus: 203.0,
        potassium: 345.0,
        selenium: 41.3,
        sodium: 82.0,
        zinc: 1.31
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '164': {
      id: 164,
      name: 'chicken thighs, boneless skinless',
      pluralName: 'chicken thighs, boneless skinless',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 0.45,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 121.0,
        ash: 0.95,
        water: 76.22,
        fat: 4.12,
        monounsaturated: 1.497,
        polyunsaturated: 0.94,
        omega_3: 0.048,
        omega_6: 0.891,
        saturated: 1.097,
        transfats: 0.02,
        cholesterol: 94.0,
        protein: 19.66,
        alanine: 1.177,
        arginine: 1.372,
        aspartic_acid: 1.933,
        cystine: 0.23,
        glutamic_acid: 3.221,
        glycine: 0.902,
        histidine: 0.587,
        isoleucine: 0.939,
        leucine: 1.675,
        lysine: 1.855,
        methionine: 0.562,
        phenylalanine: 0.785,
        proline: 0.793,
        serine: 0.823,
        threonine: 0.93,
        tryptophan: 0.222,
        tyrosine: 0.739,
        valine: 0.956,
        b1_thiamine: 0.088,
        b2_riboflavin: 0.196,
        b3_niacin: 5.557,
        b6_pyridoxine: 0.451,
        b12_cobalamin: 0.61,
        choline: 53.6,
        folate: 4.0,
        vitamin_a: 24.0,
        vitamin_d: 1.0,
        vitamin_e: 0.18,
        vitamin_k: 2.9,
        calcium: 7.0,
        copper: 0.062,
        iron: 0.81,
        magnesium: 23.0,
        manganese: 0.013,
        phosphorus: 185.0,
        potassium: 242.0,
        selenium: 22.9,
        sodium: 95.0,
        zinc: 1.58
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '269': {
      id: 269,
      name: 'basmati rice',
      pluralName: 'basmati rice',
      description: '',
      sectionId: 3,
      subsectionId: 0,
      density: 0.781949275,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 365.0,
        ash: 0.64,
        water: 11.62,
        carbs: 79.95,
        fiber: 1.3,
        sugars: 0.12,
        fructose: 0.02,
        glucose: 0.02,
        sucrose: 0.08,
        fat: 0.66,
        monounsaturated: 0.206,
        polyunsaturated: 0.177,
        omega_3: 0.031,
        omega_6: 0.146,
        saturated: 0.18,
        protein: 7.13,
        alanine: 0.413,
        arginine: 0.594,
        aspartic_acid: 0.67,
        cystine: 0.146,
        glutamic_acid: 1.389,
        glycine: 0.325,
        histidine: 0.168,
        isoleucine: 0.308,
        leucine: 0.589,
        lysine: 0.258,
        methionine: 0.168,
        phenylalanine: 0.381,
        proline: 0.335,
        serine: 0.375,
        threonine: 0.255,
        tryptophan: 0.083,
        tyrosine: 0.238,
        valine: 0.435,
        b1_thiamine: 0.576,
        b2_riboflavin: 0.049,
        b3_niacin: 4.192,
        b5_pantothenic_acid: 1.014,
        b6_pyridoxine: 0.164,
        choline: 5.8,
        folate: 231.0,
        vitamin_e: 0.11,
        vitamin_k: 0.1,
        calcium: 28.0,
        copper: 0.22,
        iron: 4.31,
        magnesium: 25.0,
        manganese: 1.088,
        phosphorus: 115.0,
        potassium: 115.0,
        selenium: 15.1,
        sodium: 5.0,
        zinc: 1.09
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '78': {
      id: 78,
      name: 'dill, dried',
      pluralName: 'dill, dried',
      description: '',
      sectionId: 8,
      subsectionId: null,
      density: 0.209646941,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 253.0,
        ash: 12.56,
        water: 7.3,
        carbs: 55.82,
        fiber: 15.2,
        starch: 3.98,
        sugars: 35.52,
        fructose: 9.05,
        glucose: 13.57,
        sucrose: 12.9,
        fat: 4.36,
        monounsaturated: 2.94,
        polyunsaturated: 0.36,
        omega_3: 0.08,
        omega_6: 0.28,
        saturated: 0.23,
        protein: 19.96,
        alanine: 1.33,
        arginine: 0.81,
        aspartic_acid: 1.96,
        cystine: 0.12,
        glutamic_acid: 1.67,
        glycine: 0.98,
        histidine: 0.4,
        isoleucine: 1.15,
        leucine: 0.92,
        lysine: 1.44,
        methionine: 0.06,
        phenylalanine: 0.35,
        proline: 1.44,
        serine: 0.92,
        threonine: 0.4,
        tryptophan: 0.06,
        tyrosine: 0.58,
        valine: 0.87,
        b1_thiamine: 0.42,
        b2_riboflavin: 0.28,
        b3_niacin: 2.81,
        b5_pantothenic_acid: 2.24,
        b6_pyridoxine: 1.71,
        choline: 61.4,
        vitamin_a: 8.0,
        vitamin_c: 50.0,
        vitamin_e: 9.27,
        vitamin_k: 1.0,
        calcium: 1.0,
        copper: 0.49,
        iron: 48.78,
        magnesium: 451.0,
        manganese: 3.95,
        phosphorus: 543.0,
        potassium: 3.0,
        selenium: 13.2,
        sodium: 208.0,
        zinc: 3.3
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '215': {
      id: 215,
      name: 'dried apricot',
      pluralName: 'dried apricots',
      description: '',
      sectionId: 12,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 4.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 4.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 241.0,
        ash: 2.57,
        water: 30.89,
        carbs: 62.64,
        fiber: 7.3,
        starch: 0.35,
        sugars: 53.44,
        fructose: 12.47,
        glucose: 33.08,
        sucrose: 7.89,
        fat: 0.51,
        monounsaturated: 0.074,
        polyunsaturated: 0.074,
        omega_6: 0.074,
        saturated: 0.017,
        protein: 3.39,
        alanine: 0.11,
        arginine: 0.066,
        aspartic_acid: 0.937,
        cystine: 0.019,
        glutamic_acid: 0.188,
        glycine: 0.07,
        histidine: 0.047,
        isoleucine: 0.063,
        leucine: 0.105,
        lysine: 0.083,
        methionine: 0.015,
        phenylalanine: 0.062,
        proline: 0.821,
        serine: 0.087,
        threonine: 0.073,
        tryptophan: 0.016,
        tyrosine: 0.039,
        valine: 0.078,
        b1_thiamine: 0.015,
        b2_riboflavin: 0.074,
        b3_niacin: 2.589,
        b5_pantothenic_acid: 0.516,
        b6_pyridoxine: 0.143,
        choline: 13.9,
        folate: 10.0,
        vitamin_a: 3.0,
        vitamin_c: 1.0,
        vitamin_e: 4.33,
        vitamin_k: 3.1,
        calcium: 55.0,
        copper: 0.343,
        iron: 2.66,
        magnesium: 32.0,
        manganese: 0.235,
        phosphorus: 71.0,
        potassium: 1.0,
        selenium: 2.2,
        sodium: 10.0,
        zinc: 0.39
      },
      restrictionWarnings: [],
      restrictionViolations: [14],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '44': {
      id: 44,
      name: 'chicken breasts, boneless skinless',
      pluralName: 'chicken breasts, boneless skinless',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 0.454,
          amountInBase: null,
          equivalents: '1 chicken breast = 170-227 g'
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '1 chicken breast = 6-8 oz'
        }
      },
      nutrition: {
        energy: 120.0,
        ash: 1.13,
        water: 73.9,
        fat: 2.62,
        monounsaturated: 0.689,
        polyunsaturated: 0.424,
        omega_3: 0.026,
        omega_6: 0.398,
        saturated: 0.563,
        transfats: 0.007,
        cholesterol: 73.0,
        protein: 22.5,
        alanine: 1.313,
        arginine: 1.521,
        aspartic_acid: 2.116,
        cystine: 0.236,
        glutamic_acid: 3.333,
        glycine: 0.996,
        histidine: 0.839,
        isoleucine: 1.104,
        leucine: 1.861,
        lysine: 2.163,
        methionine: 0.585,
        phenylalanine: 0.908,
        proline: 0.715,
        serine: 0.858,
        threonine: 1.009,
        tryptophan: 0.283,
        tyrosine: 0.81,
        valine: 1.165,
        b1_thiamine: 0.094,
        b2_riboflavin: 0.177,
        b3_niacin: 9.6,
        b5_pantothenic_acid: 1.495,
        b6_pyridoxine: 0.811,
        b12_cobalamin: 0.21,
        choline: 82.1,
        folate: 9.0,
        vitamin_a: 25.0,
        vitamin_d: 1.0,
        vitamin_e: 0.56,
        vitamin_k: 0.2,
        calcium: 5.0,
        copper: 0.037,
        iron: 0.37,
        magnesium: 28.0,
        manganese: 0.011,
        phosphorus: 213.0,
        potassium: 334.0,
        selenium: 22.8,
        sodium: 45.0,
        zinc: 0.68
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '170': {
      id: 170,
      name: 'ground turkey',
      pluralName: 'ground turkey',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 0.68,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.5,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 148.0,
        ash: 1.0,
        water: 72.36,
        fat: 7.66,
        monounsaturated: 2.635,
        polyunsaturated: 2.205,
        omega_3: 0.129,
        omega_6: 2.077,
        saturated: 2.024,
        transfats: 0.103,
        cholesterol: 69.0,
        protein: 19.66,
        alanine: 1.223,
        arginine: 1.426,
        aspartic_acid: 1.897,
        cystine: 0.208,
        glutamic_acid: 3.144,
        glycine: 1.075,
        histidine: 0.582,
        isoleucine: 0.88,
        leucine: 1.622,
        lysine: 1.757,
        methionine: 0.578,
        phenylalanine: 0.765,
        proline: 0.869,
        serine: 0.835,
        threonine: 0.901,
        tryptophan: 0.223,
        tyrosine: 0.696,
        valine: 0.91,
        b1_thiamine: 0.066,
        b2_riboflavin: 0.156,
        b3_niacin: 6.733,
        b5_pantothenic_acid: 0.993,
        b6_pyridoxine: 0.564,
        b12_cobalamin: 1.0,
        choline: 50.8,
        folate: 7.0,
        vitamin_a: 66.0,
        vitamin_d: 14.0,
        vitamin_e: 0.09,
        calcium: 19.0,
        copper: 0.096,
        iron: 1.09,
        magnesium: 23.0,
        manganese: 0.008,
        phosphorus: 200.0,
        potassium: 237.0,
        selenium: 21.9,
        sodium: 58.0,
        zinc: 2.35
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '326': {
      id: 326,
      name: 'sea scallops',
      pluralName: 'sea scallops',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 69.0,
        ash: 1.74,
        water: 82.53,
        carbs: 3.18,
        starch: 2.17,
        fat: 0.49,
        monounsaturated: 0.048,
        polyunsaturated: 0.13,
        omega_3: 0.113,
        omega_6: 0.016,
        saturated: 0.128,
        transfats: 0.005,
        cholesterol: 24.0,
        protein: 12.06,
        alanine: 0.536,
        arginine: 0.646,
        aspartic_acid: 0.923,
        cystine: 0.12,
        glutamic_acid: 1.404,
        glycine: 1.034,
        histidine: 0.185,
        isoleucine: 0.406,
        leucine: 0.72,
        lysine: 0.739,
        methionine: 0.286,
        phenylalanine: 0.351,
        proline: 0.286,
        serine: 0.36,
        threonine: 0.369,
        tryptophan: 0.102,
        tyrosine: 0.296,
        valine: 0.379,
        b1_thiamine: 0.007,
        b2_riboflavin: 0.015,
        b3_niacin: 0.703,
        b5_pantothenic_acid: 0.215,
        b6_pyridoxine: 0.073,
        b12_cobalamin: 1.41,
        choline: 65.0,
        folate: 16.0,
        vitamin_a: 3.0,
        vitamin_d: 1.0,
        calcium: 6.0,
        copper: 0.023,
        iron: 0.38,
        magnesium: 22.0,
        manganese: 0.017,
        phosphorus: 334.0,
        potassium: 205.0,
        selenium: 12.8,
        sodium: 392.0,
        zinc: 0.91
      },
      restrictionWarnings: [],
      restrictionViolations: [4],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '161': {
      id: 161,
      name: 'chicken drumsticks',
      pluralName: 'chicken drumsticks',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '567 g = 4-6 chicken drumsticks'
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '1.25 lb = 4-6 chicken drumsticks'
        }
      },
      nutrition: {
        energy: 161.0,
        ash: 0.86,
        water: 72.46,
        carbs: 0.11,
        fat: 9.2,
        monounsaturated: 3.719,
        polyunsaturated: 1.965,
        omega_3: 0.033,
        omega_6: 0.635,
        saturated: 2.459,
        transfats: 0.047,
        cholesterol: 92.0,
        protein: 18.08,
        alanine: 1.094,
        arginine: 1.264,
        aspartic_acid: 1.747,
        cystine: 0.207,
        glutamic_acid: 2.902,
        glycine: 0.936,
        histidine: 0.527,
        isoleucine: 0.838,
        leucine: 1.501,
        lysine: 1.657,
        methionine: 0.502,
        phenylalanine: 0.71,
        proline: 0.776,
        serine: 0.749,
        threonine: 0.835,
        tryptophan: 0.196,
        tyrosine: 0.661,
        valine: 0.862,
        b1_thiamine: 0.083,
        b2_riboflavin: 0.195,
        b3_niacin: 4.841,
        b5_pantothenic_acid: 1.034,
        b6_pyridoxine: 0.345,
        b12_cobalamin: 0.53,
        choline: 52.8,
        folate: 3.0,
        vitamin_a: 46.0,
        vitamin_d: 2.0,
        vitamin_e: 0.19,
        vitamin_k: 2.5,
        calcium: 8.0,
        copper: 0.062,
        iron: 0.71,
        magnesium: 6.0,
        manganese: 0.018,
        phosphorus: 163.0,
        potassium: 211.0,
        selenium: 20.2,
        sodium: 106.0,
        zinc: 1.86
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '319': {
      id: 319,
      name: 'jasmine rice',
      pluralName: 'jasmine rice',
      description: '',
      sectionId: 3,
      subsectionId: 0,
      density: 0.781949275,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 365.0,
        ash: 0.64,
        water: 11.62,
        carbs: 79.95,
        fiber: 1.3,
        sugars: 0.12,
        fructose: 0.02,
        glucose: 0.02,
        sucrose: 0.08,
        fat: 0.66,
        monounsaturated: 0.206,
        polyunsaturated: 0.177,
        omega_3: 0.031,
        omega_6: 0.146,
        saturated: 0.18,
        protein: 7.13,
        alanine: 0.413,
        arginine: 0.594,
        aspartic_acid: 0.67,
        cystine: 0.146,
        glutamic_acid: 1.389,
        glycine: 0.325,
        histidine: 0.168,
        isoleucine: 0.308,
        leucine: 0.589,
        lysine: 0.258,
        methionine: 0.168,
        phenylalanine: 0.381,
        proline: 0.335,
        serine: 0.375,
        threonine: 0.255,
        tryptophan: 0.083,
        tyrosine: 0.238,
        valine: 0.435,
        b1_thiamine: 0.576,
        b2_riboflavin: 0.049,
        b3_niacin: 4.192,
        b5_pantothenic_acid: 1.014,
        b6_pyridoxine: 0.164,
        choline: 5.8,
        folate: 231.0,
        vitamin_e: 0.11,
        vitamin_k: 0.1,
        calcium: 28.0,
        copper: 0.22,
        iron: 4.31,
        magnesium: 25.0,
        manganese: 1.088,
        phosphorus: 115.0,
        potassium: 115.0,
        selenium: 15.1,
        sodium: 5.0,
        zinc: 1.09
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '207': {
      id: 207,
      name: 'lamb loin chops',
      pluralName: 'lamb loin chops',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 71.0,
          equivalents: '1 lamb loin chop = 113-170 g\r\n'
        },
        us: {
          unitId: 1,
          packageAmount: 4.0,
          amountInBase: 71.0,
          equivalents: '1 lamb loin chop = 4-6 oz\r\n'
        }
      },
      nutrition: {
        energy: 276.0,
        ash: 1.21,
        water: 56.26,
        fat: 19.97,
        monounsaturated: 8.17,
        polyunsaturated: 1.62,
        omega_3: 0.34,
        omega_6: 1.28,
        saturated: 8.44,
        transfats: 0.8,
        cholesterol: 92.0,
        protein: 22.51,
        alanine: 1.35,
        arginine: 1.34,
        aspartic_acid: 1.98,
        cystine: 0.27,
        glutamic_acid: 3.27,
        glycine: 1.1,
        histidine: 0.71,
        isoleucine: 1.09,
        leucine: 1.75,
        lysine: 1.99,
        methionine: 0.58,
        phenylalanine: 0.92,
        proline: 0.94,
        serine: 0.84,
        threonine: 0.96,
        tryptophan: 0.26,
        tyrosine: 0.76,
        valine: 1.22,
        b1_thiamine: 0.09,
        b2_riboflavin: 0.24,
        b3_niacin: 6.15,
        b5_pantothenic_acid: 0.7,
        b6_pyridoxine: 0.13,
        b12_cobalamin: 2.64,
        choline: 87.9,
        folate: 21.0,
        vitamin_d: 2.0,
        vitamin_e: 0.14,
        vitamin_k: 4.6,
        calcium: 20.0,
        copper: 0.11,
        iron: 1.97,
        magnesium: 23.0,
        manganese: 0.02,
        phosphorus: 184.0,
        potassium: 251.0,
        selenium: 26.2,
        sodium: 66.0,
        zinc: 5.23
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '123': {
      id: 123,
      name: 'mozzarella style shreds',
      pluralName: 'mozzarella style shreds',
      description: '',
      sectionId: 2,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: true,
      unitAssignments: {
        metric: {
          unitId: 42,
          packageAmount: 0.5,
          amountInBase: 227.0,
          equivalents: ''
        },
        us: {
          unitId: 18,
          packageAmount: 1.0,
          amountInBase: 227.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 321.43,
        carbs: 25.0,
        fiber: 3.57,
        fat: 21.43,
        saturated: 7.14,
        protein: 3.57,
        b1_thiamine: 2.04,
        b2_riboflavin: 2.04,
        b3_niacin: 12.07,
        b6_pyridoxine: 2.07,
        b12_cobalamin: 1.68,
        folate: 52.79,
        vitamin_e: 2.63,
        calcium: 535.71,
        magnesium: 2.39,
        phosphorus: 32.82,
        potassium: 118.0,
        sodium: 1.0,
        zinc: 0.03
      },
      restrictionWarnings: [14, 13, 12, 11, 9, 6, 5, 1],
      restrictionViolations: [10],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '195': {
      id: 195,
      name: 'no-boil lasagna noodles',
      pluralName: 'no-boil lasagna noodles',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 25.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 25.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 371.0,
        ash: 0.88,
        water: 9.9,
        carbs: 74.67,
        fiber: 3.2,
        starch: 62.45,
        sugars: 2.67,
        fructose: 0.11,
        glucose: 0.12,
        maltose: 1.96,
        sucrose: 0.48,
        fat: 1.51,
        monounsaturated: 0.171,
        polyunsaturated: 0.564,
        omega_3: 0.024,
        omega_6: 0.54,
        saturated: 0.277,
        protein: 13.04,
        alanine: 0.438,
        arginine: 0.474,
        aspartic_acid: 0.624,
        cystine: 0.255,
        glutamic_acid: 4.596,
        glycine: 0.441,
        histidine: 0.298,
        isoleucine: 0.511,
        leucine: 0.988,
        lysine: 0.298,
        methionine: 0.147,
        phenylalanine: 0.668,
        proline: 1.569,
        serine: 0.617,
        threonine: 0.462,
        tryptophan: 0.185,
        tyrosine: 0.243,
        valine: 0.588,
        b1_thiamine: 0.09,
        b2_riboflavin: 0.06,
        b3_niacin: 1.7,
        b5_pantothenic_acid: 0.431,
        b6_pyridoxine: 0.142,
        folate: 18.0,
        vitamin_e: 0.11,
        vitamin_k: 0.1,
        calcium: 21.0,
        copper: 0.289,
        iron: 1.3,
        magnesium: 53.0,
        manganese: 0.917,
        phosphorus: 189.0,
        potassium: 223.0,
        selenium: 63.2,
        sodium: 6.0,
        zinc: 1.41
      },
      restrictionWarnings: [11],
      restrictionViolations: [1],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '185': {
      id: 185,
      name: 'black beans',
      pluralName: 'black beans',
      description: '',
      sectionId: 9,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 53,
          packageAmount: 1.0,
          amountInBase: 258.0,
          equivalents: ''
        },
        us: {
          unitId: 30,
          packageAmount: 1.0,
          amountInBase: 258.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 140.0,
        ash: 1.3,
        water: 63.81,
        carbs: 26.05,
        fiber: 10.5,
        starch: 15.4,
        sugars: 0.37,
        sucrose: 0.37,
        fat: 0.62,
        monounsaturated: 0.14,
        polyunsaturated: 0.49,
        omega_3: 0.18,
        omega_6: 0.14,
        saturated: 0.1,
        protein: 8.23,
        alanine: 0.37,
        arginine: 0.42,
        aspartic_acid: 1.06,
        cystine: 0.08,
        glutamic_acid: 1.26,
        glycine: 0.33,
        histidine: 0.21,
        isoleucine: 0.39,
        leucine: 0.7,
        lysine: 0.52,
        methionine: 0.11,
        phenylalanine: 0.47,
        proline: 0.45,
        serine: 0.48,
        threonine: 0.29,
        tryptophan: 0.1,
        tyrosine: 0.2,
        valine: 0.5,
        b1_thiamine: 0.24,
        b2_riboflavin: 0.07,
        b3_niacin: 0.65,
        b5_pantothenic_acid: 0.27,
        b6_pyridoxine: 0.14,
        choline: 44.7,
        folate: 140.0,
        vitamin_c: 0.9,
        vitamin_e: 0.01,
        vitamin_k: 0.6,
        calcium: 69.0,
        copper: 0.21,
        iron: 2.36,
        magnesium: 53.0,
        manganese: 0.53,
        phosphorus: 144.0,
        potassium: 389.0,
        selenium: 2.9,
        sodium: 385.0,
        zinc: 1.03
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '54': {
      id: 54,
      name: 'tamari soy sauce',
      pluralName: 'tamari soy sauce',
      description: '',
      sectionId: 5,
      subsectionId: 0,
      density: 1.217304818,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 53.0,
        ash: 15.31,
        water: 70.77,
        carbs: 7.61,
        fiber: 0.8,
        starch: 3.76,
        sugars: 2.83,
        fructose: 0.9,
        galactose: 0.4,
        glucose: 0.08,
        maltose: 0.01,
        sucrose: 1.44,
        fat: 0.04,
        polyunsaturated: 0.02,
        omega_6: 0.02,
        protein: 6.28,
        alanine: 0.28,
        arginine: 0.43,
        aspartic_acid: 0.67,
        cystine: 0.11,
        glutamic_acid: 1.48,
        glycine: 0.28,
        histidine: 0.16,
        isoleucine: 0.3,
        leucine: 0.5,
        lysine: 0.36,
        methionine: 0.09,
        phenylalanine: 0.33,
        proline: 0.46,
        serine: 0.36,
        threonine: 0.25,
        tryptophan: 0.09,
        tyrosine: 0.23,
        valine: 0.31,
        b1_thiamine: 0.03,
        b2_riboflavin: 0.17,
        b3_niacin: 2.2,
        b5_pantothenic_acid: 0.3,
        b6_pyridoxine: 0.15,
        choline: 18.3,
        folate: 14.0,
        calcium: 19.0,
        copper: 0.1,
        iron: 1.93,
        magnesium: 43.0,
        manganese: 0.42,
        phosphorus: 125.0,
        potassium: 217.0,
        selenium: 0.5,
        sodium: 5.0,
        zinc: 0.52
      },
      restrictionWarnings: [14, 1],
      restrictionViolations: [9],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '216': {
      id: 216,
      name: 'pine nuts',
      pluralName: 'pine nuts',
      description: '',
      sectionId: 12,
      subsectionId: 0,
      density: 0.570611633,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 673.0,
        ash: 2.59,
        water: 2.28,
        carbs: 13.08,
        fiber: 3.7,
        starch: 1.43,
        sugars: 3.59,
        fructose: 0.07,
        glucose: 0.07,
        sucrose: 3.45,
        fat: 68.37,
        monounsaturated: 18.764,
        polyunsaturated: 34.071,
        omega_3: 0.465,
        omega_6: 33.606,
        saturated: 4.899,
        protein: 13.69,
        alanine: 0.684,
        arginine: 2.413,
        aspartic_acid: 1.303,
        cystine: 0.289,
        glutamic_acid: 2.926,
        glycine: 0.691,
        histidine: 0.341,
        isoleucine: 0.542,
        leucine: 0.991,
        lysine: 0.54,
        methionine: 0.259,
        phenylalanine: 0.524,
        proline: 0.673,
        serine: 0.835,
        threonine: 0.37,
        tryptophan: 0.107,
        tyrosine: 0.509,
        valine: 0.687,
        b1_thiamine: 0.364,
        b2_riboflavin: 0.227,
        b3_niacin: 4.387,
        b5_pantothenic_acid: 0.313,
        b6_pyridoxine: 0.094,
        choline: 55.8,
        folate: 34.0,
        vitamin_a: 29.0,
        vitamin_c: 0.8,
        vitamin_e: 9.33,
        vitamin_k: 53.9,
        calcium: 16.0,
        copper: 1.324,
        iron: 5.53,
        magnesium: 251.0,
        manganese: 8.802,
        phosphorus: 575.0,
        potassium: 597.0,
        selenium: 0.7,
        sodium: 2.0,
        zinc: 6.45
      },
      restrictionWarnings: [],
      restrictionViolations: [6],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '127': {
      id: 127,
      name: 'gluten-free bun or roll',
      pluralName: 'gluten-free buns or rolls',
      description: '',
      sectionId: 6,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1,
          amountInBase: 77.0,
          equivalents: null
        },
        us: {
          unitId: 1,
          packageAmount: 1,
          amountInBase: 77.0,
          equivalents: null
        }
      },
      nutrition: {
        energy: 222.22,
        carbs: 39.51,
        fiber: 7.41,
        starch: 27.16,
        sugars: 4.94,
        fat: 6.17,
        protein: 6.17,
        b1_thiamine: 0.16,
        b2_riboflavin: 0.16,
        b3_niacin: 1.14,
        b6_pyridoxine: 0.11,
        folate: 19.4,
        vitamin_e: 1.89,
        calcium: 24.69,
        copper: 0.09,
        iron: 1.33,
        magnesium: 31.2,
        phosphorus: 73.22,
        potassium: 127.8,
        sodium: 358.02,
        zinc: 0.48
      },
      restrictionWarnings: [14, 13, 12, 9, 6, 5, 2],
      restrictionViolations: [11, 10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '214': {
      id: 214,
      name: 'raisins',
      pluralName: 'raisins',
      description: '',
      sectionId: 12,
      subsectionId: 0,
      density: 0.612879162,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 299.0,
        ash: 1.85,
        water: 15.43,
        carbs: 79.18,
        fiber: 3.7,
        starch: 2.7,
        sugars: 59.19,
        fructose: 29.68,
        glucose: 27.75,
        sucrose: 0.45,
        fat: 0.46,
        monounsaturated: 0.051,
        polyunsaturated: 0.037,
        omega_3: 0.007,
        omega_6: 0.029,
        saturated: 0.058,
        protein: 3.07,
        alanine: 0.105,
        arginine: 0.413,
        aspartic_acid: 0.11,
        cystine: 0.019,
        glutamic_acid: 0.164,
        glycine: 0.08,
        histidine: 0.072,
        isoleucine: 0.057,
        leucine: 0.096,
        lysine: 0.084,
        methionine: 0.021,
        phenylalanine: 0.065,
        proline: 0.254,
        serine: 0.07,
        threonine: 0.077,
        tryptophan: 0.05,
        tyrosine: 0.012,
        valine: 0.083,
        b1_thiamine: 0.106,
        b2_riboflavin: 0.125,
        b3_niacin: 0.766,
        b5_pantothenic_acid: 0.095,
        b6_pyridoxine: 0.174,
        choline: 11.1,
        folate: 5.0,
        vitamin_c: 2.3,
        vitamin_e: 0.12,
        vitamin_k: 3.5,
        calcium: 50.0,
        copper: 0.318,
        iron: 1.88,
        magnesium: 32.0,
        manganese: 0.299,
        phosphorus: 101.0,
        potassium: 749.0,
        selenium: 0.6,
        sodium: 11.0,
        zinc: 0.22
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '121': {
      id: 121,
      name: 'egg',
      pluralName: 'eggs',
      description: '',
      sectionId: 2,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 44.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 44.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 143.0,
        ash: 1.06,
        water: 76.15,
        carbs: 0.72,
        sugars: 0.37,
        glucose: 0.37,
        fat: 9.51,
        monounsaturated: 3.658,
        polyunsaturated: 1.911,
        omega_3: 0.102,
        omega_6: 1.808,
        saturated: 3.126,
        transfats: 0.038,
        cholesterol: 372.0,
        protein: 12.56,
        alanine: 0.735,
        arginine: 0.82,
        aspartic_acid: 1.329,
        cystine: 0.272,
        glutamic_acid: 1.673,
        glycine: 0.432,
        histidine: 0.309,
        isoleucine: 0.671,
        leucine: 1.086,
        lysine: 0.912,
        methionine: 0.38,
        phenylalanine: 0.68,
        proline: 0.512,
        serine: 0.971,
        threonine: 0.556,
        tryptophan: 0.167,
        tyrosine: 0.499,
        valine: 0.858,
        b1_thiamine: 0.04,
        b2_riboflavin: 0.457,
        b3_niacin: 0.075,
        b5_pantothenic_acid: 1.533,
        b6_pyridoxine: 0.17,
        b12_cobalamin: 0.89,
        choline: 293.8,
        folate: 47.0,
        vitamin_a: 540.0,
        vitamin_d: 82.0,
        vitamin_e: 1.05,
        vitamin_k: 0.3,
        calcium: 56.0,
        copper: 0.072,
        iron: 1.75,
        magnesium: 12.0,
        manganese: 0.028,
        phosphorus: 198.0,
        potassium: 138.0,
        selenium: 30.7,
        sodium: 142.0,
        zinc: 1.29
      },
      restrictionWarnings: [],
      restrictionViolations: [11],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '300': {
      id: 300,
      name: 'gluten-free fettuccine pasta',
      pluralName: 'gluten-free fettuccine pasta',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        },
        us: {
          unitId: 11,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        }
      },
      nutrition: {
        energy: 352.73,
        carbs: 77.6,
        fiber: 3.53,
        fat: 2.65,
        monounsaturated: 0.88,
        polyunsaturated: 0.88,
        protein: 7.05,
        iron: 0.63
      },
      restrictionWarnings: [14, 12, 11, 10, 9, 6, 5, 2],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '301': {
      id: 301,
      name: 'gluten-free linguine pasta',
      pluralName: 'gluten-free linguine pasta',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        },
        us: {
          unitId: 11,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        }
      },
      nutrition: {
        energy: 352.73,
        carbs: 77.6,
        fiber: 3.53,
        fat: 2.65,
        monounsaturated: 0.88,
        polyunsaturated: 0.88,
        protein: 7.05,
        iron: 0.63
      },
      restrictionWarnings: [14, 12, 11, 10, 9, 6, 5, 2],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '250': {
      id: 250,
      name: 'flat rice (pad thai) noodles',
      pluralName: 'flat rice (pad thai) noodles',
      description: '',
      sectionId: 5,
      subsectionId: 0,
      density: null,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 364.0,
        ash: 0.85,
        water: 11.91,
        carbs: 80.18,
        fiber: 1.6,
        starch: 78.46,
        sugars: 0.12,
        fat: 0.56,
        monounsaturated: 0.175,
        polyunsaturated: 0.15,
        omega_3: 0.026,
        omega_6: 0.124,
        saturated: 0.153,
        protein: 5.95,
        alanine: 0.332,
        arginine: 0.516,
        aspartic_acid: 0.549,
        cystine: 0.107,
        glutamic_acid: 1.098,
        glycine: 0.267,
        histidine: 0.149,
        isoleucine: 0.244,
        leucine: 0.488,
        lysine: 0.207,
        methionine: 0.144,
        phenylalanine: 0.317,
        proline: 0.278,
        serine: 0.31,
        threonine: 0.21,
        tryptophan: 0.072,
        tyrosine: 0.314,
        valine: 0.348,
        b1_thiamine: 0.031,
        b2_riboflavin: 0.017,
        b3_niacin: 0.221,
        b5_pantothenic_acid: 0.051,
        b6_pyridoxine: 0.015,
        choline: 5.5,
        folate: 3.0,
        vitamin_e: 0.11,
        calcium: 18.0,
        copper: 0.078,
        iron: 0.7,
        magnesium: 12.0,
        manganese: 0.498,
        phosphorus: 153.0,
        potassium: 30.0,
        selenium: 15.1,
        sodium: 182.0,
        zinc: 0.74
      },
      restrictionWarnings: [14, 10],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '266': {
      id: 266,
      name: 'gluten-free bread',
      pluralName: 'gluten-free bread',
      description: '',
      sectionId: 6,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 67,
          packageAmount: 1,
          amountInBase: 30.0,
          equivalents: null
        },
        us: {
          unitId: 67,
          packageAmount: 1,
          amountInBase: 30.0,
          equivalents: null
        }
      },
      nutrition: {
        energy: 229.09,
        ash: 2.0,
        water: 56.46,
        carbs: 45.79,
        fiber: 3.64,
        starch: 36.25,
        sugars: 5.83,
        fructose: 0.01,
        glucose: 0.02,
        maltose: 0.01,
        sucrose: 5.78,
        fat: 4.36,
        monounsaturated: 1.65,
        polyunsaturated: 1.45,
        omega_3: 0.09,
        omega_6: 1.36,
        saturated: 0.9,
        protein: 3.85,
        alanine: 0.23,
        arginine: 0.31,
        aspartic_acid: 0.36,
        cystine: 0.07,
        glutamic_acid: 0.66,
        glycine: 0.19,
        histidine: 0.1,
        isoleucine: 0.16,
        leucine: 0.31,
        lysine: 0.17,
        methionine: 0.09,
        phenylalanine: 0.2,
        proline: 0.18,
        serine: 0.2,
        threonine: 0.15,
        tryptophan: 0.04,
        tyrosine: 0.18,
        valine: 0.23,
        b1_thiamine: 0.33,
        b2_riboflavin: 0.08,
        b3_niacin: 3.23,
        b5_pantothenic_acid: 0.88,
        b6_pyridoxine: 0.4,
        choline: 4.49,
        folate: 36.01,
        vitamin_e: 1.14,
        vitamin_k: 0.75,
        calcium: 22.98,
        copper: 0.1,
        iron: 1.04,
        magnesium: 53.76,
        manganese: 1.19,
        phosphorus: 128.06,
        potassium: 125.37,
        selenium: 7.89,
        sodium: 447.25,
        zinc: 0.74
      },
      restrictionWarnings: [14, 13, 12, 9, 6, 5, 2],
      restrictionViolations: [11, 10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '303': {
      id: 303,
      name: 'frozen corn',
      pluralName: 'frozen corn',
      description: '',
      sectionId: 13,
      subsectionId: 0,
      density: 0.574838386,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 88.0,
        ash: 0.48,
        water: 75.0,
        carbs: 20.71,
        fiber: 2.1,
        starch: 15.1,
        sugars: 2.5,
        fructose: 0.3,
        glucose: 0.38,
        maltose: 0.17,
        sucrose: 1.65,
        fat: 0.78,
        monounsaturated: 0.227,
        polyunsaturated: 0.366,
        omega_3: 0.011,
        omega_6: 0.355,
        saturated: 0.119,
        protein: 3.02,
        alanine: 0.236,
        arginine: 0.122,
        aspartic_acid: 0.205,
        cystine: 0.045,
        glutamic_acid: 0.577,
        glycine: 0.106,
        histidine: 0.079,
        isoleucine: 0.092,
        leucine: 0.363,
        lysine: 0.274,
        methionine: 0.067,
        phenylalanine: 0.135,
        proline: 0.366,
        serine: 0.162,
        threonine: 0.088,
        tryptophan: 0.027,
        tyrosine: 0.113,
        valine: 0.137,
        b1_thiamine: 0.083,
        b2_riboflavin: 0.068,
        b3_niacin: 1.739,
        b5_pantothenic_acid: 0.361,
        b6_pyridoxine: 0.168,
        choline: 24.0,
        folate: 36.0,
        vitamin_a: 195.0,
        vitamin_c: 6.4,
        vitamin_e: 0.08,
        vitamin_k: 0.3,
        calcium: 4.0,
        copper: 0.036,
        iron: 0.42,
        magnesium: 18.0,
        manganese: 0.123,
        phosphorus: 70.0,
        potassium: 213.0,
        selenium: 0.7,
        sodium: 3.0,
        zinc: 0.38
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '305': {
      id: 305,
      name: 'green lentils, dried',
      pluralName: 'green lentils, dried',
      description: '',
      sectionId: 3,
      subsectionId: null,
      density: 0.811536545,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 352.0,
        ash: 2.71,
        water: 8.26,
        carbs: 63.35,
        fiber: 10.7,
        starch: 49.9,
        sugars: 2.03,
        fructose: 0.27,
        maltose: 0.3,
        sucrose: 1.47,
        fat: 1.06,
        monounsaturated: 0.193,
        polyunsaturated: 0.526,
        omega_3: 0.112,
        omega_6: 0.414,
        saturated: 0.154,
        protein: 24.63,
        alanine: 1.029,
        arginine: 1.903,
        aspartic_acid: 2.725,
        cystine: 0.322,
        glutamic_acid: 3.819,
        glycine: 1.002,
        histidine: 0.693,
        isoleucine: 1.065,
        leucine: 1.786,
        lysine: 1.72,
        methionine: 0.21,
        phenylalanine: 1.215,
        proline: 1.029,
        serine: 1.136,
        threonine: 0.882,
        tryptophan: 0.221,
        tyrosine: 0.658,
        valine: 1.223,
        b1_thiamine: 0.873,
        b2_riboflavin: 0.211,
        b3_niacin: 2.605,
        b5_pantothenic_acid: 2.14,
        b6_pyridoxine: 0.54,
        choline: 96.4,
        folate: 479.0,
        vitamin_a: 39.0,
        vitamin_c: 4.5,
        vitamin_e: 0.49,
        vitamin_k: 5.0,
        calcium: 35.0,
        copper: 0.754,
        iron: 6.51,
        magnesium: 47.0,
        manganese: 1.393,
        phosphorus: 281.0,
        potassium: 677.0,
        selenium: 0.1,
        sodium: 6.0,
        zinc: 3.27
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '295': {
      id: 295,
      name: 'whole grain pita',
      pluralName: 'whole grain pitas',
      description: '',
      sectionId: 6,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 64.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 64.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 266.0,
        ash: 2.0,
        water: 30.6,
        carbs: 55.0,
        fiber: 7.4,
        starch: 42.52,
        sugars: 0.82,
        fructose: 0.04,
        glucose: 0.8,
        maltose: 0.07,
        sucrose: 0.26,
        fat: 2.6,
        monounsaturated: 0.349,
        polyunsaturated: 1.055,
        omega_3: 0.052,
        omega_6: 1.004,
        saturated: 0.41,
        protein: 9.8,
        alanine: 0.343,
        arginine: 0.455,
        aspartic_acid: 0.494,
        cystine: 0.228,
        glutamic_acid: 3.153,
        glycine: 0.394,
        histidine: 0.226,
        isoleucine: 0.367,
        leucine: 0.671,
        lysine: 0.265,
        methionine: 0.153,
        phenylalanine: 0.47,
        proline: 1.046,
        serine: 0.469,
        threonine: 0.282,
        tryptophan: 0.149,
        tyrosine: 0.292,
        valine: 0.441,
        b1_thiamine: 0.339,
        b2_riboflavin: 0.08,
        b3_niacin: 2.84,
        b5_pantothenic_acid: 0.831,
        b6_pyridoxine: 0.265,
        choline: 26.5,
        folate: 35.0,
        vitamin_e: 0.61,
        vitamin_k: 1.4,
        calcium: 15.0,
        copper: 0.29,
        iron: 3.06,
        magnesium: 69.0,
        manganese: 1.74,
        phosphorus: 180.0,
        potassium: 170.0,
        selenium: 44.0,
        sodium: 444.0,
        zinc: 1.52
      },
      restrictionWarnings: [14, 12, 10, 9, 2],
      restrictionViolations: [1],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '198': {
      id: 198,
      name: 'arrowroot starch/powder',
      pluralName: 'arrowroot starch/powder',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.541024364,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 357.0,
        ash: 0.08,
        water: 11.37,
        carbs: 88.15,
        fiber: 3.4,
        starch: 84.75,
        fat: 0.1,
        monounsaturated: 0.002,
        polyunsaturated: 0.045,
        omega_3: 0.009,
        omega_6: 0.036,
        saturated: 0.019,
        protein: 0.3,
        alanine: 0.014,
        arginine: 0.012,
        aspartic_acid: 0.047,
        cystine: 0.006,
        glutamic_acid: 0.05,
        glycine: 0.014,
        histidine: 0.004,
        isoleucine: 0.01,
        leucine: 0.019,
        lysine: 0.013,
        methionine: 0.006,
        phenylalanine: 0.012,
        proline: 0.009,
        serine: 0.013,
        threonine: 0.012,
        tryptophan: 0.004,
        tyrosine: 0.009,
        valine: 0.014,
        b1_thiamine: 0.001,
        b5_pantothenic_acid: 0.13,
        b6_pyridoxine: 0.005,
        choline: 52.9,
        folate: 7.0,
        vitamin_a: 85.0,
        vitamin_k: 1.9,
        calcium: 40.0,
        copper: 0.04,
        iron: 0.33,
        magnesium: 3.0,
        manganese: 0.47,
        phosphorus: 5.0,
        potassium: 11.0,
        sodium: 2.0,
        zinc: 0.07
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '284': {
      id: 284,
      name: 'potato gnocchi',
      pluralName: 'potato gnocchi',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 72,
          packageAmount: 1.0,
          amountInBase: 454.0,
          equivalents: ''
        },
        us: {
          unitId: 70,
          packageAmount: 1.0,
          amountInBase: 454.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 132.94,
        ash: 1.19,
        water: 73.12,
        carbs: 17.05,
        fiber: 1.1,
        starch: 14.58,
        sugars: 0.96,
        fructose: 0.11,
        glucose: 0.13,
        lactose: 0.62,
        maltose: 0.01,
        sucrose: 0.09,
        fat: 6.23,
        monounsaturated: 1.59,
        polyunsaturated: 0.3,
        omega_3: 0.03,
        omega_6: 0.27,
        saturated: 3.87,
        transfats: 0.25,
        cholesterol: 16.43,
        protein: 2.36,
        alanine: 0.08,
        arginine: 0.09,
        aspartic_acid: 0.24,
        cystine: 0.04,
        glutamic_acid: 0.64,
        glycine: 0.07,
        histidine: 0.06,
        isoleucine: 0.09,
        leucine: 0.17,
        lysine: 0.1,
        methionine: 0.05,
        phenylalanine: 0.12,
        proline: 0.22,
        serine: 0.12,
        threonine: 0.08,
        tryptophan: 0.03,
        tyrosine: 0.09,
        valine: 0.12,
        b1_thiamine: 0.14,
        b2_riboflavin: 0.08,
        b3_niacin: 0.1,
        b5_pantothenic_acid: 0.28,
        b6_pyridoxine: 0.1,
        b12_cobalamin: 0.08,
        choline: 9.18,
        folate: 20.1,
        vitamin_a: 232.15,
        vitamin_c: 2.53,
        vitamin_d: 10.36,
        vitamin_e: 0.22,
        vitamin_k: 1.32,
        calcium: 22.42,
        copper: 0.08,
        iron: 0.71,
        magnesium: 11.49,
        manganese: 0.14,
        phosphorus: 40.45,
        potassium: 144.79,
        selenium: 4.78,
        sodium: 299.02,
        zinc: 0.25
      },
      restrictionWarnings: [13, 11, 9, 2],
      restrictionViolations: [14, 10, 1],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '264': {
      id: 264,
      name: 'cornstarch',
      pluralName: 'cornstarch',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.541024364,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 381.0,
        ash: 0.09,
        water: 8.32,
        carbs: 91.27,
        fiber: 1.08,
        starch: 90.19,
        fat: 0.05,
        monounsaturated: 0.016,
        polyunsaturated: 0.025,
        omega_6: 0.025,
        saturated: 0.009,
        protein: 0.26,
        alanine: 0.019,
        arginine: 0.012,
        aspartic_acid: 0.02,
        cystine: 0.006,
        glutamic_acid: 0.053,
        glycine: 0.009,
        histidine: 0.008,
        isoleucine: 0.01,
        leucine: 0.036,
        lysine: 0.006,
        methionine: 0.006,
        phenylalanine: 0.013,
        proline: 0.024,
        serine: 0.012,
        threonine: 0.009,
        tryptophan: 0.001,
        tyrosine: 0.01,
        valine: 0.014,
        choline: 0.4,
        calcium: 2.0,
        copper: 0.05,
        iron: 0.47,
        magnesium: 3.0,
        manganese: 0.053,
        phosphorus: 13.0,
        potassium: 3.0,
        selenium: 2.8,
        sodium: 9.0,
        zinc: 0.06
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '211': {
      id: 211,
      name: 'gluten-free bread crumbs',
      pluralName: 'gluten-free bread crumbs',
      description: '',
      sectionId: 6,
      subsectionId: 0,
      density: 0.456489307,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        },
        us: {
          unitId: 91,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        }
      },
      nutrition: {
        energy: 308.55,
        carbs: 49.8,
        fiber: 2.49,
        sugars: 1.5,
        fat: 10.79,
        saturated: 0.6,
        cholesterol: 2.0,
        protein: 2.7,
        b1_thiamine: 0.1,
        b2_riboflavin: 0.1,
        b3_niacin: 1.39,
        folate: 42.73,
        vitamin_a: 10.0,
        calcium: 41.0,
        iron: 0.77,
        potassium: 140.0
      },
      restrictionWarnings: [14, 13, 12, 11, 10, 9, 6, 5, 2],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '271': {
      id: 271,
      name: 'gluten-free flour',
      pluralName: 'gluten-free flour',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.50847458,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        },
        us: {
          unitId: 91,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        }
      },
      nutrition: {
        energy: 362.73,
        ash: 0.99,
        water: 8.97,
        carbs: 80.32,
        fiber: 3.09,
        starch: 73.09,
        sugars: 0.54,
        sucrose: 0.51,
        fat: 1.77,
        monounsaturated: 0.64,
        polyunsaturated: 0.64,
        omega_3: 0.03,
        omega_6: 0.61,
        saturated: 0.35,
        protein: 4.62,
        alanine: 0.27,
        arginine: 0.35,
        aspartic_acid: 0.43,
        cystine: 0.06,
        glutamic_acid: 0.94,
        glycine: 0.23,
        histidine: 0.12,
        isoleucine: 0.2,
        leucine: 0.39,
        lysine: 0.18,
        methionine: 0.1,
        phenylalanine: 0.24,
        proline: 0.22,
        serine: 0.24,
        threonine: 0.17,
        tryptophan: 0.06,
        tyrosine: 0.17,
        valine: 0.27,
        b1_thiamine: 0.28,
        b2_riboflavin: 0.05,
        b3_niacin: 4.02,
        b5_pantothenic_acid: 1.01,
        b6_pyridoxine: 0.47,
        choline: 19.13,
        folate: 10.13,
        vitamin_e: 0.76,
        vitamin_k: 1.23,
        calcium: 7.3,
        copper: 0.15,
        iron: 1.33,
        magnesium: 71.43,
        manganese: 2.55,
        phosphorus: 215.6,
        potassium: 183.53,
        selenium: 5.6,
        sodium: 6.57,
        zinc: 1.56
      },
      restrictionWarnings: [14, 12, 11, 10, 9, 6, 5, 2],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '257': {
      id: 257,
      name: 'rice vinegar',
      pluralName: 'rice vinegar',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 1.010193929,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        },
        us: {
          unitId: 91,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        }
      },
      nutrition: {
        energy: 18.0,
        water: 94.78,
        carbs: 0.04,
        sugars: 0.04,
        fructose: 0.02,
        glucose: 0.02,
        calcium: 6.0,
        iron: 0.03,
        magnesium: 1.0,
        manganese: 0.06,
        phosphorus: 4.0,
        potassium: 2.0,
        selenium: 0.5,
        sodium: 2.0,
        zinc: 0.01
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '86': {
      id: 86,
      name: 'rosemary, dried',
      pluralName: 'rosemary, dried',
      description: '',
      sectionId: 8,
      subsectionId: null,
      density: 0.22317255,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 331.0,
        ash: 6.53,
        water: 9.31,
        carbs: 64.06,
        fiber: 42.6,
        fat: 15.22,
        monounsaturated: 3.014,
        polyunsaturated: 2.339,
        omega_3: 1.076,
        omega_6: 1.16,
        saturated: 7.371,
        protein: 4.88,
        alanine: 0.25,
        arginine: 0.22,
        aspartic_acid: 0.57,
        cystine: 0.06,
        glutamic_acid: 0.53,
        glycine: 0.24,
        histidine: 0.1,
        isoleucine: 0.21,
        leucine: 0.37,
        lysine: 0.21,
        methionine: 0.07,
        phenylalanine: 0.25,
        proline: 0.21,
        serine: 0.19,
        threonine: 0.21,
        tryptophan: 0.07,
        tyrosine: 0.15,
        valine: 0.24,
        b1_thiamine: 0.514,
        b2_riboflavin: 0.428,
        b3_niacin: 1.0,
        b5_pantothenic_acid: 2.24,
        b6_pyridoxine: 1.74,
        choline: 61.4,
        folate: 307.0,
        vitamin_a: 3.0,
        vitamin_c: 61.2,
        vitamin_e: 16.67,
        calcium: 1.0,
        copper: 0.55,
        iron: 29.25,
        magnesium: 220.0,
        manganese: 1.867,
        phosphorus: 70.0,
        potassium: 955.0,
        selenium: 4.6,
        sodium: 50.0,
        zinc: 3.23
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '312': {
      id: 312,
      name: 'xylitol',
      pluralName: 'xylitol',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.8,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: { energy: 250.0, carbs: 100.0, sugar_alcohol: 100.0 },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '273': {
      id: 273,
      name: 'ketchup',
      pluralName: 'ketchup',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 1.014420682,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 101.0,
        ash: 2.94,
        water: 68.51,
        carbs: 27.4,
        fiber: 0.3,
        sugars: 21.27,
        fructose: 8.72,
        glucose: 10.25,
        sucrose: 2.3,
        fat: 0.1,
        monounsaturated: 0.015,
        polyunsaturated: 0.041,
        omega_3: 0.001,
        omega_6: 0.039,
        saturated: 0.014,
        protein: 1.04,
        alanine: 0.033,
        arginine: 0.016,
        aspartic_acid: 0.133,
        cystine: 0.005,
        glutamic_acid: 0.349,
        glycine: 0.016,
        histidine: 0.013,
        isoleucine: 0.014,
        leucine: 0.021,
        lysine: 0.023,
        methionine: 0.005,
        phenylalanine: 0.021,
        proline: 0.012,
        serine: 0.021,
        threonine: 0.019,
        tryptophan: 0.005,
        tyrosine: 0.009,
        valine: 0.014,
        b1_thiamine: 0.011,
        b2_riboflavin: 0.166,
        b3_niacin: 1.434,
        b5_pantothenic_acid: 0.047,
        b6_pyridoxine: 0.158,
        choline: 12.5,
        folate: 9.0,
        vitamin_a: 527.0,
        vitamin_c: 4.1,
        vitamin_e: 1.46,
        vitamin_k: 3.0,
        calcium: 15.0,
        copper: 0.085,
        iron: 0.35,
        magnesium: 13.0,
        manganese: 0.084,
        phosphorus: 26.0,
        potassium: 281.0,
        selenium: 0.7,
        sodium: 907.0,
        zinc: 0.17
      },
      restrictionWarnings: [14, 13, 12, 9, 1],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '200': {
      id: 200,
      name: 'onion powder',
      pluralName: 'onion powder',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.466633514,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 341.0,
        ash: 4.04,
        water: 5.39,
        carbs: 79.12,
        fiber: 15.2,
        starch: 7.52,
        sugars: 6.63,
        fructose: 1.67,
        galactose: 0.36,
        glucose: 0.73,
        sucrose: 3.87,
        fat: 1.04,
        monounsaturated: 0.202,
        polyunsaturated: 0.31,
        omega_3: 0.015,
        omega_6: 0.296,
        saturated: 0.219,
        protein: 10.41,
        alanine: 0.18,
        arginine: 1.95,
        aspartic_acid: 0.66,
        cystine: 0.08,
        glutamic_acid: 2.09,
        glycine: 0.27,
        histidine: 0.16,
        isoleucine: 0.14,
        leucine: 0.22,
        lysine: 0.49,
        methionine: 0.09,
        phenylalanine: 0.28,
        proline: 0.69,
        serine: 0.14,
        threonine: 0.14,
        tryptophan: 0.06,
        tyrosine: 0.13,
        valine: 0.17,
        b1_thiamine: 0.457,
        b2_riboflavin: 0.08,
        b3_niacin: 0.321,
        b5_pantothenic_acid: 0.732,
        b6_pyridoxine: 0.718,
        choline: 39.0,
        folate: 64.0,
        vitamin_c: 23.4,
        vitamin_e: 0.27,
        vitamin_k: 4.1,
        calcium: 384.0,
        copper: 0.59,
        iron: 3.9,
        magnesium: 113.0,
        manganese: 1.3,
        phosphorus: 322.0,
        potassium: 985.0,
        selenium: 14.3,
        sodium: 73.0,
        zinc: 4.05
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '84': {
      id: 84,
      name: 'parsley flakes',
      pluralName: 'parsley flakes',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.108204873,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 292.0,
        ash: 11.36,
        water: 5.89,
        carbs: 50.64,
        fiber: 26.7,
        starch: 16.67,
        sugars: 7.27,
        fructose: 0.42,
        glucose: 2.76,
        sucrose: 4.09,
        fat: 5.48,
        monounsaturated: 0.761,
        polyunsaturated: 3.124,
        omega_3: 1.86,
        omega_6: 1.264,
        saturated: 1.378,
        protein: 26.63,
        alanine: 1.778,
        arginine: 1.756,
        aspartic_acid: 3.169,
        cystine: 0.298,
        glutamic_acid: 3.688,
        glycine: 1.756,
        histidine: 0.718,
        isoleucine: 1.546,
        leucine: 2.794,
        lysine: 2.098,
        methionine: 0.596,
        phenylalanine: 1.712,
        proline: 2.01,
        serine: 1.159,
        threonine: 1.193,
        tryptophan: 0.475,
        tyrosine: 1.159,
        valine: 2.021,
        b1_thiamine: 0.196,
        b2_riboflavin: 2.383,
        b3_niacin: 9.943,
        b5_pantothenic_acid: 1.062,
        b6_pyridoxine: 0.9,
        choline: 97.1,
        folate: 180.0,
        vitamin_a: 1.0,
        vitamin_c: 125.0,
        vitamin_e: 8.96,
        vitamin_k: 1.0,
        calcium: 1.0,
        copper: 0.78,
        iron: 22.04,
        magnesium: 400.0,
        manganese: 9.81,
        phosphorus: 436.0,
        potassium: 2.0,
        selenium: 14.1,
        sodium: 452.0,
        zinc: 5.44
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '347': {
      id: 347,
      name: 'powdered sugar',
      pluralName: 'powdered sugar',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.51,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 389.0,
        ash: 0.01,
        water: 0.23,
        carbs: 99.77,
        starch: 1.26,
        sugars: 97.81,
        b2_riboflavin: 0.019,
        calcium: 1.0,
        copper: 0.007,
        iron: 0.06,
        manganese: 0.004,
        potassium: 2.0,
        selenium: 0.6,
        sodium: 2.0,
        zinc: 0.01
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '307': {
      id: 307,
      name: 'ribeye steak',
      pluralName: 'ribeye steak',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '1 ribeye steak = 0.227-0.34 kg'
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '1 ribeye steak = 8-12 oz / 0.5-0.75 lb'
        }
      },
      nutrition: {
        energy: 253.0,
        ash: 0.87,
        water: 60.9,
        fat: 19.95,
        monounsaturated: 9.322,
        polyunsaturated: 0.932,
        omega_3: 0.039,
        omega_6: 0.893,
        saturated: 8.741,
        transfats: 1.244,
        cholesterol: 68.0,
        protein: 18.39,
        alanine: 1.137,
        arginine: 1.241,
        aspartic_acid: 1.776,
        cystine: 0.185,
        glutamic_acid: 2.991,
        glycine: 0.907,
        histidine: 0.677,
        isoleucine: 0.854,
        leucine: 1.571,
        lysine: 1.739,
        methionine: 0.496,
        phenylalanine: 0.734,
        proline: 0.82,
        serine: 0.751,
        threonine: 0.862,
        tryptophan: 0.206,
        tyrosine: 0.68,
        valine: 0.919,
        b1_thiamine: 0.107,
        b2_riboflavin: 0.244,
        b3_niacin: 4.629,
        b5_pantothenic_acid: 0.474,
        b6_pyridoxine: 0.404,
        b12_cobalamin: 1.75,
        choline: 41.1,
        folate: 3.0,
        vitamin_a: 15.0,
        vitamin_d: 6.0,
        vitamin_e: 0.17,
        vitamin_k: 1.5,
        calcium: 8.0,
        copper: 0.063,
        iron: 1.81,
        magnesium: 19.0,
        manganese: 0.063,
        phosphorus: 128.0,
        potassium: 224.0,
        selenium: 23.3,
        sodium: 50.0,
        zinc: 4.71
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '137': {
      id: 137,
      name: 'gluten-free Worcestershire sauce',
      pluralName: 'gluten-free Worcestershire sauce',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 1.162357031,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        },
        us: {
          unitId: 91,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        }
      },
      nutrition: {
        energy: 78.0,
        ash: 2.0,
        water: 78.54,
        carbs: 19.46,
        starch: 6.7,
        sugars: 10.03,
        fructose: 1.8,
        galactose: 0.01,
        glucose: 1.68,
        sucrose: 6.35,
        b1_thiamine: 0.07,
        b2_riboflavin: 0.13,
        b3_niacin: 0.7,
        b5_pantothenic_acid: 0.15,
        choline: 2.7,
        folate: 8.0,
        vitamin_a: 79.0,
        vitamin_c: 13.0,
        vitamin_e: 0.08,
        vitamin_k: 1.0,
        calcium: 107.0,
        copper: 0.2,
        iron: 5.3,
        magnesium: 13.0,
        manganese: 0.59,
        phosphorus: 60.0,
        potassium: 800.0,
        selenium: 0.5,
        sodium: 980.0,
        zinc: 0.19
      },
      restrictionWarnings: [13, 12, 9],
      restrictionViolations: [14, 10, 3],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '136': {
      id: 136,
      name: 'Worcestershire sauce',
      pluralName: 'Worcestershire sauce',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 1.162357031,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        },
        us: {
          unitId: 91,
          packageAmount: 1,
          amountInBase: null,
          equivalents: null
        }
      },
      nutrition: {
        energy: 78.0,
        ash: 2.0,
        water: 78.54,
        carbs: 19.46,
        starch: 6.7,
        sugars: 10.03,
        fructose: 1.8,
        galactose: 0.01,
        glucose: 1.68,
        sucrose: 6.35,
        b1_thiamine: 0.07,
        b2_riboflavin: 0.13,
        b3_niacin: 0.7,
        b5_pantothenic_acid: 0.15,
        choline: 2.7,
        folate: 8.0,
        vitamin_a: 79.0,
        vitamin_c: 13.0,
        vitamin_e: 0.08,
        vitamin_k: 1.0,
        calcium: 107.0,
        copper: 0.2,
        iron: 5.3,
        magnesium: 13.0,
        manganese: 0.59,
        phosphorus: 60.0,
        potassium: 800.0,
        selenium: 0.5,
        sodium: 980.0,
        zinc: 0.19
      },
      restrictionWarnings: [13, 12, 9, 1],
      restrictionViolations: [14, 10, 3],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '173': {
      id: 173,
      name: 'cod fillet',
      pluralName: 'cod fillet',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 69.0,
        ash: 1.46,
        water: 83.95,
        fat: 0.41,
        monounsaturated: 0.073,
        polyunsaturated: 0.164,
        omega_3: 0.136,
        omega_6: 0.028,
        saturated: 0.085,
        transfats: 0.005,
        cholesterol: 47.0,
        protein: 15.27,
        alanine: 0.877,
        arginine: 0.982,
        aspartic_acid: 1.525,
        cystine: 0.136,
        glutamic_acid: 2.297,
        glycine: 0.71,
        histidine: 0.324,
        isoleucine: 0.679,
        leucine: 1.211,
        lysine: 1.399,
        methionine: 0.418,
        phenylalanine: 0.595,
        proline: 0.512,
        serine: 0.647,
        threonine: 0.658,
        tryptophan: 0.188,
        tyrosine: 0.553,
        valine: 0.731,
        b1_thiamine: 0.033,
        b2_riboflavin: 0.045,
        b3_niacin: 1.095,
        b5_pantothenic_acid: 0.294,
        b6_pyridoxine: 0.117,
        b12_cobalamin: 1.98,
        choline: 65.0,
        folate: 7.0,
        vitamin_a: 6.0,
        vitamin_d: 20.0,
        vitamin_e: 0.54,
        calcium: 8.0,
        copper: 0.019,
        iron: 0.16,
        magnesium: 20.0,
        manganese: 0.012,
        phosphorus: 281.0,
        potassium: 235.0,
        selenium: 22.9,
        sodium: 303.0,
        zinc: 0.31
      },
      restrictionWarnings: [],
      restrictionViolations: [3],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '176': {
      id: 176,
      name: 'tilapia fillet',
      pluralName: 'tilapia fillet',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 96.0,
        ash: 0.93,
        water: 78.08,
        fat: 1.7,
        monounsaturated: 0.498,
        polyunsaturated: 0.363,
        omega_3: 0.18,
        omega_6: 0.184,
        saturated: 0.585,
        cholesterol: 50.0,
        protein: 20.08,
        alanine: 1.22,
        arginine: 1.277,
        aspartic_acid: 2.297,
        cystine: 0.22,
        glutamic_acid: 3.213,
        glycine: 1.043,
        histidine: 0.47,
        isoleucine: 0.93,
        leucine: 1.603,
        lysine: 1.81,
        methionine: 0.593,
        phenylalanine: 0.81,
        proline: 0.757,
        serine: 0.813,
        threonine: 0.95,
        tryptophan: 0.21,
        tyrosine: 0.68,
        valine: 0.97,
        b1_thiamine: 0.041,
        b2_riboflavin: 0.063,
        b3_niacin: 3.903,
        b5_pantothenic_acid: 0.487,
        b6_pyridoxine: 0.162,
        b12_cobalamin: 1.58,
        choline: 42.5,
        folate: 24.0,
        vitamin_d: 124.0,
        vitamin_e: 0.4,
        vitamin_k: 1.4,
        calcium: 10.0,
        copper: 0.075,
        iron: 0.56,
        magnesium: 27.0,
        manganese: 0.037,
        phosphorus: 170.0,
        potassium: 302.0,
        selenium: 41.8,
        sodium: 52.0,
        zinc: 0.33
      },
      restrictionWarnings: [],
      restrictionViolations: [3],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '179': {
      id: 179,
      name: 'halibut fillet',
      pluralName: 'halibut fillet',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 91.0,
        ash: 1.29,
        water: 80.34,
        fat: 1.33,
        monounsaturated: 0.471,
        polyunsaturated: 0.29,
        omega_3: 0.224,
        omega_6: 0.051,
        saturated: 0.292,
        cholesterol: 49.0,
        protein: 18.56,
        alanine: 1.259,
        arginine: 1.245,
        aspartic_acid: 2.131,
        cystine: 0.223,
        glutamic_acid: 3.107,
        glycine: 0.999,
        histidine: 0.613,
        isoleucine: 0.959,
        leucine: 1.692,
        lysine: 1.911,
        methionine: 0.616,
        phenylalanine: 0.813,
        proline: 0.736,
        serine: 0.849,
        threonine: 0.912,
        tryptophan: 0.233,
        tyrosine: 0.703,
        valine: 1.072,
        b1_thiamine: 0.05,
        b2_riboflavin: 0.03,
        b3_niacin: 6.513,
        b5_pantothenic_acid: 0.343,
        b6_pyridoxine: 0.548,
        b12_cobalamin: 1.1,
        choline: 61.8,
        folate: 12.0,
        vitamin_a: 67.0,
        vitamin_d: 190.0,
        vitamin_e: 0.61,
        calcium: 7.0,
        copper: 0.023,
        iron: 0.16,
        magnesium: 23.0,
        manganese: 0.011,
        phosphorus: 236.0,
        potassium: 435.0,
        selenium: 45.6,
        sodium: 68.0,
        zinc: 0.36
      },
      restrictionWarnings: [],
      restrictionViolations: [3],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '302': {
      id: 302,
      name: 'bacon',
      pluralName: 'bacon',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 67,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: ''
        },
        us: {
          unitId: 67,
          packageAmount: 8.0,
          amountInBase: 28.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 417.0,
        ash: 2.17,
        water: 44.24,
        carbs: 1.28,
        sugars: 1.28,
        sucrose: 1.28,
        fat: 39.69,
        monounsaturated: 17.439,
        polyunsaturated: 6.454,
        omega_3: 0.297,
        omega_6: 6.153,
        saturated: 13.296,
        transfats: 0.133,
        cholesterol: 66.0,
        protein: 12.62,
        alanine: 0.723,
        arginine: 0.813,
        aspartic_acid: 1.181,
        cystine: 0.142,
        glutamic_acid: 1.928,
        glycine: 0.57,
        histidine: 0.513,
        isoleucine: 0.598,
        leucine: 1.039,
        lysine: 1.123,
        methionine: 0.346,
        phenylalanine: 0.524,
        proline: 0.51,
        serine: 0.527,
        threonine: 0.555,
        tryptophan: 0.15,
        tyrosine: 0.5,
        valine: 0.638,
        b1_thiamine: 0.276,
        b2_riboflavin: 0.081,
        b3_niacin: 4.022,
        b5_pantothenic_acid: 0.555,
        b6_pyridoxine: 0.266,
        b12_cobalamin: 0.5,
        choline: 47.8,
        vitamin_a: 37.0,
        vitamin_d: 16.0,
        vitamin_e: 0.43,
        calcium: 5.0,
        copper: 0.042,
        iron: 0.41,
        magnesium: 12.0,
        manganese: 0.011,
        phosphorus: 144.0,
        potassium: 198.0,
        selenium: 20.1,
        sodium: 662.0,
        zinc: 1.18
      },
      restrictionWarnings: [14, 13, 12, 10, 9, 2, 1],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '205': {
      id: 205,
      name: 'chicken wings, split with tips removed',
      pluralName: 'chicken wings, split with tips removed',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 118.0,
        ash: 0.46,
        water: 42.9,
        fat: 8.16,
        monounsaturated: 3.861,
        polyunsaturated: 1.778,
        omega_3: 0.094,
        omega_6: 1.682,
        saturated: 2.518,
        transfats: 0.043,
        cholesterol: 69.0,
        protein: 10.86,
        alanine: 0.634,
        arginine: 0.735,
        aspartic_acid: 1.022,
        cystine: 0.113,
        glutamic_acid: 1.609,
        glycine: 0.48,
        histidine: 0.405,
        isoleucine: 0.533,
        leucine: 0.898,
        lysine: 1.045,
        methionine: 0.283,
        phenylalanine: 0.438,
        proline: 0.345,
        serine: 0.414,
        threonine: 0.487,
        tryptophan: 0.137,
        tyrosine: 0.391,
        valine: 0.562,
        b1_thiamine: 0.033,
        b2_riboflavin: 0.064,
        b3_niacin: 3.532,
        b5_pantothenic_acid: 0.397,
        b6_pyridoxine: 0.327,
        b12_cobalamin: 0.16,
        choline: 50.8,
        folate: 4.0,
        vitamin_a: 18.0,
        vitamin_d: 3.0,
        vitamin_e: 0.4,
        vitamin_k: 2.4,
        calcium: 7.0,
        copper: 0.021,
        iron: 0.29,
        magnesium: 10.0,
        manganese: 0.007,
        phosphorus: 76.0,
        potassium: 116.0,
        selenium: 10.9,
        sodium: 52.0,
        zinc: 0.75
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '325': {
      id: 325,
      name: 'anchovy fillet, packed in oil',
      pluralName: 'anchovy fillets, packed in oil',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 4.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 4.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 210.0,
        ash: 11.1,
        water: 50.3,
        fat: 9.71,
        monounsaturated: 3.77,
        polyunsaturated: 2.562,
        omega_3: 2.191,
        omega_6: 0.372,
        saturated: 2.203,
        transfats: 0.02,
        cholesterol: 85.0,
        protein: 28.89,
        alanine: 1.747,
        arginine: 1.729,
        aspartic_acid: 2.958,
        cystine: 0.31,
        glutamic_acid: 4.312,
        glycine: 1.387,
        histidine: 0.85,
        isoleucine: 1.331,
        leucine: 2.348,
        lysine: 2.653,
        methionine: 0.855,
        phenylalanine: 1.128,
        proline: 1.021,
        serine: 1.179,
        threonine: 1.266,
        tryptophan: 0.324,
        tyrosine: 0.975,
        valine: 1.488,
        b1_thiamine: 0.078,
        b2_riboflavin: 0.363,
        b3_niacin: 19.903,
        b5_pantothenic_acid: 0.909,
        b6_pyridoxine: 0.203,
        b12_cobalamin: 0.88,
        choline: 85.0,
        folate: 13.0,
        vitamin_a: 40.0,
        vitamin_d: 69.0,
        vitamin_e: 3.33,
        vitamin_k: 12.1,
        calcium: 232.0,
        copper: 0.339,
        iron: 4.63,
        magnesium: 69.0,
        manganese: 0.1,
        phosphorus: 252.0,
        potassium: 544.0,
        selenium: 68.1,
        sodium: 3668.0,
        zinc: 2.44
      },
      restrictionWarnings: [],
      restrictionViolations: [3],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '268': {
      id: 268,
      name: 'avocado oil mayonnaise',
      pluralName: 'avocado oil mayonnaise',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 0.929885625,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 666.67,
        fat: 80.0,
        saturated: 10.0,
        cholesterol: 133.0,
        sodium: 766.67
      },
      restrictionWarnings: [14, 10],
      restrictionViolations: [13, 11],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '294': {
      id: 294,
      name: 'soba (buckwheat) noodles',
      pluralName: 'soba (buckwheat) noodles',
      description: '',
      sectionId: 3,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 336.0,
        ash: 3.41,
        water: 6.88,
        carbs: 74.62,
        fat: 0.71,
        monounsaturated: 0.185,
        polyunsaturated: 0.22,
        omega_3: 0.016,
        omega_6: 0.204,
        saturated: 0.136,
        protein: 14.38,
        alanine: 0.705,
        arginine: 0.899,
        aspartic_acid: 1.058,
        cystine: 0.268,
        glutamic_acid: 3.113,
        glycine: 0.942,
        histidine: 0.339,
        isoleucine: 0.553,
        leucine: 0.937,
        lysine: 0.607,
        methionine: 0.204,
        phenylalanine: 0.617,
        proline: 0.868,
        serine: 0.738,
        threonine: 0.503,
        tryptophan: 0.204,
        tyrosine: 0.299,
        valine: 0.707,
        b1_thiamine: 0.48,
        b2_riboflavin: 0.13,
        b3_niacin: 3.21,
        b5_pantothenic_acid: 0.954,
        b6_pyridoxine: 0.24,
        folate: 60.0,
        calcium: 35.0,
        copper: 0.23,
        iron: 2.7,
        magnesium: 95.0,
        manganese: 1.291,
        phosphorus: 254.0,
        potassium: 252.0,
        sodium: 792.0,
        zinc: 1.71
      },
      restrictionWarnings: [10, 1],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '323': {
      id: 323,
      name: 'tomato paste',
      pluralName: 'tomato paste',
      description: '',
      sectionId: 9,
      subsectionId: null,
      density: 1.11586275,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 80,
          packageAmount: 236.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 80,
          packageAmount: 12.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 82.0,
        ash: 2.8,
        water: 73.5,
        carbs: 18.91,
        fiber: 4.1,
        starch: 0.22,
        sugars: 12.18,
        fructose: 5.85,
        glucose: 5.75,
        maltose: 0.28,
        sucrose: 0.3,
        fat: 0.47,
        monounsaturated: 0.067,
        polyunsaturated: 0.16,
        omega_3: 0.007,
        omega_6: 0.152,
        saturated: 0.1,
        protein: 4.32,
        alanine: 0.133,
        arginine: 0.102,
        aspartic_acid: 0.661,
        cystine: 0.046,
        glutamic_acid: 2.11,
        glycine: 0.095,
        histidine: 0.071,
        isoleucine: 0.089,
        leucine: 0.124,
        lysine: 0.134,
        methionine: 0.027,
        phenylalanine: 0.13,
        proline: 0.075,
        serine: 0.126,
        threonine: 0.133,
        tryptophan: 0.031,
        tyrosine: 0.066,
        valine: 0.088,
        b1_thiamine: 0.06,
        b2_riboflavin: 0.153,
        b3_niacin: 3.076,
        b5_pantothenic_acid: 0.142,
        b6_pyridoxine: 0.216,
        choline: 38.5,
        folate: 12.0,
        vitamin_a: 1525.0,
        vitamin_c: 21.9,
        vitamin_e: 4.3,
        vitamin_k: 11.4,
        calcium: 36.0,
        copper: 0.365,
        iron: 2.98,
        magnesium: 42.0,
        manganese: 0.302,
        phosphorus: 83.0,
        potassium: 1014.0,
        selenium: 5.3,
        sodium: 59.0,
        zinc: 0.63
      },
      restrictionWarnings: [14, 13],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '132': {
      id: 132,
      name: 'white wine vinegar',
      pluralName: 'white wine vinegar',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 1.010193929,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 19.0,
        ash: 0.17,
        water: 94.47,
        carbs: 0.27,
        starch: 0.27,
        protein: 0.04,
        choline: 5.7,
        vitamin_c: 0.5,
        calcium: 6.0,
        copper: 0.01,
        iron: 0.45,
        magnesium: 4.0,
        manganese: 0.046,
        phosphorus: 8.0,
        potassium: 39.0,
        selenium: 0.1,
        sodium: 8.0,
        zinc: 0.03
      },
      restrictionWarnings: [2],
      restrictionViolations: [14],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '254': {
      id: 254,
      name: 'nutritional yeast flakes',
      pluralName: 'nutritional yeast flakes',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.5,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 375.0,
        carbs: 37.5,
        fiber: 25.0,
        fat: 4.06,
        protein: 53.13,
        b1_thiamine: 62.81,
        b2_riboflavin: 62.69,
        b3_niacin: 362.5,
        b5_pantothenic_acid: 62.5,
        b6_pyridoxine: 60.0,
        b12_cobalamin: 49.88,
        folate: 1.0,
        calcium: 70.0,
        iron: 4.5,
        magnesium: 126.99,
        manganese: 0.63,
        phosphorus: 1.0,
        potassium: 2.0,
        selenium: 140.0,
        sodium: 32.0,
        zinc: 19.69
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '61': {
      id: 61,
      name: 'macaroni (elbow) pasta',
      pluralName: 'macaroni (elbow) pasta',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 371.0,
        ash: 0.88,
        water: 9.9,
        carbs: 74.67,
        fiber: 3.2,
        starch: 62.45,
        sugars: 2.67,
        fructose: 0.11,
        glucose: 0.12,
        maltose: 1.96,
        sucrose: 0.48,
        fat: 1.51,
        monounsaturated: 0.171,
        polyunsaturated: 0.564,
        omega_3: 0.024,
        omega_6: 0.54,
        saturated: 0.277,
        protein: 13.04,
        alanine: 0.438,
        arginine: 0.474,
        aspartic_acid: 0.624,
        cystine: 0.255,
        glutamic_acid: 4.596,
        glycine: 0.441,
        histidine: 0.298,
        isoleucine: 0.511,
        leucine: 0.988,
        lysine: 0.298,
        methionine: 0.147,
        phenylalanine: 0.668,
        proline: 1.569,
        serine: 0.617,
        threonine: 0.462,
        tryptophan: 0.185,
        tyrosine: 0.243,
        valine: 0.588,
        b1_thiamine: 0.891,
        b2_riboflavin: 0.4,
        b3_niacin: 7.177,
        b5_pantothenic_acid: 0.431,
        b6_pyridoxine: 0.142,
        choline: 15.1,
        folate: 237.0,
        vitamin_e: 0.11,
        vitamin_k: 0.1,
        calcium: 21.0,
        copper: 0.289,
        iron: 3.3,
        magnesium: 53.0,
        manganese: 0.917,
        phosphorus: 189.0,
        potassium: 223.0,
        selenium: 63.2,
        sodium: 6.0,
        zinc: 1.41
      },
      restrictionWarnings: [11],
      restrictionViolations: [1],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '119': {
      id: 119,
      name: 'panko bread crumbs',
      pluralName: 'panko bread crumbs',
      description: '',
      sectionId: 6,
      subsectionId: 0,
      density: 0.25,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 395.0,
        ash: 2.87,
        water: 6.51,
        carbs: 71.98,
        fiber: 4.5,
        starch: 59.66,
        sugars: 6.2,
        fructose: 2.46,
        glucose: 1.82,
        maltose: 1.92,
        fat: 5.3,
        monounsaturated: 1.023,
        polyunsaturated: 2.06,
        omega_3: 0.186,
        omega_6: 1.874,
        saturated: 1.203,
        transfats: 0.07,
        protein: 13.35,
        alanine: 0.483,
        arginine: 0.552,
        aspartic_acid: 0.766,
        cystine: 0.278,
        glutamic_acid: 4.123,
        glycine: 0.488,
        histidine: 0.296,
        isoleucine: 0.544,
        leucine: 0.963,
        lysine: 0.43,
        methionine: 0.232,
        phenylalanine: 0.655,
        proline: 1.366,
        serine: 0.662,
        threonine: 0.427,
        tryptophan: 0.162,
        tyrosine: 0.398,
        valine: 0.6,
        b1_thiamine: 0.967,
        b2_riboflavin: 0.403,
        b3_niacin: 6.634,
        b5_pantothenic_acid: 0.552,
        b6_pyridoxine: 0.121,
        b12_cobalamin: 0.35,
        choline: 14.6,
        folate: 107.0,
        vitamin_e: 0.08,
        vitamin_k: 6.6,
        calcium: 183.0,
        copper: 0.255,
        iron: 4.83,
        magnesium: 43.0,
        manganese: 0.919,
        phosphorus: 165.0,
        potassium: 196.0,
        selenium: 25.2,
        sodium: 732.0,
        zinc: 1.45
      },
      restrictionWarnings: [14, 13, 12, 11, 10, 9, 6, 2],
      restrictionViolations: [1],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '85': {
      id: 85,
      name: 'crushed red pepper',
      pluralName: 'crushed red pepper',
      description: '',
      sectionId: 8,
      subsectionId: 0,
      density: 0.358428641,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 318.0,
        ash: 6.04,
        water: 8.05,
        carbs: 56.63,
        fiber: 27.2,
        sugars: 10.34,
        fructose: 5.66,
        glucose: 4.68,
        fat: 17.27,
        monounsaturated: 2.75,
        polyunsaturated: 8.37,
        omega_3: 0.66,
        omega_6: 7.71,
        saturated: 3.26,
        protein: 12.01,
        alanine: 0.37,
        arginine: 0.26,
        aspartic_acid: 0.51,
        cystine: 0.04,
        glutamic_acid: 0.65,
        glycine: 0.03,
        histidine: 0.24,
        isoleucine: 0.14,
        leucine: 0.23,
        lysine: 0.07,
        methionine: 0.09,
        phenylalanine: 0.13,
        proline: 0.45,
        serine: 0.02,
        threonine: 0.1,
        tryptophan: 0.11,
        tyrosine: 0.04,
        valine: 0.23,
        b1_thiamine: 0.328,
        b2_riboflavin: 0.919,
        b3_niacin: 8.701,
        b5_pantothenic_acid: 0.37,
        b6_pyridoxine: 2.45,
        choline: 51.5,
        folate: 106.0,
        vitamin_a: 41.0,
        vitamin_c: 76.4,
        vitamin_e: 29.83,
        vitamin_k: 80.3,
        calcium: 148.0,
        copper: 0.373,
        iron: 7.8,
        magnesium: 152.0,
        manganese: 2.0,
        phosphorus: 293.0,
        potassium: 2.0,
        selenium: 8.8,
        sodium: 30.0,
        zinc: 2.48
      },
      restrictionWarnings: [],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '299': {
      id: 299,
      name: 'fettuccine pasta',
      pluralName: 'fettuccine pasta',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 371.0,
        ash: 0.88,
        water: 9.9,
        carbs: 74.67,
        fiber: 3.2,
        starch: 62.45,
        sugars: 2.67,
        fructose: 0.11,
        glucose: 0.12,
        maltose: 1.96,
        sucrose: 0.48,
        fat: 1.51,
        monounsaturated: 0.171,
        polyunsaturated: 0.564,
        omega_3: 0.024,
        omega_6: 0.54,
        saturated: 0.277,
        protein: 13.04,
        alanine: 0.438,
        arginine: 0.474,
        aspartic_acid: 0.624,
        cystine: 0.255,
        glutamic_acid: 4.596,
        glycine: 0.441,
        histidine: 0.298,
        isoleucine: 0.511,
        leucine: 0.988,
        lysine: 0.298,
        methionine: 0.147,
        phenylalanine: 0.668,
        proline: 1.569,
        serine: 0.617,
        threonine: 0.462,
        tryptophan: 0.185,
        tyrosine: 0.243,
        valine: 0.588,
        b1_thiamine: 0.09,
        b2_riboflavin: 0.06,
        b3_niacin: 1.7,
        b5_pantothenic_acid: 0.431,
        b6_pyridoxine: 0.142,
        folate: 18.0,
        vitamin_e: 0.11,
        vitamin_k: 0.1,
        calcium: 21.0,
        copper: 0.289,
        iron: 1.3,
        magnesium: 53.0,
        manganese: 0.917,
        phosphorus: 189.0,
        potassium: 223.0,
        selenium: 63.2,
        sodium: 6.0,
        zinc: 1.41
      },
      restrictionWarnings: [11],
      restrictionViolations: [1],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '289': {
      id: 289,
      name: 'polenta (cornmeal)',
      pluralName: 'polenta (cornmeal)',
      description: '',
      sectionId: 3,
      subsectionId: 0,
      density: 0.515663846,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 370.0,
        ash: 0.51,
        water: 11.18,
        carbs: 79.45,
        fiber: 3.9,
        starch: 73.3,
        sugars: 1.61,
        fructose: 0.17,
        glucose: 0.56,
        maltose: 0.19,
        sucrose: 0.68,
        fat: 1.75,
        monounsaturated: 0.39,
        polyunsaturated: 0.828,
        omega_3: 0.02,
        omega_6: 0.808,
        saturated: 0.22,
        protein: 7.11,
        alanine: 0.56,
        arginine: 0.239,
        aspartic_acid: 0.465,
        cystine: 0.159,
        glutamic_acid: 1.455,
        glycine: 0.217,
        histidine: 0.172,
        isoleucine: 0.242,
        leucine: 1.006,
        lysine: 0.105,
        methionine: 0.162,
        phenylalanine: 0.366,
        proline: 0.746,
        serine: 0.341,
        threonine: 0.172,
        tryptophan: 0.038,
        tyrosine: 0.187,
        valine: 0.337,
        b1_thiamine: 0.551,
        b2_riboflavin: 0.382,
        b3_niacin: 4.968,
        b5_pantothenic_acid: 0.24,
        b6_pyridoxine: 0.182,
        choline: 8.6,
        folate: 209.0,
        vitamin_a: 214.0,
        vitamin_e: 0.12,
        calcium: 3.0,
        copper: 0.076,
        iron: 4.36,
        magnesium: 32.0,
        manganese: 0.174,
        phosphorus: 99.0,
        potassium: 142.0,
        selenium: 10.5,
        sodium: 7.0,
        zinc: 0.66
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '15': {
      id: 15,
      name: 'capers',
      pluralName: 'capers',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 0.581601191,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 80,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 80,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 23.0,
        ash: 8.04,
        water: 83.85,
        carbs: 4.89,
        fiber: 3.2,
        starch: 0.2,
        sugars: 0.41,
        fructose: 0.14,
        glucose: 0.27,
        fat: 0.86,
        monounsaturated: 0.063,
        polyunsaturated: 0.304,
        omega_3: 0.184,
        omega_6: 0.113,
        saturated: 0.233,
        protein: 2.36,
        alanine: 0.08,
        arginine: 0.16,
        aspartic_acid: 0.15,
        cystine: 0.02,
        glutamic_acid: 0.7,
        glycine: 0.09,
        histidine: 0.04,
        isoleucine: 0.08,
        leucine: 0.1,
        lysine: 0.1,
        methionine: 0.02,
        phenylalanine: 0.07,
        proline: 0.05,
        serine: 0.07,
        threonine: 0.07,
        tryptophan: 0.02,
        tyrosine: 0.04,
        valine: 0.08,
        b1_thiamine: 0.018,
        b2_riboflavin: 0.139,
        b3_niacin: 0.652,
        b5_pantothenic_acid: 0.027,
        b6_pyridoxine: 0.023,
        choline: 6.5,
        folate: 23.0,
        vitamin_a: 138.0,
        vitamin_c: 4.3,
        vitamin_e: 0.88,
        vitamin_k: 24.6,
        calcium: 40.0,
        copper: 0.374,
        iron: 1.67,
        magnesium: 33.0,
        manganese: 0.078,
        phosphorus: 10.0,
        potassium: 40.0,
        selenium: 1.2,
        sodium: 2.0,
        zinc: 0.32
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '324': {
      id: 324,
      name: 'sole fillets',
      pluralName: 'sole fillets',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 70.0,
        ash: 1.22,
        water: 84.63,
        fat: 1.93,
        monounsaturated: 0.535,
        polyunsaturated: 0.374,
        omega_3: 0.307,
        omega_6: 0.054,
        saturated: 0.441,
        transfats: 0.011,
        cholesterol: 45.0,
        protein: 12.41,
        alanine: 0.771,
        arginine: 0.895,
        aspartic_acid: 1.382,
        cystine: 0.149,
        glutamic_acid: 2.127,
        glycine: 0.64,
        histidine: 0.304,
        isoleucine: 0.614,
        leucine: 1.087,
        lysine: 1.27,
        methionine: 0.455,
        phenylalanine: 0.508,
        proline: 0.487,
        serine: 0.579,
        threonine: 0.585,
        tryptophan: 0.161,
        tyrosine: 0.479,
        valine: 0.651,
        b1_thiamine: 0.022,
        b2_riboflavin: 0.02,
        b3_niacin: 1.04,
        b5_pantothenic_acid: 0.185,
        b6_pyridoxine: 0.098,
        b12_cobalamin: 1.13,
        choline: 65.0,
        folate: 5.0,
        vitamin_a: 33.0,
        vitamin_d: 113.0,
        vitamin_e: 0.63,
        vitamin_k: 0.1,
        calcium: 21.0,
        copper: 0.019,
        iron: 0.18,
        magnesium: 18.0,
        manganese: 0.014,
        phosphorus: 252.0,
        potassium: 160.0,
        selenium: 26.6,
        sodium: 296.0,
        zinc: 0.32
      },
      restrictionWarnings: [],
      restrictionViolations: [3],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '204': {
      id: 204,
      name: 'wild pink salmon, traditional style',
      pluralName: 'wild pink salmon, traditional style',
      description: '',
      sectionId: 9,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 49,
          packageAmount: 1.0,
          amountInBase: 213.0,
          equivalents: ''
        },
        us: {
          unitId: 25,
          packageAmount: 1.0,
          amountInBase: 213.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 138.0,
        ash: 2.5,
        water: 70.71,
        fat: 5.02,
        monounsaturated: 1.211,
        polyunsaturated: 1.507,
        omega_3: 1.361,
        omega_6: 0.142,
        saturated: 0.895,
        transfats: 0.027,
        cholesterol: 83.0,
        protein: 23.1,
        alanine: 1.472,
        arginine: 1.449,
        aspartic_acid: 2.899,
        cystine: 0.179,
        glutamic_acid: 3.268,
        glycine: 1.421,
        histidine: 0.611,
        isoleucine: 1.074,
        leucine: 1.759,
        lysine: 1.98,
        methionine: 0.649,
        phenylalanine: 0.951,
        proline: 0.976,
        serine: 1.019,
        threonine: 1.2,
        tryptophan: 0.249,
        tyrosine: 0.835,
        valine: 1.238,
        b1_thiamine: 0.027,
        b2_riboflavin: 0.202,
        b3_niacin: 7.409,
        b5_pantothenic_acid: 0.563,
        b6_pyridoxine: 0.105,
        b12_cobalamin: 4.95,
        choline: 87.8,
        folate: 4.0,
        vitamin_a: 65.0,
        vitamin_d: 580.0,
        vitamin_e: 1.28,
        vitamin_k: 0.1,
        calcium: 283.0,
        copper: 0.082,
        iron: 0.77,
        magnesium: 32.0,
        manganese: 0.031,
        phosphorus: 379.0,
        potassium: 333.0,
        selenium: 39.5,
        sodium: 381.0,
        zinc: 0.96
      },
      restrictionWarnings: [],
      restrictionViolations: [3],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '193': {
      id: 193,
      name: 'coconut aminos',
      pluralName: 'coconut aminos',
      description: '',
      sectionId: 5,
      subsectionId: 0,
      density: 1.217304818,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: { energy: 77.88, carbs: 15.58, sodium: 1.0 },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '111': {
      id: 111,
      name: 'green beans',
      pluralName: 'green beans',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 31.0,
        ash: 0.66,
        water: 90.32,
        carbs: 6.97,
        fiber: 2.7,
        starch: 0.88,
        sugars: 3.26,
        fructose: 1.39,
        glucose: 1.51,
        sucrose: 0.36,
        fat: 0.22,
        monounsaturated: 0.01,
        polyunsaturated: 0.113,
        omega_3: 0.069,
        omega_6: 0.044,
        saturated: 0.05,
        protein: 1.83,
        alanine: 0.084,
        arginine: 0.073,
        aspartic_acid: 0.255,
        cystine: 0.018,
        glutamic_acid: 0.187,
        glycine: 0.065,
        histidine: 0.034,
        isoleucine: 0.066,
        leucine: 0.112,
        lysine: 0.088,
        methionine: 0.022,
        phenylalanine: 0.067,
        proline: 0.068,
        serine: 0.099,
        threonine: 0.079,
        tryptophan: 0.019,
        tyrosine: 0.042,
        valine: 0.09,
        b1_thiamine: 0.082,
        b2_riboflavin: 0.104,
        b3_niacin: 0.734,
        b5_pantothenic_acid: 0.225,
        b6_pyridoxine: 0.141,
        choline: 15.3,
        folate: 33.0,
        vitamin_a: 690.0,
        vitamin_c: 12.2,
        vitamin_e: 0.41,
        vitamin_k: 14.4,
        calcium: 37.0,
        copper: 0.069,
        iron: 1.03,
        magnesium: 25.0,
        manganese: 0.216,
        phosphorus: 38.0,
        potassium: 211.0,
        selenium: 0.6,
        sodium: 6.0,
        zinc: 0.24
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '32': {
      id: 32,
      name: 'crumbled feta cheese',
      pluralName: 'crumbled feta cheese',
      description: '',
      sectionId: 7,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 38,
          packageAmount: 1.0,
          amountInBase: 113.0,
          equivalents: ''
        },
        us: {
          unitId: 14,
          packageAmount: 1.0,
          amountInBase: 113.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 264.0,
        ash: 5.2,
        water: 55.22,
        carbs: 4.09,
        sugars: 4.09,
        lactose: 4.09,
        fat: 21.28,
        monounsaturated: 4.623,
        polyunsaturated: 0.591,
        omega_3: 0.265,
        omega_6: 0.326,
        saturated: 14.946,
        transfats: 1.06,
        cholesterol: 89.0,
        protein: 14.21,
        alanine: 0.639,
        arginine: 0.47,
        aspartic_acid: 0.779,
        cystine: 0.083,
        glutamic_acid: 2.421,
        glycine: 0.097,
        histidine: 0.397,
        isoleucine: 0.803,
        leucine: 1.395,
        lysine: 1.219,
        methionine: 0.368,
        phenylalanine: 0.675,
        proline: 1.378,
        serine: 1.169,
        threonine: 0.637,
        tryptophan: 0.2,
        tyrosine: 0.668,
        valine: 1.065,
        b1_thiamine: 0.154,
        b2_riboflavin: 0.844,
        b3_niacin: 0.991,
        b5_pantothenic_acid: 0.967,
        b6_pyridoxine: 0.424,
        b12_cobalamin: 1.69,
        choline: 15.4,
        folate: 32.0,
        vitamin_a: 422.0,
        vitamin_d: 16.0,
        vitamin_e: 0.18,
        vitamin_k: 1.8,
        calcium: 493.0,
        copper: 0.032,
        iron: 0.65,
        magnesium: 19.0,
        manganese: 0.028,
        phosphorus: 337.0,
        potassium: 62.0,
        selenium: 15.0,
        sodium: 917.0,
        zinc: 2.88
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '213': {
      id: 213,
      name: 'small flour tortilla',
      pluralName: 'small flour tortillas',
      description: '',
      sectionId: 6,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 301.0,
        ash: 2.5,
        water: 31.58,
        carbs: 51.21,
        fiber: 2.3,
        starch: 42.51,
        sugars: 4.39,
        fructose: 0.03,
        glucose: 0.08,
        maltose: 3.63,
        sucrose: 0.66,
        fat: 6.7,
        monounsaturated: 3.53,
        polyunsaturated: 1.43,
        omega_3: 0.05,
        omega_6: 1.32,
        saturated: 1.63,
        transfats: 1.22,
        protein: 8.0,
        alanine: 0.29,
        arginine: 0.36,
        aspartic_acid: 0.39,
        cystine: 0.18,
        glutamic_acid: 2.85,
        glycine: 0.32,
        histidine: 0.2,
        isoleucine: 0.31,
        leucine: 0.6,
        lysine: 0.21,
        methionine: 0.16,
        phenylalanine: 0.44,
        proline: 0.98,
        serine: 0.43,
        threonine: 0.25,
        tryptophan: 0.11,
        tyrosine: 0.27,
        valine: 0.36,
        b1_thiamine: 0.49,
        b2_riboflavin: 0.13,
        b3_niacin: 3.63,
        b5_pantothenic_acid: 0.16,
        b6_pyridoxine: 0.05,
        choline: 7.9,
        folate: 123.0,
        vitamin_e: 0.19,
        vitamin_k: 3.4,
        calcium: 106.0,
        copper: 0.11,
        iron: 3.32,
        magnesium: 20.0,
        manganese: 0.49,
        phosphorus: 190.0,
        potassium: 153.0,
        selenium: 24.0,
        sodium: 686.0,
        zinc: 0.54
      },
      restrictionWarnings: [14, 13, 12, 11, 10, 9, 6, 2],
      restrictionViolations: [1],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '168': {
      id: 168,
      name: 'lean ground beef',
      pluralName: 'lean ground beef',
      description: '',
      sectionId: 1,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 0.454,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 215.0,
        ash: 0.91,
        water: 65.66,
        fat: 15.0,
        monounsaturated: 6.469,
        polyunsaturated: 0.433,
        omega_3: 0.112,
        omega_6: 0.377,
        saturated: 5.715,
        transfats: 0.86,
        cholesterol: 68.0,
        protein: 18.59,
        alanine: 1.165,
        arginine: 1.21,
        aspartic_acid: 1.674,
        cystine: 0.191,
        glutamic_acid: 2.788,
        glycine: 1.262,
        histidine: 0.604,
        isoleucine: 0.822,
        leucine: 1.45,
        lysine: 1.54,
        methionine: 0.478,
        phenylalanine: 0.725,
        proline: 0.948,
        serine: 0.744,
        threonine: 0.72,
        tryptophan: 0.094,
        tyrosine: 0.572,
        valine: 0.914,
        b1_thiamine: 0.042,
        b2_riboflavin: 0.151,
        b3_niacin: 4.649,
        b5_pantothenic_acid: 0.549,
        b6_pyridoxine: 0.346,
        b12_cobalamin: 2.17,
        choline: 61.2,
        folate: 6.0,
        vitamin_a: 14.0,
        vitamin_d: 3.0,
        vitamin_e: 0.17,
        vitamin_k: 1.3,
        calcium: 15.0,
        copper: 0.067,
        iron: 2.09,
        magnesium: 18.0,
        manganese: 0.01,
        phosphorus: 171.0,
        potassium: 295.0,
        selenium: 15.8,
        sodium: 66.0,
        zinc: 4.48
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '63': {
      id: 63,
      name: 'penne pasta',
      pluralName: 'penne pasta',
      description: '',
      sectionId: 15,
      subsectionId: 0,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 371.0,
        ash: 0.88,
        water: 9.9,
        carbs: 74.67,
        fiber: 3.2,
        starch: 62.45,
        sugars: 2.67,
        fructose: 0.11,
        glucose: 0.12,
        maltose: 1.96,
        sucrose: 0.48,
        fat: 1.51,
        monounsaturated: 0.171,
        polyunsaturated: 0.564,
        omega_3: 0.024,
        omega_6: 0.54,
        saturated: 0.277,
        protein: 13.04,
        alanine: 0.438,
        arginine: 0.474,
        aspartic_acid: 0.624,
        cystine: 0.255,
        glutamic_acid: 4.596,
        glycine: 0.441,
        histidine: 0.298,
        isoleucine: 0.511,
        leucine: 0.988,
        lysine: 0.298,
        methionine: 0.147,
        phenylalanine: 0.668,
        proline: 1.569,
        serine: 0.617,
        threonine: 0.462,
        tryptophan: 0.185,
        tyrosine: 0.243,
        valine: 0.588,
        b1_thiamine: 0.891,
        b2_riboflavin: 0.4,
        b3_niacin: 7.177,
        b5_pantothenic_acid: 0.431,
        b6_pyridoxine: 0.142,
        choline: 15.1,
        folate: 237.0,
        vitamin_e: 0.11,
        vitamin_k: 0.1,
        calcium: 21.0,
        copper: 0.289,
        iron: 3.3,
        magnesium: 53.0,
        manganese: 0.917,
        phosphorus: 189.0,
        potassium: 223.0,
        selenium: 63.2,
        sodium: 6.0,
        zinc: 1.41
      },
      restrictionWarnings: [11],
      restrictionViolations: [1],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '130': {
      id: 130,
      name: 'red wine vinegar',
      pluralName: 'red wine vinegar',
      description: '',
      sectionId: 14,
      subsectionId: 0,
      density: 1.010193929,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 19.0,
        ash: 0.17,
        water: 94.47,
        carbs: 0.27,
        starch: 0.27,
        protein: 0.04,
        choline: 5.7,
        vitamin_c: 0.5,
        calcium: 6.0,
        copper: 0.01,
        iron: 0.45,
        magnesium: 4.0,
        manganese: 0.046,
        phosphorus: 8.0,
        potassium: 39.0,
        selenium: 0.1,
        sodium: 8.0,
        zinc: 0.03
      },
      restrictionWarnings: [],
      restrictionViolations: [14],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '275': {
      id: 275,
      name: 'frozen peas',
      pluralName: 'frozen peas',
      description: '',
      sectionId: 13,
      subsectionId: 0,
      density: 0.566384881,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 77.0,
        ash: 0.78,
        water: 79.98,
        carbs: 13.62,
        fiber: 4.5,
        starch: 4.17,
        sugars: 5.0,
        fructose: 0.25,
        glucose: 0.08,
        maltose: 0.08,
        sucrose: 4.6,
        fat: 0.4,
        monounsaturated: 0.033,
        polyunsaturated: 0.175,
        omega_3: 0.033,
        omega_6: 0.142,
        saturated: 0.066,
        protein: 5.22,
        alanine: 0.231,
        arginine: 0.412,
        aspartic_acid: 0.476,
        cystine: 0.031,
        glutamic_acid: 0.712,
        glycine: 0.177,
        histidine: 0.102,
        isoleucine: 0.187,
        leucine: 0.311,
        lysine: 0.305,
        methionine: 0.079,
        phenylalanine: 0.192,
        proline: 0.167,
        serine: 0.174,
        threonine: 0.195,
        tryptophan: 0.036,
        tyrosine: 0.109,
        valine: 0.226,
        b1_thiamine: 0.259,
        b2_riboflavin: 0.1,
        b3_niacin: 1.723,
        b5_pantothenic_acid: 0.547,
        b6_pyridoxine: 0.083,
        choline: 27.0,
        folate: 53.0,
        vitamin_a: 2.0,
        vitamin_c: 18.0,
        vitamin_e: 0.02,
        vitamin_k: 27.9,
        calcium: 22.0,
        copper: 0.124,
        iron: 1.53,
        magnesium: 26.0,
        manganese: 0.336,
        phosphorus: 82.0,
        potassium: 153.0,
        selenium: 1.9,
        sodium: 108.0,
        zinc: 0.82
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '25': {
      id: 25,
      name: 'romaine lettuce',
      pluralName: 'romaine lettuce',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 5,
          packageAmount: 1.0,
          amountInBase: 626.0,
          equivalents: ''
        },
        us: {
          unitId: 5,
          packageAmount: 1.0,
          amountInBase: 626.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 17.0,
        ash: 0.58,
        water: 94.61,
        carbs: 3.29,
        fiber: 2.1,
        sugars: 1.19,
        fructose: 0.8,
        glucose: 0.39,
        fat: 0.3,
        monounsaturated: 0.012,
        polyunsaturated: 0.16,
        omega_3: 0.113,
        omega_6: 0.047,
        saturated: 0.039,
        protein: 1.23,
        alanine: 0.056,
        arginine: 0.054,
        aspartic_acid: 0.139,
        cystine: 0.006,
        glutamic_acid: 0.178,
        glycine: 0.049,
        histidine: 0.021,
        isoleucine: 0.045,
        leucine: 0.076,
        lysine: 0.064,
        methionine: 0.015,
        phenylalanine: 0.065,
        proline: 0.045,
        serine: 0.05,
        threonine: 0.043,
        tryptophan: 0.01,
        tyrosine: 0.025,
        valine: 0.055,
        b1_thiamine: 0.072,
        b2_riboflavin: 0.067,
        b3_niacin: 0.313,
        b5_pantothenic_acid: 0.142,
        b6_pyridoxine: 0.074,
        choline: 9.9,
        folate: 136.0,
        vitamin_a: 8.0,
        vitamin_c: 4.0,
        vitamin_e: 0.13,
        vitamin_k: 102.5,
        calcium: 33.0,
        copper: 0.048,
        iron: 0.97,
        magnesium: 14.0,
        manganese: 0.155,
        phosphorus: 30.0,
        potassium: 247.0,
        selenium: 0.4,
        sodium: 8.0,
        zinc: 0.23
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '352': {
      id: 352,
      name: 'chia seeds',
      pluralName: 'chia seeds',
      description: null,
      sectionId: 12,
      subsectionId: null,
      density: 0.81,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 486.0,
        ash: 4.8,
        water: 5.8,
        carbs: 42.12,
        fiber: 34.4,
        fat: 30.74,
        monounsaturated: 2.309,
        polyunsaturated: 23.665,
        omega_3: 17.83,
        omega_6: 5.835,
        saturated: 3.33,
        transfats: 0.14,
        protein: 16.54,
        alanine: 1.044,
        arginine: 2.143,
        aspartic_acid: 1.689,
        cystine: 0.407,
        glutamic_acid: 3.5,
        glycine: 0.943,
        histidine: 0.531,
        isoleucine: 0.801,
        leucine: 1.371,
        lysine: 0.97,
        methionine: 0.588,
        phenylalanine: 1.016,
        proline: 0.776,
        serine: 1.049,
        threonine: 0.709,
        tryptophan: 0.436,
        tyrosine: 0.563,
        valine: 0.95,
        b1_thiamine: 0.62,
        b2_riboflavin: 0.17,
        b3_niacin: 8.83,
        folate: 49.0,
        vitamin_a: 54.0,
        vitamin_c: 1.6,
        vitamin_e: 0.5,
        calcium: 631.0,
        copper: 0.924,
        iron: 7.72,
        magnesium: 335.0,
        manganese: 2.723,
        phosphorus: 860.0,
        potassium: 407.0,
        selenium: 55.2,
        sodium: 16.0,
        zinc: 4.58
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '153': {
      id: 153,
      name: 'green onions (scallions)',
      pluralName: 'green onions (scallions)',
      description: '',
      sectionId: 4,
      subsectionId: 4,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 6,
          packageAmount: 1.0,
          amountInBase: 100.0,
          equivalents: '1 small bunch = 8-10 onions'
        },
        us: {
          unitId: 6,
          packageAmount: 1.0,
          amountInBase: 100.0,
          equivalents: '1 small bunch = 8-10 onions'
        }
      },
      nutrition: {
        energy: 32.0,
        ash: 0.81,
        water: 89.83,
        carbs: 7.34,
        fiber: 2.6,
        starch: 0.26,
        sugars: 2.33,
        fructose: 0.8,
        glucose: 1.97,
        sucrose: 0.36,
        fat: 0.19,
        monounsaturated: 0.027,
        polyunsaturated: 0.074,
        omega_3: 0.004,
        omega_6: 0.07,
        saturated: 0.032,
        protein: 1.83,
        alanine: 0.082,
        arginine: 0.132,
        aspartic_acid: 0.169,
        cystine: 0.03,
        glutamic_acid: 0.378,
        glycine: 0.091,
        histidine: 0.032,
        isoleucine: 0.077,
        leucine: 0.109,
        lysine: 0.091,
        methionine: 0.02,
        phenylalanine: 0.059,
        proline: 0.121,
        serine: 0.082,
        threonine: 0.072,
        tryptophan: 0.02,
        tyrosine: 0.053,
        valine: 0.081,
        b1_thiamine: 0.055,
        b2_riboflavin: 0.08,
        b3_niacin: 0.525,
        b5_pantothenic_acid: 0.075,
        b6_pyridoxine: 0.061,
        choline: 5.7,
        folate: 64.0,
        vitamin_a: 997.0,
        vitamin_c: 18.8,
        vitamin_e: 0.55,
        vitamin_k: 207.0,
        calcium: 72.0,
        copper: 0.083,
        iron: 1.48,
        magnesium: 20.0,
        manganese: 0.16,
        phosphorus: 37.0,
        potassium: 276.0,
        selenium: 0.6,
        sodium: 16.0,
        zinc: 0.39
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '282': {
      id: 282,
      name: 'brown lentils, dried',
      pluralName: 'brown lentils, dried',
      description: '',
      sectionId: 3,
      subsectionId: null,
      density: 0.811536545,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 352.0,
        ash: 2.71,
        water: 8.26,
        carbs: 63.35,
        fiber: 10.7,
        starch: 49.9,
        sugars: 2.03,
        fructose: 0.27,
        maltose: 0.3,
        sucrose: 1.47,
        fat: 1.06,
        monounsaturated: 0.193,
        polyunsaturated: 0.526,
        omega_3: 0.112,
        omega_6: 0.414,
        saturated: 0.154,
        protein: 24.63,
        alanine: 1.029,
        arginine: 1.903,
        aspartic_acid: 2.725,
        cystine: 0.322,
        glutamic_acid: 3.819,
        glycine: 1.002,
        histidine: 0.693,
        isoleucine: 1.065,
        leucine: 1.786,
        lysine: 1.72,
        methionine: 0.21,
        phenylalanine: 1.215,
        proline: 1.029,
        serine: 1.136,
        threonine: 0.882,
        tryptophan: 0.221,
        tyrosine: 0.658,
        valine: 1.223,
        b1_thiamine: 0.873,
        b2_riboflavin: 0.211,
        b3_niacin: 2.605,
        b5_pantothenic_acid: 2.14,
        b6_pyridoxine: 0.54,
        choline: 96.4,
        folate: 479.0,
        vitamin_a: 39.0,
        vitamin_c: 4.5,
        vitamin_e: 0.49,
        vitamin_k: 5.0,
        calcium: 35.0,
        copper: 0.754,
        iron: 6.51,
        magnesium: 47.0,
        manganese: 1.393,
        phosphorus: 281.0,
        potassium: 677.0,
        selenium: 0.1,
        sodium: 6.0,
        zinc: 3.27
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '13': {
      id: 13,
      name: 'beet',
      pluralName: 'beets',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 82.0,
          equivalents: '1 medium beet = 112 g'
        },
        us: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 82.0,
          equivalents: '1 medium beet =  4 oz / 0.25 lb'
        }
      },
      nutrition: {
        energy: 43.0,
        ash: 1.08,
        water: 87.58,
        carbs: 9.56,
        fiber: 2.8,
        starch: 0.48,
        sugars: 6.76,
        fructose: 0.2,
        glucose: 0.2,
        sucrose: 6.1,
        fat: 0.17,
        monounsaturated: 0.032,
        polyunsaturated: 0.06,
        omega_3: 0.005,
        omega_6: 0.055,
        saturated: 0.027,
        protein: 1.61,
        alanine: 0.06,
        arginine: 0.042,
        aspartic_acid: 0.116,
        cystine: 0.019,
        glutamic_acid: 0.428,
        glycine: 0.031,
        histidine: 0.021,
        isoleucine: 0.048,
        leucine: 0.068,
        lysine: 0.058,
        methionine: 0.018,
        phenylalanine: 0.046,
        proline: 0.042,
        serine: 0.059,
        threonine: 0.047,
        tryptophan: 0.019,
        tyrosine: 0.038,
        valine: 0.056,
        b1_thiamine: 0.031,
        b2_riboflavin: 0.04,
        b3_niacin: 0.334,
        b5_pantothenic_acid: 0.155,
        b6_pyridoxine: 0.067,
        choline: 6.0,
        folate: 109.0,
        vitamin_a: 33.0,
        vitamin_c: 4.9,
        vitamin_e: 0.04,
        vitamin_k: 0.2,
        calcium: 16.0,
        copper: 0.075,
        iron: 0.8,
        magnesium: 23.0,
        manganese: 0.329,
        phosphorus: 40.0,
        potassium: 325.0,
        selenium: 0.7,
        sodium: 78.0,
        zinc: 0.35
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '105': {
      id: 105,
      name: 'broccoli',
      pluralName: 'broccoli',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 59,
          packageAmount: 1.0,
          amountInBase: 170.0,
          equivalents: ''
        },
        us: {
          unitId: 59,
          packageAmount: 1.0,
          amountInBase: 170.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 34.0,
        ash: 0.87,
        water: 89.3,
        carbs: 6.64,
        fiber: 2.6,
        sugars: 1.7,
        fructose: 0.68,
        glucose: 0.49,
        lactose: 0.21,
        maltose: 0.21,
        sucrose: 0.1,
        fat: 0.37,
        monounsaturated: 0.011,
        polyunsaturated: 0.038,
        omega_3: 0.021,
        omega_6: 0.017,
        saturated: 0.039,
        protein: 2.82,
        alanine: 0.104,
        arginine: 0.191,
        aspartic_acid: 0.325,
        cystine: 0.028,
        glutamic_acid: 0.542,
        glycine: 0.089,
        histidine: 0.059,
        isoleucine: 0.079,
        leucine: 0.129,
        lysine: 0.135,
        methionine: 0.038,
        phenylalanine: 0.117,
        proline: 0.11,
        serine: 0.121,
        threonine: 0.088,
        tryptophan: 0.033,
        tyrosine: 0.05,
        valine: 0.125,
        b1_thiamine: 0.071,
        b2_riboflavin: 0.117,
        b3_niacin: 0.639,
        b5_pantothenic_acid: 0.573,
        b6_pyridoxine: 0.175,
        choline: 18.7,
        folate: 63.0,
        vitamin_a: 623.0,
        vitamin_c: 89.2,
        vitamin_e: 1.44,
        vitamin_k: 101.6,
        calcium: 47.0,
        copper: 0.049,
        iron: 0.73,
        magnesium: 21.0,
        manganese: 0.21,
        phosphorus: 66.0,
        potassium: 316.0,
        selenium: 2.5,
        sodium: 33.0,
        zinc: 0.41
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '142': {
      id: 142,
      name: 'butternut squash',
      pluralName: 'butternut squash',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 83,
          packageAmount: 1.0,
          amountInBase: 476.0,
          equivalents: '1 small squash = 454-680 g'
        },
        us: {
          unitId: 83,
          packageAmount: 1.0,
          amountInBase: 476.0,
          equivalents: '1 small squash = 1-1.5 lb'
        }
      },
      nutrition: {
        energy: 40.0,
        ash: 0.72,
        water: 87.8,
        carbs: 10.49,
        fiber: 3.2,
        starch: 2.66,
        sugars: 1.97,
        fructose: 0.66,
        glucose: 0.66,
        sucrose: 0.62,
        fat: 0.09,
        polyunsaturated: 0.04,
        omega_3: 0.02,
        omega_6: 0.01,
        saturated: 0.02,
        protein: 0.9,
        alanine: 0.04,
        arginine: 0.05,
        aspartic_acid: 0.1,
        glutamic_acid: 0.16,
        glycine: 0.03,
        histidine: 0.02,
        isoleucine: 0.04,
        leucine: 0.05,
        lysine: 0.03,
        methionine: 0.01,
        phenylalanine: 0.04,
        proline: 0.03,
        serine: 0.04,
        threonine: 0.03,
        tryptophan: 0.01,
        tyrosine: 0.03,
        valine: 0.04,
        b1_thiamine: 0.07,
        b2_riboflavin: 0.02,
        b3_niacin: 0.97,
        b5_pantothenic_acid: 0.36,
        b6_pyridoxine: 0.12,
        choline: 10.6,
        folate: 19.0,
        vitamin_a: 11.0,
        vitamin_c: 15.1,
        vitamin_e: 1.29,
        vitamin_k: 1.0,
        calcium: 41.0,
        copper: 0.07,
        iron: 0.6,
        magnesium: 29.0,
        manganese: 0.17,
        phosphorus: 27.0,
        potassium: 284.0,
        selenium: 0.5,
        sodium: 4.0,
        zinc: 0.13
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '245': {
      id: 245,
      name: 'broccoli slaw',
      pluralName: 'broccoli slaw',
      description: '',
      sectionId: 4,
      subsectionId: 5,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 43,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 19,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 28.0,
        ash: 0.92,
        water: 90.69,
        carbs: 5.24,
        fiber: 2.05,
        starch: 1.85,
        sugars: 1.34,
        fructose: 0.54,
        glucose: 0.4,
        lactose: 0.16,
        maltose: 0.16,
        sucrose: 0.08,
        fat: 0.35,
        monounsaturated: 0.024,
        polyunsaturated: 0.167,
        omega_3: 0.129,
        omega_6: 0.038,
        saturated: 0.054,
        protein: 2.98,
        alanine: 0.118,
        arginine: 0.145,
        aspartic_acid: 0.213,
        cystine: 0.02,
        glutamic_acid: 0.375,
        glycine: 0.095,
        histidine: 0.05,
        isoleucine: 0.109,
        leucine: 0.131,
        lysine: 0.141,
        methionine: 0.034,
        phenylalanine: 0.084,
        proline: 0.114,
        serine: 0.1,
        threonine: 0.091,
        tryptophan: 0.029,
        tyrosine: 0.063,
        valine: 0.128,
        b1_thiamine: 0.065,
        b2_riboflavin: 0.119,
        b3_niacin: 0.638,
        b5_pantothenic_acid: 0.535,
        b6_pyridoxine: 0.159,
        choline: 18.7,
        folate: 71.0,
        vitamin_a: 400.0,
        vitamin_c: 93.2,
        vitamin_e: 0.78,
        vitamin_k: 101.6,
        calcium: 48.0,
        copper: 0.045,
        iron: 0.88,
        magnesium: 25.0,
        manganese: 0.229,
        phosphorus: 66.0,
        potassium: 325.0,
        selenium: 3.0,
        sodium: 27.0,
        zinc: 0.4
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '158': {
      id: 158,
      name: 'cauliflower',
      pluralName: 'cauliflower',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 93,
          packageAmount: 1.0,
          amountInBase: 588.0,
          equivalents: '1 medium head = 1.5 kg'
        },
        us: {
          unitId: 93,
          packageAmount: 1.0,
          amountInBase: 588.0,
          equivalents: '1 medium head = 3.3 lb'
        }
      },
      nutrition: {
        energy: 25.0,
        ash: 0.76,
        water: 92.07,
        carbs: 4.97,
        fiber: 2.0,
        starch: 0.36,
        sugars: 1.91,
        fructose: 0.97,
        glucose: 0.94,
        fat: 0.28,
        monounsaturated: 0.034,
        polyunsaturated: 0.031,
        omega_3: 0.015,
        omega_6: 0.016,
        saturated: 0.13,
        protein: 1.92,
        alanine: 0.116,
        arginine: 0.086,
        aspartic_acid: 0.177,
        cystine: 0.02,
        glutamic_acid: 0.257,
        glycine: 0.071,
        histidine: 0.056,
        isoleucine: 0.071,
        leucine: 0.106,
        lysine: 0.217,
        methionine: 0.02,
        phenylalanine: 0.065,
        proline: 0.071,
        serine: 0.086,
        threonine: 0.076,
        tryptophan: 0.02,
        tyrosine: 0.051,
        valine: 0.125,
        b1_thiamine: 0.05,
        b2_riboflavin: 0.06,
        b3_niacin: 0.507,
        b5_pantothenic_acid: 0.667,
        b6_pyridoxine: 0.184,
        choline: 44.3,
        folate: 57.0,
        vitamin_c: 48.2,
        vitamin_e: 0.08,
        vitamin_k: 15.5,
        calcium: 22.0,
        copper: 0.039,
        iron: 0.42,
        magnesium: 15.0,
        manganese: 0.155,
        phosphorus: 44.0,
        potassium: 299.0,
        selenium: 0.6,
        sodium: 30.0,
        zinc: 0.27
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '230': {
      id: 230,
      name: 'cremini mushrooms',
      pluralName: 'cremini mushrooms',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 22.0,
        ash: 0.98,
        water: 92.12,
        carbs: 4.3,
        fiber: 0.6,
        starch: 0.7,
        sugars: 1.72,
        fructose: 0.17,
        glucose: 1.48,
        fat: 0.1,
        monounsaturated: 0.002,
        polyunsaturated: 0.042,
        omega_6: 0.04,
        saturated: 0.014,
        protein: 2.5,
        alanine: 0.187,
        arginine: 0.123,
        aspartic_acid: 0.228,
        cystine: 0.006,
        glutamic_acid: 0.428,
        glycine: 0.111,
        histidine: 0.067,
        isoleucine: 0.099,
        leucine: 0.153,
        lysine: 0.252,
        methionine: 0.048,
        phenylalanine: 0.097,
        proline: 0.176,
        serine: 0.113,
        threonine: 0.113,
        tryptophan: 0.056,
        tyrosine: 0.054,
        valine: 0.115,
        b1_thiamine: 0.095,
        b2_riboflavin: 0.49,
        b3_niacin: 3.8,
        b5_pantothenic_acid: 1.5,
        b6_pyridoxine: 0.11,
        b12_cobalamin: 0.1,
        choline: 22.1,
        folate: 25.0,
        vitamin_d: 3.0,
        vitamin_e: 0.01,
        calcium: 18.0,
        copper: 0.5,
        iron: 0.4,
        magnesium: 9.0,
        manganese: 0.142,
        phosphorus: 120.0,
        potassium: 448.0,
        selenium: 26.0,
        sodium: 6.0,
        zinc: 1.1
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '286': {
      id: 286,
      name: 'fresh rosemary',
      pluralName: 'fresh rosemary',
      description: '',
      sectionId: 4,
      subsectionId: 4,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 8,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: '1 small pkg = 28 g'
        },
        us: {
          unitId: 8,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: '1 small pkg = 1 oz'
        }
      },
      nutrition: {
        energy: 131.0,
        ash: 2.35,
        water: 67.77,
        carbs: 20.7,
        fiber: 14.1,
        fat: 5.86,
        monounsaturated: 1.16,
        polyunsaturated: 0.901,
        omega_3: 0.414,
        omega_6: 0.447,
        saturated: 2.838,
        protein: 3.31,
        alanine: 0.172,
        arginine: 0.153,
        aspartic_acid: 0.391,
        cystine: 0.037,
        glutamic_acid: 0.361,
        glycine: 0.159,
        histidine: 0.066,
        isoleucine: 0.136,
        leucine: 0.249,
        lysine: 0.143,
        methionine: 0.047,
        phenylalanine: 0.169,
        proline: 0.136,
        serine: 0.129,
        threonine: 0.136,
        tryptophan: 0.051,
        tyrosine: 0.1,
        valine: 0.165,
        b1_thiamine: 0.036,
        b2_riboflavin: 0.152,
        b3_niacin: 0.912,
        b5_pantothenic_acid: 0.804,
        b6_pyridoxine: 0.336,
        choline: 11.4,
        folate: 109.0,
        vitamin_a: 2.0,
        vitamin_c: 21.8,
        vitamin_e: 6.42,
        calcium: 317.0,
        copper: 0.301,
        iron: 6.65,
        magnesium: 91.0,
        manganese: 0.96,
        phosphorus: 66.0,
        potassium: 668.0,
        selenium: 1.63,
        sodium: 26.0,
        zinc: 0.93
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '222': {
      id: 222,
      name: 'grapefruit',
      pluralName: 'grapefruits',
      description: '',
      sectionId: 4,
      subsectionId: 1,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 256.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 256.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 42.0,
        ash: 0.36,
        water: 88.06,
        carbs: 10.66,
        fiber: 1.6,
        sugars: 6.89,
        fructose: 1.77,
        glucose: 1.61,
        sucrose: 3.51,
        fat: 0.14,
        monounsaturated: 0.02,
        polyunsaturated: 0.036,
        omega_3: 0.008,
        omega_6: 0.029,
        saturated: 0.021,
        protein: 0.77,
        alanine: 0.024,
        arginine: 0.087,
        aspartic_acid: 0.138,
        cystine: 0.008,
        glutamic_acid: 0.197,
        glycine: 0.015,
        histidine: 0.008,
        isoleucine: 0.008,
        leucine: 0.015,
        lysine: 0.019,
        methionine: 0.007,
        phenylalanine: 0.013,
        proline: 0.063,
        serine: 0.028,
        threonine: 0.013,
        tryptophan: 0.008,
        tyrosine: 0.008,
        valine: 0.015,
        b1_thiamine: 0.043,
        b2_riboflavin: 0.031,
        b3_niacin: 0.204,
        b5_pantothenic_acid: 0.262,
        b6_pyridoxine: 0.053,
        choline: 7.7,
        folate: 13.0,
        vitamin_a: 1.0,
        vitamin_c: 31.2,
        vitamin_e: 0.13,
        calcium: 22.0,
        copper: 0.032,
        iron: 0.08,
        magnesium: 9.0,
        manganese: 0.022,
        phosphorus: 18.0,
        potassium: 135.0,
        selenium: 0.1,
        zinc: 0.07
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '18': {
      id: 18,
      name: 'eggplant',
      pluralName: 'eggplants',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 572.0,
          equivalents: '1 medium eggplant = about 572 g'
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 572.0,
          equivalents: '1 medium eggplant = 1.3 lb'
        }
      },
      nutrition: {
        energy: 24.0,
        ash: 0.7,
        water: 92.41,
        carbs: 5.7,
        fiber: 3.4,
        starch: 0.19,
        sugars: 2.35,
        fructose: 0.84,
        glucose: 1.15,
        sucrose: 0.1,
        fat: 0.18,
        monounsaturated: 0.016,
        polyunsaturated: 0.076,
        omega_3: 0.013,
        omega_6: 0.063,
        saturated: 0.034,
        protein: 0.98,
        alanine: 0.051,
        arginine: 0.057,
        aspartic_acid: 0.164,
        cystine: 0.006,
        glutamic_acid: 0.186,
        glycine: 0.041,
        histidine: 0.023,
        isoleucine: 0.045,
        leucine: 0.064,
        lysine: 0.047,
        methionine: 0.011,
        phenylalanine: 0.043,
        proline: 0.043,
        serine: 0.042,
        threonine: 0.037,
        tryptophan: 0.009,
        tyrosine: 0.027,
        valine: 0.053,
        b1_thiamine: 0.039,
        b2_riboflavin: 0.037,
        b3_niacin: 0.649,
        b5_pantothenic_acid: 0.281,
        b6_pyridoxine: 0.084,
        choline: 6.9,
        folate: 22.0,
        vitamin_a: 23.0,
        vitamin_c: 2.2,
        vitamin_e: 0.3,
        vitamin_k: 3.5,
        calcium: 9.0,
        copper: 0.081,
        iron: 0.23,
        magnesium: 14.0,
        manganese: 0.232,
        phosphorus: 24.0,
        potassium: 229.0,
        selenium: 0.3,
        sodium: 2.0,
        zinc: 0.16
      },
      restrictionWarnings: [],
      restrictionViolations: [10],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '22': {
      id: 22,
      name: 'turnip',
      pluralName: 'turnips',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 122.0,
          equivalents: ''
        },
        us: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 122.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 28.0,
        ash: 0.7,
        water: 91.87,
        carbs: 6.43,
        fiber: 1.8,
        starch: 0.18,
        sugars: 3.8,
        fructose: 1.43,
        glucose: 1.93,
        sucrose: 0.5,
        fat: 0.1,
        monounsaturated: 0.006,
        polyunsaturated: 0.053,
        omega_3: 0.04,
        omega_6: 0.012,
        saturated: 0.011,
        protein: 0.9,
        alanine: 0.035,
        arginine: 0.024,
        aspartic_acid: 0.063,
        cystine: 0.005,
        glutamic_acid: 0.13,
        glycine: 0.025,
        histidine: 0.014,
        isoleucine: 0.036,
        leucine: 0.033,
        lysine: 0.036,
        methionine: 0.011,
        phenylalanine: 0.017,
        proline: 0.026,
        serine: 0.029,
        threonine: 0.025,
        tryptophan: 0.009,
        tyrosine: 0.013,
        valine: 0.03,
        b1_thiamine: 0.04,
        b2_riboflavin: 0.03,
        b3_niacin: 0.4,
        b5_pantothenic_acid: 0.2,
        b6_pyridoxine: 0.09,
        choline: 11.1,
        folate: 15.0,
        vitamin_c: 21.0,
        vitamin_e: 0.03,
        vitamin_k: 0.1,
        calcium: 30.0,
        copper: 0.085,
        iron: 0.3,
        magnesium: 11.0,
        manganese: 0.134,
        phosphorus: 27.0,
        potassium: 191.0,
        selenium: 0.7,
        sodium: 67.0,
        zinc: 0.27
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '36': {
      id: 36,
      name: 'sugar snap peas',
      pluralName: 'sugar snap peas',
      description: '',
      sectionId: 4,
      subsectionId: 5,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 42,
          packageAmount: 1.0,
          amountInBase: 227.0,
          equivalents: ''
        },
        us: {
          unitId: 18,
          packageAmount: 1.0,
          amountInBase: 227.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 42.0,
        ash: 0.56,
        water: 88.89,
        carbs: 7.55,
        fiber: 2.6,
        starch: 1.16,
        sugars: 4.0,
        fructose: 0.4,
        galactose: 0.01,
        glucose: 2.38,
        sucrose: 0.85,
        fat: 0.2,
        monounsaturated: 0.021,
        polyunsaturated: 0.089,
        omega_3: 0.013,
        omega_6: 0.075,
        saturated: 0.039,
        protein: 2.8,
        alanine: 0.058,
        arginine: 0.134,
        aspartic_acid: 0.228,
        cystine: 0.032,
        glutamic_acid: 0.448,
        glycine: 0.072,
        histidine: 0.017,
        isoleucine: 0.161,
        leucine: 0.228,
        lysine: 0.202,
        methionine: 0.011,
        phenylalanine: 0.09,
        proline: 0.063,
        serine: 0.125,
        threonine: 0.099,
        tryptophan: 0.027,
        tyrosine: 0.099,
        valine: 0.273,
        b1_thiamine: 0.15,
        b2_riboflavin: 0.08,
        b3_niacin: 0.6,
        b5_pantothenic_acid: 0.75,
        b6_pyridoxine: 0.16,
        choline: 17.4,
        folate: 42.0,
        vitamin_a: 1.0,
        vitamin_c: 60.0,
        vitamin_e: 0.39,
        vitamin_k: 25.0,
        calcium: 43.0,
        copper: 0.079,
        iron: 2.08,
        magnesium: 24.0,
        manganese: 0.244,
        phosphorus: 53.0,
        potassium: 200.0,
        selenium: 0.7,
        sodium: 4.0,
        zinc: 0.27
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '27': {
      id: 27,
      name: 'corn',
      pluralName: 'corn',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 7,
          packageAmount: 1.0,
          amountInBase: 102.0,
          equivalents: '1 medium ear (wish husk) = 283 g'
        },
        us: {
          unitId: 7,
          packageAmount: 1.0,
          amountInBase: 102.0,
          equivalents: '1 medium ear (with husk) = 10 oz / 0.62 lb'
        }
      },
      nutrition: {
        energy: 86.0,
        ash: 0.62,
        water: 76.05,
        carbs: 18.7,
        fiber: 2.0,
        starch: 5.7,
        sugars: 6.26,
        fructose: 1.94,
        glucose: 3.43,
        sucrose: 0.89,
        fat: 1.35,
        monounsaturated: 0.432,
        polyunsaturated: 0.487,
        omega_3: 0.014,
        omega_6: 0.473,
        saturated: 0.325,
        transfats: 0.007,
        protein: 3.27,
        alanine: 0.295,
        arginine: 0.131,
        aspartic_acid: 0.244,
        cystine: 0.026,
        glutamic_acid: 0.636,
        glycine: 0.127,
        histidine: 0.089,
        isoleucine: 0.129,
        leucine: 0.348,
        lysine: 0.137,
        methionine: 0.067,
        phenylalanine: 0.15,
        proline: 0.292,
        serine: 0.153,
        threonine: 0.129,
        tryptophan: 0.023,
        tyrosine: 0.123,
        valine: 0.185,
        b1_thiamine: 0.155,
        b2_riboflavin: 0.055,
        b3_niacin: 1.77,
        b5_pantothenic_acid: 0.717,
        b6_pyridoxine: 0.093,
        choline: 23.0,
        folate: 42.0,
        vitamin_a: 187.0,
        vitamin_c: 6.8,
        vitamin_e: 0.07,
        vitamin_k: 0.3,
        calcium: 2.0,
        copper: 0.054,
        iron: 0.52,
        magnesium: 37.0,
        manganese: 0.163,
        phosphorus: 89.0,
        potassium: 270.0,
        selenium: 0.6,
        sodium: 15.0,
        zinc: 0.46
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '238': {
      id: 238,
      name: 'new potatoes',
      pluralName: 'new potatoes',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 69.0,
        ash: 0.94,
        water: 81.58,
        carbs: 15.71,
        fiber: 2.4,
        starch: 13.49,
        sugars: 1.15,
        fructose: 0.34,
        glucose: 0.53,
        sucrose: 0.28,
        fat: 0.1,
        monounsaturated: 0.002,
        polyunsaturated: 0.043,
        omega_3: 0.01,
        omega_6: 0.032,
        saturated: 0.026,
        protein: 1.68,
        alanine: 0.051,
        arginine: 0.082,
        aspartic_acid: 0.391,
        cystine: 0.02,
        glutamic_acid: 0.286,
        glycine: 0.046,
        histidine: 0.028,
        isoleucine: 0.054,
        leucine: 0.08,
        lysine: 0.087,
        methionine: 0.026,
        phenylalanine: 0.066,
        proline: 0.051,
        serine: 0.061,
        threonine: 0.055,
        tryptophan: 0.017,
        tyrosine: 0.039,
        valine: 0.084,
        b1_thiamine: 0.071,
        b2_riboflavin: 0.034,
        b3_niacin: 1.066,
        b5_pantothenic_acid: 0.281,
        b6_pyridoxine: 0.203,
        choline: 11.0,
        folate: 18.0,
        vitamin_a: 8.0,
        vitamin_c: 9.1,
        vitamin_e: 0.01,
        vitamin_k: 1.6,
        calcium: 9.0,
        copper: 0.116,
        iron: 0.52,
        magnesium: 21.0,
        manganese: 0.145,
        phosphorus: 62.0,
        potassium: 407.0,
        selenium: 0.3,
        sodium: 16.0,
        zinc: 0.29
      },
      restrictionWarnings: [],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '270': {
      id: 270,
      name: 'chives',
      pluralName: 'chives',
      description: '',
      sectionId: 4,
      subsectionId: 4,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 8,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: '1 small pkg = about 28 g'
        },
        us: {
          unitId: 8,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: '1 small pkg = about 1 oz'
        }
      },
      nutrition: {
        energy: 30.0,
        ash: 1.0,
        water: 90.65,
        carbs: 4.35,
        fiber: 2.5,
        sugars: 1.85,
        fructose: 0.55,
        glucose: 0.74,
        sucrose: 0.56,
        fat: 0.73,
        monounsaturated: 0.095,
        polyunsaturated: 0.267,
        omega_3: 0.015,
        omega_6: 0.252,
        saturated: 0.146,
        protein: 3.27,
        alanine: 0.148,
        arginine: 0.237,
        aspartic_acid: 0.303,
        cystine: 0.05,
        glutamic_acid: 0.677,
        glycine: 0.162,
        histidine: 0.057,
        isoleucine: 0.139,
        leucine: 0.195,
        lysine: 0.163,
        methionine: 0.036,
        phenylalanine: 0.105,
        proline: 0.216,
        serine: 0.148,
        threonine: 0.128,
        tryptophan: 0.037,
        tyrosine: 0.095,
        valine: 0.145,
        b1_thiamine: 0.078,
        b2_riboflavin: 0.115,
        b3_niacin: 0.647,
        b5_pantothenic_acid: 0.324,
        b6_pyridoxine: 0.138,
        choline: 5.2,
        folate: 105.0,
        vitamin_a: 4.0,
        vitamin_c: 58.1,
        vitamin_e: 0.21,
        vitamin_k: 212.7,
        calcium: 92.0,
        copper: 0.157,
        iron: 1.6,
        magnesium: 42.0,
        manganese: 0.373,
        phosphorus: 58.0,
        potassium: 296.0,
        selenium: 0.9,
        sodium: 3.0,
        zinc: 0.56
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '310': {
      id: 310,
      name: 'red cabbage',
      pluralName: 'red cabbage',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 89,
          packageAmount: 1.0,
          amountInBase: 567.0,
          equivalents: '1 small head = 709 g'
        },
        us: {
          unitId: 89,
          packageAmount: 1.0,
          amountInBase: 567.0,
          equivalents: '1 small head = 1.56 lb'
        }
      },
      nutrition: {
        energy: 31.0,
        ash: 0.64,
        water: 90.39,
        carbs: 7.37,
        fiber: 2.1,
        sugars: 3.83,
        fructose: 1.48,
        glucose: 1.74,
        sucrose: 0.6,
        fat: 0.16,
        monounsaturated: 0.012,
        polyunsaturated: 0.08,
        omega_3: 0.045,
        omega_6: 0.035,
        saturated: 0.021,
        protein: 1.43,
        alanine: 0.048,
        arginine: 0.083,
        aspartic_acid: 0.136,
        cystine: 0.012,
        glutamic_acid: 0.329,
        glycine: 0.034,
        histidine: 0.024,
        isoleucine: 0.034,
        leucine: 0.046,
        lysine: 0.049,
        methionine: 0.014,
        phenylalanine: 0.036,
        proline: 0.054,
        serine: 0.059,
        threonine: 0.039,
        tryptophan: 0.012,
        tyrosine: 0.022,
        valine: 0.048,
        b1_thiamine: 0.064,
        b2_riboflavin: 0.069,
        b3_niacin: 0.418,
        b5_pantothenic_acid: 0.147,
        b6_pyridoxine: 0.209,
        choline: 17.1,
        folate: 18.0,
        vitamin_a: 1.0,
        vitamin_c: 57.0,
        vitamin_e: 0.11,
        vitamin_k: 38.2,
        calcium: 45.0,
        copper: 0.017,
        iron: 0.8,
        magnesium: 16.0,
        manganese: 0.243,
        phosphorus: 30.0,
        potassium: 243.0,
        selenium: 0.6,
        sodium: 27.0,
        zinc: 0.22
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '149': {
      id: 149,
      name: 'red potato',
      pluralName: 'red potatoes',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 213.0,
          equivalents: '1 medium red potato = 284 g'
        },
        us: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 213.0,
          equivalents: '1 medium red potato = 10 oz / 0.63 lb'
        }
      },
      nutrition: {
        energy: 70.0,
        ash: 1.1,
        water: 80.96,
        carbs: 15.9,
        fiber: 1.7,
        starch: 13.35,
        sugars: 1.29,
        fructose: 0.31,
        glucose: 0.4,
        sucrose: 0.29,
        fat: 0.14,
        monounsaturated: 0.003,
        polyunsaturated: 0.059,
        omega_3: 0.014,
        omega_6: 0.044,
        saturated: 0.035,
        protein: 1.89,
        alanine: 0.058,
        arginine: 0.093,
        aspartic_acid: 0.442,
        cystine: 0.022,
        glutamic_acid: 0.323,
        glycine: 0.052,
        histidine: 0.032,
        isoleucine: 0.061,
        leucine: 0.09,
        lysine: 0.098,
        methionine: 0.029,
        phenylalanine: 0.075,
        proline: 0.058,
        serine: 0.069,
        threonine: 0.062,
        tryptophan: 0.019,
        tyrosine: 0.044,
        valine: 0.095,
        b1_thiamine: 0.081,
        b2_riboflavin: 0.031,
        b3_niacin: 1.149,
        b5_pantothenic_acid: 0.279,
        b6_pyridoxine: 0.17,
        choline: 16.4,
        folate: 18.0,
        vitamin_a: 7.0,
        vitamin_c: 8.6,
        vitamin_e: 0.01,
        vitamin_k: 2.9,
        calcium: 10.0,
        copper: 0.134,
        iron: 0.73,
        magnesium: 22.0,
        manganese: 0.141,
        phosphorus: 61.0,
        potassium: 455.0,
        selenium: 0.5,
        sodium: 18.0,
        zinc: 0.33
      },
      restrictionWarnings: [],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '350': {
      id: 350,
      name: 'cardamom, ground',
      pluralName: 'cardamom, ground',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.57,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 311.0,
        ash: 5.78,
        water: 8.28,
        carbs: 68.47,
        fiber: 28.0,
        fat: 6.7,
        monounsaturated: 0.87,
        polyunsaturated: 0.43,
        omega_3: 0.12,
        omega_6: 0.31,
        saturated: 0.68,
        protein: 10.76,
        b1_thiamine: 0.198,
        b2_riboflavin: 0.182,
        b3_niacin: 1.102,
        b6_pyridoxine: 0.23,
        vitamin_c: 21.0,
        calcium: 383.0,
        copper: 0.383,
        iron: 13.97,
        magnesium: 229.0,
        manganese: 28.0,
        phosphorus: 178.0,
        potassium: 1119.0,
        sodium: 18.0,
        zinc: 7.47
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '292': {
      id: 292,
      name: 'napa cabbage',
      pluralName: 'napa cabbage',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 93,
          packageAmount: 1.0,
          amountInBase: 840.0,
          equivalents: '1 medium head = about 840g'
        },
        us: {
          unitId: 93,
          packageAmount: 1.0,
          amountInBase: 840.0,
          equivalents: '1 medium head = about 1.85 lb'
        }
      },
      nutrition: {
        energy: 16.0,
        ash: 0.98,
        water: 94.39,
        carbs: 3.23,
        fiber: 1.2,
        sugars: 1.41,
        fructose: 0.6,
        glucose: 0.8,
        fat: 0.2,
        monounsaturated: 0.023,
        polyunsaturated: 0.072,
        omega_3: 0.057,
        omega_6: 0.015,
        saturated: 0.043,
        protein: 1.2,
        alanine: 0.069,
        arginine: 0.067,
        aspartic_acid: 0.086,
        cystine: 0.013,
        glutamic_acid: 0.288,
        glycine: 0.035,
        histidine: 0.021,
        isoleucine: 0.068,
        leucine: 0.07,
        lysine: 0.071,
        methionine: 0.007,
        phenylalanine: 0.035,
        proline: 0.025,
        serine: 0.038,
        threonine: 0.039,
        tryptophan: 0.012,
        tyrosine: 0.023,
        valine: 0.053,
        b1_thiamine: 0.04,
        b2_riboflavin: 0.05,
        b3_niacin: 0.4,
        b5_pantothenic_acid: 0.105,
        b6_pyridoxine: 0.232,
        choline: 7.6,
        folate: 79.0,
        vitamin_a: 318.0,
        vitamin_c: 27.0,
        vitamin_e: 0.12,
        vitamin_k: 42.9,
        calcium: 77.0,
        copper: 0.036,
        iron: 0.31,
        magnesium: 13.0,
        manganese: 0.19,
        phosphorus: 29.0,
        potassium: 238.0,
        selenium: 0.6,
        sodium: 9.0,
        zinc: 0.23
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '259': {
      id: 259,
      name: 'orange bell pepper',
      pluralName: 'orange bell peppers',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 119.0,
          equivalents: '1 medium pepper = 145 g'
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 119.0,
          equivalents: '1 medium pepper = 5 oz / 0.32 lb'
        }
      },
      nutrition: {
        energy: 27.0,
        ash: 0.45,
        water: 92.02,
        carbs: 6.32,
        fiber: 0.9,
        sugars: 5.23,
        fructose: 3.07,
        galactose: 0.01,
        glucose: 2.15,
        fat: 0.21,
        saturated: 0.031,
        protein: 1.0,
        alanine: 0.041,
        arginine: 0.048,
        aspartic_acid: 0.143,
        cystine: 0.019,
        glutamic_acid: 0.132,
        glycine: 0.037,
        histidine: 0.02,
        isoleucine: 0.032,
        leucine: 0.052,
        lysine: 0.044,
        methionine: 0.012,
        phenylalanine: 0.031,
        proline: 0.044,
        serine: 0.04,
        threonine: 0.037,
        tryptophan: 0.013,
        tyrosine: 0.021,
        valine: 0.042,
        b1_thiamine: 0.028,
        b2_riboflavin: 0.025,
        b3_niacin: 0.89,
        b5_pantothenic_acid: 0.168,
        b6_pyridoxine: 0.168,
        choline: 5.6,
        folate: 26.0,
        vitamin_a: 309.0,
        vitamin_c: 183.5,
        vitamin_e: 0.37,
        vitamin_k: 2.36,
        calcium: 11.0,
        copper: 0.107,
        iron: 0.46,
        magnesium: 12.0,
        manganese: 0.117,
        phosphorus: 24.0,
        potassium: 212.0,
        selenium: 0.3,
        sodium: 2.0,
        zinc: 0.17
      },
      restrictionWarnings: [],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '344': {
      id: 344,
      name: 'artichoke hearts, marinated',
      pluralName: 'artichoke hearts, marinated',
      description: null,
      sectionId: 9,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 96,
          packageAmount: 1.0,
          amountInBase: 106.0,
          equivalents: ''
        },
        us: {
          unitId: 97,
          packageAmount: 1.0,
          amountInBase: 106.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 51.0,
        ash: 1.3,
        water: 84.08,
        carbs: 11.39,
        fiber: 5.7,
        sugars: 0.99,
        fructose: 0.02,
        glucose: 0.24,
        sucrose: 0.73,
        fat: 0.34,
        monounsaturated: 0.011,
        polyunsaturated: 0.145,
        omega_3: 0.038,
        omega_6: 0.105,
        saturated: 0.079,
        protein: 2.89,
        b1_thiamine: 0.05,
        b2_riboflavin: 0.089,
        b3_niacin: 1.11,
        b5_pantothenic_acid: 0.24,
        b6_pyridoxine: 0.081,
        choline: 34.4,
        folate: 89.0,
        vitamin_a: 13.0,
        vitamin_c: 7.4,
        vitamin_e: 0.19,
        vitamin_k: 14.8,
        calcium: 21.0,
        copper: 0.127,
        iron: 0.61,
        magnesium: 42.0,
        manganese: 0.225,
        phosphorus: 73.0,
        potassium: 286.0,
        selenium: 0.2,
        sodium: 296.0,
        zinc: 0.4
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '285': {
      id: 285,
      name: 'baby bok choy',
      pluralName: 'baby bok choy',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 113.0,
          equivalents: '6 heads = 0.454-0.68 kg'
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 113.0,
          equivalents: '6 heads = 1-1.5 lb'
        }
      },
      nutrition: {
        energy: 13.0,
        ash: 0.8,
        water: 95.32,
        carbs: 2.18,
        fiber: 1.0,
        sugars: 1.18,
        fructose: 0.4,
        glucose: 0.6,
        fat: 0.2,
        monounsaturated: 0.015,
        polyunsaturated: 0.096,
        omega_3: 0.055,
        omega_6: 0.042,
        saturated: 0.027,
        protein: 1.5,
        alanine: 0.086,
        arginine: 0.084,
        aspartic_acid: 0.108,
        cystine: 0.017,
        glutamic_acid: 0.36,
        glycine: 0.043,
        histidine: 0.026,
        isoleucine: 0.085,
        leucine: 0.088,
        lysine: 0.089,
        methionine: 0.009,
        phenylalanine: 0.044,
        proline: 0.031,
        serine: 0.048,
        threonine: 0.049,
        tryptophan: 0.015,
        tyrosine: 0.029,
        valine: 0.066,
        b1_thiamine: 0.04,
        b2_riboflavin: 0.07,
        b3_niacin: 0.5,
        b5_pantothenic_acid: 0.088,
        b6_pyridoxine: 0.194,
        choline: 6.4,
        folate: 66.0,
        vitamin_a: 4.0,
        vitamin_c: 45.0,
        vitamin_e: 0.09,
        vitamin_k: 45.5,
        calcium: 105.0,
        copper: 0.021,
        iron: 0.8,
        magnesium: 19.0,
        manganese: 0.159,
        phosphorus: 37.0,
        potassium: 252.0,
        selenium: 0.5,
        sodium: 65.0,
        zinc: 0.19
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '14': {
      id: 14,
      name: 'Brussels sprouts',
      pluralName: 'Brussels sprouts',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '0.454 kg = 20-25 sprouts'
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '1 lb = 20-25 sprouts'
        }
      },
      nutrition: {
        energy: 43.0,
        ash: 1.37,
        water: 86.0,
        carbs: 8.95,
        fiber: 3.8,
        starch: 2.95,
        sugars: 2.2,
        fructose: 0.93,
        glucose: 0.81,
        sucrose: 0.46,
        fat: 0.3,
        monounsaturated: 0.023,
        polyunsaturated: 0.153,
        omega_3: 0.099,
        omega_6: 0.046,
        saturated: 0.062,
        protein: 3.38,
        arginine: 0.203,
        cystine: 0.022,
        histidine: 0.076,
        isoleucine: 0.132,
        leucine: 0.152,
        lysine: 0.154,
        methionine: 0.032,
        phenylalanine: 0.098,
        threonine: 0.12,
        tryptophan: 0.037,
        valine: 0.155,
        b1_thiamine: 0.139,
        b2_riboflavin: 0.09,
        b3_niacin: 0.745,
        b5_pantothenic_acid: 0.309,
        b6_pyridoxine: 0.219,
        choline: 19.1,
        folate: 61.0,
        vitamin_a: 754.0,
        vitamin_c: 85.0,
        vitamin_e: 0.88,
        vitamin_k: 177.0,
        calcium: 42.0,
        copper: 0.07,
        iron: 1.4,
        magnesium: 23.0,
        manganese: 0.337,
        phosphorus: 69.0,
        potassium: 389.0,
        selenium: 1.6,
        sodium: 25.0,
        zinc: 0.42
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '334': {
      id: 334,
      name: 'tahini',
      pluralName: 'tahini',
      description: null,
      sectionId: 14,
      subsectionId: null,
      density: 1.01,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 595.0,
        ash: 5.0,
        water: 3.05,
        carbs: 21.19,
        fiber: 9.3,
        sugars: 0.49,
        fat: 53.76,
        monounsaturated: 20.302,
        polyunsaturated: 23.564,
        omega_3: 0.407,
        omega_6: 23.133,
        saturated: 7.529,
        protein: 17.0,
        alanine: 0.889,
        arginine: 2.521,
        aspartic_acid: 1.578,
        cystine: 0.343,
        glutamic_acid: 3.792,
        glycine: 1.165,
        histidine: 0.5,
        isoleucine: 0.731,
        leucine: 1.302,
        lysine: 0.545,
        methionine: 0.561,
        phenylalanine: 0.901,
        proline: 0.776,
        serine: 0.927,
        threonine: 0.706,
        tryptophan: 0.372,
        tyrosine: 0.712,
        valine: 0.95,
        b1_thiamine: 1.22,
        b2_riboflavin: 0.473,
        b3_niacin: 5.45,
        b5_pantothenic_acid: 0.693,
        b6_pyridoxine: 0.149,
        choline: 25.8,
        folate: 98.0,
        vitamin_a: 67.0,
        vitamin_e: 0.25,
        calcium: 426.0,
        copper: 1.61,
        iron: 8.95,
        magnesium: 95.0,
        manganese: 1.456,
        phosphorus: 732.0,
        potassium: 414.0,
        selenium: 34.4,
        sodium: 115.0,
        zinc: 4.62
      },
      restrictionWarnings: [],
      restrictionViolations: [12],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '265': {
      id: 265,
      name: 'yellow bell pepper',
      pluralName: 'yellow bell peppers',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 119.0,
          equivalents: '1 medium bell pepper = 145 g'
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 119.0,
          equivalents: '1 medium bell pepper = 5.1 oz / 0.32 lb'
        }
      },
      nutrition: {
        energy: 27.0,
        ash: 0.45,
        water: 92.02,
        carbs: 6.32,
        fiber: 0.9,
        sugars: 5.23,
        fructose: 3.07,
        galactose: 0.01,
        glucose: 2.15,
        fat: 0.21,
        saturated: 0.031,
        protein: 1.0,
        alanine: 0.041,
        arginine: 0.048,
        aspartic_acid: 0.143,
        cystine: 0.019,
        glutamic_acid: 0.132,
        glycine: 0.037,
        histidine: 0.02,
        isoleucine: 0.032,
        leucine: 0.052,
        lysine: 0.044,
        methionine: 0.012,
        phenylalanine: 0.031,
        proline: 0.044,
        serine: 0.04,
        threonine: 0.037,
        tryptophan: 0.013,
        tyrosine: 0.021,
        valine: 0.042,
        b1_thiamine: 0.028,
        b2_riboflavin: 0.025,
        b3_niacin: 0.89,
        b5_pantothenic_acid: 0.168,
        b6_pyridoxine: 0.168,
        choline: 5.6,
        folate: 26.0,
        vitamin_a: 309.0,
        vitamin_c: 183.5,
        vitamin_e: 0.37,
        vitamin_k: 2.36,
        calcium: 11.0,
        copper: 0.107,
        iron: 0.46,
        magnesium: 12.0,
        manganese: 0.117,
        phosphorus: 24.0,
        potassium: 212.0,
        selenium: 0.3,
        sodium: 2.0,
        zinc: 0.17
      },
      restrictionWarnings: [],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '341': {
      id: 341,
      name: 'lean ground pork',
      pluralName: 'lean ground pork',
      description: null,
      sectionId: 1,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 0.68,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.5,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 218.0,
        ash: 0.91,
        water: 64.67,
        carbs: 0.44,
        fat: 16.0,
        monounsaturated: 6.68,
        polyunsaturated: 2.05,
        omega_3: 0.089,
        omega_6: 1.841,
        saturated: 4.93,
        transfats: 0.124,
        cholesterol: 68.0,
        protein: 17.99,
        alanine: 1.06,
        arginine: 1.173,
        aspartic_acid: 1.62,
        cystine: 0.208,
        glutamic_acid: 2.658,
        glycine: 1.005,
        histidine: 0.711,
        isoleucine: 0.822,
        leucine: 1.435,
        lysine: 1.547,
        methionine: 0.48,
        phenylalanine: 0.766,
        proline: 0.883,
        serine: 0.722,
        threonine: 0.763,
        tryptophan: 0.232,
        tyrosine: 0.762,
        valine: 0.905,
        b1_thiamine: 0.332,
        b2_riboflavin: 0.338,
        b3_niacin: 6.416,
        b5_pantothenic_acid: 0.639,
        b6_pyridoxine: 0.551,
        b12_cobalamin: 0.73,
        choline: 61.5,
        folate: 2.0,
        vitamin_d: 17.0,
        vitamin_e: 0.45,
        calcium: 15.0,
        copper: 0.032,
        iron: 0.88,
        magnesium: 16.0,
        manganese: 0.01,
        phosphorus: 161.0,
        potassium: 244.0,
        selenium: 30.2,
        sodium: 68.0,
        zinc: 1.91
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '314': {
      id: 314,
      name: 'spaghetti squash',
      pluralName: 'spaghetti squash',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 1360.0,
          equivalents: '1 medium spaghetti squash = 1.4-1.5 kg'
        },
        us: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 1360.0,
          equivalents: '1 medium spaghetti squash = 3-3.25 lb'
        }
      },
      nutrition: {
        energy: 27.0,
        ash: 0.32,
        water: 92.3,
        carbs: 6.46,
        fiber: 1.4,
        starch: 0.64,
        sugars: 2.53,
        fructose: 0.89,
        glucose: 1.04,
        sucrose: 0.57,
        fat: 0.26,
        monounsaturated: 0.02,
        polyunsaturated: 0.13,
        omega_3: 0.08,
        omega_6: 0.05,
        saturated: 0.06,
        protein: 0.64,
        alanine: 0.025,
        arginine: 0.033,
        aspartic_acid: 0.064,
        cystine: 0.005,
        glutamic_acid: 0.105,
        glycine: 0.022,
        histidine: 0.011,
        isoleucine: 0.024,
        leucine: 0.034,
        lysine: 0.022,
        methionine: 0.007,
        phenylalanine: 0.024,
        proline: 0.021,
        serine: 0.024,
        threonine: 0.018,
        tryptophan: 0.009,
        tyrosine: 0.02,
        valine: 0.026,
        b1_thiamine: 0.037,
        b2_riboflavin: 0.018,
        b3_niacin: 0.81,
        b5_pantothenic_acid: 0.36,
        b6_pyridoxine: 0.101,
        choline: 7.5,
        folate: 8.0,
        vitamin_a: 110.0,
        vitamin_c: 3.5,
        vitamin_e: 0.12,
        vitamin_k: 0.8,
        calcium: 21.0,
        copper: 0.04,
        iron: 0.34,
        magnesium: 11.0,
        manganese: 0.11,
        phosphorus: 14.0,
        potassium: 117.0,
        selenium: 0.3,
        sodium: 18.0,
        zinc: 0.2
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '356': {
      id: 356,
      name: 'medjool dates, pitted',
      pluralName: 'medjool dates, pitted',
      description: null,
      sectionId: 12,
      subsectionId: null,
      density: 0.85,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 277.0,
        ash: 1.74,
        water: 21.32,
        carbs: 74.97,
        fiber: 6.7,
        sugars: 66.47,
        fructose: 31.95,
        glucose: 33.68,
        maltose: 0.3,
        sucrose: 0.53,
        fat: 0.15,
        protein: 1.81,
        alanine: 0.078,
        arginine: 0.06,
        aspartic_acid: 0.22,
        cystine: 0.046,
        glutamic_acid: 0.265,
        glycine: 0.09,
        histidine: 0.029,
        isoleucine: 0.045,
        leucine: 0.082,
        lysine: 0.054,
        methionine: 0.017,
        phenylalanine: 0.048,
        proline: 0.111,
        serine: 0.062,
        threonine: 0.042,
        tryptophan: 0.007,
        tyrosine: 0.016,
        valine: 0.066,
        b1_thiamine: 0.05,
        b2_riboflavin: 0.06,
        b3_niacin: 1.61,
        b5_pantothenic_acid: 0.805,
        b6_pyridoxine: 0.249,
        choline: 9.9,
        folate: 15.0,
        vitamin_a: 149.0,
        vitamin_k: 2.7,
        calcium: 64.0,
        copper: 0.362,
        iron: 0.9,
        magnesium: 54.0,
        manganese: 0.296,
        phosphorus: 62.0,
        potassium: 696.0,
        sodium: 1.0,
        zinc: 0.44
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '233': {
      id: 233,
      name: 'parsnip',
      pluralName: 'parsnips',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 96.0,
          equivalents: '1 medium parsnip = 113 g'
        },
        us: {
          unitId: 86,
          packageAmount: 1.0,
          amountInBase: 96.0,
          equivalents: '1 medium parsnip = 4 oz / 0.25 lb'
        }
      },
      nutrition: {
        energy: 75.0,
        ash: 0.98,
        water: 79.53,
        carbs: 17.99,
        fiber: 4.9,
        starch: 5.84,
        sugars: 4.8,
        fructose: 0.52,
        glucose: 0.84,
        sucrose: 4.27,
        fat: 0.3,
        monounsaturated: 0.112,
        polyunsaturated: 0.047,
        omega_3: 0.003,
        omega_6: 0.041,
        saturated: 0.05,
        protein: 1.2,
        alanine: 0.06,
        arginine: 0.13,
        aspartic_acid: 0.12,
        glutamic_acid: 0.11,
        glycine: 0.04,
        histidine: 0.02,
        isoleucine: 0.05,
        leucine: 0.06,
        lysine: 0.07,
        methionine: 0.02,
        phenylalanine: 0.04,
        proline: 0.04,
        serine: 0.04,
        threonine: 0.05,
        tryptophan: 0.01,
        tyrosine: 0.02,
        valine: 0.06,
        b1_thiamine: 0.09,
        b2_riboflavin: 0.05,
        b3_niacin: 0.7,
        b5_pantothenic_acid: 0.6,
        b6_pyridoxine: 0.09,
        choline: 8.8,
        folate: 67.0,
        vitamin_c: 17.0,
        vitamin_e: 1.49,
        vitamin_k: 22.5,
        calcium: 36.0,
        copper: 0.12,
        iron: 0.59,
        magnesium: 29.0,
        manganese: 0.56,
        phosphorus: 71.0,
        potassium: 375.0,
        selenium: 1.8,
        sodium: 10.0,
        zinc: 0.59
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '231': {
      id: 231,
      name: 'portobello mushroom',
      pluralName: 'portobello mushrooms',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 2,
          packageAmount: 1.0,
          amountInBase: 84.0,
          equivalents: ''
        },
        us: {
          unitId: 2,
          packageAmount: 2.0,
          amountInBase: 84.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 22.0,
        ash: 0.85,
        water: 92.82,
        carbs: 3.87,
        fiber: 1.3,
        starch: 0.5,
        sugars: 2.5,
        fructose: 0.49,
        glucose: 2.01,
        fat: 0.35,
        monounsaturated: 0.02,
        polyunsaturated: 0.117,
        omega_6: 0.117,
        saturated: 0.06,
        transfats: 0.004,
        protein: 2.11,
        alanine: 0.168,
        arginine: 0.082,
        aspartic_acid: 0.221,
        cystine: 0.01,
        glutamic_acid: 0.319,
        glycine: 0.096,
        histidine: 0.058,
        isoleucine: 0.082,
        leucine: 0.13,
        lysine: 0.122,
        methionine: 0.029,
        phenylalanine: 0.076,
        proline: 0.076,
        serine: 0.092,
        threonine: 0.101,
        tryptophan: 0.035,
        tyrosine: 0.014,
        valine: 0.076,
        b1_thiamine: 0.059,
        b2_riboflavin: 0.13,
        b3_niacin: 4.494,
        b5_pantothenic_acid: 1.14,
        b6_pyridoxine: 0.148,
        b12_cobalamin: 0.05,
        choline: 21.2,
        folate: 28.0,
        vitamin_d: 10.0,
        vitamin_e: 0.02,
        calcium: 3.0,
        copper: 0.286,
        iron: 0.31,
        magnesium: 11.0,
        manganese: 0.069,
        phosphorus: 108.0,
        potassium: 364.0,
        selenium: 18.6,
        sodium: 9.0,
        zinc: 0.53
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '260': {
      id: 260,
      name: 'tomato',
      pluralName: 'tomatoes',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 123.0,
          equivalents: '1 medium tomato = 135 g / 0.135 kg'
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 123.0,
          equivalents: '1 medium tomato = 4.8 oz / 0.3 lb'
        }
      },
      nutrition: {
        energy: 18.0,
        ash: 0.5,
        water: 94.52,
        carbs: 3.89,
        fiber: 1.2,
        sugars: 2.63,
        fructose: 1.37,
        glucose: 1.25,
        fat: 0.2,
        monounsaturated: 0.031,
        polyunsaturated: 0.083,
        omega_3: 0.003,
        omega_6: 0.08,
        saturated: 0.028,
        protein: 0.88,
        alanine: 0.027,
        arginine: 0.021,
        aspartic_acid: 0.135,
        cystine: 0.009,
        glutamic_acid: 0.431,
        glycine: 0.019,
        histidine: 0.014,
        isoleucine: 0.018,
        leucine: 0.025,
        lysine: 0.027,
        methionine: 0.006,
        phenylalanine: 0.027,
        proline: 0.015,
        serine: 0.026,
        threonine: 0.027,
        tryptophan: 0.006,
        tyrosine: 0.014,
        valine: 0.018,
        b1_thiamine: 0.037,
        b2_riboflavin: 0.019,
        b3_niacin: 0.594,
        b5_pantothenic_acid: 0.089,
        b6_pyridoxine: 0.08,
        choline: 6.7,
        folate: 15.0,
        vitamin_a: 833.0,
        vitamin_c: 13.7,
        vitamin_e: 0.54,
        vitamin_k: 7.9,
        calcium: 10.0,
        copper: 0.059,
        iron: 0.27,
        magnesium: 11.0,
        manganese: 0.114,
        phosphorus: 24.0,
        potassium: 237.0,
        sodium: 5.0,
        zinc: 0.17
      },
      restrictionWarnings: [],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '345': {
      id: 345,
      name: 'pre-cooked vegan sausage',
      pluralName: 'pre-cooked vegan sausage',
      description: null,
      sectionId: 1,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 4.0,
          amountInBase: 99.0,
          equivalents: '1 pkg = 4 sausages'
        },
        us: {
          unitId: 1,
          packageAmount: 4.0,
          amountInBase: 99.0,
          equivalents: '1 pkg = 4 sausages'
        }
      },
      nutrition: {
        energy: 255.0,
        ash: 3.07,
        water: 50.4,
        carbs: 8.09,
        fiber: 2.8,
        fat: 18.16,
        monounsaturated: 4.498,
        polyunsaturated: 9.279,
        omega_3: 1.07,
        omega_6: 8.209,
        saturated: 2.926,
        protein: 20.28,
        alanine: 0.853,
        arginine: 1.519,
        aspartic_acid: 2.307,
        cystine: 0.305,
        glutamic_acid: 4.215,
        glycine: 0.821,
        histidine: 0.519,
        isoleucine: 0.97,
        leucine: 1.587,
        lysine: 1.262,
        methionine: 0.253,
        phenylalanine: 1.061,
        proline: 1.116,
        serine: 1.087,
        threonine: 0.785,
        tryptophan: 0.279,
        tyrosine: 0.694,
        valine: 1.029,
        b1_thiamine: 2.343,
        b2_riboflavin: 0.402,
        b3_niacin: 11.195,
        b5_pantothenic_acid: 0.323,
        b6_pyridoxine: 0.828,
        choline: 69.7,
        folate: 26.0,
        vitamin_e: 2.1,
        calcium: 63.0,
        copper: 0.25,
        iron: 3.72,
        magnesium: 36.0,
        manganese: 0.725,
        phosphorus: 225.0,
        potassium: 231.0,
        selenium: 7.4,
        sodium: 888.0,
        zinc: 1.46
      },
      restrictionWarnings: [10, 14, 13, 12, 9, 5, 6, 1],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '283': {
      id: 283,
      name: 'red chili pepper',
      pluralName: 'red chili peppers',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 45.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 45.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 40.0,
        ash: 0.87,
        water: 88.02,
        carbs: 8.81,
        fiber: 1.5,
        sugars: 5.3,
        fructose: 2.9,
        glucose: 2.4,
        fat: 0.44,
        monounsaturated: 0.024,
        polyunsaturated: 0.239,
        omega_3: 0.011,
        omega_6: 0.228,
        saturated: 0.042,
        protein: 1.87,
        alanine: 0.082,
        arginine: 0.096,
        aspartic_acid: 0.286,
        cystine: 0.038,
        glutamic_acid: 0.264,
        glycine: 0.074,
        histidine: 0.041,
        isoleucine: 0.065,
        leucine: 0.105,
        lysine: 0.089,
        methionine: 0.024,
        phenylalanine: 0.062,
        proline: 0.087,
        serine: 0.08,
        threonine: 0.074,
        tryptophan: 0.026,
        tyrosine: 0.042,
        valine: 0.084,
        b1_thiamine: 0.072,
        b2_riboflavin: 0.086,
        b3_niacin: 1.244,
        b5_pantothenic_acid: 0.201,
        b6_pyridoxine: 0.506,
        choline: 10.9,
        folate: 23.0,
        vitamin_a: 952.0,
        vitamin_c: 143.7,
        vitamin_e: 0.69,
        vitamin_k: 14.0,
        calcium: 14.0,
        copper: 0.129,
        iron: 1.03,
        magnesium: 23.0,
        manganese: 0.187,
        phosphorus: 43.0,
        potassium: 322.0,
        selenium: 0.5,
        sodium: 9.0,
        zinc: 0.26
      },
      restrictionWarnings: [],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '124': {
      id: 124,
      name: 'cheddar style shreds',
      pluralName: 'cheddar style shreds',
      description: '',
      sectionId: 2,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: true,
      unitAssignments: {
        metric: {
          unitId: 42,
          packageAmount: 0.5,
          amountInBase: 227.0,
          equivalents: ''
        },
        us: {
          unitId: 18,
          packageAmount: 1.0,
          amountInBase: 227.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 321.43,
        carbs: 25.0,
        fiber: 3.57,
        fat: 21.43,
        saturated: 7.14,
        protein: 3.57,
        b1_thiamine: 2.04,
        b2_riboflavin: 2.04,
        b3_niacin: 12.07,
        b6_pyridoxine: 2.07,
        b12_cobalamin: 1.68,
        folate: 52.79,
        vitamin_e: 2.63,
        calcium: 535.71,
        magnesium: 2.39,
        phosphorus: 32.82,
        potassium: 118.0,
        sodium: 892.86,
        zinc: 0.03
      },
      restrictionWarnings: [14, 13, 12, 11, 9, 6, 5, 1],
      restrictionViolations: [10],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '472': {
      id: 472,
      name: 'alfalfa sprouts',
      pluralName: 'alfalfa sprouts',
      description: null,
      sectionId: 4,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 197,
          packageAmount: 1.0,
          amountInBase: 85.0,
          equivalents: ''
        },
        us: {
          unitId: 197,
          packageAmount: 1.0,
          amountInBase: 85.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 23.0,
        water: 92.8,
        carbs: 2.1,
        fiber: 1.9,
        sugars: 0.2,
        fat: 0.69,
        monounsaturated: 0.056,
        polyunsaturated: 0.409,
        omega_3: 0.175,
        omega_6: 0.234,
        saturated: 0.069,
        protein: 3.99,
        b1_thiamine: 0.076,
        b2_riboflavin: 0.126,
        b3_niacin: 0.481,
        b6_pyridoxine: 0.034,
        choline: 14.4,
        folate: 36.0,
        vitamin_c: 8.2,
        vitamin_e: 0.02,
        vitamin_k: 30.5,
        calcium: 32.0,
        copper: 0.157,
        iron: 0.96,
        magnesium: 27.0,
        phosphorus: 70.0,
        potassium: 79.0,
        selenium: 0.6,
        sodium: 6.0,
        zinc: 0.92
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '338': {
      id: 338,
      name: 'red lentils, dried',
      pluralName: 'red lentils, dried',
      description: null,
      sectionId: 3,
      subsectionId: null,
      density: 0.76,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 358.0,
        ash: 3.0,
        water: 7.82,
        carbs: 63.1,
        fiber: 10.8,
        starch: 47.62,
        fat: 2.17,
        monounsaturated: 0.5,
        polyunsaturated: 1.137,
        omega_3: 0.25,
        omega_6: 0.886,
        saturated: 0.379,
        protein: 23.91,
        alanine: 1.042,
        arginine: 1.928,
        aspartic_acid: 2.758,
        cystine: 0.327,
        glutamic_acid: 3.868,
        glycine: 1.014,
        histidine: 0.702,
        isoleucine: 1.078,
        leucine: 1.809,
        lysine: 1.74,
        methionine: 0.212,
        phenylalanine: 1.23,
        proline: 1.042,
        serine: 1.15,
        threonine: 0.895,
        tryptophan: 0.223,
        tyrosine: 0.667,
        valine: 1.238,
        b1_thiamine: 0.51,
        b2_riboflavin: 0.106,
        b3_niacin: 1.495,
        b5_pantothenic_acid: 0.348,
        b6_pyridoxine: 0.403,
        folate: 204.0,
        vitamin_a: 58.0,
        vitamin_c: 1.7,
        calcium: 48.0,
        copper: 1.303,
        iron: 7.39,
        magnesium: 59.0,
        manganese: 1.716,
        phosphorus: 294.0,
        potassium: 668.0,
        sodium: 7.0,
        zinc: 3.6
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '353': {
      id: 353,
      name: 'vanilla extract',
      pluralName: 'vanilla extract',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.88,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 288.0,
        alcohol: 34.4,
        ash: 0.26,
        water: 52.58,
        carbs: 12.65,
        sugars: 12.65,
        fat: 0.06,
        monounsaturated: 0.01,
        polyunsaturated: 0.004,
        omega_3: 0.001,
        omega_6: 0.003,
        saturated: 0.01,
        protein: 0.06,
        b1_thiamine: 0.011,
        b2_riboflavin: 0.095,
        b3_niacin: 0.425,
        b5_pantothenic_acid: 0.035,
        b6_pyridoxine: 0.026,
        calcium: 11.0,
        copper: 0.072,
        iron: 0.12,
        magnesium: 12.0,
        manganese: 0.23,
        phosphorus: 6.0,
        potassium: 148.0,
        sodium: 9.0,
        zinc: 0.11
      },
      restrictionWarnings: [11, 9, 2, 5, 6, 1],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '354': {
      id: 354,
      name: 'dark chocolate chips',
      pluralName: 'dark chocolate chips',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 1.014,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 528.0,
        caffeine: 56.0,
        water: 0.75,
        carbs: 60.18,
        fiber: 6.4,
        sugars: 48.81,
        fat: 33.2,
        monounsaturated: 10.51,
        polyunsaturated: 1.048,
        omega_3: 0.056,
        omega_6: 0.988,
        saturated: 19.536,
        cholesterol: 4.0,
        protein: 4.5,
        b1_thiamine: 0.023,
        b2_riboflavin: 0.146,
        b3_niacin: 0.708,
        b6_pyridoxine: 0.042,
        b12_cobalamin: 0.12,
        choline: 28.8,
        folate: 2.0,
        vitamin_e: 0.4,
        vitamin_k: 7.2,
        calcium: 40.0,
        copper: 0.825,
        iron: 5.44,
        magnesium: 132.0,
        phosphorus: 180.0,
        potassium: 429.0,
        selenium: 3.2,
        sodium: 20.0,
        zinc: 1.8
      },
      restrictionWarnings: [14, 9, 2, 5, 6, 1],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '355': {
      id: 355,
      name: 'cream cheese',
      pluralName: 'cream cheese',
      description: null,
      sectionId: 2,
      subsectionId: null,
      density: 0.981,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 69,
          packageAmount: 0.5,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 68,
          packageAmount: 0.5,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 350.0,
        ash: 1.27,
        water: 52.62,
        carbs: 5.52,
        starch: 0.35,
        sugars: 3.76,
        lactose: 3.76,
        fat: 34.44,
        monounsaturated: 8.907,
        polyunsaturated: 1.483,
        omega_3: 0.131,
        omega_6: 1.212,
        saturated: 20.213,
        transfats: 1.173,
        cholesterol: 101.0,
        protein: 6.15,
        alanine: 0.184,
        arginine: 0.235,
        aspartic_acid: 0.514,
        cystine: 0.042,
        glutamic_acid: 1.304,
        glycine: 0.142,
        histidine: 0.175,
        isoleucine: 0.324,
        leucine: 0.657,
        lysine: 0.567,
        methionine: 0.191,
        phenylalanine: 0.291,
        proline: 0.665,
        serine: 0.374,
        threonine: 0.233,
        tryptophan: 0.069,
        tyrosine: 0.303,
        valine: 0.395,
        b1_thiamine: 0.023,
        b2_riboflavin: 0.23,
        b3_niacin: 0.091,
        b5_pantothenic_acid: 0.517,
        b6_pyridoxine: 0.056,
        b12_cobalamin: 0.22,
        choline: 26.8,
        folate: 9.0,
        vitamin_a: 1111.0,
        vitamin_e: 0.86,
        vitamin_k: 2.1,
        calcium: 97.0,
        copper: 0.018,
        iron: 0.11,
        magnesium: 9.0,
        manganese: 0.011,
        phosphorus: 107.0,
        potassium: 132.0,
        selenium: 8.6,
        sodium: 314.0,
        zinc: 0.5
      },
      restrictionWarnings: [14],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '357': {
      id: 357,
      name: 'frozen cherries',
      pluralName: 'frozen cherries',
      description: null,
      sectionId: 13,
      subsectionId: null,
      density: 0.66,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 46.0,
        ash: 0.42,
        water: 87.2,
        carbs: 11.02,
        fiber: 1.6,
        sugars: 9.02,
        fat: 0.44,
        monounsaturated: 0.12,
        polyunsaturated: 0.132,
        omega_3: 0.065,
        omega_6: 0.067,
        saturated: 0.1,
        protein: 0.92,
        b1_thiamine: 0.044,
        b2_riboflavin: 0.034,
        b3_niacin: 0.137,
        b5_pantothenic_acid: 0.178,
        b6_pyridoxine: 0.067,
        choline: 5.6,
        folate: 5.0,
        vitamin_a: 870.0,
        vitamin_c: 1.7,
        vitamin_e: 0.05,
        vitamin_k: 1.5,
        calcium: 13.0,
        copper: 0.09,
        iron: 0.53,
        magnesium: 9.0,
        manganese: 0.057,
        phosphorus: 16.0,
        potassium: 124.0,
        sodium: 1.0,
        zinc: 0.1
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '339': {
      id: 339,
      name: 'fresh sage',
      pluralName: 'fresh sage',
      description: null,
      sectionId: 4,
      subsectionId: 4,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 8,
          packageAmount: 1.0,
          amountInBase: 21.26,
          equivalents: '1 small pkg = 0.75 oz'
        },
        us: {
          unitId: 8,
          packageAmount: 1.0,
          amountInBase: 21.26,
          equivalents: '1 small pkg = 21 g'
        }
      },
      nutrition: {
        energy: 315.0,
        ash: 7.95,
        water: 7.96,
        carbs: 60.73,
        fiber: 40.3,
        sugars: 1.71,
        fat: 12.75,
        monounsaturated: 1.87,
        polyunsaturated: 1.76,
        omega_3: 1.23,
        omega_6: 0.53,
        saturated: 7.03,
        protein: 10.63,
        b1_thiamine: 0.754,
        b2_riboflavin: 0.336,
        b3_niacin: 5.72,
        b6_pyridoxine: 2.69,
        choline: 43.6,
        folate: 274.0,
        vitamin_a: 5900.0,
        vitamin_c: 32.4,
        vitamin_e: 7.48,
        vitamin_k: 1714.5,
        calcium: 1652.0,
        copper: 0.757,
        iron: 28.12,
        magnesium: 428.0,
        manganese: 3.133,
        phosphorus: 91.0,
        potassium: 1070.0,
        selenium: 3.7,
        sodium: 11.0,
        zinc: 4.7
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '291': {
      id: 291,
      name: 'green bell pepper',
      pluralName: 'green bell peppers',
      description: '',
      sectionId: 4,
      subsectionId: 3,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 119.0,
          equivalents: '1 medium pepper = 145 g'
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 119.0,
          equivalents: '1 medium pepper = 5 oz / 0.32 lb'
        }
      },
      nutrition: {
        energy: 20.0,
        ash: 0.43,
        water: 93.89,
        carbs: 4.64,
        fiber: 1.7,
        sugars: 2.4,
        fructose: 1.12,
        glucose: 1.16,
        sucrose: 0.11,
        fat: 0.17,
        monounsaturated: 0.008,
        polyunsaturated: 0.062,
        omega_3: 0.008,
        omega_6: 0.054,
        saturated: 0.058,
        protein: 0.86,
        alanine: 0.036,
        arginine: 0.027,
        aspartic_acid: 0.208,
        cystine: 0.012,
        glutamic_acid: 0.194,
        glycine: 0.03,
        histidine: 0.01,
        isoleucine: 0.024,
        leucine: 0.036,
        lysine: 0.039,
        methionine: 0.007,
        phenylalanine: 0.092,
        proline: 0.024,
        serine: 0.054,
        threonine: 0.036,
        tryptophan: 0.012,
        tyrosine: 0.012,
        valine: 0.036,
        b1_thiamine: 0.057,
        b2_riboflavin: 0.028,
        b3_niacin: 0.48,
        b5_pantothenic_acid: 0.099,
        b6_pyridoxine: 0.224,
        choline: 5.5,
        folate: 10.0,
        vitamin_a: 370.0,
        vitamin_c: 80.4,
        vitamin_e: 0.37,
        vitamin_k: 7.4,
        calcium: 10.0,
        copper: 0.066,
        iron: 0.34,
        magnesium: 10.0,
        manganese: 0.122,
        phosphorus: 20.0,
        potassium: 175.0,
        sodium: 3.0,
        zinc: 0.13
      },
      restrictionWarnings: [],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '331': {
      id: 331,
      name: 'baking powder',
      pluralName: 'baking powder',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.93,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 53.0,
        ash: 67.3,
        water: 5.0,
        carbs: 27.7,
        fiber: 0.2,
        calcium: 5876.0,
        copper: 0.01,
        iron: 11.02,
        magnesium: 27.0,
        manganese: 0.011,
        phosphorus: 2191.0,
        potassium: 20.0,
        selenium: 0.2,
        sodium: 10600.0,
        zinc: 0.01
      },
      restrictionWarnings: [],
      restrictionViolations: [14],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '315': {
      id: 315,
      name: 'acorn squash',
      pluralName: 'acorn squash',
      description: '',
      sectionId: 4,
      subsectionId: 2,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 431.0,
          equivalents: '1 medium squash = 0.454 - 0.68 kg'
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 431.0,
          equivalents: '1 medium squash = 1 - 1.5 lb'
        }
      },
      nutrition: {
        energy: 56.0,
        ash: 1.26,
        water: 82.9,
        carbs: 14.58,
        fiber: 4.4,
        starch: 8.58,
        sugars: 1.28,
        fructose: 0.73,
        glucose: 0.4,
        sucrose: 0.15,
        fat: 0.14,
        monounsaturated: 0.01,
        polyunsaturated: 0.06,
        omega_3: 0.04,
        omega_6: 0.02,
        saturated: 0.03,
        protein: 1.12,
        alanine: 0.05,
        arginine: 0.06,
        aspartic_acid: 0.12,
        cystine: 0.01,
        glutamic_acid: 0.2,
        glycine: 0.04,
        histidine: 0.02,
        isoleucine: 0.04,
        leucine: 0.06,
        lysine: 0.04,
        methionine: 0.01,
        phenylalanine: 0.04,
        proline: 0.04,
        serine: 0.04,
        threonine: 0.03,
        tryptophan: 0.02,
        tyrosine: 0.04,
        valine: 0.05,
        b1_thiamine: 0.17,
        b2_riboflavin: 0.01,
        b3_niacin: 0.88,
        b5_pantothenic_acid: 0.5,
        b6_pyridoxine: 0.19,
        choline: 10.6,
        folate: 19.0,
        vitamin_a: 428.0,
        vitamin_c: 10.8,
        vitamin_e: 0.12,
        vitamin_k: 4.4,
        calcium: 44.0,
        copper: 0.09,
        iron: 0.93,
        magnesium: 43.0,
        manganese: 0.24,
        phosphorus: 45.0,
        potassium: 437.0,
        selenium: 0.7,
        sodium: 4.0,
        zinc: 0.17
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '434': {
      id: 434,
      name: 'vanilla ice cream, vegan',
      pluralName: 'vanilla ice cream, vegan',
      description: null,
      sectionId: 13,
      subsectionId: null,
      density: 0.57,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: 473.0,
          equivalents: ''
        },
        us: {
          unitId: 60,
          packageAmount: 1.0,
          amountInBase: 16.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 234.0,
        carbs: 24.32,
        fiber: 0.9,
        sugars: 22.52,
        fat: 14.41,
        saturated: 10.81,
        protein: 0.9,
        calcium: 5.0,
        potassium: 27.0,
        sodium: 122.0
      },
      restrictionWarnings: [14, 12, 9, 5, 6, 1],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '432': {
      id: 432,
      name: 'French vinaigrette',
      pluralName: 'French vinaigrette',
      description: null,
      sectionId: 14,
      subsectionId: null,
      density: 0.98,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: { energy: 448.0, fat: 48.28, saturated: 3.45, sodium: 931.0 },
      restrictionWarnings: [14, 12, 11, 9, 2, 5, 6, 1, 3, 4],
      restrictionViolations: [10, 13],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '437': {
      id: 437,
      name: 'granola, nut free',
      pluralName: 'granola, nut free',
      description: null,
      sectionId: 22,
      subsectionId: null,
      density: 0.46,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: 1.0,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: 1.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 489.0,
        ash: 2.31,
        water: 5.84,
        carbs: 53.88,
        fiber: 8.9,
        sugars: 19.8,
        fat: 24.31,
        monounsaturated: 10.951,
        polyunsaturated: 7.809,
        omega_3: 0.611,
        omega_6: 7.194,
        saturated: 3.957,
        transfats: 0.023,
        protein: 13.67,
        alanine: 0.694,
        arginine: 1.279,
        aspartic_acid: 1.396,
        cystine: 0.302,
        glutamic_acid: 3.111,
        glycine: 0.812,
        histidine: 0.349,
        isoleucine: 0.546,
        leucine: 0.977,
        lysine: 0.631,
        methionine: 0.225,
        phenylalanine: 0.668,
        proline: 0.658,
        serine: 0.668,
        threonine: 0.479,
        tryptophan: 0.186,
        tyrosine: 0.379,
        valine: 0.698,
        b1_thiamine: 0.548,
        b2_riboflavin: 0.354,
        b3_niacin: 2.739,
        b5_pantothenic_acid: 0.752,
        b6_pyridoxine: 0.37,
        choline: 49.2,
        folate: 84.0,
        vitamin_a: 19.0,
        vitamin_c: 1.2,
        vitamin_e: 11.1,
        vitamin_k: 5.3,
        calcium: 76.0,
        copper: 0.647,
        iron: 3.95,
        magnesium: 168.0,
        manganese: 3.996,
        phosphorus: 431.0,
        potassium: 539.0,
        selenium: 25.4,
        sodium: 26.0,
        zinc: 4.17
      },
      restrictionWarnings: [14, 12, 11, 9, 2, 1],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '438': {
      id: 438,
      name: 'granola, vegan',
      pluralName: 'granola, vegan',
      description: null,
      sectionId: 6,
      subsectionId: null,
      density: 0.46,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: 1.0,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: 1.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 489.0,
        ash: 2.31,
        water: 5.84,
        carbs: 53.88,
        fiber: 8.9,
        sugars: 19.8,
        fat: 24.31,
        monounsaturated: 10.951,
        polyunsaturated: 7.809,
        omega_3: 0.611,
        omega_6: 7.194,
        saturated: 3.957,
        transfats: 0.023,
        protein: 13.67,
        alanine: 0.694,
        arginine: 1.279,
        aspartic_acid: 1.396,
        cystine: 0.302,
        glutamic_acid: 3.111,
        glycine: 0.812,
        histidine: 0.349,
        isoleucine: 0.546,
        leucine: 0.977,
        lysine: 0.631,
        methionine: 0.225,
        phenylalanine: 0.668,
        proline: 0.658,
        serine: 0.668,
        threonine: 0.479,
        tryptophan: 0.186,
        tyrosine: 0.379,
        valine: 0.698,
        b1_thiamine: 0.548,
        b2_riboflavin: 0.354,
        b3_niacin: 2.739,
        b5_pantothenic_acid: 0.752,
        b6_pyridoxine: 0.37,
        choline: 49.2,
        folate: 84.0,
        vitamin_a: 19.0,
        vitamin_c: 1.2,
        vitamin_e: 11.1,
        vitamin_k: 5.3,
        calcium: 76.0,
        copper: 0.647,
        iron: 3.95,
        magnesium: 168.0,
        manganese: 3.996,
        phosphorus: 431.0,
        potassium: 539.0,
        selenium: 25.4,
        sodium: 26.0,
        zinc: 4.17
      },
      restrictionWarnings: [14, 12, 9, 5, 6, 1],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '395': {
      id: 395,
      name: 'rotisserie chicken, white and dark meat',
      pluralName: 'rotisserie chicken, white and dark meat',
      description: null,
      sectionId: 7,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 134,
          packageAmount: 1.0,
          amountInBase: 532.324,
          equivalents: ''
        },
        us: {
          unitId: 133,
          packageAmount: 1.0,
          amountInBase: 532.324,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 164.0,
        water: 66.02,
        fat: 6.36,
        monounsaturated: 2.706,
        polyunsaturated: 0.937,
        omega_3: 0.051,
        omega_6: 0.859,
        saturated: 1.579,
        cholesterol: 112.0,
        protein: 26.79,
        b1_thiamine: 0.07,
        b2_riboflavin: 0.182,
        b3_niacin: 7.598,
        b6_pyridoxine: 0.247,
        b12_cobalamin: 0.36,
        choline: 66.3,
        folate: 10.0,
        vitamin_e: 0.41,
        calcium: 14.0,
        copper: 0.064,
        iron: 0.73,
        magnesium: 23.0,
        phosphorus: 237.0,
        potassium: 279.0,
        selenium: 24.0,
        sodium: 345.0,
        zinc: 1.61
      },
      restrictionWarnings: [13, 12],
      restrictionViolations: [14],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '407': {
      id: 407,
      name: 'peanut sauce',
      pluralName: 'peanut sauce',
      description: null,
      sectionId: 5,
      subsectionId: null,
      density: 1.18,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 257.0,
        ash: 4.25,
        water: 51.4,
        carbs: 22.02,
        fiber: 1.8,
        sugars: 17.07,
        fructose: 2.13,
        glucose: 2.53,
        sucrose: 12.4,
        fat: 16.02,
        monounsaturated: 6.986,
        polyunsaturated: 4.786,
        omega_3: 0.026,
        omega_6: 4.752,
        saturated: 2.907,
        transfats: 0.035,
        protein: 6.31,
        b1_thiamine: 0.06,
        b2_riboflavin: 0.127,
        b3_niacin: 3.2,
        b5_pantothenic_acid: 0.31,
        b6_pyridoxine: 0.19,
        choline: 28.9,
        folate: 22.0,
        vitamin_e: 2.23,
        vitamin_k: 1.0,
        calcium: 22.0,
        copper: 0.123,
        iron: 0.91,
        magnesium: 45.0,
        manganese: 0.484,
        phosphorus: 107.0,
        potassium: 235.0,
        selenium: 3.4,
        sodium: 1338.0,
        zinc: 0.78
      },
      restrictionWarnings: [14, 13, 2],
      restrictionViolations: [10, 12, 9, 5, 6, 1],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '443': {
      id: 443,
      name: 'tortilla chips',
      pluralName: 'tortilla chips',
      description: null,
      sectionId: 19,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 497.0,
        ash: 1.73,
        water: 1.94,
        carbs: 67.38,
        fiber: 4.7,
        starch: 60.55,
        sugars: 1.21,
        fructose: 0.16,
        glucose: 0.16,
        sucrose: 0.89,
        fat: 22.33,
        monounsaturated: 8.584,
        polyunsaturated: 9.179,
        omega_3: 0.313,
        omega_6: 8.847,
        saturated: 2.776,
        transfats: 0.066,
        protein: 6.62,
        b1_thiamine: 0.13,
        b2_riboflavin: 0.043,
        b3_niacin: 1.498,
        b5_pantothenic_acid: 0.411,
        b6_pyridoxine: 0.202,
        choline: 19.1,
        folate: 22.0,
        vitamin_a: 158.0,
        vitamin_e: 2.97,
        vitamin_k: 0.6,
        calcium: 104.0,
        copper: 0.105,
        iron: 1.32,
        magnesium: 84.0,
        manganese: 0.357,
        phosphorus: 234.0,
        potassium: 206.0,
        selenium: 8.4,
        sodium: 310.0,
        zinc: 1.46
      },
      restrictionWarnings: [14, 5, 6, 1],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '441': {
      id: 441,
      name: 'guacamole',
      pluralName: 'guacamole',
      description: null,
      sectionId: 7,
      subsectionId: null,
      density: 1.01,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 155.0,
        water: 73.02,
        carbs: 8.45,
        fiber: 6.5,
        sugars: 0.72,
        fat: 14.18,
        monounsaturated: 9.477,
        polyunsaturated: 1.757,
        omega_3: 0.121,
        omega_6: 1.619,
        saturated: 2.057,
        protein: 1.95,
        b1_thiamine: 0.066,
        b2_riboflavin: 0.126,
        b3_niacin: 1.683,
        b6_pyridoxine: 0.251,
        choline: 13.9,
        folate: 79.0,
        vitamin_c: 10.2,
        vitamin_e: 2.0,
        vitamin_k: 20.3,
        calcium: 12.0,
        copper: 0.184,
        iron: 0.54,
        magnesium: 28.0,
        phosphorus: 51.0,
        potassium: 472.0,
        selenium: 0.4,
        sodium: 344.0,
        zinc: 0.62
      },
      restrictionWarnings: [10, 14, 13, 12, 9, 2, 5, 6, 1],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '440': {
      id: 440,
      name: 'romaine hearts',
      pluralName: 'romaine hearts',
      description: null,
      sectionId: 4,
      subsectionId: 5,
      density: 0.18,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 137,
          packageAmount: 1.0,
          amountInBase: 113.33,
          equivalents: ''
        },
        us: {
          unitId: 137,
          packageAmount: 1.0,
          amountInBase: 113.33,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 17.0,
        ash: 0.58,
        water: 94.61,
        carbs: 3.29,
        fiber: 2.1,
        sugars: 1.19,
        fructose: 0.8,
        glucose: 0.39,
        fat: 0.3,
        monounsaturated: 0.012,
        polyunsaturated: 0.16,
        omega_3: 0.113,
        omega_6: 0.047,
        saturated: 0.039,
        protein: 1.23,
        alanine: 0.056,
        arginine: 0.054,
        aspartic_acid: 0.139,
        cystine: 0.006,
        glutamic_acid: 0.178,
        glycine: 0.049,
        histidine: 0.021,
        isoleucine: 0.045,
        leucine: 0.076,
        lysine: 0.064,
        methionine: 0.015,
        phenylalanine: 0.065,
        proline: 0.045,
        serine: 0.05,
        threonine: 0.043,
        tryptophan: 0.01,
        tyrosine: 0.025,
        valine: 0.055,
        b1_thiamine: 0.072,
        b2_riboflavin: 0.067,
        b3_niacin: 0.313,
        b5_pantothenic_acid: 0.142,
        b6_pyridoxine: 0.074,
        choline: 9.9,
        folate: 136.0,
        vitamin_a: 8710.0,
        vitamin_c: 4.0,
        vitamin_e: 0.13,
        vitamin_k: 102.5,
        calcium: 33.0,
        copper: 0.048,
        iron: 0.97,
        magnesium: 14.0,
        manganese: 0.155,
        phosphorus: 30.0,
        potassium: 247.0,
        selenium: 0.4,
        sodium: 8.0,
        zinc: 0.23
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '439': {
      id: 439,
      name: 'sockeye salmon fillet',
      pluralName: 'sockeye salmon fillets',
      description: null,
      sectionId: 1,
      subsectionId: null,
      density: 1.0,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '1 fillet = ~130g'
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: '1 fillet = ~4oz'
        }
      },
      nutrition: {
        energy: 131.0,
        ash: 1.26,
        water: 72.54,
        fat: 4.69,
        monounsaturated: 1.371,
        polyunsaturated: 1.115,
        omega_3: 0.048,
        omega_6: 0.16,
        saturated: 0.814,
        transfats: 0.019,
        cholesterol: 51.0,
        protein: 22.25,
        alanine: 1.386,
        arginine: 1.442,
        aspartic_acid: 2.276,
        cystine: 0.248,
        glutamic_acid: 3.279,
        glycine: 1.07,
        histidine: 0.597,
        isoleucine: 1.07,
        leucine: 1.837,
        lysine: 2.163,
        methionine: 0.721,
        phenylalanine: 0.913,
        proline: 0.823,
        serine: 0.89,
        threonine: 1.048,
        tryptophan: 0.282,
        tyrosine: 1.014,
        valine: 1.228,
        b1_thiamine: 0.132,
        b2_riboflavin: 0.207,
        b3_niacin: 8.507,
        b5_pantothenic_acid: 1.07,
        b6_pyridoxine: 0.731,
        b12_cobalamin: 4.69,
        choline: 94.6,
        folate: 6.0,
        vitamin_a: 162.0,
        vitamin_d: 14.1,
        vitamin_e: 0.83,
        vitamin_k: 0.1,
        calcium: 9.0,
        copper: 0.064,
        iron: 0.43,
        magnesium: 30.0,
        manganese: 0.011,
        phosphorus: 257.0,
        potassium: 367.0,
        selenium: 29.8,
        sodium: 78.0,
        zinc: 0.46
      },
      restrictionWarnings: [],
      restrictionViolations: [3],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '433': {
      id: 433,
      name: 'shredded cheese, Mexican blend',
      pluralName: 'shredded cheese, Mexican blend',
      description: null,
      sectionId: 2,
      subsectionId: null,
      density: 0.47,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 358.0,
        water: 42.48,
        carbs: 1.75,
        sugars: 1.23,
        fat: 28.51,
        monounsaturated: 7.871,
        polyunsaturated: 0.864,
        omega_3: 0.332,
        omega_6: 0.532,
        saturated: 17.027,
        cholesterol: 95.0,
        protein: 23.54,
        b1_thiamine: 0.023,
        b2_riboflavin: 0.318,
        b3_niacin: 0.114,
        b6_pyridoxine: 0.061,
        b12_cobalamin: 1.23,
        choline: 15.7,
        folate: 13.0,
        vitamin_d: 0.5,
        vitamin_e: 0.25,
        vitamin_k: 2.5,
        calcium: 659.0,
        copper: 0.025,
        iron: 0.59,
        magnesium: 25.0,
        phosphorus: 438.0,
        potassium: 85.0,
        selenium: 15.0,
        sodium: 607.0,
        zinc: 3.01
      },
      restrictionWarnings: [10, 14, 13, 12, 11, 9, 5, 6, 1],
      restrictionViolations: [2],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '444': {
      id: 444,
      name: 'veggie ground, plant based',
      pluralName: 'veggie ground, plant based',
      description: null,
      sectionId: 7,
      subsectionId: null,
      density: 0.7,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 212.0,
        carbs: 7.96,
        fiber: 2.7,
        sugars: 0.88,
        fat: 12.39,
        saturated: 7.08,
        protein: 16.81,
        b1_thiamine: 31.0,
        b2_riboflavin: 0.226,
        b3_niacin: 8.85,
        b6_pyridoxine: 0.354,
        b12_cobalamin: 6.9,
        folate: 71.0,
        calcium: 150.0,
        iron: 3.72,
        phosphorus: 133.0,
        potassium: 540.0,
        sodium: 327.0,
        zinc: 6.64
      },
      restrictionWarnings: [10, 14, 13, 12, 11, 9, 5, 6, 1],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '445': {
      id: 445,
      name: 'ground pork sausage',
      pluralName: 'ground pork sausage',
      description: null,
      sectionId: 1,
      subsectionId: null,
      density: 0.88,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 0.454,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 296.0,
        ash: 2.72,
        water: 54.77,
        carbs: 3.78,
        fat: 25.1,
        monounsaturated: 10.603,
        polyunsaturated: 4.296,
        omega_3: 0.161,
        omega_6: 3.758,
        saturated: 8.595,
        transfats: 0.123,
        cholesterol: 63.0,
        protein: 13.63,
        b1_thiamine: 0.207,
        b2_riboflavin: 0.31,
        b3_niacin: 5.47,
        b6_pyridoxine: 0.313,
        b12_cobalamin: 2.0,
        choline: 96.7,
        vitamin_d: 1.5,
        vitamin_e: 0.22,
        calcium: 19.0,
        copper: 0.084,
        iron: 1.41,
        magnesium: 20.0,
        manganese: 0.093,
        phosphorus: 149.0,
        potassium: 308.0,
        selenium: 18.6,
        sodium: 788.0,
        zinc: 1.68
      },
      restrictionWarnings: [10, 14, 13, 12, 11, 9, 1],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '399': {
      id: 399,
      name: 'barbecue sauce',
      pluralName: 'barbecue sauce',
      description: null,
      sectionId: 14,
      subsectionId: null,
      density: 1.15,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 172.0,
        ash: 3.07,
        water: 54.71,
        carbs: 40.77,
        fiber: 0.9,
        starch: 0.35,
        sugars: 33.24,
        fructose: 14.17,
        glucose: 16.39,
        sucrose: 2.68,
        fat: 0.63,
        monounsaturated: 0.082,
        polyunsaturated: 0.101,
        omega_3: 0.021,
        omega_6: 0.08,
        saturated: 0.045,
        protein: 0.82,
        b1_thiamine: 0.023,
        b2_riboflavin: 0.056,
        b3_niacin: 0.597,
        b5_pantothenic_acid: 0.164,
        b6_pyridoxine: 0.075,
        choline: 7.1,
        folate: 2.0,
        vitamin_a: 224.0,
        vitamin_c: 0.6,
        vitamin_e: 0.8,
        vitamin_k: 1.8,
        calcium: 33.0,
        copper: 0.072,
        iron: 0.64,
        magnesium: 13.0,
        manganese: 0.126,
        phosphorus: 20.0,
        potassium: 232.0,
        selenium: 1.3,
        sodium: 1027.0,
        zinc: 0.17
      },
      restrictionWarnings: [14, 12, 11, 9, 2, 5, 6, 3, 4],
      restrictionViolations: [10, 13, 1],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '403': {
      id: 403,
      name: 'Thai curry paste, red',
      pluralName: 'Thai curry paste, red',
      description: null,
      sectionId: 5,
      subsectionId: null,
      density: 1.42,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: { energy: 100.0, carbs: 20.0, sodium: 2000.0 },
      restrictionWarnings: [14, 13, 12, 9, 2, 5, 6, 1, 3, 4],
      restrictionViolations: [10],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '448': {
      id: 448,
      name: 'hot sauce',
      pluralName: 'hot sauce',
      description: null,
      sectionId: 14,
      subsectionId: null,
      density: 1.01,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 11.0,
        ash: 7.39,
        water: 89.98,
        carbs: 1.75,
        fiber: 0.3,
        sugars: 1.26,
        fat: 0.37,
        monounsaturated: 0.03,
        polyunsaturated: 0.196,
        omega_3: 0.001,
        omega_6: 0.195,
        saturated: 0.052,
        protein: 0.51,
        alanine: 0.021,
        arginine: 0.025,
        aspartic_acid: 0.073,
        cystine: 0.01,
        glutamic_acid: 0.067,
        glycine: 0.019,
        histidine: 0.01,
        isoleucine: 0.017,
        leucine: 0.027,
        lysine: 0.023,
        methionine: 0.006,
        phenylalanine: 0.016,
        proline: 0.022,
        serine: 0.021,
        threonine: 0.019,
        tryptophan: 0.007,
        tyrosine: 0.011,
        valine: 0.022,
        b1_thiamine: 0.036,
        b2_riboflavin: 0.082,
        b3_niacin: 0.254,
        b5_pantothenic_acid: 0.11,
        b6_pyridoxine: 0.157,
        folate: 6.0,
        vitamin_a: 162.0,
        vitamin_c: 74.8,
        vitamin_e: 0.12,
        vitamin_k: 2.4,
        calcium: 8.0,
        copper: 0.028,
        iron: 0.48,
        magnesium: 5.0,
        manganese: 0.049,
        phosphorus: 11.0,
        potassium: 144.0,
        sodium: 2643.0,
        zinc: 0.11
      },
      restrictionWarnings: [14, 13, 12, 9, 5, 6, 1],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '416': {
      id: 416,
      name: 'oven roasted turkey breast, sliced',
      pluralName: 'oven roasted turkey breast, sliced',
      description: null,
      sectionId: 1,
      subsectionId: null,
      density: 1.0,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 680.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 24.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 106.0,
        water: 75.98,
        carbs: 2.2,
        sugars: 0.91,
        fat: 3.77,
        monounsaturated: 1.145,
        polyunsaturated: 1.024,
        omega_3: 0.051,
        omega_6: 0.884,
        saturated: 0.906,
        cholesterol: 49.0,
        protein: 14.81,
        b1_thiamine: 0.038,
        b2_riboflavin: 0.145,
        b3_niacin: 7.15,
        b6_pyridoxine: 0.41,
        b12_cobalamin: 0.37,
        choline: 30.1,
        folate: 4.0,
        vitamin_d: 0.2,
        vitamin_e: 0.13,
        calcium: 14.0,
        copper: 0.024,
        iron: 0.42,
        magnesium: 19.0,
        phosphorus: 249.0,
        potassium: 371.0,
        selenium: 13.0,
        sodium: 898.0,
        zinc: 0.94
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '430': {
      id: 430,
      name: 'frozen butternut squash, pieces',
      pluralName: 'frozen butternut squash, pieces',
      description: null,
      sectionId: 13,
      subsectionId: null,
      density: 1.01,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: 1.0,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: 1.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 26.0,
        water: 91.6,
        carbs: 6.5,
        fiber: 0.5,
        sugars: 2.76,
        fat: 0.1,
        monounsaturated: 0.013,
        polyunsaturated: 0.005,
        omega_3: 0.003,
        omega_6: 0.002,
        saturated: 0.052,
        protein: 1.0,
        b1_thiamine: 0.05,
        b2_riboflavin: 0.11,
        b3_niacin: 0.6,
        b6_pyridoxine: 0.061,
        choline: 8.2,
        folate: 16.0,
        vitamin_c: 9.0,
        vitamin_e: 1.06,
        vitamin_k: 1.1,
        calcium: 21.0,
        copper: 0.127,
        iron: 0.8,
        magnesium: 12.0,
        phosphorus: 44.0,
        potassium: 340.0,
        selenium: 0.3,
        sodium: 1.0,
        zinc: 0.32
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '425': {
      id: 425,
      name: 'blue cheese dressing',
      pluralName: 'blue cheese dressing',
      description: null,
      sectionId: 14,
      subsectionId: null,
      density: 1.01,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 484.0,
        ash: 3.2,
        water: 39.71,
        carbs: 4.77,
        fiber: 0.4,
        sugars: 3.48,
        fat: 51.1,
        monounsaturated: 13.279,
        polyunsaturated: 27.545,
        omega_3: 3.196,
        omega_6: 24.317,
        saturated: 8.275,
        transfats: 1.34,
        cholesterol: 31.0,
        protein: 1.37,
        b1_thiamine: 0.01,
        b2_riboflavin: 0.1,
        b3_niacin: 0.1,
        b5_pantothenic_acid: 0.388,
        b6_pyridoxine: 0.037,
        b12_cobalamin: 0.27,
        choline: 17.9,
        folate: 5.0,
        vitamin_a: 73.0,
        vitamin_c: 0.7,
        vitamin_d: 0.1,
        vitamin_e: 4.28,
        vitamin_k: 85.9,
        calcium: 37.0,
        copper: 0.009,
        iron: 0.09,
        magnesium: 8.0,
        manganese: 0.006,
        phosphorus: 74.0,
        potassium: 88.0,
        selenium: 1.0,
        sodium: 642.0,
        zinc: 0.21
      },
      restrictionWarnings: [10, 14, 13, 12, 11, 5, 6, 1],
      restrictionViolations: [9, 2],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '455': {
      id: 455,
      name: 'Oikos Triple Zero Greek vanilla yogurt',
      pluralName: 'Oikos Triple Zero Greek vanilla yogurt',
      description: null,
      sectionId: 2,
      subsectionId: null,
      density: 1.035554446,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 67.0,
        carbs: 6.67,
        fiber: 2.0,
        sugars: 3.33,
        cholesterol: 3.0,
        protein: 10.0,
        calcium: 67.0,
        potassium: 93.0,
        sodium: 43.0
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: true
    },
    '453': {
      id: 453,
      name: 'RO-TEL diced tomatoes \u0026 green chilies',
      pluralName: 'RO-TEL diced tomatoes \u0026 green chilies',
      description: null,
      sectionId: 9,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 139,
          packageAmount: 1.0,
          amountInBase: 283.0,
          equivalents: ''
        },
        us: {
          unitId: 138,
          packageAmount: 1.0,
          amountInBase: 283.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 22.22,
        carbs: 4.0,
        fiber: 0.8,
        sugars: 2.4,
        protein: 0.8,
        vitamin_a: 80.0,
        vitamin_c: 4.8,
        iron: 1.15,
        potassium: 168.0,
        sodium: 304.0
      },
      restrictionWarnings: [14],
      restrictionViolations: [10],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: true
    },
    '456': {
      id: 456,
      name: 'Silk Original almond milk',
      pluralName: 'Silk Original almond milk',
      description: null,
      sectionId: 2,
      subsectionId: null,
      density: 1.11,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 60,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 25.0,
        carbs: 3.33,
        sugars: 2.92,
        fat: 1.04,
        monounsaturated: 0.62,
        polyunsaturated: 0.21,
        protein: 0.42,
        vitamin_e: 2.0,
        calcium: 188.0,
        iron: 0.21,
        potassium: 75.0,
        sodium: 67.0
      },
      restrictionWarnings: [],
      restrictionViolations: [6],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: true
    },
    '457': {
      id: 457,
      name: 'OREO chocolate sandwich cookies',
      pluralName: 'OREO chocolate sandwich cookies',
      description: null,
      sectionId: 27,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 11.33,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 11.33,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 471.0,
        carbs: 73.53,
        fiber: 2.9,
        sugars: 41.18,
        fat: 20.59,
        saturated: 5.88,
        protein: 2.94,
        calcium: 29.0,
        iron: 4.12,
        potassium: 147.0,
        sodium: 397.0
      },
      restrictionWarnings: [2, 5, 6],
      restrictionViolations: [9, 1],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: true
    },
    '458': {
      id: 458,
      name: 'Philadelphia brick cream cheese',
      pluralName: 'Philadelphia brick cream cheese',
      description: null,
      sectionId: 2,
      subsectionId: null,
      density: 0.981,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 69,
          packageAmount: 0.5,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 68,
          packageAmount: 0.5,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 357.0,
        carbs: 7.14,
        sugars: 3.57,
        fat: 32.14,
        saturated: 21.43,
        cholesterol: 125.0,
        protein: 7.14,
        vitamin_a: 1071.0,
        calcium: 71.0,
        sodium: 375.0
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: true
    },
    '459': {
      id: 459,
      name: 'Pace chunky salsa',
      pluralName: 'Pace chunky salsa',
      description: null,
      sectionId: 19,
      subsectionId: null,
      density: 1.21,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 33.0,
        carbs: 10.0,
        fiber: 3.3,
        sugars: 6.67,
        potassium: 233.0,
        sodium: 767.0
      },
      restrictionWarnings: [14],
      restrictionViolations: [10],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: true
    },
    '460': {
      id: 460,
      name: 'Late July sea salt multigrain tortilla chips',
      pluralName: 'Late July sea salt multigrain tortilla chips',
      description: null,
      sectionId: 19,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 464.0,
        carbs: 60.71,
        fiber: 7.1,
        fat: 21.43,
        monounsaturated: 14.29,
        polyunsaturated: 5.36,
        saturated: 1.79,
        protein: 7.14,
        calcium: 36.0,
        iron: 3.21,
        potassium: 250.0,
        sodium: 232.0
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: true
    },
    '446': {
      id: 446,
      name: 'kale, cut and ready to use',
      pluralName: 'kale, cut and ready to use',
      description: null,
      sectionId: 4,
      subsectionId: null,
      density: 0.12,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 188,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 145,
          packageAmount: 1.0,
          amountInBase: 283.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 53.0,
        carbs: 9.73,
        fiber: 1.8,
        fat: 0.88,
        protein: 3.54,
        b2_riboflavin: 0.12,
        b3_niacin: 1.062,
        b6_pyridoxine: 0.265,
        folate: 28.0,
        vitamin_a: 15487.0,
        vitamin_c: 122.1,
        vitamin_k: 828.3,
        calcium: 133.0,
        copper: 0.265,
        iron: 1.59,
        magnesium: 35.0,
        manganese: 1.0,
        phosphorus: 53.0,
        potassium: 447.0,
        sodium: 44.0
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '449': {
      id: 449,
      name: 'frozen chopped spinach',
      pluralName: 'frozen chopped spinach',
      description: null,
      sectionId: 4,
      subsectionId: 5,
      density: 1.05,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 190,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 181,
          packageAmount: 1.0,
          amountInBase: 454.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 34.0,
        ash: 1.39,
        water: 88.94,
        carbs: 4.8,
        fiber: 3.7,
        sugars: 0.51,
        fructose: 0.09,
        glucose: 0.2,
        sucrose: 0.21,
        fat: 0.87,
        polyunsaturated: 0.371,
        omega_3: 0.371,
        saturated: 0.157,
        protein: 4.01,
        alanine: 0.222,
        arginine: 0.489,
        aspartic_acid: 0.436,
        cystine: 0.029,
        glutamic_acid: 0.516,
        glycine: 0.225,
        histidine: 0.049,
        isoleucine: 0.128,
        leucine: 0.205,
        lysine: 0.256,
        methionine: 0.053,
        phenylalanine: 0.211,
        proline: 0.201,
        serine: 0.173,
        threonine: 0.22,
        tryptophan: 0.101,
        tyrosine: 0.219,
        valine: 0.181,
        b1_thiamine: 0.078,
        b2_riboflavin: 0.176,
        b3_niacin: 0.439,
        b5_pantothenic_acid: 0.075,
        b6_pyridoxine: 0.136,
        choline: 24.8,
        folate: 121.0,
        vitamin_a: 12061.0,
        vitamin_c: 2.2,
        vitamin_e: 3.54,
        vitamin_k: 540.7,
        calcium: 153.0,
        copper: 0.161,
        iron: 1.96,
        magnesium: 82.0,
        manganese: 0.717,
        phosphorus: 50.0,
        potassium: 302.0,
        selenium: 5.5,
        sodium: 97.0,
        zinc: 0.49
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '451': {
      id: 451,
      name: 'shredded carrots',
      pluralName: 'shredded carrots',
      description: null,
      sectionId: 4,
      subsectionId: null,
      density: 0.54,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 188,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 145,
          packageAmount: 1.0,
          amountInBase: 283.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 41.0,
        ash: 0.97,
        water: 88.29,
        carbs: 9.58,
        fiber: 2.8,
        starch: 1.43,
        sugars: 4.74,
        fructose: 0.55,
        glucose: 0.59,
        sucrose: 3.59,
        fat: 0.24,
        monounsaturated: 0.012,
        polyunsaturated: 0.102,
        omega_3: 0.002,
        omega_6: 0.1,
        saturated: 0.032,
        protein: 0.93,
        alanine: 0.113,
        arginine: 0.091,
        aspartic_acid: 0.19,
        cystine: 0.083,
        glutamic_acid: 0.366,
        glycine: 0.047,
        histidine: 0.04,
        isoleucine: 0.077,
        leucine: 0.102,
        lysine: 0.101,
        methionine: 0.02,
        phenylalanine: 0.061,
        proline: 0.054,
        serine: 0.054,
        threonine: 0.191,
        tryptophan: 0.012,
        tyrosine: 0.043,
        valine: 0.069,
        b1_thiamine: 0.066,
        b2_riboflavin: 0.058,
        b3_niacin: 0.983,
        b5_pantothenic_acid: 0.273,
        b6_pyridoxine: 0.138,
        choline: 8.8,
        folate: 19.0,
        vitamin_a: 16706.0,
        vitamin_c: 5.9,
        vitamin_e: 0.66,
        vitamin_k: 13.2,
        calcium: 33.0,
        copper: 0.045,
        iron: 0.3,
        magnesium: 12.0,
        manganese: 0.143,
        phosphorus: 35.0,
        potassium: 320.0,
        selenium: 0.1,
        sodium: 69.0,
        zinc: 0.24
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '447': {
      id: 447,
      name: 'shredded cabbage (coleslaw mix)',
      pluralName: 'shredded cabbage (coleslaw mix)',
      description: null,
      sectionId: 4,
      subsectionId: 5,
      density: 0.24,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 189,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 146,
          packageAmount: 1.0,
          amountInBase: 397.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 25.0,
        ash: 0.64,
        water: 92.18,
        carbs: 5.8,
        fiber: 2.5,
        sugars: 3.2,
        fructose: 1.45,
        glucose: 1.67,
        maltose: 0.01,
        sucrose: 0.08,
        fat: 0.1,
        monounsaturated: 0.017,
        polyunsaturated: 0.017,
        omega_6: 0.017,
        saturated: 0.034,
        protein: 1.28,
        alanine: 0.042,
        arginine: 0.075,
        aspartic_acid: 0.122,
        cystine: 0.011,
        glutamic_acid: 0.294,
        glycine: 0.03,
        histidine: 0.022,
        isoleucine: 0.03,
        leucine: 0.041,
        lysine: 0.044,
        methionine: 0.012,
        phenylalanine: 0.032,
        proline: 0.048,
        serine: 0.053,
        threonine: 0.035,
        tryptophan: 0.011,
        tyrosine: 0.019,
        valine: 0.042,
        b1_thiamine: 0.061,
        b2_riboflavin: 0.04,
        b3_niacin: 0.234,
        b5_pantothenic_acid: 0.212,
        b6_pyridoxine: 0.124,
        choline: 10.7,
        folate: 43.0,
        vitamin_a: 98.0,
        vitamin_c: 36.6,
        vitamin_e: 0.15,
        vitamin_k: 76.0,
        calcium: 40.0,
        copper: 0.019,
        iron: 0.47,
        magnesium: 12.0,
        manganese: 0.16,
        phosphorus: 26.0,
        potassium: 170.0,
        selenium: 0.3,
        sodium: 18.0,
        zinc: 0.18
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '450': {
      id: 450,
      name: 'sliced white mushrooms',
      pluralName: 'sliced white mushrooms',
      description: null,
      sectionId: 4,
      subsectionId: null,
      density: 1.1,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 191,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 18,
          packageAmount: 1.0,
          amountInBase: 227.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 22.0,
        ash: 0.85,
        water: 92.45,
        carbs: 3.26,
        fiber: 1.0,
        sugars: 1.98,
        fructose: 0.17,
        glucose: 1.48,
        fat: 0.34,
        polyunsaturated: 0.16,
        omega_6: 0.16,
        saturated: 0.05,
        protein: 3.09,
        alanine: 0.199,
        arginine: 0.078,
        aspartic_acid: 0.195,
        cystine: 0.012,
        glutamic_acid: 0.343,
        glycine: 0.092,
        histidine: 0.057,
        isoleucine: 0.076,
        leucine: 0.12,
        lysine: 0.107,
        methionine: 0.031,
        phenylalanine: 0.085,
        proline: 0.076,
        serine: 0.094,
        threonine: 0.107,
        tryptophan: 0.035,
        tyrosine: 0.044,
        valine: 0.232,
        b1_thiamine: 0.081,
        b2_riboflavin: 0.402,
        b3_niacin: 3.607,
        b5_pantothenic_acid: 1.497,
        b6_pyridoxine: 0.104,
        b12_cobalamin: 0.04,
        choline: 17.3,
        folate: 17.0,
        vitamin_c: 2.1,
        vitamin_d: 0.2,
        vitamin_e: 0.01,
        calcium: 3.0,
        copper: 0.318,
        iron: 0.5,
        magnesium: 9.0,
        manganese: 0.047,
        phosphorus: 86.0,
        potassium: 318.0,
        selenium: 9.3,
        sodium: 5.0,
        zinc: 0.52
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '435': {
      id: 435,
      name: 'veggie burger patties, plant-based',
      pluralName: 'veggie burger patties, plant-based',
      description: null,
      sectionId: 7,
      subsectionId: null,
      density: 1.0,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 113.398,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 113.398,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 212.0,
        carbs: 7.96,
        fiber: 2.7,
        sugars: 0.88,
        fat: 12.39,
        saturated: 7.08,
        protein: 16.81,
        b1_thiamine: 31.0,
        b2_riboflavin: 0.226,
        b3_niacin: 8.85,
        b6_pyridoxine: 0.354,
        b12_cobalamin: 6.9,
        folate: 71.0,
        calcium: 150.0,
        iron: 3.72,
        phosphorus: 133.0,
        potassium: 540.0,
        sodium: 327.0,
        zinc: 6.64
      },
      restrictionWarnings: [10, 14, 13, 12, 11, 9, 2, 5, 6, 1],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '442': {
      id: 442,
      name: 'ground chicken',
      pluralName: 'ground chicken',
      description: null,
      sectionId: 1,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 79,
          packageAmount: 0.454,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 10,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 189.0,
        ash: 1.57,
        water: 64.92,
        fat: 10.92,
        monounsaturated: 4.879,
        polyunsaturated: 2.08,
        omega_3: 0.1,
        omega_6: 1.818,
        saturated: 3.11,
        transfats: 0.087,
        cholesterol: 107.0,
        protein: 23.28,
        alanine: 1.323,
        arginine: 1.505,
        aspartic_acid: 2.156,
        cystine: 0.251,
        glutamic_acid: 3.484,
        glycine: 1.118,
        histidine: 0.706,
        isoleucine: 1.06,
        leucine: 1.816,
        lysine: 2.014,
        methionine: 0.596,
        phenylalanine: 0.912,
        proline: 0.878,
        serine: 0.976,
        threonine: 0.97,
        tryptophan: 0.196,
        tyrosine: 0.806,
        valine: 1.102,
        b1_thiamine: 0.121,
        b2_riboflavin: 0.302,
        b3_niacin: 7.107,
        b5_pantothenic_acid: 1.327,
        b6_pyridoxine: 0.538,
        b12_cobalamin: 0.51,
        choline: 59.0,
        folate: 2.0,
        vitamin_e: 0.39,
        vitamin_k: 2.1,
        calcium: 8.0,
        copper: 0.062,
        iron: 0.93,
        magnesium: 28.0,
        manganese: 0.016,
        phosphorus: 234.0,
        potassium: 677.0,
        selenium: 14.3,
        sodium: 75.0,
        zinc: 1.92
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '452': {
      id: 452,
      name: 'roasted red bell peppers',
      pluralName: 'roasted red bell peppers',
      description: null,
      sectionId: 7,
      subsectionId: null,
      density: 1.0,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 183,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 149,
          packageAmount: 1.0,
          amountInBase: 340.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 17.0,
        carbs: 3.33,
        sugars: 3.33,
        vitamin_a: 1333.0,
        vitamin_c: 30.0,
        potassium: 167.0,
        sodium: 283.0
      },
      restrictionWarnings: [14],
      restrictionViolations: [10],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '454': {
      id: 454,
      name: 'vanilla wafer cookies',
      pluralName: 'vanilla wafer cookies',
      description: null,
      sectionId: 27,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 3.75,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 3.75,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 455.0,
        water: 4.98,
        carbs: 72.6,
        fiber: 1.6,
        sugars: 35.07,
        fat: 16.41,
        monounsaturated: 4.451,
        polyunsaturated: 6.738,
        omega_3: 0.749,
        omega_6: 5.968,
        saturated: 4.245,
        cholesterol: 9.0,
        protein: 4.9,
        b1_thiamine: 0.289,
        b2_riboflavin: 0.316,
        b3_niacin: 3.307,
        b6_pyridoxine: 0.055,
        choline: 11.5,
        folate: 83.0,
        vitamin_e: 2.27,
        vitamin_k: 29.0,
        calcium: 36.0,
        copper: 0.07,
        iron: 2.79,
        magnesium: 12.0,
        phosphorus: 88.0,
        potassium: 99.0,
        selenium: 5.6,
        sodium: 325.0,
        zinc: 0.36
      },
      restrictionWarnings: [14, 5, 6],
      restrictionViolations: [11, 9, 2, 1],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: true
    },
    '28': {
      id: 28,
      name: 'fresh basil',
      pluralName: 'fresh basil',
      description: '',
      sectionId: 4,
      subsectionId: 4,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 8,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: '1 small pkg = 28 g'
        },
        us: {
          unitId: 8,
          packageAmount: 1.0,
          amountInBase: 28.0,
          equivalents: '1 small pkg = 1 oz'
        }
      },
      nutrition: {
        energy: 23.0,
        ash: 1.49,
        water: 92.06,
        carbs: 2.65,
        fiber: 1.6,
        starch: 0.75,
        sugars: 0.3,
        fructose: 0.02,
        galactose: 0.27,
        glucose: 0.02,
        fat: 0.64,
        monounsaturated: 0.088,
        polyunsaturated: 0.389,
        omega_3: 0.316,
        omega_6: 0.073,
        saturated: 0.041,
        protein: 3.15,
        alanine: 0.132,
        arginine: 0.117,
        aspartic_acid: 0.301,
        cystine: 0.028,
        glutamic_acid: 0.277,
        glycine: 0.122,
        histidine: 0.051,
        isoleucine: 0.104,
        leucine: 0.191,
        lysine: 0.11,
        methionine: 0.036,
        phenylalanine: 0.13,
        proline: 0.104,
        serine: 0.099,
        threonine: 0.104,
        tryptophan: 0.039,
        tyrosine: 0.077,
        valine: 0.127,
        b1_thiamine: 0.034,
        b2_riboflavin: 0.076,
        b3_niacin: 0.902,
        b5_pantothenic_acid: 0.209,
        b6_pyridoxine: 0.155,
        choline: 11.4,
        folate: 68.0,
        vitamin_a: 5.0,
        vitamin_c: 18.0,
        vitamin_e: 0.8,
        vitamin_k: 414.8,
        calcium: 177.0,
        copper: 0.385,
        iron: 3.17,
        magnesium: 64.0,
        manganese: 1.148,
        phosphorus: 56.0,
        potassium: 295.0,
        selenium: 0.3,
        sodium: 4.0,
        zinc: 0.81
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '394': {
      id: 394,
      name: 'Italian salad dressing',
      pluralName: 'Italian salad dressing',
      description: null,
      sectionId: 14,
      subsectionId: null,
      density: 1.01,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 240.0,
        ash: 2.95,
        water: 63.4,
        carbs: 12.12,
        sugars: 10.77,
        fructose: 5.82,
        glucose: 4.43,
        sucrose: 0.53,
        fat: 21.12,
        monounsaturated: 5.638,
        polyunsaturated: 10.748,
        omega_3: 1.489,
        omega_6: 9.243,
        saturated: 2.948,
        transfats: 0.067,
        protein: 0.41,
        alanine: 0.008,
        arginine: 0.038,
        aspartic_acid: 0.029,
        cystine: 0.004,
        glutamic_acid: 0.049,
        glycine: 0.012,
        histidine: 0.007,
        isoleucine: 0.015,
        leucine: 0.023,
        lysine: 0.013,
        methionine: 0.008,
        phenylalanine: 0.011,
        proline: 0.006,
        serine: 0.011,
        threonine: 0.011,
        tryptophan: 0.005,
        tyrosine: 0.005,
        valine: 0.016,
        b1_thiamine: 0.02,
        b3_niacin: 0.131,
        b6_pyridoxine: 0.064,
        choline: 2.6,
        vitamin_a: 36.0,
        vitamin_c: 0.4,
        vitamin_e: 2.19,
        vitamin_k: 56.0,
        calcium: 13.0,
        copper: 0.019,
        iron: 0.26,
        magnesium: 5.0,
        manganese: 0.031,
        phosphorus: 15.0,
        potassium: 84.0,
        selenium: 2.0,
        sodium: 993.0,
        zinc: 0.07
      },
      restrictionWarnings: [10, 14, 13, 12, 11, 9, 2, 5, 6, 1],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '412': {
      id: 412,
      name: 'Cajun seasoning',
      pluralName: 'Cajun seasoning',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.97,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: { calcium: 154.0, potassium: 615.0, sodium: 17692.0 },
      restrictionWarnings: [14, 12, 9, 5, 6, 1, 3, 4],
      restrictionViolations: [10, 13],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '427': {
      id: 427,
      name: 'Parmesan cheese, shredded',
      pluralName: 'Parmesan cheese, shredded',
      description: null,
      sectionId: 2,
      subsectionId: null,
      density: 0.47,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 415.0,
        ash: 6.39,
        water: 25.0,
        carbs: 3.41,
        sugars: 0.9,
        fat: 27.34,
        monounsaturated: 8.734,
        polyunsaturated: 0.661,
        omega_3: 0.345,
        omega_6: 0.29,
        saturated: 17.37,
        cholesterol: 72.0,
        protein: 37.86,
        alanine: 1.218,
        arginine: 1.531,
        aspartic_acid: 2.599,
        cystine: 0.274,
        glutamic_acid: 9.543,
        glycine: 0.723,
        histidine: 1.609,
        isoleucine: 2.202,
        leucine: 4.013,
        lysine: 3.843,
        methionine: 1.114,
        phenylalanine: 2.234,
        proline: 4.86,
        serine: 2.404,
        threonine: 1.531,
        tryptophan: 0.56,
        tyrosine: 2.319,
        valine: 2.853,
        b1_thiamine: 0.041,
        b2_riboflavin: 0.352,
        b3_niacin: 0.287,
        b5_pantothenic_acid: 0.527,
        b6_pyridoxine: 0.105,
        b12_cobalamin: 1.4,
        choline: 15.4,
        folate: 8.0,
        vitamin_a: 865.0,
        vitamin_d: 0.5,
        vitamin_e: 0.25,
        vitamin_k: 1.9,
        calcium: 1253.0,
        copper: 0.037,
        iron: 0.87,
        magnesium: 51.0,
        manganese: 0.023,
        phosphorus: 735.0,
        potassium: 97.0,
        selenium: 23.9,
        sodium: 1696.0,
        zinc: 3.19
      },
      restrictionWarnings: [14, 12, 11, 9, 5, 6, 1],
      restrictionViolations: [2],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '327': {
      id: 327,
      name: 'pecan halves',
      pluralName: 'pecan halves',
      description: '',
      sectionId: 12,
      subsectionId: null,
      density: 0.464942812,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 710.0,
        ash: 1.56,
        water: 1.12,
        carbs: 13.55,
        fiber: 9.4,
        starch: 0.24,
        sugars: 4.06,
        fructose: 0.04,
        glucose: 0.04,
        sucrose: 4.0,
        fat: 74.27,
        monounsaturated: 43.957,
        polyunsaturated: 20.572,
        omega_3: 0.994,
        omega_6: 19.578,
        saturated: 6.283,
        protein: 9.5,
        alanine: 0.411,
        arginine: 1.22,
        aspartic_acid: 0.963,
        cystine: 0.158,
        glutamic_acid: 1.895,
        glycine: 0.469,
        histidine: 0.271,
        isoleucine: 0.348,
        leucine: 0.619,
        lysine: 0.297,
        methionine: 0.189,
        phenylalanine: 0.441,
        proline: 0.376,
        serine: 0.491,
        threonine: 0.317,
        tryptophan: 0.096,
        tyrosine: 0.223,
        valine: 0.426,
        b1_thiamine: 0.45,
        b2_riboflavin: 0.107,
        b3_niacin: 1.167,
        b5_pantothenic_acid: 0.703,
        b6_pyridoxine: 0.187,
        choline: 40.5,
        folate: 16.0,
        vitamin_a: 148.0,
        vitamin_c: 0.7,
        vitamin_e: 1.3,
        vitamin_k: 3.5,
        calcium: 72.0,
        copper: 1.167,
        iron: 2.8,
        magnesium: 132.0,
        manganese: 3.933,
        phosphorus: 293.0,
        potassium: 424.0,
        selenium: 4.0,
        sodium: 1.0,
        zinc: 5.07
      },
      restrictionWarnings: [],
      restrictionViolations: [6],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '431': {
      id: 431,
      name: 'taco seasoning',
      pluralName: 'taco seasoning',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.61,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 322.0,
        ash: 31.8,
        water: 5.7,
        carbs: 58.0,
        fiber: 13.3,
        sugars: 10.83,
        protein: 4.5,
        vitamin_a: 3744.0,
        iron: 7.2,
        potassium: 1000.0,
        sodium: 7203.0
      },
      restrictionWarnings: [14, 13, 12, 9, 5, 6, 1, 3, 4],
      restrictionViolations: [10],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '462': {
      id: 462,
      name: 'shredded sweetened coconut',
      pluralName: 'shredded sweetened coconut',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.352,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 501.0,
        ash: 1.42,
        water: 12.55,
        carbs: 47.67,
        fiber: 4.5,
        sugars: 43.17,
        fat: 35.49,
        monounsaturated: 1.51,
        polyunsaturated: 0.388,
        omega_6: 0.388,
        saturated: 31.468,
        protein: 2.88,
        alanine: 0.147,
        arginine: 0.473,
        aspartic_acid: 0.282,
        cystine: 0.057,
        glutamic_acid: 0.659,
        glycine: 0.137,
        histidine: 0.066,
        isoleucine: 0.113,
        leucine: 0.214,
        lysine: 0.127,
        methionine: 0.054,
        phenylalanine: 0.146,
        proline: 0.119,
        serine: 0.149,
        threonine: 0.105,
        tryptophan: 0.034,
        tyrosine: 0.089,
        valine: 0.175,
        b1_thiamine: 0.031,
        b2_riboflavin: 0.02,
        b3_niacin: 0.474,
        b5_pantothenic_acid: 0.722,
        b6_pyridoxine: 0.271,
        choline: 19.3,
        folate: 8.0,
        vitamin_c: 0.7,
        vitamin_e: 0.39,
        vitamin_k: 0.3,
        calcium: 15.0,
        copper: 0.313,
        iron: 1.92,
        magnesium: 50.0,
        manganese: 2.475,
        phosphorus: 107.0,
        potassium: 337.0,
        selenium: 16.7,
        sodium: 262.0,
        zinc: 1.82
      },
      restrictionWarnings: [],
      restrictionViolations: [14],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '463': {
      id: 463,
      name: 'graham cracker',
      pluralName: 'graham crackers',
      description: null,
      sectionId: 19,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 3.64,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 3.64,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 430.0,
        water: 3.39,
        carbs: 77.7,
        fiber: 3.4,
        sugars: 24.8,
        fat: 10.6,
        monounsaturated: 2.51,
        polyunsaturated: 5.39,
        omega_3: 0.622,
        omega_6: 4.76,
        saturated: 1.63,
        protein: 6.69,
        b1_thiamine: 0.265,
        b2_riboflavin: 0.317,
        b3_niacin: 4.44,
        b6_pyridoxine: 0.156,
        choline: 5.9,
        folate: 91.0,
        vitamin_e: 1.51,
        vitamin_k: 14.3,
        calcium: 77.0,
        copper: 0.17,
        iron: 3.78,
        magnesium: 40.0,
        phosphorus: 185.0,
        potassium: 170.0,
        selenium: 6.3,
        sodium: 516.0,
        zinc: 0.96
      },
      restrictionWarnings: [14, 9, 5, 6],
      restrictionViolations: [1],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '474': {
      id: 474,
      name: 'tempeh',
      pluralName: 'tempeh',
      description: null,
      sectionId: 7,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 191,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 148,
          packageAmount: 1.0,
          amountInBase: 227.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 192.0,
        ash: 1.62,
        water: 59.65,
        carbs: 7.64,
        fat: 10.8,
        monounsaturated: 3.205,
        polyunsaturated: 4.3,
        omega_3: 0.248,
        omega_6: 4.052,
        saturated: 2.539,
        protein: 20.29,
        alanine: 0.96,
        arginine: 1.252,
        aspartic_acid: 1.995,
        cystine: 0.193,
        glutamic_acid: 3.292,
        glycine: 0.754,
        histidine: 0.466,
        isoleucine: 0.88,
        leucine: 1.43,
        lysine: 0.908,
        methionine: 0.175,
        phenylalanine: 0.893,
        proline: 1.03,
        serine: 1.019,
        threonine: 0.796,
        tryptophan: 0.194,
        tyrosine: 0.664,
        valine: 0.92,
        b1_thiamine: 0.078,
        b2_riboflavin: 0.358,
        b3_niacin: 2.64,
        b5_pantothenic_acid: 0.278,
        b6_pyridoxine: 0.215,
        b12_cobalamin: 0.08,
        folate: 24.0,
        calcium: 111.0,
        copper: 0.56,
        iron: 2.7,
        magnesium: 81.0,
        manganese: 1.3,
        phosphorus: 266.0,
        potassium: 412.0,
        sodium: 9.0,
        zinc: 1.14
      },
      restrictionWarnings: [14],
      restrictionViolations: [9],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '464': {
      id: 464,
      name: 'halloumi cheese',
      pluralName: 'halloumi cheese',
      description: null,
      sectionId: 7,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 193,
          packageAmount: 1.0,
          amountInBase: 250.0,
          equivalents: ''
        },
        us: {
          unitId: 135,
          packageAmount: 1.0,
          amountInBase: 250.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 321.0,
        fat: 25.0,
        saturated: 14.29,
        cholesterol: 71.0,
        protein: 21.43,
        vitamin_a: 357.0,
        calcium: 714.0,
        sodium: 2393.0
      },
      restrictionWarnings: [],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '466': {
      id: 466,
      name: 'English muffin',
      pluralName: 'English muffins',
      description: null,
      sectionId: 6,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 62.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 62.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 227.0,
        water: 43.6,
        carbs: 44.2,
        fiber: 3.5,
        sugars: 3.53,
        fat: 1.69,
        monounsaturated: 0.383,
        polyunsaturated: 0.498,
        omega_3: 0.048,
        omega_6: 0.449,
        saturated: 0.733,
        protein: 8.87,
        b1_thiamine: 0.477,
        b2_riboflavin: 0.25,
        b3_niacin: 4.07,
        b6_pyridoxine: 0.054,
        b12_cobalamin: 0.04,
        choline: 16.0,
        folate: 94.0,
        vitamin_c: 1.8,
        vitamin_e: 0.31,
        vitamin_k: 1.2,
        calcium: 163.0,
        copper: 0.135,
        iron: 4.0,
        magnesium: 24.0,
        phosphorus: 92.0,
        potassium: 109.0,
        selenium: 22.4,
        sodium: 425.0,
        zinc: 1.05
      },
      restrictionWarnings: [14, 11, 2],
      restrictionViolations: [9, 1],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '465': {
      id: 465,
      name: 'sliced black olives',
      pluralName: 'sliced black olives',
      description: null,
      sectionId: 9,
      subsectionId: null,
      density: 0.54,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 196,
          packageAmount: 1.0,
          amountInBase: 108.0,
          equivalents: ''
        },
        us: {
          unitId: 195,
          packageAmount: 1.0,
          amountInBase: 108.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 125.0,
        carbs: 6.25,
        fiber: 6.2,
        fat: 9.38,
        monounsaturated: 6.25,
        iron: 6.25,
        sodium: 875.0
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '475': {
      id: 475,
      name: 'sourdough bread',
      pluralName: 'sourdough bread',
      description: null,
      sectionId: 6,
      subsectionId: null,
      density: null,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 67,
          packageAmount: 1.0,
          amountInBase: 57.0,
          equivalents: ''
        },
        us: {
          unitId: 67,
          packageAmount: 1.0,
          amountInBase: 57.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 272.0,
        ash: 1.96,
        water: 33.0,
        carbs: 51.88,
        fiber: 2.2,
        starch: 44.23,
        sugars: 4.62,
        fructose: 0.51,
        glucose: 0.4,
        maltose: 3.7,
        fat: 2.42,
        monounsaturated: 0.362,
        polyunsaturated: 0.855,
        omega_3: 0.064,
        omega_6: 0.79,
        saturated: 0.529,
        transfats: 0.005,
        protein: 10.75,
        b1_thiamine: 0.71,
        b2_riboflavin: 0.427,
        b3_niacin: 4.817,
        b5_pantothenic_acid: 0.455,
        b6_pyridoxine: 0.107,
        choline: 8.0,
        folate: 123.0,
        vitamin_a: 1.0,
        vitamin_e: 0.21,
        vitamin_k: 0.7,
        calcium: 52.0,
        copper: 0.152,
        iron: 3.91,
        magnesium: 32.0,
        manganese: 0.577,
        phosphorus: 105.0,
        potassium: 117.0,
        selenium: 28.6,
        sodium: 602.0,
        zinc: 1.04
      },
      restrictionWarnings: [14, 12, 11, 9, 2, 5, 6],
      restrictionViolations: [1],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '461': {
      id: 461,
      name: 'hard taco shell',
      pluralName: 'hard taco shells',
      description: null,
      sectionId: 6,
      subsectionId: null,
      density: 0.11,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 13.0,
          equivalents: ''
        },
        us: {
          unitId: 1,
          packageAmount: 1.0,
          amountInBase: 13.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 468.0,
        ash: 1.8,
        water: 6.0,
        carbs: 62.4,
        fiber: 7.5,
        fat: 22.6,
        monounsaturated: 8.934,
        polyunsaturated: 8.494,
        omega_3: 0.534,
        omega_6: 7.941,
        saturated: 3.245,
        protein: 7.2,
        alanine: 0.54,
        arginine: 0.36,
        aspartic_acid: 0.502,
        cystine: 0.13,
        glutamic_acid: 1.354,
        glycine: 0.297,
        histidine: 0.221,
        isoleucine: 0.259,
        leucine: 0.886,
        lysine: 0.203,
        methionine: 0.151,
        phenylalanine: 0.354,
        proline: 0.63,
        serine: 0.343,
        threonine: 0.271,
        tryptophan: 0.052,
        tyrosine: 0.294,
        valine: 0.366,
        b1_thiamine: 0.228,
        b2_riboflavin: 0.053,
        b3_niacin: 1.35,
        b5_pantothenic_acid: 0.47,
        b6_pyridoxine: 0.296,
        folate: 105.0,
        calcium: 160.0,
        copper: 0.12,
        iron: 2.5,
        magnesium: 105.0,
        manganese: 0.435,
        phosphorus: 248.0,
        potassium: 179.0,
        sodium: 15.0,
        zinc: 1.4
      },
      restrictionWarnings: [10, 14, 12, 11, 9, 2, 6, 1],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '476': {
      id: 476,
      name: 'orzo pasta',
      pluralName: 'orzo pasta',
      description: null,
      sectionId: 15,
      subsectionId: null,
      density: 0.72,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 382.0,
        carbs: 76.36,
        fiber: 3.6,
        sugars: 3.64,
        fat: 1.82,
        protein: 12.73,
        b1_thiamine: 1.0,
        b2_riboflavin: 0.545,
        b3_niacin: 10.909,
        folate: 282.0,
        calcium: 31.0,
        iron: 3.45,
        potassium: 236.0
      },
      restrictionWarnings: [11],
      restrictionViolations: [1],
      isBreakfastAllowed: false,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '477': {
      id: 477,
      name: 'neutral vegetable oil',
      pluralName: 'neutral vegetable oil',
      description: null,
      sectionId: 14,
      subsectionId: null,
      density: 0.92,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 900.0,
        fat: 100.0,
        monounsaturated: 40.3,
        polyunsaturated: 40.3,
        omega_3: 4.69,
        omega_6: 35.5,
        saturated: 13.7,
        choline: 0.2,
        vitamin_e: 16.2,
        vitamin_k: 109.0,
        iron: 0.16
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '478': {
      id: 478,
      name: 'smoked paprika',
      pluralName: 'smoked paprika',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.459870709,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 282.0,
        ash: 7.74,
        water: 11.24,
        carbs: 53.99,
        fiber: 34.9,
        sugars: 10.34,
        fructose: 6.71,
        galactose: 0.19,
        glucose: 2.63,
        sucrose: 0.81,
        fat: 12.89,
        monounsaturated: 1.695,
        polyunsaturated: 7.766,
        omega_3: 0.453,
        omega_6: 7.314,
        saturated: 2.14,
        protein: 14.14,
        alanine: 0.64,
        arginine: 0.89,
        aspartic_acid: 2.85,
        cystine: 0.23,
        glutamic_acid: 2.26,
        glycine: 0.78,
        histidine: 0.25,
        isoleucine: 0.57,
        leucine: 0.92,
        lysine: 0.69,
        methionine: 0.2,
        phenylalanine: 0.61,
        proline: 2.31,
        serine: 0.61,
        threonine: 0.49,
        tryptophan: 0.07,
        tyrosine: 0.38,
        valine: 0.75,
        b1_thiamine: 0.33,
        b2_riboflavin: 1.23,
        b3_niacin: 10.06,
        b5_pantothenic_acid: 2.51,
        b6_pyridoxine: 2.141,
        choline: 51.5,
        folate: 49.0,
        vitamin_a: 49254.0,
        vitamin_c: 0.9,
        vitamin_e: 29.1,
        vitamin_k: 80.3,
        calcium: 229.0,
        copper: 0.713,
        iron: 21.14,
        magnesium: 178.0,
        manganese: 1.59,
        phosphorus: 314.0,
        potassium: 2280.0,
        selenium: 6.3,
        sodium: 68.0,
        zinc: 4.33
      },
      restrictionWarnings: [14],
      restrictionViolations: [10],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '479': {
      id: 479,
      name: 'sauerkraut',
      pluralName: 'sauerkraut',
      description: null,
      sectionId: 9,
      subsectionId: null,
      density: 0.6,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 19.0,
        ash: 2.15,
        water: 92.52,
        carbs: 4.28,
        fiber: 2.9,
        sugars: 1.78,
        fructose: 0.04,
        glucose: 0.14,
        fat: 0.14,
        monounsaturated: 0.013,
        polyunsaturated: 0.067,
        omega_3: 0.033,
        omega_6: 0.034,
        saturated: 0.034,
        protein: 0.91,
        alanine: 0.03,
        arginine: 0.053,
        aspartic_acid: 0.087,
        cystine: 0.008,
        glutamic_acid: 0.209,
        glycine: 0.021,
        histidine: 0.016,
        isoleucine: 0.021,
        leucine: 0.029,
        lysine: 0.031,
        methionine: 0.009,
        phenylalanine: 0.023,
        proline: 0.034,
        serine: 0.037,
        threonine: 0.025,
        tryptophan: 0.008,
        tyrosine: 0.014,
        valine: 0.03,
        b1_thiamine: 0.021,
        b2_riboflavin: 0.022,
        b3_niacin: 0.143,
        b5_pantothenic_acid: 0.093,
        b6_pyridoxine: 0.13,
        choline: 10.4,
        folate: 24.0,
        vitamin_a: 18.0,
        vitamin_c: 14.7,
        vitamin_e: 0.14,
        vitamin_k: 13.0,
        calcium: 30.0,
        copper: 0.096,
        iron: 1.47,
        magnesium: 13.0,
        manganese: 0.151,
        phosphorus: 20.0,
        potassium: 170.0,
        selenium: 0.6,
        sodium: 661.0,
        zinc: 0.19
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '480': {
      id: 480,
      name: 'frozen shredded hash brown potatoes',
      pluralName: 'frozen shredded hash brown potatoes',
      description: null,
      sectionId: 13,
      subsectionId: null,
      density: 0.89,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 82.0,
        ash: 0.75,
        water: 78.85,
        carbs: 17.72,
        fiber: 1.4,
        fat: 0.62,
        monounsaturated: 0.014,
        polyunsaturated: 0.269,
        omega_3: 0.064,
        omega_6: 0.201,
        saturated: 0.163,
        protein: 2.06,
        alanine: 0.071,
        arginine: 0.098,
        aspartic_acid: 0.478,
        cystine: 0.013,
        glutamic_acid: 0.322,
        glycine: 0.075,
        histidine: 0.035,
        isoleucine: 0.089,
        leucine: 0.124,
        lysine: 0.11,
        methionine: 0.023,
        phenylalanine: 0.088,
        proline: 0.066,
        serine: 0.074,
        threonine: 0.094,
        tryptophan: 0.028,
        tyrosine: 0.052,
        valine: 0.105,
        b1_thiamine: 0.097,
        b2_riboflavin: 0.014,
        b3_niacin: 1.664,
        b5_pantothenic_acid: 0.323,
        b6_pyridoxine: 0.087,
        folate: 4.0,
        vitamin_c: 8.2,
        calcium: 10.0,
        copper: 0.099,
        iron: 0.98,
        magnesium: 11.0,
        manganese: 0.146,
        phosphorus: 47.0,
        potassium: 285.0,
        selenium: 0.3,
        sodium: 22.0,
        zinc: 0.21
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '481': {
      id: 481,
      name: 'frozen mixed vegetables (carrots, corn, green beans, peas)',
      pluralName: 'frozen mixed vegetables (carrots, corn, green beans, peas)',
      description: null,
      sectionId: 13,
      subsectionId: null,
      density: 0.63,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 72.0,
        ash: 0.6,
        water: 82.08,
        carbs: 13.47,
        fiber: 4.0,
        fat: 0.52,
        monounsaturated: 0.031,
        polyunsaturated: 0.235,
        omega_3: 0.063,
        omega_6: 0.173,
        saturated: 0.098,
        protein: 3.33,
        alanine: 0.143,
        arginine: 0.225,
        aspartic_acid: 0.353,
        cystine: 0.03,
        glutamic_acid: 0.455,
        glycine: 0.121,
        histidine: 0.085,
        isoleucine: 0.162,
        leucine: 0.221,
        lysine: 0.198,
        methionine: 0.04,
        phenylalanine: 0.139,
        proline: 0.082,
        serine: 0.158,
        threonine: 0.133,
        tryptophan: 0.034,
        tyrosine: 0.086,
        valine: 0.174,
        b1_thiamine: 0.122,
        b2_riboflavin: 0.085,
        b3_niacin: 1.252,
        b5_pantothenic_acid: 0.163,
        b6_pyridoxine: 0.096,
        folate: 29.0,
        vitamin_a: 5078.0,
        vitamin_c: 10.4,
        calcium: 25.0,
        copper: 0.093,
        iron: 0.95,
        magnesium: 24.0,
        manganese: 0.244,
        phosphorus: 59.0,
        potassium: 212.0,
        selenium: 0.4,
        sodium: 47.0,
        zinc: 0.45
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '482': {
      id: 482,
      name: 'deli roast beef',
      pluralName: 'deli roast beef',
      description: null,
      sectionId: 7,
      subsectionId: null,
      density: 0.85,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 12,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 11,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 115.0,
        water: 73.7,
        carbs: 0.64,
        sugars: 0.29,
        fat: 3.69,
        monounsaturated: 1.52,
        polyunsaturated: 0.27,
        omega_3: 0.018,
        omega_6: 0.178,
        saturated: 1.32,
        cholesterol: 51.0,
        protein: 18.6,
        b1_thiamine: 0.043,
        b2_riboflavin: 0.213,
        b3_niacin: 5.58,
        b6_pyridoxine: 0.46,
        b12_cobalamin: 2.04,
        choline: 43.9,
        folate: 5.0,
        vitamin_e: 0.49,
        vitamin_k: 1.6,
        calcium: 5.0,
        copper: 0.086,
        iron: 2.05,
        magnesium: 20.0,
        phosphorus: 242.0,
        potassium: 647.0,
        selenium: 14.7,
        sodium: 853.0,
        zinc: 3.2
      },
      restrictionWarnings: [14],
      restrictionViolations: [],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '467': {
      id: 467,
      name: 'cottage cheese',
      pluralName: 'cottage cheese',
      description: null,
      sectionId: 2,
      subsectionId: null,
      density: 0.96,
      isEssential: false,
      isPerishable: true,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 9,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 98.0,
        ash: 1.41,
        water: 79.79,
        carbs: 3.38,
        sugars: 2.67,
        lactose: 2.67,
        fat: 4.3,
        monounsaturated: 0.778,
        polyunsaturated: 0.123,
        omega_3: 0.017,
        omega_6: 0.105,
        saturated: 1.718,
        cholesterol: 17.0,
        protein: 11.12,
        alanine: 0.384,
        arginine: 0.497,
        aspartic_acid: 0.905,
        cystine: 0.066,
        glutamic_acid: 2.603,
        glycine: 0.222,
        histidine: 0.326,
        isoleucine: 0.591,
        leucine: 1.116,
        lysine: 0.934,
        methionine: 0.269,
        phenylalanine: 0.577,
        proline: 1.229,
        serine: 0.639,
        threonine: 0.5,
        tryptophan: 0.147,
        tyrosine: 0.604,
        valine: 0.748,
        b1_thiamine: 0.027,
        b2_riboflavin: 0.163,
        b3_niacin: 0.099,
        b5_pantothenic_acid: 0.557,
        b6_pyridoxine: 0.046,
        b12_cobalamin: 0.43,
        choline: 18.4,
        folate: 12.0,
        vitamin_a: 140.0,
        vitamin_d: 0.1,
        vitamin_e: 0.08,
        calcium: 83.0,
        copper: 0.029,
        iron: 0.07,
        magnesium: 8.0,
        manganese: 0.002,
        phosphorus: 159.0,
        potassium: 104.0,
        selenium: 9.7,
        sodium: 315.0,
        zinc: 0.4
      },
      restrictionWarnings: [14],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '484': {
      id: 484,
      name: 'sesame ginger dressing',
      pluralName: 'sesame ginger dressing',
      description: null,
      sectionId: 14,
      subsectionId: null,
      density: 0.99,
      isEssential: false,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 443.0,
        water: 39.2,
        carbs: 8.6,
        fiber: 1.0,
        sugars: 8.32,
        fat: 45.2,
        monounsaturated: 11.9,
        polyunsaturated: 25.1,
        omega_3: 2.0,
        omega_6: 23.2,
        saturated: 6.2,
        protein: 3.1,
        choline: 4.0,
        vitamin_e: 5.0,
        vitamin_k: 56.0,
        calcium: 19.0,
        iron: 0.6,
        phosphorus: 37.0,
        potassium: 157.0,
        selenium: 1.6,
        sodium: 1000.0,
        zinc: 0.1
      },
      restrictionWarnings: [10, 14, 13, 11, 2, 5, 6, 3, 4],
      restrictionViolations: [12, 9, 1],
      isBreakfastAllowed: false,
      isDinnerAllowed: false,
      isDessertAllowed: false,
      isSimpleOnly: true,
      isCpgOnly: false
    },
    '351': {
      id: 351,
      name: 'cloves, ground',
      pluralName: 'cloves, ground',
      description: null,
      sectionId: 8,
      subsectionId: null,
      density: 0.41,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 91,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 274.0,
        ash: 5.63,
        water: 9.87,
        carbs: 65.53,
        fiber: 33.9,
        fructose: 1.07,
        galactose: 0.15,
        glucose: 1.14,
        sucrose: 0.02,
        fat: 13.0,
        monounsaturated: 1.393,
        polyunsaturated: 3.606,
        omega_3: 0.585,
        omega_6: 2.657,
        saturated: 3.952,
        transfats: 0.254,
        protein: 5.97,
        alanine: 0.29,
        arginine: 0.32,
        aspartic_acid: 0.6,
        cystine: 0.07,
        glutamic_acid: 0.56,
        glycine: 0.28,
        histidine: 0.13,
        isoleucine: 0.24,
        leucine: 0.4,
        lysine: 0.37,
        methionine: 0.08,
        phenylalanine: 0.23,
        proline: 0.39,
        serine: 0.24,
        threonine: 0.18,
        tryptophan: 0.03,
        tyrosine: 0.19,
        valine: 0.34,
        b1_thiamine: 0.158,
        b2_riboflavin: 0.22,
        b3_niacin: 1.56,
        b5_pantothenic_acid: 0.509,
        b6_pyridoxine: 0.391,
        choline: 37.4,
        folate: 25.0,
        vitamin_a: 160.0,
        vitamin_c: 0.2,
        vitamin_e: 8.82,
        vitamin_k: 141.8,
        calcium: 632.0,
        copper: 0.368,
        iron: 11.83,
        magnesium: 259.0,
        manganese: 60.127,
        phosphorus: 104.0,
        potassium: 1020.0,
        selenium: 7.2,
        sodium: 277.0,
        zinc: 2.32
      },
      restrictionWarnings: [],
      restrictionViolations: [],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: true,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '486': {
      id: 486,
      name:
        'vanilla plant-based protein powder (containing 20 grams protein/serving)',
      pluralName:
        'vanilla plant-based protein powder (containing 20 grams protein/serving)',
      description: null,
      sectionId: 28,
      subsectionId: null,
      density: null,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 199,
          packageAmount: 1.0,
          amountInBase: 36.0,
          equivalents: ''
        },
        us: {
          unitId: 199,
          packageAmount: 1.0,
          amountInBase: 36.0,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 388.0,
        ash: 5.87,
        water: 4.13,
        carbs: 28.89,
        fiber: 6.7,
        fat: 5.56,
        monounsaturated: 1.057,
        polyunsaturated: 2.701,
        omega_3: 0.32,
        omega_6: 2.381,
        saturated: 1.111,
        protein: 55.56,
        alanine: 2.433,
        arginine: 4.273,
        aspartic_acid: 6.576,
        cystine: 0.722,
        glutamic_acid: 10.849,
        glycine: 2.367,
        histidine: 1.511,
        isoleucine: 2.762,
        leucine: 4.602,
        lysine: 3.551,
        methionine: 0.722,
        phenylalanine: 2.958,
        proline: 2.893,
        serine: 2.958,
        threonine: 2.169,
        tryptophan: 0.722,
        tyrosine: 2.169,
        valine: 2.827,
        b1_thiamine: 0.288,
        b2_riboflavin: 0.164,
        b3_niacin: 2.357,
        b6_pyridoxine: 0.164,
        choline: 312.9,
        folate: 289.0,
        calcium: 178.0,
        copper: 2.62,
        iron: 12.0,
        magnesium: 64.0,
        phosphorus: 1272.0,
        potassium: 933.0,
        selenium: 1.3,
        sodium: 733.0,
        zinc: 6.6
      },
      restrictionWarnings: [14, 12, 11, 2, 5, 6, 1],
      restrictionViolations: [9],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    },
    '485': {
      id: 485,
      name:
        'vanilla whey-based protein powder (containing 20 grams protein/serving)',
      pluralName:
        'vanilla whey-based protein powder (containing 20 grams protein/serving)',
      description: null,
      sectionId: 28,
      subsectionId: null,
      density: null,
      isEssential: true,
      isPerishable: false,
      isDisabled: false,
      unitAssignments: {
        metric: {
          unitId: 198,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        },
        us: {
          unitId: 198,
          packageAmount: 1.0,
          amountInBase: null,
          equivalents: ''
        }
      },
      nutrition: {
        energy: 352.0,
        ash: 10.55,
        water: 3.44,
        carbs: 6.25,
        fiber: 3.1,
        fat: 1.56,
        monounsaturated: 0.158,
        polyunsaturated: 0.299,
        omega_3: 0.034,
        omega_6: 0.264,
        saturated: 0.781,
        cholesterol: 16.0,
        protein: 78.13,
        b1_thiamine: 0.609,
        b2_riboflavin: 2.017,
        b3_niacin: 1.136,
        b5_pantothenic_acid: 5.516,
        b6_pyridoxine: 0.607,
        b12_cobalamin: 2.45,
        choline: 224.0,
        folate: 33.0,
        calcium: 469.0,
        copper: 0.049,
        iron: 1.13,
        magnesium: 195.0,
        phosphorus: 1321.0,
        potassium: 500.0,
        selenium: 26.7,
        sodium: 156.0,
        zinc: 6.18
      },
      restrictionWarnings: [14, 12, 11, 9, 5, 6, 1],
      restrictionViolations: [2],
      isBreakfastAllowed: true,
      isDinnerAllowed: true,
      isDessertAllowed: false,
      isSimpleOnly: false,
      isCpgOnly: false
    }
  },
  cookwares: {
    '133': { id: 133, name: 'ice cream scoop (optional)' },
    '34': { id: 34, name: 'oven mitts' },
    '77': { id: 77, name: 'toaster (optional)' },
    '15': { id: 15, name: 'grater' },
    '35': { id: 35, name: 'tongs' },
    '10': { id: 10, name: 'can opener' },
    '90': { id: 90, name: 'aluminum foil' },
    '83': { id: 83, name: 'baking dish' },
    '33': { id: 33, name: 'baking sheet pan' },
    '84': { id: 84, name: 'broiler pan or baking dish' },
    '2': { id: 2, name: "chef's knife" },
    '37': { id: 37, name: 'citrus juicer (optional)' },
    '11': { id: 11, name: 'colander' },
    '1': { id: 1, name: 'cutting board' },
    '3': { id: 3, name: 'garlic press (optional)' },
    '86': { id: 86, name: 'julienne peeler (optional)' },
    '57': { id: 57, name: 'ladle' },
    '52': { id: 52, name: 'grater (optional)' },
    '87': { id: 87, name: 'grater or food processor' },
    '45': { id: 45, name: 'grill pan, outdoor grill, or skillet' },
    '85': { id: 85, name: 'hand blender or potato masher' },
    '74': { id: 74, name: 'hand or regular blender' },
    '78': { id: 78, name: 'large pot' },
    '73': { id: 73, name: 'mandoline slicer (optional)' },
    '22': { id: 22, name: 'measuring cups' },
    '5': { id: 5, name: 'measuring spoons' },
    '48': { id: 48, name: 'medium saucepan' },
    '88': { id: 88, name: 'metal or wooden skewers (optional)' },
    '23': { id: 23, name: 'mixing bowls' },
    '91': { id: 91, name: 'nonstick skillet' },
    '66': { id: 66, name: 'panini press or something heavy' },
    '16': { id: 16, name: 'potato masher' },
    '36': { id: 36, name: 'salad spinner (optional)' },
    '14': { id: 14, name: 'small saucepan' },
    '18': { id: 18, name: 'spatula' },
    '59': { id: 59, name: 'stainless steel or cast iron skillet' },
    '68': { id: 68, name: 'stainless steel sauté pan' },
    '27': { id: 27, name: 'stainless steel skillet' },
    '13': { id: 13, name: 'stirring spoon' },
    '26': { id: 26, name: 'vegetable peeler' },
    '42': { id: 42, name: 'whisk or fork' },
    '124': { id: 124, name: 'muffin pan' },
    '126': { id: 126, name: 'slotted spoon' },
    '125': { id: 125, name: 'parchment paper' },
    '127': { id: 127, name: 'plastic wrap' },
    '130': { id: 130, name: 'strainer' },
    '128': { id: 128, name: 'rolling pin' },
    '129': { id: 129, name: 'silicone spatula' },
    '131': { id: 131, name: 'pastry brush (optional)' },
    '132': { id: 132, name: 'muffin pan paper liners' },
    '134': { id: 134, name: 'hand blender (optional)' },
    '135': { id: 135, name: 'ice pop mold (3.3 oz capacity)' },
    '137': { id: 137, name: 'steamer basket trivet (6QT)' },
    '138': { id: 138, name: 'springform pan (6QT)' },
    '139': { id: 139, name: 'short steam rack trivet (6QT)' },
    '140': { id: 140, name: 'tall steam rack trivet (6QT)' },
    '141': { id: 141, name: 'multicooker (6QT)' },
    '142': { id: 142, name: 'multicooker (8QT)' },
    '136': { id: 136, name: 'egg bite mold (6QT)' },
    '143': { id: 143, name: 'steamer basket' },
    '144': { id: 144, name: 'ice cube tray' },
    '145': { id: 145, name: 'silicone baking mat' },
    '147': { id: 147, name: 'loaf pan' },
    '149': { id: 149, name: 'regular blender or food processor' },
    '151': { id: 151, name: 'food processor or potato masher' },
    '152': { id: 152, name: 'food processor (optional)' },
    '146': { id: 146, name: 'ramekins (6 oz capacity)' },
    '148': { id: 148, name: 'resealable plastic bag' },
    '150': { id: 150, name: 'food processor' }
  },
  menus: {
    '4': {
      id: 4,
      name: 'Pescatarian',
      description: 'Vegetarian + seafood',
      position: 7,
      isActive: true
    },
    '7': {
      id: 7,
      name: 'Low Carb',
      description: 'Easy on the carbs',
      position: 2,
      isActive: true
    },
    '8': {
      id: 8,
      name: 'Keto',
      description: 'Ultra low carb',
      position: 3,
      isActive: true
    },
    '5': {
      id: 5,
      name: 'Paleo',
      description: 'Eat like a caveman',
      position: 5,
      isActive: true
    },
    '6': {
      id: 6,
      name: 'Vegan',
      description: 'Only plants',
      position: 8,
      isActive: true
    },
    '1': {
      id: 1,
      name: 'Classic',
      description: 'No holds barred',
      position: 1,
      isActive: true
    },
    '3': {
      id: 3,
      name: 'Flexitarian',
      description: 'Easy on the meat',
      position: 4,
      isActive: true
    },
    '2': {
      id: 2,
      name: 'Vegetarian',
      description: 'Zero meat',
      position: 6,
      isActive: true
    }
  },
  varietyTags: {
    '6': { id: 6, name: 'Curries' },
    '7': { id: 7, name: 'Frittatas' },
    '8': { id: 8, name: 'Fritters \u0026 Cakes' },
    '11': { id: 11, name: 'Stir-Fries' },
    '14': { id: 14, name: 'Steaks' },
    '15': { id: 15, name: 'Chops' },
    '2': { id: 2, name: 'Soups, Stews \u0026 Chilis' },
    '25': { id: 25, name: 'Bowls' },
    '20': { id: 20, name: 'Main-Course Salads' },
    '26': { id: 26, name: 'Stuffed Vegetables' },
    '22': { id: 22, name: 'BBQ \u0026 Grilling' },
    '29': { id: 29, name: 'Low-Carb "Pastas"' },
    '32': { id: 32, name: 'Skillets \u0026 Sautés' },
    '30': { id: 30, name: 'Wings \u0026 Tenders' },
    '4': { id: 4, name: 'Burgers \u0026 Sandwiches' },
    '24': { id: 24, name: 'Baked' },
    '33': { id: 33, name: 'Pan-Fried' },
    '27': { id: 27, name: 'Wraps' },
    '1': { id: 1, name: 'Pasta \u0026 Pizza' },
    '18': { id: 18, name: 'Fried Rice \u0026 Noodles' },
    '12': { id: 12, name: 'Tacos \u0026 Quesadillas' }
  }
}

export default db
