import { React, M, styled } from '../common'
import * as Admin from '../../services/admin'

interface IProps {
  recipeId: string
}

interface IState {
  result?: Admin.IRecipeStats
}

export default class StatsReport extends React.Component<IProps, IState> {
  state: IState = {
    result: undefined
  }

  componentDidMount() {
    this.loadStats()
  }

  loadStats = async () => {
    const { recipeId } = this.props
    const result = await Admin.getStats(recipeId)
    this.setState({ result })
  }

  renderResult = (result: Admin.IRecipeStats) => {
    return (
      <M.Grid container spacing={24}>
        <M.Grid item xs={6}>
          <Stat>
            <Title>{formatPercent(result.rating)}</Title>
            <Body>rating</Body>
            <Info>Based on {result.rating_count} ratings</Info>
          </Stat>
        </M.Grid>

        <M.Grid item xs={6}>
          <Stat>
            <Title>{result.favourite_count}</Title>
            <Body>times favourited this week</Body>
          </Stat>
        </M.Grid>
      </M.Grid>
    )
  }

  render() {
    const { result } = this.state

    if (result) {
      return this.renderResult(result)
    } else {
      return <M.CircularProgress />
    }
  }
}

const Stat = styled(M.Paper)`
  padding: 16px;
`

const Title = styled(M.Typography).attrs({
  variant: 'display2'
})``

const Body = styled(M.Typography).attrs({
  variant: 'subheading'
})``

const Info = styled(M.Typography).attrs({
  variant: 'caption'
})``

function formatPercent(x: number): string {
  return Math.round(1000 * x) / 10 + '%'
}
