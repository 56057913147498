import * as RecipeCopier from '../../services/recipe-copier'
import {
  React,
  styled,
  M,
  Navbar,
  Recipe,
  IAction,
  ISceneProps,
  IRecipe,
  IVariant,
  Page,
  FabPlus,
  Modal,
  Variant,
  Util,
  IUser,
  Icon
} from '../common'
import ImageUploadModal from '../../views/image-upload-modal'
import NewVariant from './new-variant'
import Edit from './edit'
import PublishModal from './publish-modal'
import AuthorLine from './author-line'
import VariantList from './variant-list'
import StateLine from './state-line'

interface IProps extends ISceneProps {
  recipe: IRecipe
  variants: IVariant[]
  onBack: () => void
}

interface IState {
  newVariantModal: boolean
  editTitleModal: boolean
  publishModal: boolean
  imageModal: boolean
}

export default class Content extends React.Component<IProps, IState> {
  state: IState = {
    newVariantModal: false,
    editTitleModal: false,
    publishModal: false,
    imageModal: false
  }

  handleBack = () => {
    this.props.onBack()
  }

  handleEditVariant = (id: string) => {
    const { nav } = this.props
    nav.go('Variant', { id })
  }

  handleCopyVariant = (id: string) => {
    const variant = this.props.variants.find(v => v.id == id)

    if (!variant) {
      throw new Error(`Variant not found '${id}`)
    }

    const copy = Variant.duplicate(variant, this.props.variants.length)
    this.props.store.setVariant(copy)
  }

  setRecipe = (recipe: IRecipe) => {
    this.props.store.setRecipe(recipe)
  }

  toggleEditTitleModal = () => {
    this.setState({ editTitleModal: !this.state.editTitleModal })
  }

  toggleNewVariantModal = () => {
    this.setState({ newVariantModal: !this.state.newVariantModal })
  }

  toggleImageModal = () => {
    this.setState({ imageModal: !this.state.imageModal })
  }

  togglePublishModal = () => {
    this.setState({ publishModal: !this.state.publishModal })
  }

  handleSubmitImageUrl = (imageUrl: string, thumbnailUrl: string) => {
    const { store, recipe } = this.props
    store.setRecipe(Recipe.setImage(recipe, imageUrl, thumbnailUrl))
    this.toggleImageModal()
  }

  handleDuplicate = async () => {
    const { store, recipe, currentUser, variants } = this.props

    if (!confirm('Are you sure you want to duplicate this recipe?')) {
      return
    }

    const newRecipe = RecipeCopier.copyRecipe(recipe, {
      authorId: currentUser.id
    })
    await store.setRecipe(newRecipe)
    console.log(`Created new recipe '${newRecipe.title}'...`)

    const newVariants = RecipeCopier.copyVariants(variants, newRecipe.id)

    await store.setVariants(newVariants)
    console.log('Created new variants...')

    alert('Successfully duplicated recipe')
  }

  handleArchive = () => {
    const { store, recipe } = this.props

    if (confirm('Are you sure you want to archive this recipe?')) {
      store.setRecipe({
        ...recipe,
        isDeleted: true
      })
    }
  }

  handleRestore = () => {
    const { store, recipe } = this.props

    store.setRecipe({
      ...recipe,
      isDeleted: false
    })
  }

  handleSortVariants = (source: number, destination: number) => {
    let variants = Variant.sortByRank(this.props.variants)
    variants = Util.reorder(variants, source, destination)

    variants = variants.map((v, rank) => ({
      ...v,
      rank
    }))

    this.props.store.setVariants(variants)
    this.props.store.touchRecipe(this.props.recipe.id)
  }

  handleShowStats = () => {
    const { nav, recipe } = this.props
    nav.go('RecipeStats', { recipeId: recipe.id, recipeTitle: recipe.title })
  }

  handleChecker = () => {
    const { nav, recipe } = this.props
    nav.go('RecipeChecker', { recipeId: recipe.id })
  }

  createNewVariant = async (label: string) => {
    const { store, nav, recipe, variants } = this.props

    const variant = Variant.make(recipe.id, label, variants.length)
    await store.setVariant(variant)

    nav.go('Variant', { id: variant.id })
  }

  getActions(): IAction[] {
    const { recipe } = this.props

    const actions: IAction[] = [
      {
        label: 'Run checker',
        icon: 'Spellcheck',
        onClick: this.handleChecker
      },
      {
        label: 'Edit recipe',
        icon: 'Edit',
        onClick: this.toggleEditTitleModal
      },
      {
        label: 'Upload an image',
        icon: 'AddAPhoto',
        onClick: this.toggleImageModal
      },
      {
        label: 'Duplicate',
        icon: 'FileCopy',
        onClick: this.handleDuplicate
      },
      recipe.isDeleted
        ? {
            label: 'Restore',
            icon: 'Unarchive',
            onClick: this.handleRestore
          }
        : {
            label: 'Archive',
            icon: 'Archive',
            onClick: this.handleArchive
          }
    ]

    if (!!this.props.recipe.publishedAt) {
      actions.unshift({
        label: 'Show stats',
        icon: 'ShowChart',
        onClick: this.handleShowStats
      })
    }

    if (this.props.currentUser.isEditor) {
      actions.push({
        label: 'Publish changes',
        icon: 'CloudUpload',
        onClick: this.togglePublishModal
      })
    }

    return actions
  }

  getAuthor = (): IUser => {
    return this.props.users[this.props.recipe.authorId]
  }

  render() {
    const { recipe, store, variants, currentUser } = this.props

    return (
      <Container>
        {this.props.drawer}
        <Navbar
          onBack={this.handleBack}
          title={recipe.title}
          actions={this.getActions()}
          onToggleDrawer={this.props.onToggleDrawer}
        />
        <Page>
          <Body>
            <Details>
              <Image recipe={recipe} />
              <AuthorLine
                user={this.getAuthor()}
                createdAt={recipe.createdAt}
              />
              <StateLine recipe={recipe} />
            </Details>

            <VariantList
              variants={variants}
              onSort={this.handleSortVariants}
              onEdit={this.handleEditVariant}
              onCopy={this.handleCopyVariant}
            />
          </Body>

          <FabPlus onClick={this.toggleNewVariantModal} />

          <Modal
            open={this.state.newVariantModal}
            onClose={this.toggleNewVariantModal}
          >
            <NewVariant
              onSubmit={this.createNewVariant}
              onClose={this.toggleNewVariantModal}
            />
          </Modal>

          <Modal
            open={this.state.editTitleModal}
            onClose={this.toggleEditTitleModal}
          >
            <Edit
              recipe={recipe}
              onChange={this.setRecipe}
              onClose={this.toggleEditTitleModal}
            />
          </Modal>

          <Modal
            open={this.state.publishModal}
            onClose={this.togglePublishModal}
          >
            <PublishModal
              recipe={recipe}
              variants={variants}
              store={store}
              currentUser={currentUser}
              onClose={this.togglePublishModal}
            />
          </Modal>

          <ImageUploadModal
            // We accidentally uploaded recipe images to a redundant
            // directory. Maintain backwards compatibility.
            path="recipe_images/recipe_images"
            isVisible={this.state.imageModal}
            onClose={this.toggleImageModal}
            uploadFile={store.uploadFile}
            onSubmit={this.handleSubmitImageUrl}
          />
        </Page>
      </Container>
    )
  }
}

const Image = ({ recipe }: { recipe: IRecipe }) => {
  if (!recipe.imageUrl) {
    return (
      <M.Paper square style={{ height: 300 }}>
        <PlaceholderImage style={{ backgroundColor: Recipe.getColor(recipe) }}>
          <Icon.Restaurant
            style={{ width: 64, height: 64, color: 'rgba(255,255,255,0.8)' }}
          />
        </PlaceholderImage>
      </M.Paper>
    )
  } else {
    return (
      <M.Paper square style={{ height: 300 }}>
        <img style={{ width: 300, height: 300 }} src={recipe.imageUrl} />
      </M.Paper>
    )
  }
}

const Container = styled.div`
  background-color: #f3f3f3;
`

const PlaceholderImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  opacity: 0.4;
`

const Details = styled.div`
  margin-right: 32px;
  max-width: 340px;
`

const Body = styled.div`
  display: flex;
  margin: 24px 24px 64px;
`
