import * as AutoGlutenFree from '../../services/auto-gluten-free'
import { React, M, IVariant, Variant } from '../common'
import { round } from './util'

interface IProps {
  variant: IVariant
  onChange: (variant: IVariant) => void
}

export default class MakeGlutenFreeField extends React.PureComponent<IProps> {
  handleChange = (makeGlutenFreeCopy: boolean) => {
    const { variant, onChange } = this.props

    onChange({
      ...variant,
      makeGlutenFreeCopy
    })
  }

  renderNutritionChanges(variant: IVariant) {
    const orig = Variant.compileNutrition(variant)
    const gf = Variant.compileNutrition(
      AutoGlutenFree.convertVariantToGlutenFree(variant)
    )
    return (
      <ul>
        <li>
          Calories: {round(orig.energy || 0)} -> {round(gf.energy || 0)}
        </li>
        <li>
          Fat: {round(orig.fat || 0, 1)}g -> {round(gf.fat || 0, 1)}g
        </li>
        <li>
          Protein: {round(orig.protein || 0, 1)}g -> {round(gf.protein || 0, 1)}
          g
        </li>
        <li>
          Carbs: {round(orig.carbs || 0, 1)}g -> {round(gf.carbs || 0, 1)}g
        </li>
      </ul>
    )
  }

  renderCheckbox() {
    const { variant } = this.props
    return (
      <>
        <M.Typography variant="body1">
          We can automatically create a gluten-free version of this variant when
          publishing.
          <br />
          <br />
          Nutrition changes:
          <br />
          {this.renderNutritionChanges(variant)}
        </M.Typography>
        <M.FormControlLabel
          control={
            <M.Checkbox
              checked={variant.makeGlutenFreeCopy}
              onChange={(_, isChecked) => this.handleChange(isChecked)}
              color="primary"
            />
          }
          label="Make a gluten-free variant"
        />
      </>
    )
  }

  renderContent() {
    const { variant } = this.props
    if (AutoGlutenFree.variantHasGluten(variant)) {
      if (AutoGlutenFree.canMakeVariantGlutenFree(variant)) {
        return this.renderCheckbox()
      } else {
        return <CantConvertMessage />
      }
    } else {
      return <GlutenFreeMessage />
    }
  }

  render() {
    return (
      <M.Card>
        <M.CardContent>
          <M.Typography color="textSecondary" gutterBottom>
            Gluten allergy
          </M.Typography>
          {this.renderContent()}
        </M.CardContent>
      </M.Card>
    )
  }
}

function GlutenFreeMessage() {
  return (
    <M.Typography variant="body1" component="h2">
      This variant is gluten-free.
    </M.Typography>
  )
}

function CantConvertMessage() {
  return (
    <M.Typography variant="body1">
      We cannot automatically create a gluten-free version of this variant.
    </M.Typography>
  )
}
