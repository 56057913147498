import { IRecipe, IVariant, IInstruction, IUnitFamily } from '../../types'
import * as Variant from './variant'
import * as Admin from './admin'
import * as AutoGlutenFree from './auto-gluten-free'

const RECIPE_CATEGORIES: { [k: string]: number } = {
  meat: 4,
  fish: 5,
  vegetarian: 6
}

export function validate(recipe: IRecipe, variants: IVariant[]): string[] {
  // Disable validation rules for CPG recipes for now, until we uncover
  // good restrictions.
  if (recipe.ruleset == 'cpg') {
    return []
  }

  const isBreakfast = recipe.ruleset == 'breakfast'
  const isDessert = recipe.ruleset == 'dessert'
  const isSimple = recipe.ruleset == 'simple'
  const isSnack = recipe.ruleset == 'snack'

  const errors: string[] = []

  // `title` must be 25-70 characters
  if (recipe.title.length < 25 || recipe.title.length > 70) {
    errors.push('Title must be between 25-70 characters')
  }

  // `imageUrl` must be present
  if (!recipe.imageUrl) {
    errors.push('You must upload an image')
  }

  // Must have (at least) a 2/4 serving of every label
  const groups = groupBy(variants, v => v.label)
  for (const label in groups) {
    if (groups[label].length < 2) {
      errors.push(`Must have a 2 and 4 serving variant of ${label}`)
    }
  }

  for (const variant of variants) {
    const id = `${variant.label}x${variant.servingCount}`

    // `category` must be present
    if (!variant.category) {
      errors.push(`Category is missing on variant ${id}`)
    }

    // Must select at least 1 allowed menu ids
    if (variant.allowedMenuIds.length == 0) {
      errors.push(`Must select at least 1 allowed menu for ${id}`)
    }

    // Must select at least 1 cookware
    if (variant.cookwareIds.length == 0) {
      errors.push(`Must select at least 1 cookware for ${id}`)
    }

    if (isBreakfast) {
      // Breakfast recipes must take 5 - 30 minutes
      if (variant.cookingMinutes < 5 || variant.cookingMinutes > 30) {
        errors.push(
          `Cooking time for breakfast recipes must be between 5-30 minutes for ${id}`
        )
      }
    } else if (isDessert) {
      // Dessert recipes must take 10 - 45 minutes
      if (variant.cookingMinutes < 10 || variant.cookingMinutes > 45) {
        errors.push(
          `Cooking time for dessert recipes must be between 10-45 minutes for ${id}`
        )
      }
    } else if (isSimple) {
      // Dessert recipes must take 15 - 45 minutes
      if (variant.cookingMinutes < 15 || variant.cookingMinutes > 45) {
        errors.push(
          `Cooking time for simple recipes must be between 15-45 minutes for ${id}`
        )
      }
    } else if (isSnack) {
      // Snack recipes must take 5 - 45 minutes
      if (variant.cookingMinutes < 5 || variant.cookingMinutes > 45) {
        errors.push(
          `Cooking time for snack recipes must be between 5-45 minutes for ${id}`
        )
      }
    } else {
      // Dinner recipes must take 20 - 45 minutes
      if (variant.cookingMinutes < 20 || variant.cookingMinutes > 45) {
        errors.push(
          `Cooking time for dinner recipes must be between 20-45 minutes for ${id}`
        )
      }
    }

    const instructionCount = variant.instructions.length
    if (isBreakfast || isDessert || isSnack) {
      // Breakfast and dessert recipes must have at least 3 instructions
      if (instructionCount < 3) {
        errors.push(`Must have at least 3 instructions for ${id}`)
      }
    } else {
      // Dinner recipes must have at least 5 instructions
      if (instructionCount < 5) {
        errors.push(`Must have at least 5 instructions for ${id}`)
      }
    }

    const calories = Variant.compileNutrition(variant).energy || 0
    if (isBreakfast) {
      // Breakfast must have been 200-550 calories (actual guideline is 200-500)
      if (calories < 200 || calories > 550) {
        errors.push(
          `Calories must be between 200-550 per serving for ${id}, but it was '${calories}'.`
        )
      }
    } else if (isDessert) {
      // Dessert must have been 200-750 calories
      if (calories < 200 || calories > 750) {
        errors.push(
          `Calories must be between 200-750 per serving for ${id}, but it was '${calories}'.`
        )
      }
    } else if (isSnack) {
      // Snack must have been 150-360 calories
      if (calories < 150 || calories > 360) {
        errors.push(
          `Calories must be between 150-360 per serving for ${id}, but it was '${calories}'.`
        )
      }
    } else {
      // Dinner must have between 300-800 calories (actual guideline is 500-700)
      if (calories < 300 || calories > 800) {
        errors.push(
          `Calories must be between 300-800 per serving for ${id}, but it was '${calories}'.`
        )
      }
    }
  }

  return errors
}

export function makePayload(options: {
  recipe: IRecipe
  variants: IVariant[]
  userId: string
  note: string
}): Admin.IPublishPayload {
  return {
    recipe: makeAdminRecipe(options.recipe),
    variants: appendConvertedVariants(options.variants).map(makeAdminVariant),
    user_id: options.userId,
    note: options.note
  }
}

function appendConvertedVariants(variants: IVariant[]): IVariant[] {
  const all = [...variants]

  for (const variant of variants) {
    // Create a gluten-free variant if requested and able.
    if (
      variant.makeGlutenFreeCopy &&
      AutoGlutenFree.variantHasGluten(variant) &&
      AutoGlutenFree.canMakeVariantGlutenFree(variant)
    ) {
      all.push(AutoGlutenFree.convertVariantToGlutenFree(variant))
    }
  }

  // Create a metric variant for all variants (including auto-gluten-free
  // ones).
  // Always create a metric variant.
  return [...all, ...all.map(Variant.convertToMetric)]
}

function makeAdminRecipe(r: IRecipe): Admin.IRecipe {
  const adminRecipe: Admin.IRecipe = {
    chef_user_id: r.authorId,
    chef_id: r.id,
    name: r.title,
    is_pro: !!r.isPro,
    ruleset: r.ruleset,
    variety_tag_ids: r.varietyTagIds || []
  }

  // Only include the image in the payload if it is "new" since the last
  // publishing, or the recipe is unpublished.
  // Otherwise the API won't know that it is the same, and will be forced
  // to upload a new version.
  if (r.hasNewImage || !r.publishedAt) {
    adminRecipe.remote_image_url = r.imageUrl as string
  }

  return adminRecipe
}

function makeAdminVariant(v: IVariant): Admin.IVariant {
  const adminVariant: Admin.IVariant = {
    chef_id: v.id,
    name: v.title.length > 0 ? v.title : undefined,
    group_label: v.label,
    position: v.rank,
    unit_family_id: v.unitFamily == 'metric' ? 1 : 2,
    recipe_category_id: RECIPE_CATEGORIES[v.category as string],
    serving_count: v.servingCount,
    cooking_minutes: v.cookingMinutes,
    cookware_ids: v.cookwareIds,
    allowed_type_ids: v.allowedMenuIds,
    line_items_attributes: makeAdminLineItems(v),
    instructions_attributes: v.instructions.map(i =>
      makeAdminInstruction(i, v.unitFamily || 'us')
    )
  }

  if (v.cookingTip) {
    adminVariant.cooking_tip = v.cookingTip
  }

  // Only include the image in the payload if it is "new" since the last
  // publishing. Otherwise the API won't know that it is the same, and
  // will be forced to upload a new version.
  if (v.hasNewImage) {
    adminVariant.remote_image_url = v.imageUrl
  }

  return adminVariant
}

function makeAdminLineItems(v: IVariant): Admin.ILineItem[] {
  const lineItems = Variant.compileLineItems(v)
  return lineItems.map(li => ({
    amount: li.amount,
    ingredient_id: li.ingredientId
  }))
}

function makeAdminInstruction(
  instruction: IInstruction,
  unitFamily: IUnitFamily
): Admin.IInstruction {
  return {
    primary_message: instruction.message,
    secondary_message: Variant.compileSecondaryMessage(
      instruction.lineItems,
      unitFamily
    )
  }
}

function groupBy<T>(xs: T[], f: (x: T) => string): { [k: string]: T[] } {
  const groups: { [k: string]: T[] } = {}

  for (const x of xs) {
    const v = f(x)

    if (!groups[v]) {
      groups[v] = []
    }

    groups[v].push(x)
  }

  return groups
}
