import { React, M, withTargetValue, IVariant } from '../common'

interface IProps {
  variant: IVariant
  onChange: (variant: IVariant) => void
}

interface IState {
  cookingTip: string
}

export default class CookingTipField extends React.Component<IProps, IState> {
  state: IState = {
    cookingTip: this.props.variant.cookingTip || ''
  }

  handleChange = (cookingTip: string) => {
    const { variant, onChange } = this.props

    this.setState({ cookingTip })

    onChange({
      ...variant,
      cookingTip: cookingTip
    })
  }

  render() {
    const { cookingTip } = this.state
    const tipLength = cookingTip.length

    return (
      <M.TextField
        label="Cooking Tip"
        // NOTE: We use an uncontrolled input because the round-trip
        // to firebase seems to cause the cursor to jump to the end when editing.
        // Possible React issue. This fix means that the field won't update live.
        defaultValue={cookingTip}
        onChange={withTargetValue(this.handleChange)}
        margin="normal"
        helperText={`${tipLength} characters`}
        error={tipLength > 175}
        multiline
        fullWidth
      />
    )
  }
}
