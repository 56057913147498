import * as React from 'react'
import {
  ISceneProps,
  styled,
  M,
  Navbar,
  Page,
  Util,
  Avatar,
  FabPlus,
  Modal,
  IUserData
} from '../common'
import NewUser from './new-user'

interface IProps extends ISceneProps {}

interface IState {
  newUser: boolean
}

export default class Team extends React.Component<IProps> {
  state: IState = {
    newUser: false
  }

  toggleNewUser = () => {
    this.setState({ newUser: !this.state.newUser })
  }

  submitNewUser = async (userData: IUserData) => {
    if (await this.props.store.createUser(userData)) {
      this.setState({ newUser: false })
    } else {
      alert("Couldn't create user")
    }
  }

  render() {
    return (
      <>
        {this.props.drawer}

        <Navbar title="Team" onToggleDrawer={this.props.onToggleDrawer} />

        <Page>
          <M.Card
            style={{
              width: 800,
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 32
            }}
          >
            <M.CardContent>
              {Util.values(this.props.users).map(user => (
                <UserLine key={user.id}>
                  <Avatar user={user} size={40} />
                  <Content>
                    <Title>{user.displayName}</Title>
                    <Caption>{user.email}</Caption>
                  </Content>
                </UserLine>
              ))}
            </M.CardContent>
          </M.Card>

          <FabPlus onClick={this.toggleNewUser} />

          <Modal open={this.state.newUser} onClose={this.toggleNewUser}>
            <NewUser
              onSubmit={this.submitNewUser}
              onClose={this.toggleNewUser}
            />
          </Modal>
        </Page>
      </>
    )
  }
}

const UserLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

const Content = styled.div`
  margin-left: 8px;
`

const Title = styled(M.Typography).attrs({ variant: 'title' })``

const Caption = styled(M.Typography).attrs({ variant: 'caption' })``
