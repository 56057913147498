import { React, Util } from '../common'

export default class TimeAgo extends React.Component<
  {
    startTime: number
  },
  {
    currentTime: number
  }
> {
  interval: number | null = null

  constructor(props: any) {
    super(props)

    this.state = {
      currentTime: Date.now()
    }
  }

  componentDidMount() {
    this.interval = setInterval(this.tick)
  }

  componentWillUnmount() {
    this.interval && clearInterval(this.interval)
  }

  tick = () => {
    this.setState({ currentTime: Date.now() })
  }

  getPrettyDuration = () => {
    const dt = this.state.currentTime - this.props.startTime

    const sec = 1000
    const min = 60 * sec
    const hour = 60 * min

    if (dt < 10 * sec) {
      return 'just now'
    }

    if (dt < 60 * sec) {
      return 'less than a minute ago'
    }

    if (dt < 60 * min) {
      const mins = Math.floor(dt / min)
      if (mins == 1) {
        return `${mins} minute ago`
      } else {
        return `${mins} minute ago`
      }
    }

    if (dt < 24 * hour) {
      const hours = Math.floor(dt / hour)
      return `${hours} hours ago`
    }

    return Util.formatDate(new Date(this.props.startTime))
  }

  render() {
    return this.getPrettyDuration()
  }
}
